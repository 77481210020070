import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  Input,
  ViewChild,
  ElementRef
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-general-footer',
  templateUrl: './general-footer.component.html',
  styleUrls: ['./general-footer.component.scss']
})
export class GeneralFooterComponent implements OnInit {
  @ViewChild('navbar', { static: true }) navbar: ElementRef;
  @Output() backToTop = new EventEmitter();
  @Output() openSignupModal = new EventEmitter();
  @Input() footerPosition: string;
  @Input() isMobile: boolean;

  currentDate: Date = new Date();
  currentYear: number;
  el_navbar: HTMLElement;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.el_navbar = this.navbar.nativeElement as HTMLElement;

    this.currentYear = this.currentDate.getFullYear();
    if (this.footerPosition && this.footerPosition === 'relative') {
      this.el_navbar.classList.add('relative-header');
    }
  }

  onBackToTop(): void {
    this.backToTop.emit();
  }

  onOpenSignupModal(displayText: string): void {
    this.openSignupModal.emit(displayText);
  }
}
