import { of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LocalizeGuard implements CanActivate {
  attempted: boolean;

  constructor(private translate: TranslateService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    if (this.attempted) {
      return observableOf(true);
    } else {
      return new Observable<boolean>(subscriber => {
        this.translate.get('Hello', 'Hello').subscribe(
          account => {
            this.attempted = true;
            subscriber.next(true);
            subscriber.complete();
          },
          error => {
            this.attempted = true;
            subscriber.next(true);
            subscriber.complete();
          }
        );
      });
    }
  }
}
