import { Injectable } from '@angular/core';
import { ActionKind, MissionKind } from '@asksuzy/typescript-sdk';
import { GlobalIsolateSdkService } from '@suzy/shared/data-access/global-isolate-sdk';
import { Observable, of, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PipingTokenService {
  static Pattern = /`([^:]+):([^:]+):([^`]+)`/gi;

  private labelCache = {};
  private newtworkCache = [];

  private readonly cacheTime = 10 * 60 * 1000; // 10 minutes
  private readonly maxConcurentCalls = 2;
  private readonly refresh$ = new Subject<void>();

  constructor(private isolateSDK: GlobalIsolateSdkService) {}

  getLabel$(
    missionId: string,
    actionId: string,
    callerMissionId?: string,
    hidePriority?: boolean
  ): Observable<string> {
    if (!callerMissionId) {
      callerMissionId = missionId;
    }

    // check cache
    let label = this.get(callerMissionId, actionId);
    if (label) {
      return of(label);
    }

    let netwarkCall = this.newtworkCache.find(
      (x: any) => x.actionId === actionId && x.missionId === callerMissionId
    );
    if (netwarkCall) {
      return netwarkCall.subject;
    }

    const execute = () => {
      for (let i = 0; i < this.newtworkCache.length; i++) {
        if (i >= this.maxConcurentCalls) {
          break;
        }
        this.newtworkCache[i].run();
      }
    };

    const remove = (call: any) => {
      const index = this.newtworkCache.indexOf(call);
      if (index !== -1) {
        this.newtworkCache.splice(index, 1);
      }

      call.running = false;
      execute();
    };

    netwarkCall = {
      actionId,
      missionId: callerMissionId,
      running: false,
      subject: new Subject<string>(),
      run: () => {
        if (netwarkCall.running) {
          return;
        }
        netwarkCall.running = true;

        // labels can contain actionId and previous action was already deleted
        label = this.get(callerMissionId, actionId);
        if (label) {
          netwarkCall.complete(label);

          return;
        }

        if (missionId !== callerMissionId) {
          this.isolateSDK.Mission.getAvailableMissionsAsync(true).subscribe(
            response => {
              const prerequisiteAction = response.items?.length
                ? response.items[0]
                : undefined;
              if (prerequisiteAction) {
                label = this.getPipeLabel(
                  {
                    actionKind: prerequisiteAction.first_action.action_kind,
                    priority: prerequisiteAction.priority
                  },
                  {
                    missionKind: MissionKind.survey
                  }
                );
              } else {
                label = 'S:Q';
              }

              this.set(callerMissionId, actionId, label);
              netwarkCall.complete(label);
            },
            error => {
              netwarkCall.subject.error(error);
              remove(netwarkCall);
            }
          );
        } else {
          this.isolateSDK.Preview.stepsForMissionAsync(
            callerMissionId
          ).subscribe(
            response => {
              const actionByMission = response.item.find(
                x => x.first_action.action_id === actionId
              );
              const legalCount = response.item.filter(
                x => x.first_action.is_legal_consent
              ).length;

              label = this.getPipeLabel(
                {
                  actionKind: actionByMission.first_action.action_kind,
                  priority: actionByMission.priority - legalCount
                },
                undefined,
                hidePriority
              );

              this.set(callerMissionId, actionId, label);
              netwarkCall.complete(label);
            },
            error => {
              label = 'Q';
              netwarkCall.complete(label);
              remove(netwarkCall);
            }
          );
        }
      },
      complete: (actionLabel: string) => {
        netwarkCall.subject.next(actionLabel);
        netwarkCall.subject.complete();
        remove(netwarkCall);
      }
    };

    this.newtworkCache.push(netwarkCall);
    execute();

    return netwarkCall.subject;
  }

  getPipeLabel(
    action: { actionKind?: ActionKind; priority?: number },
    mission?: { missionKind?: MissionKind; priority?: number },
    hidePriorityNumber?: boolean
  ): string {
    if (hidePriorityNumber) {
      return !mission ? 'Q' : 'S:Q';
    }

    if (!action) {
      return 'Q1';
    }

    action.priority = action.priority || 1;
    let actionLabel = `Q${action.priority}`;

    if (!mission) {
      return actionLabel;
    }

    let missionLabel = '';
    switch (mission.missionKind) {
      case MissionKind.survey:
      case MissionKind.screening:
      default:
        missionLabel = `S${mission.priority || ''}:`;
    }

    switch (action.actionKind) {
      case ActionKind.multiplechoice:
        actionLabel = `MC${action.priority}`;
        break;
    }

    return `${missionLabel}${actionLabel}`;
  }

  clearCache(): void {
    this.labelCache = {};
  }

  onRefresh$(): Subject<void> {
    return this.refresh$;
  }

  refreshLabels(): void {
    this.clearCache();
    this.refresh$.next();
  }

  private get(missionId: string, actionId: string): string {
    const item = this.labelCache[`${missionId}_${actionId}`];
    if (!item) {
      return undefined;
    }

    if (new Date().getTime() - item.time > this.cacheTime) {
      delete this.labelCache[`${missionId}_${actionId}`];

      return undefined;
    }

    return item.label;
  }

  private set(missionId: string, actionId: string, label: string): void {
    this.labelCache[`${missionId}_${actionId}`] = {
      label,
      time: new Date().getTime()
    };
  }
}
