<h1>Crowdtap® Privacy Policy</h1>
<p>Updated: February 23, 2021</p>
<div>
  <p>
    This Privacy Policy describes how <strong>Suzy, Inc., </strong>the owner of
    the Crowdtap platform,<strong> </strong>and its subsidiaries and affiliates
    (collectively, “Suzy”, “we,” “our” or “us”) collect, use, and share personal
    information in connection with the
    <a href="http://www.crowdtap.com/">www.crowdtap.com</a> website, and any
    other websites and mobile applications that display this Privacy Policy
    (collectively, the “Sites,” and any, a “Site”).
  </p>
  <p>
    This Privacy Policy applies solely to information collected via the Sites
    and does <u>not</u> apply to
    <a href="https://www.asksuzy.com/">www.asksuzy.com</a> or
    <a href="http://www.suzy.com/">www.suzy.com</a> – our platform for Brands –
    which has a separate Privacy Policy.
  </p>
  <p>
    <br />
    <strong>Information Collected</strong> <br />
    <br />
    <strong><em>Information You Provide to Us</em></strong> . When using the
    Site, you may be asked to provide certain personally identifiable
    information ("Personal Information") about yourself. Personal Information
    may include your name, occupation, photo, email address, postal address,
    phone number(s), Facebook profile, Twitter handle, selected interests and
    other psychographic information, gender, date of birth, or other information
    you voluntarily provide to us when you take a survey or a poll or enter a
    contest, sweepstakes or other promotion. In some cases, we may require you
    to verify certain Personal Information you have provided to us, such as by
    presenting proof of address, in order to use certain features of the Site.
    <br />
    <br />
  </p>
  <p>
    <strong><em>Information We Automatically Collect.</em></strong> We, our
    service providers, and business partners, may also collect information about
    the use of our Sites by automated means, including via cookies, web beacons,
    and other technologies This information may include information about the
    devices used to access our Sites (such as IP address and browser and
    operating system type), dates and times of visits to, and use of our Sites,
    information about how our Sites are used (such as pages viewed, date and
    time of access, information about how users navigate on or between our
    webpages, or the features of our mobile applications that are used and how
    users navigate between screens on our mobile applications), the URLs that
    refer visitors to our Sites, and the search terms used to reach our Sites.
  </p>
  <p>
    A “cookie” is a text file that websites send to a visitor‘s computer or
    other Internet-connected device to uniquely identify the visitor’s browser
    or to store information or settings in the browser, such as the fact you
    have successfully logged in to a Site. A “web beacon,” also known as an
    Internet tag, pixel tag or clear GIF, is used to transmit information back
    to a web server. We and our service providers and business partners may
    collect information about individuals’ online activities over time and
    across third-party websites when an individual uses our websites and mobile
    apps.
  </p>
  <p>
    Because there is not yet a consensus on how companies should respond to web
    browser-based do-not-track (“DNT”) mechanisms, we do not respond to web
    browser-based DNT signals at this time.
  </p>
  <p><strong>How We Use Your Personal Information</strong></p>
  <p>We may use your Personal Information to:</p>
  <ul type="disc">
    <li>
      Communicate about the products and services we offer, including to notify
      the Member about new missions, rewards, and changes in our reward
      offerings
    </li>
    <li>Respond to requests, inquiries, comments, and suggestions</li>
    <li>Provide our products and services</li>
    <li>Analyze use of our products and services</li>
    <li>
      Operate, evaluate and improve our business, our Sites, and other products
      and services we offer (including to research and develop new products and
      services)
    </li>
    <li>Establish and maintain an individual’s profile on our Sites</li>
    <li>Administer surveys and other market research</li>
    <li>Fulfill orders and prize redemptions</li>
    <li>
      Verify your identity in connection with redeeming points or obtaining a
      reward
    </li>
    <li>Issue reward payouts and other payments</li>
    <li>
      Analyze and enhance our communications and strategies (including by
      identifying when emails we sent have been received and read)
    </li>
    <li>
      Tailor the content we display in our communications and on our Sites
    </li>
    <li>
      Comply with legal requirements, judicial process, and our company policies
    </li>
    <li>
      Protect against, identify, investigate, and respond to fraud, illegal
      activity (such as incidents of hacking or misuse of our Sites), and claims
      and other liabilities, including by enforcing the terms and conditions
      that govern the Sites
    </li>
  </ul>
  <p>
    We may aggregate any Personal Information collected, such as demographic
    information, in a manner which does not identify any one person ("Aggregate
    Information"). Survey answers and influencer scores that are not connected
    to Personal Information may also constitute Aggregate Information. We may
    collect and use data about access to and use of our Sites that we
    automatically collect as a form of Aggregate Information to determine how
    much time visitors spend on each page of our Site, how visitors navigate
    throughout the Site and how we may tailor our web pages to better meet the
    needs of visitors. We may use your Aggregate Information for our own
    legitimate business purposes, including operating and enhancing our Site,
    and for performing statistical analysis business planning, and market
    research purposes.
  </p>
  <p>
    <strong>Personal Information We Share</strong> <br />
    <br />
    We may share your Personal Information with our brand clients (“Brands”),
    affiliates, charity partners, technical consultants, third party auditors or
    other third parties who compile the Aggregate Information, make our Site
    available, enhance its functionality or provide associated services and/or
    process your orders and/or deliver content, samples, supplies, products, or
    services. For example, we may share your Personal Information with a vendor
    to verify your identity in connection with a reward redemption or to fulfill
    any incentive or reward which you have elected to receive, and that vendor
    may forward your email address to a third party to redeem any rewards. You
    may opt-out at any time in accordance with the "Terminating Membership"
    section below. <br />
    <br />
    We may share Aggregate Information with our Brands, affiliates, charity
    partners and other third parties for advertising, promotional and other
    purposes. Individual answers from you to questions within polls or missions
    marked as “confidential” may be shared with our affiliates, Brands and
    business partners but those answers will not be labeled with, or linked to,
    your Personal Information. <br />
    <br />
    Your user name, zip code, Points, profile photo, badges and levels earned,
    and some of your activity may be shared with other Site users, including
    without limitation publication in a live feed of ongoing activities and
    leader boards. You may also see such information related to other Members
    when logged into the Site. <br />
    <br />
    On the Site, we, our Brands and some of our business partners will have the
    ability to contact you regarding missions or rewards for which you qualify.
    If you post your Personal Information on the Site in a section of the Site
    where it was not explicitly requested, or outside of the Site where it may
    be viewable and/or accessible by Brands (e.g., contact information listed
    within feedback you provide, information on your Facebook profile linked to
    your account with us, or an email address you provided directly to a Brand),
    we cannot control how third parties (such as Brands) may use that
    information. <br />
    <br />
    We do not rent or sell your Personal Information to any third party without
    your consent except as otherwise disclosed in this Privacy Policy. Please
    note that we reserve the right to and may disclose Personal Information
    about you in response to (a) requests from local, state or federal law
    enforcement officials; (b) any judicial, administrative or similar
    proceeding or order, such as subpoena; (c) if required by law, regulation or
    order; or (d) to investigate suspected fraud, harassment, physical threats,
    or other violations of any law, rule or regulation, the Site rules or
    policies, or the rights of third parties to investigate any suspected
    conduct which we deem improper. Please also note that we reserve the right
    to transfer your personally identifiable information and other information
    to our successors in business and purchasers of site assets. Information
    obtained in connection with the Site may be intermingled with and used by us
    in conjunction with information obtained through sources other than the
    Site, including both offline and online sources. In addition, we may use the
    information you provide to comply with regulatory monitoring and reporting
    obligations imposed by Brands in connection with adverse events. In such
    instances, we may require further information from you in addition to
    Personal Information to comply with such regulatory monitoring and reporting
    obligations. Such information may be provided to the relevant Brand and
    governmental authorities.
  </p>
  <p>
    <strong>Advertising</strong> <br />
    <br />
    Some of the business partners that may collect information about your
    activities on our Sites may be members of organizations or programs that
    provide choices to individuals regarding the use of their browsing behavior
    for purposes of targeted advertising. For example, you may opt out of
    receiving targeted advertising through members of the Network Advertising
    Initiative by clicking
    <a href="http://optout.networkadvertising.org/">here</a> or the Digital
    Advertising Alliance by clicking
    <a href="http://www.aboutads.info/choices">here</a>. European users may opt
    out of receiving targeted advertising through members of the European
    Interactive Digital Advertising Alliance by clicking
    <a href="http://www.youronlinechoices.eu/">here</a>, selecting your country,
    and then clicking “Choices” (or similarly-titled link). Mobile app users may
    opt out of receiving targeted advertising in mobile apps through members of
    the Digital Advertising Alliance by installing the AppChoices mobile app,
    available <a href="https://www.youradchoices.com/appchoices">here</a>, and
    selecting the user’s choices. Please note that we may also work with
    companies that offer their own opt-out mechanisms and may not participate in
    the opt-out mechanisms that we linked to above. <br />
    <br />
  </p>
  <p>
    <strong>Security</strong> <br />
    <br />
    The security of your Personal Information is important to us. We put in
    place reasonable and appropriate technical and organizational measures to
    ensure your Personal Information is kept secure and protected from
    unauthorized access, use, disclosure, alteration or destruction, in
    accordance with applicable laws and regulations. When you enter sensitive
    information (such as login credentials), We encrypt the transmission of that
    information using Transport Layer Security (TLS). We follow generally
    accepted standards to protect the Personal Information submitted to us, both
    during transmission and once we receive it. When we share your Personal
    Information with Sub-Processors or other third-party service providers, we
    base our selection on said parties having adequate safeguards in place that
    meet our data protection standards. We audit their compliance with such
    standards and incorporate applicable contractual provisions ensuring
    compliance with (i) such standards and (ii) applicable data privacy laws and
    regulations. <br />
    <br />
  </p>
  <p>
    <strong>Marketing Emails and Service Announcements</strong> <br />
    <br />
    Individuals may unsubscribe from receiving marketing or other commercial
    emails from us by following the instructions included in the email. However,
    even if an individual opts out of receiving such communications, we retain
    the right to send them non-marketing communications (such as announcements
    when they become eligible for rewards or notices regarding changes in our
    Terms of Service). <br />
    <br />
  </p>
  <p>
    Under certain circumstances we may need to contact some or all Members in
    order to make an important announcement about the Site or Platform changes.
    Members are not able to unsubscribe or "opt out" of these announcements
    because they may contain very important information regarding their
    accounts. Before these measures are taken, however, we will post information
    regarding changes directly on the Site. We may also contact Members to
    correct account errors or to supply important information we deem relevant.
    <br />
    <br />
    <strong>Correcting/Updating Personal Information</strong> <br />
    <br />
    Members are able to correct or update certain Personal Information in our
    records at any time by editing the information entered on their profile
    pages. <br />
    <br />
    <strong>Protection for Children Age/Residency Requirements</strong> <br />
    <br />
    This Site is not intended for users under the age of 13, and we have no
    intention of collecting Personal Information from children under the age of
    13. Where appropriate, we take reasonable measures to inform children not to
    submit such information to our Site. If a child has provided us with
    personally identifiable information, we will use all reasonable efforts to
    delete such information from our database. You must be at least 13 years
    old, be a United States resident, and have a valid email address to join the
    Platform. <br />
    <br />
    <strong>Bulletin Boards and Chat Areas</strong> <br />
    <br />
    You should be aware that Personal Information which you voluntarily include
    and transmit online may be viewed and used by others. Users of the Site are
    solely responsible for the content of messages they post online, including
    on public forums. These forums include but are not limited to chat rooms on
    our Site or other websites, bulletin boards, blogs, social media web pages,
    or other publicly accessible forums which may be viewed and used by anyone
    with access to such forums. Users should be aware that when they voluntarily
    disclose Personal Information within a public forum that user information
    may be collected and used by others. The Site is unable to control such uses
    of your Personal Information, and by using such services you assume the risk
    that the Personal Information provided by you may be viewed and used by
    third parties.
  </p>
  <p><strong>Links to Third Party Sites</strong></p>
  <p>
    We may provide links to websites and other third-party content that we do
    not own or operate. The websites and third-party content to which we link
    may have separate privacy policies. We are not responsible for the privacy
    practices of any entity that we do not own or control.
  </p>
  <p>
    <br />
    <br />
    <strong>International Data Transfers</strong> <br />
    <br />
    We may transfer personal information to countries other than the country in
    which the data was originally collected for the purposes described in this
    Privacy Policy. For example, we transfer the Personal Information of
    individuals located outside of the United States to the United States, where
    we are headquartered. The countries to which we transfer personal
    information may not have the same data protection laws as the country in
    which we initially collected the information. By using the Site, you
    authorize the export of Personal Information to the United States for the
    purposes specified in this Privacy Policy. <br />
    <br />
  </p>
  <strong> European Data Privacy </strong>
  <p>
    Certain European Union residents have additional privacy rights as provided
    in the GDPR. For such residents, Suzy will collect, process, and store your
    personal information strictly in accordance with the GDPR. The GDPR further
    governs the transfer of subject personal information from the certain
    European Area countries outside of the European Union. Suzy is based in the
    U.S., the Site and Platform servers are hosted in the U.S., and many of
    Suzy’s suppliers and Sub-Processors are also based in the U.S. or otherwise
    outside of the European Union. In providing your Personal Information to
    Suzy, your Personal Information will be sent to the U.S. (or otherwise
    outside of the European Union). In such cases, Suzy will transfer such data
    in accordance with the GDPR. <br />
    <br />
  </p>
  <h2>Rights with Regard to Your Personal Information</h2>
  <p>
    In addition to the lawful transfer, processing and storage of your Personal
    Information, the GDPR gives certain European Union members additional rights
    over our use of your Personal Information. Suzy respects your control over
    your information and, in the event that you have provided Personal
    Information to us in your use of the Site, we will provide you with
    information about whether We hold any of your Personal Information as we
    detail below. You may access, correct, or request deletion of your Personal
    Information by contacting Us at
    <a href="mailto:support@crowdtap.com">support@crowdtap.com</a>. We will
    respond to your request within a reasonable timeframe.
  </p>
  <p>
    <strong>
      If located in the European Economic Area (“EEA”), you have the following
      rights regarding your Personal Information We control:
    </strong>
  </p>
  <ul type="disc">
    <li>
      <strong>Right of Access.</strong> <br />
      You can request details of your Personal Information we hold. We will
      confirm whether we are processing your Personal Information and we will
      disclose additional information including the types of Personal
      Information, the sources it originated from, the purpose and legal basis
      for the processing, the expected retention period and the safeguards
      regarding data transfers to non-EEA countries, subject to the limitations
      set out in applicable laws and regulations. We will provide you free of
      charge with a copy of your Personal Information but We may charge you a
      fee to cover our administrative costs if you request further copies of the
      same information.
    </li>
    <li>
      <strong>Right of correction.</strong> <br />
      At your request, we will correct incomplete or inaccurate parts of your
      Personal Information, although we may need to verify the accuracy of the
      new information you provide to Us.
    </li>
    <li>
      <strong>Right to be forgotten.</strong> <br />
      At your request, we will delete your Personal Information if:
    </li>
  </ul>
  <ol start="1" type="1">
    <li>
      it is no longer necessary for us to retain your Personal Information;
    </li>
    <li>
      you withdraw the consent which formed the legal basis for the processing
      of your Personal Information;
    </li>
    <li>
      you object to the processing of your Personal Information (see below) and
      there are no overriding legitimate grounds for such processing;
    </li>
    <li>the Personal Information was processed illegally;</li>
    <li>
      the Personal Information must be deleted for us to comply with our legal
      obligations.
    </li>
  </ol>
  <p>
    We will decline your request for deletion if processing of your Personal
    Information is necessary: (i) for us to comply with our legal obligations;
    (ii) for the establishment, exercise or defense of legal claims; or (iii)
    for the performance of a task in the public interest.
  </p>
  <ul type="disc">
    <li>
      <strong>Right to restrict processing.</strong> <br />
      At your request, we will restrict the processing of your Personal
      Information if:
    </li>
  </ul>
  <ol start="1" type="1">
    <li>you dispute the accuracy of your Personal Information;</li>
    <li>
      your Personal Information was processed illegally and you request a
      limitation on processing rather than the deletion of your Personal
      Information;
    </li>
    <li>
      We no longer need to process your Personal Information, but you need your
      Personal Information in connection with the establishment, exercise or
      defense of a legal claim; or
    </li>
    <li>
      you object to the processing of your Personal Information (see below)
      pending verification as to whether an overriding legitimate ground for
      such processing exists.
    </li>
  </ol>
  <p>
    We may continue to store your Personal Information to the extent required to
    ensure that your request to restrict processing is respected in the future.
  </p>
  <ul type="disc">
    <li>
      <strong>Right to data portability.</strong> <br />
      At your request, we will provide you free of charge with your Personal
      Information in a structured, commonly used and machine readable format,
      if:
    </li>
  </ul>
  <ol start="1" type="1">
    <li>you provided us with your Personal Information; or</li>
    <li>the processing is carried out by automated means.</li>
  </ol>
  <li>
    <strong>Right to object.</strong> <br />
    Where we rely on our legitimate interests (or that of a third party) to
    process your Personal Information, you have the right to object to this
    processing on grounds relating to your particular situation if you feel it
    impacts on your fundamental rights and freedoms. We will comply with your
    request unless we have compelling legitimate grounds for the processing
    which override your rights and freedoms, or where the processing is in
    connection with the establishment, exercise or defense of legal claims. We
    will always comply with your objection to processing your Personal
    Information for direct marketing purposes.
  </li>
  <li>
    <strong>
      Right not to be subject to decisions based solely on automated processing.
    </strong>
    <br />
    You will not be subject to decisions with a legal or similarly significant
    effect (including profiling) that are based solely on the automated
    processing of your Personal Information, unless you have given us your
    explicit consent or where they are necessary for the performance of a
    contract with us.
  </li>
  <li>
    <strong>Right to withdraw consent</strong> <br />
    You have the right to withdraw any consent you may have previously given us
    at any time. In order to exercise your rights in this section we may ask you
    for certain identifying information to ensure the security of your Personal
    Information. To request to exercise any of the above rights, please contact
    Us at
    <a href="mailto:support@crowdtap.com">support@crowdtap.com</a>. We will
    respond to your request within 30 days or provide you with reasons for the
    delay.
  </li>
  <p>
    Usually, we will not charge you any fees in connection with the exercise of
    your rights. If your request is manifestly unfounded or excessive, for
    example, because of its repetitive character, we may charge a reasonable
    fee, taking into account the administrative costs of dealing with your
    request. If we refuse your request We will notify you of the relevant
    reasons.
  </p>
  <p>
    In so far as practicable, we will notify our clients and third parties to
    whom we have disclosed your Personal Information with any correction,
    deletion, and/or restriction to the processing of your Personal Information.
    Please note that we cannot guarantee our clients or other third parties will
    comply with your requests and we encourage you to contact them directly.
  </p>
  <p>
    Please note that if you decide to exercise some of your rights, we may be
    unable to perform the actions necessary to achieve the purposes set out
    above or you may not be able to use or take full advantage of the Site and
    Platform.
  </p>
  <p>
    If you are not satisfied with our response, you have the right to complain
    or seek advice from a supervisory authority and/or bring a claim against us
    in any court of competent jurisdiction. <br />
    <br />
    <strong>Changes to Policy</strong> <br />
    <br />
    We reserve the right to change, add, or remove portions from this Privacy
    Policy at any time. When we make any updates to this Privacy Policy that are
    deemed material under applicable legal requirements, we will notify
    individuals of such changes by updating the date of this Privacy Policy and
    providing other notification as required by applicable law. We may also
    provide notification of such changes to the Privacy Policy in other ways,
    such as via email or using other contact information provided to us. For all
    other changes, please review the Privacy Policy from time to time to stay
    informed of how we are processing personal information. <br />
    <br />
    <strong> Terminating Membership <br /> </strong> <br />
    You may terminate your membership by contacting us or by using the
    “terminate membership” function if available. Upon terminating your
    membership, Suzy, will remove the following Personal Information from
    Crowdtap’s active database: Crowdtap password, member profile, full name,
    address (except for zip code), email address, other contact information,
    Facebook account information, Twitter account information, and profile
    photo. Any of your Personal Information or activity history that is not
    deleted will be attached to either your username or a unique database ID,
    not to your personally identifiable information.
  </p>
  <p>
    <strong>Site Terms and Conditions</strong> <br />
    <br />
    Use of this Site is governed by, and subject to, the legal notices contained
    in Crowdtap’s Terms of Service. Your use, or access, of the Site constitutes
    your agreement to be bound by these provisions.
  </p>
  <p><strong>California Privacy Rights</strong></p>
  <p>
    Under California Civil Code section 1798.83, California residents are
    entitled to ask us for a notice identifying the categories of personal
    customer information which we share with our affiliates and/or third parties
    for marketing purposes, and providing contact information for such
    affiliates and/or third parties. If you are a California resident and would
    like a copy of this notice, please submit a written request to the following
    address: Suzy Inc., 625 Broadway, 9th Floor, New York, NY 10012. You must
    put the statement "Your California Privacy Rights" in your request and
    include your name, street address, city, state, and ZIP code. We are not
    responsible for notices that are not labeled or sent properly, or do not
    have complete information.
  </p>
  <p>
    <strong>Comments and Questions:</strong> If you have any questions, comments
    or concerns about our Privacy Policy, you may
    <a href="mailto:support@crowdtap.com?subject=Privacy%20Policy%20Inquiry">
      contact us
    </a>
    . <br />
    <br />
    MAIL <br />
    Suzy Inc. <br />
    625 Broadway <br />
    9th Floor <br />
    New York, NY 10012 <br />
    <br />
    EMAIL <br />
    contact us <a href="http://home.crowdtap.com/privacy-policy"> <br /> </a>
    <a href="mailto:support@crowdtap.com?subject=Privacy%20Policy%20Inquiry">
      support@crowdtap.com
    </a>
    <br />
  </p>
</div>
