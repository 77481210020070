<div
  class="flex flex-col h-full action-modal-container"
  [ngClass]="{
    'fg-modal':
      kind === missionKind.focusgroup || kind === missionKind.sampling,
    'sampling-modal': kind === missionKind.sampling
  }"
  *ngIf="!isGlobal"
>
  <div
    class="flex justify-between p-6 sm:px-20 md:px-24 md:py-8 dark:text-white bg-body-light dark:bg-body-dark modal-heading"
  >
    <div class="flex">
      <span
        class="bg-card-{{ activeColor }} inline-block h-6 mr-2 rounded-md w-6 "
      ></span>
      <h1
        class="text-xs font-semibold"
        [innerHTML]="
          actionKindString | translate | markdown2html: { secure: true }
        "
        [attr.data-track]="'member-' + actionType + '-beginning-title'"
      ></h1>
    </div>
    <div class="modal-header">
      <div
        class="kind-icon"
        [ngClass]="{
          'icon-survey':
            kind === missionKind.survey || kind === missionKind.splittesting,
          'icon-sharing': kind === missionKind.sharing,
          'icon-focusGroup': kind === missionKind.focusgroup,
          'icon-sampling': kind === missionKind.sampling
        }"
      ></div>

      <button
        *ngIf="mission?.isGlobal === 'true' ? false : true"
        (click)="closeModal()"
        [attr.data-track]="'member-' + actionType + '-beginning-close'"
        type="button"
        aria-label="Close"
        class="close-modal-btn w-8 h-8 relative ring-card-{{ activeColor }}"
      >
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.4099 12.5002L19.7099 6.21019C20.1021 5.81806 20.1021 5.18231 19.7099 4.79019C19.3178 4.39806 18.6821 4.39806 18.2899 4.79019L11.9999 11.0902L5.70994 4.79019C5.31782 4.39806 4.68206 4.39806 4.28994 4.79019C3.89782 5.18231 3.89782 5.81806 4.28994 6.21019L10.5899 12.5002L4.28994 18.7902C4.10063 18.978 3.99414 19.2335 3.99414 19.5002C3.99414 19.7668 4.10063 20.0224 4.28994 20.2102C4.47771 20.3995 4.7333 20.506 4.99994 20.506C5.26658 20.506 5.52217 20.3995 5.70994 20.2102L11.9999 13.9102L18.2899 20.2102C18.4777 20.3995 18.7333 20.506 18.9999 20.506C19.2666 20.506 19.5222 20.3995 19.7099 20.2102C19.8993 20.0224 20.0057 19.7668 20.0057 19.5002C20.0057 19.2335 19.8993 18.978 19.7099 18.7902L13.4099 12.5002Z"
            fill="white"
            class="text-black fill-current dark:text-white"
          />
        </svg>
      </button>
    </div>
  </div>
  <div
    class="items-center justify-center flex-1 p-6 text-center border-t-2 border-card-{{
      activeColor
    }} sm:px-20 md:px-24 md:py-8 dark:text-white bg-box-light dark:bg-box-dark"
  >
    <div class="modal-body action-modal-body">
      <div class="modal-content">
        <h2
          class="text-sm font-normal md:text-lg lg:font-bold lg:text-xl mt-28"
          [innerHTML]="title | markdown2html: { secure: true }"
        ></h2>
        <p
          class="mt-12 text-sm font-normal"
          *ngIf="kind !== missionKind.sampling"
          [innerHTML]="description | convertMarkdownToHtml"
          [attr.data-track]="'member-' + actionType + '-beginning-description'"
        ></p>
        <p
          class="mt-12 text-sm font-normal"
          *ngIf="kind === missionKind.sampling"
          [attr.data-track]="'member-' + actionType + '-beginning-description'"
          [innerHTML]="
            mission.sampling.sampling_description_public | convertMarkdownToHtml
          "
        ></p>
        <p
          *ngIf="kind === missionKind.focusgroup"
          class="mt-12 text-xs font-semibold md:text-sm lg:text-base lg:font-medium extra-info"
        >
          <span class="ft-info"></span>
          {{ 'focusGroup.extraInfo' | translate }}
        </p>
      </div>

      <div class="relative flex justify-center space-x-2 md:space-x-16 actions">
        <button
          class="{{
            action?.prevent_skip
          }} text-sm font-bold w-36 md:text-base btn btn-secondary text-blue-dark skip"
          (click)="openSkipModal()"
          [disabled]="disableSkip || isSkipping"
          [attr.data-track]="'member-' + actionType + '-beginning-skip'"
          *ngIf="
            !mission?.prevent_skip &&
            (mission?.isGlobal === 'true' ? false : true) &&
            !isPreview
          "
          [ngClass]="{ loading: isSkipping }"
        >
          <span class="btn-label"> {{ 'general.skip' | translate }}</span>
          <span class="dot dot-1"></span>
          <span class="dot dot-2"></span>
          <span class="dot dot-3"></span>
        </button>

        <button
          class="animation-submit-btn"
          [ngClass]="{
            primarySection: !isLoading,
            loadingSection: isLoading
          }"
          (click)="getStarted()"
          [attr.data-track]="'member-' + actionType + '-beginning-get-started'"
          [disabled]="isSkipping || isLoading"
        >
          <div class="primaryTitle text" *ngIf="!isLoading">
            {{ 'general.getStarted' | translate }}
          </div>
          <div class="loadingTitle text" *ngIf="isLoading">Loading</div>
        </button>
      </div>
    </div>
  </div>
</div>
