<div class="wrapper signup-sampling" #wrapper>
  <header class="header">
    <div class="header-inner">
      <div class="header-l">
        <div class="crowdtap-logo">
          <a routerLink="/">
            <img
              src="/assets/img/svg/logo/ic-crowdtap-logo-white.svg"
              alt="White Crowdtap logo"
            />
          </a>
        </div>
        <div class="page-title is-tablet">
          {{ 'sampling.sampling' | translate }}
        </div>
      </div>
      <div class="header-r">
        <ul>
          <li>
            <a class="btn btn-primary" routerLink="/auth/login">{{
              'auth.login' | translate
            }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="header-content">
      <h1>{{ 'sampling.getYourFreeAveenoSamples' | translate }}</h1>
      <p class="sub-title">{{ 'sampling.signUpToApply' | translate }}</p>
    </div>
    <div class="link-support">
      <a (click)="aboutClick(aboutDialog)" href="javascript:;"
        >{{ 'auth.learnMoreAboutCrowdtap' | translate }}&reg;
        <img
          src="assets/img/svg/icons/ic-arrow-next-circle-next-right.svg"
          alt="Next icon"
        />
      </a>
    </div>
  </header>

  <div class="main-content" aria-label="main" role="main">
    <div class="fixdW flexMinH">
      <section>
        <div class="content">
          <h5>
            {{ 'sampling.positivelyRadiantDailyMoisturizer' | translate }}
          </h5>
          <p
            [innerHTML]="
              'sampling.positivelyRadiantDailyMoisturizerText' | translate
            "
          ></p>
        </div>
        <div class="actions">
          <app-social-signin label="Sign Up with Facebook"></app-social-signin>
          <div class="divider">
            <div></div>
            <div>{{ 'general.or' | translate }}</div>
            <div></div>
          </div>
          <a class="signup-email" routerLink="/auth/account-creation-email">{{
            'auth.useMyEmail' | translate
          }}</a>
          <div class="bottom-link">
            <p class="text-center">
              {{ 'auth.alreadyAMember' | translate }}
              <a
                routerLink="/auth/login"
                data-track="home-btn-login-already-member"
                >{{ 'auth.login' | translate }}</a
              >
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>

  <ng-template #aboutDialog let-c="close" let-d="dismiss">
    <div class="about-crowdtap">
      <div class="modal-header">
        <h4 class="logo">
          <img
            src="/assets/img/svg/logo/ic-crowdtap-logo.svg"
            alt="Crowdtap logo"
          />
        </h4>
        <button
          type="button"
          class="close modal-close"
          aria-label="Close"
          (click)="d('Close')"
        >
          <span class="sr-only" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="content">
          <div class="flex">
            <div class="body-text">
              <h3>{{ 'sampling.yourOpinionCounts' | translate }}</h3>
              <p
                [innerHTML]="
                  'sampling.offersUpThousandsOfQuestions' | translate
                "
              ></p>
            </div>
            <div class="testimonial">
              <div class="item">
                <div class="user-quote">
                  <p [innerHTML]="'sampling.userQoute' | translate"></p>
                </div>
                <div class="user">
                  <div class="user-avatar">
                    <img
                      src="/assets/img/portrait/avatars/user-sample-image-1.png "
                      alt="user avatar"
                    />
                  </div>
                  <span class="user-name">Bill E</span>
                </div>
              </div>
            </div>
          </div>
          <div class="benefits">
            <div class="row">
              <div class="item">
                <div class="ic-gift"></div>
                <div class="text">
                  <h5>{{ 'sampling.earnGiftCards' | translate }}</h5>
                  <p>{{ 'sampling.earnGiftCardsText' | translate }}</p>
                </div>
              </div>
              <div class="item">
                <div class="ic-gift"></div>
                <div class="text">
                  <h5>{{ 'sampling.getFreeSamples' | translate }}</h5>
                  <p>{{ 'sampling.getFreeSamplesText' | translate }}</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="item">
                <div class="ft-check"></div>
                <div class="text">
                  <h5>{{ 'sampling.freeToJoin' | translate }}</h5>
                  <p>{{ 'sampling.freeToJoinText' | translate }}</p>
                </div>
              </div>
              <div class="item">
                <div class="ft-check"></div>
                <div class="text">
                  <h5>{{ 'sampling.stickWithIt' | translate }}</h5>
                  <p>
                    {{ 'sampling.stickWithItText' | translate }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="link-new-member">
            <a
              class="btn btn-primary form-btn-with-arrow"
              routerLink="/auth/signup"
              (click)="d('Close')"
              [innerHTML]="'auth.newToCrowdtap' | translate"
            ></a>
          </div>
          <div class="bottom-link">
            <p>
              {{ 'auth.alreadyAMember' | translate }}
              <a routerLink="/auth/login" (click)="d('Close')">{{
                'auth.login' | translate
              }}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <app-general-footer [footerPosition]="'relative'" (backToTop)="onBackToTop()">
  </app-general-footer>
</div>
