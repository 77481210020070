import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'terms-of-service-policy-current',
  templateUrl: './terms-of-service-policy-current.component.html',
  styleUrls: ['./terms-of-service-policy-current.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermsOfServicePolicyCurrentComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
