<div class="flex flex-col h-full">
  <div
    class="external-main-content items-center justify-center flex-1 p-6 text-center sm:px-20 md:px-24 md:py-8 dark:text-white bg-grey-FA dark:bg-box-dark"
  >
    <div class="modal-body action-modal-body">
      <div class="modal-content">
        <img
          [src]="logoPath"
          [alt]="mission?.mission_logo_alt_text"
          class="logo"
        />
        <h2
          class="text-2xl font-semibold lg:font-bold lg:text-3xl"
          [innerHTML]="title | markdown2html : { secure: true }"
        ></h2>
        <p
          [innerHTML]="description | convertMarkdownToHtml"
          class="text-xl font-medium my-6 md:mb-8 lg:text-2xl"
        ></p>
        <button
          class="animation-submit-btn"
          (click)="getStarted()"
          [attr.data-track]="'member-external-beginning-get-started'"
          [ngClass]="{
            primarySection: !isLoading,
            loadingSection: isLoading
          }"
          [disabled]="isLoading"
        >
          <div class="primaryTitle" *ngIf="!isLoading">
            {{ 'general.getStarted' | translate }}
          </div>
          <div class="loadingTitle" *ngIf="isLoading">
            {{ 'general.loading' | translate }}
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
