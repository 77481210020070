<div class="action-modal-container">
  <suzy-spinner
    *ngIf="isSubmitting"
    text="true"
    negativeMarginTop="true"
  ></suzy-spinner>

  <app-action-modal-header [mission]="mission"></app-action-modal-header>

  <div class="action-modal-question">
    <h3>{{ action.bazaar_voice.headline }}</h3>
  </div>

  <div class="modal-body action-modal-body">
    <form (submit)="onSubmit()">
      <!-- Form Content Starts -->
      <div class="form">
        <div class="form-group form-filling">
          <div class="description">
            <p>{{ action.bazaar_voice.instruction }}</p>
          </div>
          <fieldset *ngIf="action.bazaar_voice.recommend_options">
            <h4>{{ 'review.wouldYouRecommend' | translate }}</h4>
            <div class="form-group mb-3">
              <div class="form-row">
                <div
                  class="col-sm-3 col-6"
                  *ngFor="let item of action.bazaar_voice.recommend_options"
                >
                  <input
                    name="recomendation"
                    type="radio"
                    [id]="item.answer_id"
                    [value]="item.answer_id"
                    [(ngModel)]="recomendation"
                  />
                  <label [for]="item.answer_id">
                    {{ item.answer_text }}
                    <span class="radio-icon"></span>
                  </label>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset *ngIf="action.bazaar_voice.rate_options.length">
            <h4>{{ 'review.yourRating' | translate }}</h4>
            <div class="form-group">
              <div class="rating-stars">
                <fieldset>
                  <ng-container
                    *ngFor="let item of action.bazaar_voice.rate_options"
                  >
                    <input
                      name="rating"
                      type="radio"
                      [id]="item.code"
                      [value]="item.answer_id"
                      [(ngModel)]="rating"
                    />
                    <label [for]="item.code" title="Excellent">{{
                      item.code
                    }}</label>
                  </ng-container>
                </fieldset>
              </div>
            </div>
            <div class="form-group">
              <textarea
                type="text"
                class="form-control h-144"
                name="review"
                [(ngModel)]="review"
                rows="6"
                [maxlength]="reviewMaxLength"
                placeholder="'Type your response here..."
              ></textarea>
              <span class="error-inline-msg" *ngIf="errorMessageReview">{{
                errorMessageReview
              }}</span>
              <p class="char-counter">
                {{ review.length }} / {{ reviewMaxLength }}
              </p>
            </div>
          </fieldset>
        </div>
        <div class="form-group form-cta">
          <button
            type="submit"
            class="btn form-btn form-btn-primary form-btn-with-arrow"
          >
            {{ 'general.submit' | translate }}
          </button>
        </div>
      </div>
      <!-- Form Content Ends -->
    </form>
  </div>
</div>
