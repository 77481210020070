import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';

import { Injectable } from '@angular/core';
import { AuthenticationService } from '@suzy/crowdtap/data-access/user';

@Injectable()
export class VerifyGuard implements CanActivate {
  isMobile: boolean;
  constructor(private router: Router, private auth: AuthenticationService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    this.isMobile = this.auth.getLocalKey('isMobile');

    return this.auth.ensureUser().pipe(
      map(res => {
        const user = res;
        if (
          this.accountCreatedAfter(user.created_utc, '2018-08-20T05:00:00Z') &&
          user.phone_verified_utc === undefined
        ) {
          if (this.isMobile) {
            this.router.navigate(['/auth/sms-authentication/mobile'], {
              queryParams: { returning: true }
            });
          } else {
            this.router.navigate(['/auth/get-verified'], {
              queryParams: { returning: true }
            });
          }

          return false;
        } else {
          return true;
        }
      }),
      take(1)
    );
  }

  accountCreatedAfter(created: string, goal: string): boolean {
    const difference = new Date(goal).getTime() - new Date(created).getTime();

    return difference < 0;
  }
}
