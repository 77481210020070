import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-preview-not-eligible',
  templateUrl: './preview-not-eligible.component.html',
  styleUrls: ['./preview-not-eligible.component.scss']
})
export class PreviewNotEligibleComponent {
  errorBarMessage: string;

  constructor(public modal: NgbActiveModal) {}

  closeModal(): void {
    this.modal.dismiss(true);
  }

  onRestartSurvey(): void {
    this.modal.dismiss('refresh');
  }
}
