import { AfterViewInit, Component, ChangeDetectorRef } from '@angular/core';
import { fadeInOut } from '../../../core/animations';

@Component({
  selector: 'app-anonymous-layout',
  templateUrl: './anonymous-layout.component.html',
  animations: [fadeInOut]
})
export class AnonymousLayoutComponent implements AfterViewInit {
  constructor() {}

  ngAfterViewInit(): void {}

  onActivate(event): void {
    window.scroll(0, 0);
  }
}
