<div
  [ngClass]="{ overlay: overlay, showbox: !button }"
  [ngStyle]="{ background: overlayColor }"
>
  <div class="loader-wrapper">
    <div
      class="loader"
      *ngIf="svg"
      [ngClass]="{ 'negative-mt': negativeMarginTop }"
    >
      <svg
        class="circular"
        [attr.viewBox]="getViewBoxAttr()"
        [ngClass]="{ 'animated-path': animatedPath }"
      >
        <circle
          class="path"
          [attr.cx]="svg.width"
          [attr.cy]="svg.height"
          [attr.r]="round"
          fill="none"
          [attr.stroke]="color"
          [attr.stroke-width]="svg.stroke"
          stroke-miterlimit="10"
        />
      </svg>
    </div>
    <div [ngClass]="{ showtext: text }" class="text">
      {{ 'loadingMessages.nextQuestion' | translate }}
    </div>
  </div>
</div>
