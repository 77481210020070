import { Injectable } from '@angular/core';
import { OnboardingStatus, VerificationStatus } from '@asksuzy/typescript-sdk';
import { VerificationUserStatus } from './verification-user-status.model';

@Injectable()
export class VerificationService {
  status = new VerificationUserStatus();
  minLimitPoints = 1000;

  isOnboardUser(user: any): boolean {
    return user.onboarding_status === OnboardingStatus.completed;
  }

  getUserVerification(isOnboarded: boolean, user: any): any {
    if (user.is_identity_pending) {
      return this.status.pending;
    }

    if (user.is_identity_verified) {
      return this.status.verified;
    }

    switch (user.verification_status) {
      case VerificationStatus.not_verified:
        if (isOnboarded) {
          return this.status.notVerifiedOnboard;
        } else {
          if (user.total_points >= this.minLimitPoints) {
            return this.status.readyToVerify;
          } else {
            return this.status.notVerifiedExisting;
          }
        }

      case VerificationStatus.denied:
        if (user.verification_attempts === 1) {
          return this.status.oneAttempt;
        } else {
          return this.status.denied;
        }

      case VerificationStatus.duplicate:
        return this.status.denied;

      case VerificationStatus.retry_allowed:
        return this.status.oneAttempt;

      default:
        break;
    }
  }
}
