import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import { Observable } from 'rxjs';

@Injectable()
export class IncentiveProgramService {
  constructor(private suzySdkService: SuzySdkService) {}
  getAvailable$: Observable<any>;

  getIncentivePrograms(): Observable<any> {
    this.getAvailable$ =
      this.suzySdkService.IncentiveProgram.getAvailableAsync();

    return this.getAvailable$;
  }

  isRafProgram(program_id: string): boolean {
    const rafPrograms = [
      'c0e5d7dc-302b-46b7-809a-1e6e70118c55',
      'c0ef4efd-c035-4b58-9406-a45edee0472b',
      '4b447538-d3b2-405f-8fbf-001f6aae00a2',
      '9fbeb53b-5c26-4086-ba50-59a043d0ab1e'
    ];

    return rafPrograms.includes(program_id);
  }
}
