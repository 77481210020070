<ul class="list" [ngClass]="{ signup: isSignup }">
  <li
    *ngFor="let trackReward of trackRewards; let i = index"
    class="relative block group lg:block"
  >
    <div
      class="image-container cursor-pointer"
      (click)="setModalValue($event, rewardDialog, trackReward, i)"
    >
      <img
        [ngClass]="{ 'active-img': index == i }"
        *ngIf="trackReward.track_rewards[0].photo"
        [src]="trackReward.track_rewards[0].photo.thumbs.member.url"
        alt="Reward card"
        class="mb-2 border border-black-line rounded-xl"
      />
    </div>
    <div
      *ngIf="isSignup"
      class="relative check-container"
      (click)="setModalValue($event, rewardDialog, trackReward, i)"
    >
      <p>
        <input
          type="checkbox"
          class="styled-checkbox"
          name=""
          id="check-{{ i }}"
          [checked]="index == i"
        />
        <label class="custom-checkbox" for="check-{{ i }}">
          <span>$5 {{ trackReward.reward_group_name }}</span>
        </label>
      </p>
    </div>
    <p *ngIf="!isSignup">
      {{ trackReward.reward_group_name }}
    </p>
    <ng-container *ngIf="!isSignup">
      <div
        class="dark:text-orange text-blue-dark text-xs font-semibold text-left"
        *ngIf="
          trackReward.min_points === trackReward.max_points;
          else showPointsRange
        "
      >
        {{ trackReward.min_points }} pts
      </div>
      <ng-template #showPointsRange>
        <div
          class="dark:text-orange text-blue-dark text-xs font-semibold text-left"
        >
          {{ trackReward.min_points }} - {{ trackReward.max_points }} pts
        </div>
      </ng-template>
    </ng-container>
  </li>
</ul>

<ng-template #rewardDialog let-c="close" let-d="dismiss">
  <div *ngIf="currentTrackReward" class="detail-modal relative">
    <div class="modal-header">
      <button
        type="button"
        aria-label="Close"
        (click)="d('Cross click')"
        class="absolute -top-2 right-0 sm:right-3 sm:-top-2 z-20 cursor-pointer"
      >
        <span
          class="crowdtap-multiply text-body-dark dark:text-body-light text-lg font-bold"
        ></span>
      </button>
    </div>
    <h1 class="text-box-dark dark:text-box-light text-xl font-bold">
      {{ 'rewards.chooseAmount' | translate }}
    </h1>
    <div
      class="mt-1 mb-6 text-sm text-box-dark dark:text-box-light font-medium"
    >
      {{ 'rewards.youCanUpdateTime' | translate }}
    </div>
    <div
      class="image-container-modal"
      *ngIf="currentTrackReward.track_rewards[0]?.photo"
    >
      <img
        [src]="currentTrackReward.track_rewards[0].photo.thumbs?.member?.url"
        alt="Reward logo"
        class="object-cover border dark:border-white-line border-black-line"
      />
    </div>
    <div class="pt-1 text-xs text-box-dark dark:text-box-light font-semibold">
      {{ currentTrackReward.reward_group_name }}

      <button
        type="button"
        (click)="editGoal('dialog')"
        class="text-sm font-semibold text-blue-dark ml-1"
      >
        {{ 'general.edit' | translate }}
      </button>
    </div>
    <ul class="values">
      <ng-container
        *ngFor="let trackReward of currentTrackReward.track_rewards"
      >
        <li
          (click)="selectValue(trackReward)"
          class="text-active-link-dark dark:text-white"
          [ngClass]="{
            'bg-blue-dark dark:bg-blue-dark':
              selectedRewardValue === trackReward &&
              currentUser.total_points < trackReward.points_required
          }"
        >
          ${{ trackReward.reward_value }}
        </li>
      </ng-container>
    </ul>
    <div class="warnings-container">
      <div class="text-light-black text-sm font-semibold dark:text-white">
        <span>
          {{ selectedRewardValue.points_required }}
          {{ 'general.points' | translate }}
          {{ 'general.for' | translate }}
          ${{ selectedRewardValue.reward_value }}
        </span>
      </div>
    </div>
    <div class="flex justify-center space-x-5 items-baseline mt-5">
      <div
        class="text-blue-dark font-bold text-sm cursor-pointer"
        aria-label="Close"
        (click)="d('Cross click')"
      >
        {{ 'dashboard.maybeLater' | translate }}
      </div>
      <button
        type="button"
        data-track="member-redeem-redeem-btn"
        class="bg-blue-dark relative text-white px-8 h-10 text-sm font-bold rounded-full"
        (click)="saveGoal()"
        [disabled]="isLoading"
      >
        {{ 'rewards.saveGoal' | translate }}

        <div class="absolute left-4 button-spinner top-5">
          <suzy-spinner
            *ngIf="isLoading"
            size="small"
            button="true"
            [round]="5"
            [color]="'#ffffff'"
            [overlay]="false"
          >
          </suzy-spinner>
        </div>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #rewardSuccess let-c="close" let-d="dismiss">
  <div class="detail-modal success-modal relative">
    <div class="modal-header">
      <button
        type="button"
        aria-label="Close"
        (click)="d('Cross click')"
        class="absolute -top-6 -right-4 sm:right-3 sm:-top-2 z-20 cursor-pointer"
      >
        <span
          class="crowdtap-multiply text-body-dark dark:text-body-light text-lg font-bold"
        ></span>
      </button>
    </div>
    <h1 class="text-box-dark dark:text-box-light text-xl font-bold">
      {{ 'rewards.yourGoalHasBeenSet' | translate }}
    </h1>
    <div
      class="mt-1 mb-5 text-sm text-box-dark dark:text-box-light font-medium"
    >
      {{ 'rewards.TraceOrUpdateYourGoal' | translate }}
    </div>
    <div
      class="image-container-modal"
      *ngIf="currentTrackReward.track_rewards[0]?.photo"
    >
      <img
        [src]="currentTrackReward.track_rewards[0].photo.thumbs?.member?.url"
        alt="Reward logo"
        class="object-cover border dark:border-white-line border-black-line"
      />
    </div>
    <div class="text-xs text-box-dark dark:text-box-light font-semibold">
      {{ currentTrackReward.reward_group_name }}

      <button
        type="button"
        (click)="editGoal('success')"
        class="text-sm font-semibold text-blue-dark ml-1"
      >
        {{ 'general.edit' | translate }}
      </button>
    </div>

    <div class="flex space-x-6 rounded-lg my-4 w-64 m-auto">
      <div
        class="w-full bg-light-gray dark:bg-body-light mt-2 rounded-full h-2.5 relative"
      >
        <ul
          [ngClass]="goalInfo?.reward_value > 5 ? 'visible' : 'invisible'"
          class="flex justify-evenly w-full relative z-10 last-circle"
        >
          <li class="step"></li>
          <li class="step"></li>
          <li class="step"></li>
          <li class="step"></li>
          <li class="step"></li>
        </ul>
        <div
          class="bg-progress-2 absolute top-0 h-1.5 m-0.5 rounded-l-full"
          [ngStyle]="{ width: progressBarValue + '%' }"
        ></div>
        <div
          *ngIf="currentUser.total_points != -1"
          class="text-box-dark dark:text-box-light text-left text-xs font-semibold mt-2"
        >
          {{ currentUser.total_points }}/{{ goalInfo?.points_required }}
        </div>
      </div>
      <div class="doller-wrapper success-modal-doller">
        <div
          class="point relative bg-progress-2 text-box-dark flex items-center justify-center rounded-sm font-semibold text-sm"
        >
          ${{ goalInfo?.reward_value }}
        </div>
      </div>
    </div>

    <div class="actions mt-6">
      <button
        type="button"
        routerLink="/dashboard"
        class="bg-blue-dark text-white px-4 h-10 text-sm font-bold rounded-full"
        (click)="onCloseSuccessModal()"
      >
        {{ 'rewards.startEarning' | translate }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #rewardFailed let-c="close" let-d="dismiss">
  <div class="detail-modal failed-modal relative">
    <div class="modal-header">
      <button
        type="button"
        aria-label="Close"
        (click)="d('Cross click')"
        class="absolute -top-6 -right-4 sm:right-3 sm:-top-2 z-20 cursor-pointer"
      >
        <span
          class="crowdtap-multiply text-body-dark dark:text-body-light text-lg font-bold"
        ></span>
      </button>
    </div>

    <span
      class="crowdtap-exclamation-circle text-5xl md:text-7xl text-active-link-dark dark:text-body-light"
    ></span>

    <h4 class="text-lg md:text-xl font-bold mt-2">
      {{ 'rewards.somethingWentWrong' | translate }}
    </h4>
    <div class="text-box-dark dark:text-box-light text-xs font-semibold mb-4">
      {{ 'rewards.encounteredMessage' | translate }}
      <a
        href="https://support.crowdtap.com/hc/en-us"
        target="_blank"
        class="link"
        >{{ 'support.contactSupport' | translate }}</a
      >.
    </div>
    <div class="actions">
      <button
        type="button"
        class="gotIt-btn"
        aria-label="Close"
        (click)="d('Cross click')"
      >
        {{ 'general.gotIt' | translate }}
      </button>
    </div>
  </div>
</ng-template>
