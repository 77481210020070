<div
  class="link-view-media"
  (click)="onClick()"
  *ngIf="isValidKind && !isInline"
  onContextMenu="return false;"
  data-track="member-view-media"
>
  <div class="icon">
    <svg
      class="inline-block mr-2 ltr:mr-2 rtl:ml-2"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.6667 8.41402L9.08337 5.76486C8.51643 5.43753 7.81797 5.43735 7.25085 5.76439C6.68374 6.09142 6.33405 6.69604 6.33337 7.35069V12.649C6.33405 13.3033 6.68339 13.9077 7.25004 14.2349C7.81728 14.5623 8.51614 14.5623 9.08337 14.2349L13.6667 11.5857C14.2321 11.2577 14.5801 10.6535 14.5801 9.99986C14.5801 9.34621 14.2321 8.74201 13.6667 8.41402ZM12.75 9.99992L8.1667 12.6491V7.35075L12.75 9.99992ZM10 0.833252C4.93743 0.833252 0.833374 4.93731 0.833374 9.99992C0.833374 15.0625 4.93743 19.1666 10 19.1666C15.0626 19.1666 19.1667 15.0625 19.1667 9.99992C19.1667 7.56877 18.2009 5.23719 16.4819 3.51811C14.7628 1.79902 12.4312 0.833252 10 0.833252ZM10 17.3331C5.94994 17.3331 2.6667 14.0499 2.6667 9.99981C2.6667 5.94972 5.94994 2.66647 10 2.66647C14.0501 2.66647 17.3334 5.94972 17.3334 9.99981C17.3334 11.9447 16.5607 13.81 15.1855 15.1853C13.8102 16.5605 11.945 17.3331 10 17.3331Z"
        fill="white"
      />
    </svg>

    <span class="align-middle"> {{ 'general.viewMedia' | translate }}</span>
  </div>
</div>

<div
  class="link-view-media"
  (click)="onClick()"
  *ngIf="isValidKind && isInline"
  onContextMenu="return false;"
  data-track="member-view-media"
>
  <div style="cursor: pointer">
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="18" height="18" fill="white" fill-opacity="0.4" />
      <path
        d="M16.5059 0H1.57342C0.727629 0 0 0.662273 0 1.50789V16.4403C0 17.2859 0.727629 18 1.57342 18H16.5059C17.3517 18 18 17.2859 18 16.4403V1.50789C18 0.662273 17.3516 0 16.5059 0ZM17.2341 16.4662C17.2341 16.8902 16.8903 17.234 16.4662 17.234H1.5338C1.10971 17.234 0.765984 16.8902 0.765984 16.4662V1.5338C0.765984 1.10978 1.10974 0.765984 1.5338 0.765984H16.4662C16.8903 0.765984 17.2341 1.10978 17.2341 1.5338V16.4662Z"
        fill="#1A152E"
      />
      <path
        d="M14.3588 3.29785H10.529C10.3175 3.29785 10.146 3.46913 10.146 3.68084C10.146 3.89255 10.3175 4.06384 10.529 4.06384H13.4342L10.4923 6.9929C10.3427 7.14249 10.3427 7.37832 10.4923 7.52791C10.5671 7.60272 10.6651 7.63693 10.763 7.63693C10.861 7.63693 10.9392 7.59794 11.014 7.52313L13.9362 4.57944V7.48468C13.9362 7.69636 14.1076 7.86767 14.3191 7.86767C14.5306 7.86767 14.7021 7.69639 14.7021 7.48468V3.65493C14.7021 3.44322 14.5703 3.29785 14.3588 3.29785Z"
        fill="#1A152E"
      />
      <path
        d="M7.96015 10.05C7.81056 9.90038 7.55828 9.89391 7.40869 10.0435L4.44689 12.9855V10.0803C4.44689 9.86858 4.2754 9.69727 4.0639 9.69727C3.8524 9.69727 3.68091 9.86855 3.68091 10.0803V13.91C3.68091 13.9352 3.70334 13.9602 3.70829 13.985C3.71062 13.9969 3.72496 14.008 3.7284 14.0194C3.7321 14.0318 3.73987 14.0444 3.74482 14.0564C3.75073 14.0706 3.7611 14.0835 3.76855 14.0968C3.77326 14.1052 3.77819 14.1141 3.78356 14.1222C3.81169 14.1643 3.8486 14.2006 3.89079 14.2288C3.89902 14.2342 3.90823 14.2379 3.91677 14.2427C3.92996 14.2501 3.94296 14.2579 3.95706 14.2638C3.96908 14.2688 3.98171 14.2716 3.99408 14.2753C4.00558 14.2787 4.01662 14.2961 4.0285 14.2984C4.05321 14.3034 4.07838 14.3189 4.10349 14.3189H7.93327C8.14477 14.3189 8.31626 14.1476 8.31626 13.9359C8.31626 13.7242 8.14477 13.5529 7.93327 13.5529H5.02803L7.97003 10.598C8.11969 10.4485 8.10977 10.1996 7.96015 10.05Z"
        fill="#1A152E"
      />
    </svg>
  </div>
</div>
