import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'privacy-policy-v20220915',
  templateUrl: './privacy-policy-v20220915.component.html',
  styleUrls: ['./privacy-policy-v20220915.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivacyPolicyV20220915Component implements OnInit {
  showSection3Details = false;
  showExampleOfTargeting = false;

  constructor() {}

  ngOnInit(): void {}
}
