import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-global-ending',
  templateUrl: './global-ending.component.html',
  styleUrls: ['./global-ending.component.scss']
})
export class GlobalEndingComponent {
  points: any;
  currentPoints: number;
  type: string;
  mission: any;
  startTime: number;
  showLoader = true;
  cintRedirect: string;
  timeOut: any;
  message: string;

  constructor(public modal: NgbActiveModal) {}

  closeModal(): void {
    this.modal.dismiss(true);
  }

  timeCompleted(event): void {
    if (event === 'completed') {
      this.showLoader = false;
    } else if (event === 'redirect') {
      if (this.timeOut) {
        return;
      }
      this.timeOut = setTimeout(() => {
        this.closeModal();
      });
    }
  }
}
