<h1>Crowdtap® Accessibility Statement</h1>
<div>
  <h2>Compliance status</h2>
  <p>
    Crowdtap firmly believes the internet should be available and accessible to
    anyone. We are committed to providing a website that is accessible to the
    widest possible audience, regardless of circumstance and ability.
  </p>
  <p>
    To fulfill this, we aim to adhere as strictly as possible to the World Wide
    Web Consortium's (W3C) Web Content Accessibility Guidelines 2.1 (WCAG 2.1)
    at the AA level. These guidelines explain how to make web content accessible
    to people with a wide array of disabilities. Complying with those guidelines
    helps us ensure that the website is accessible to all people: blind people,
    people with motor impairments, visual impairment, cognitive disabilities,
    and more.
  </p>
  <p>
    This website utilizes various technologies that are meant to make it as
    accessible as possible at all times. We utilize an accessibility interface
    that allows persons with specific disabilities to adjust the website's UI
    (user interface) and design it to their personal needs.
  </p>
  <p>
    Additionally, Crowdtap utilizes an AI-based application that runs in the
    background and optimizes its accessibility level constantly. This
    application remediates Crowdtap's HTML, adapts its functionality and
    behavior for screen-readers used by blind users, and for keyboard functions
    used by individuals with motor impairments.
  </p>
  <h2>Screen-reader and keyboard navigation</h2>
  <p>
    Crowdtap implements the ARIA attributes (Accessible Rich Internet
    Applications) technique, alongside various different behavioral changes, to
    ensure blind users visiting with screen-readers are able to read,
    comprehend, and enjoy the website's functions. As soon as a user with a
    screen-reader enters Crowdtap.com, they immediately receive a prompt to
    enter the Screen-Reader Profile so they can browse and operate your site
    effectively. Here's how our website covers some of the most important
    screen-reader requirements, alongside console screenshots of code examples:
  </p>
  <ol>
    <li>
      <p>
        <strong>Screen-reader optimization:</strong> we run a background process
        that learns Crowdtap.com's components from top to bottom, to ensure
        ongoing compliance even when updating the website. In this process, we
        provide screen-readers with meaningful data using the ARIA set of
        attributes. For example, we provide accurate form labels; descriptions
        for actionable icons (social media icons, search icons, cart icons,
        etc.); validation guidance for form inputs; element roles such as
        buttons, menus, modal dialogues (popups), and others.
      </p>
      <p>
        Additionally, the background process scans all of the website's images
        and provides an accurate and meaningful image-object-recognition-based
        description as an ALT (alternate text) tag for images that are not
        described. It will also extract texts that are embedded within the
        image, using an OCR (optical character recognition) technology. To turn
        on screen-reader adjustments at any time, users need only to press the
        Alt+1 keyboard combination. Screen-reader users also get automatic
        announcements to turn the Screen-reader mode on as soon as they enter
        the website.
      </p>
      <p>
        These adjustments are compatible with all popular screen readers,
        including JAWS and NVDA.
      </p>
    </li>
    <li>
      <p>
        <strong>Keyboard navigation optimization:</strong> The background
        process also adjusts the website's HTML, and adds various behaviors
        using JavaScript code to make the website operable by the keyboard. This
        includes the ability to navigate the website using the Tab and Shift+Tab
        keys, operate dropdowns with the arrow keys, close them with Esc,
        trigger buttons and links using the Enter key, navigate between radio
        and checkbox elements using the arrow keys, and fill them in with the
        Spacebar or Enter key.
      </p>
      <p>
        Additionally, keyboard users will find quick navigation and content-skip
        menus, available at any time by clicking Alt+1, or as the first element
        of the site while navigating with the keyboard. The background process
        also handles triggered popups by moving the keyboard focus towards them
        as soon as they appear, and not allow the focus to drift outside of it.
      </p>
      <p>
        Users can also use shortcuts such as “M” (menus), “H” (headings), “F”
        (forms), “B” (buttons), and “G” (graphics) to jump to specific elements.
      </p>
    </li>
  </ol>
  <h2>Disability profiles supported on our website</h2>
  <ul>
    <li>
      <strong>Epilepsy Safe Profile:</strong> this profile enables people with
      epilepsy to use the website safely by eliminating the risk of seizures
      that result from flashing or blinking animations and risky color
      combinations.
    </li>
    <li>
      <strong>Vision Impaired Profile:</strong> this profile adjusts the website
      so that it is accessible to the majority of visual impairments such as
      Degrading Eyesight, Tunnel Vision, Cataract, Glaucoma, and others.
    </li>
    <li>
      <strong>Cognitive Disability Profile:</strong> this profile provides
      various assistive features to help users with cognitive disabilities such
      as Autism, Dyslexia, CVA, and others, to focus on the essential elements
      more easily.
    </li>
    <li>
      <strong>ADHD Friendly Profile:</strong> this profile significantly reduces
      distractions and noise, to help people with ADHD and Neurodevelopmental
      disorders browse, read, and focus on the essential elements more easily.
    </li>
    <li>
      <strong>Blind Users Profile (Screen-readers):</strong> this profile
      adjusts the website to be compatible with screen-readers such as JAWS,
      NVDA, VoiceOver, and TalkBack. A screen-reader is installed on the blind
      user's computer, and this site is compatible with it.
    </li>
    <li>
      <strong>Keyboard Navigation Profile (Motor-Impaired):</strong> this
      profile enables motor-impaired persons to operate the website using the
      keyboard Tab, Shift+Tab, and the Enter keys. Users can also use shortcuts
      such as “M” (menus), “H” (headings), “F” (forms), “B” (buttons), and “G”
      (graphics) to jump to specific elements.
    </li>
  </ul>
  <h2>Additional UI, design, and readability adjustments</h2>
  <ol>
    <li>
      <strong>Font adjustments –</strong> users can increase and decrease its
      size, change its family (type), adjust the spacing, alignment, line
      height, and more.
    </li>
    <li>
      <strong>Color adjustments –</strong> users can select various color
      contrast profiles such as light, dark, inverted, and monochrome.
      Additionally, users can swap color schemes of titles, texts, and
      backgrounds, with over 7 different coloring options.
    </li>
    <li>
      <strong>Animations –</strong> epileptic users can stop all running
      animations with the click of a button. Animations controlled by the
      interface include videos, GIFs, and CSS flashing transitions.
    </li>
    <li>
      <strong>Content highlighting –</strong> users can choose to emphasize
      important elements such as links and titles. They can also choose to
      highlight focused or hovered elements only.
    </li>
    <li>
      <strong>Audio muting –</strong> users with hearing devices may experience
      headaches or other issues due to automatic audio playing. This option lets
      users mute the entire website instantly.
    </li>
    <li>
      <strong>Cognitive disorders –</strong> we utilize a search engine that is
      linked to Wikipedia and Wiktionary, allowing people with cognitive
      disorders to decipher meanings of phrases, initials, slang, and others.
    </li>
    <li>
      <strong>Additional functions –</strong> we provide users the option to
      change cursor color and size, use a printing mode, enable a virtual
      keyboard, and many other functions.
    </li>
  </ol>
  <h2>Assistive technology and browser compatibility</h2>
  <p>
    We aim to support the widest array of browsers and assistive technologies as
    possible, so our users can choose the best fitting tools for them, with as
    few limitations as possible. Therefore, we have worked very hard to be able
    to support all major systems that comprise over 95% of the user market share
    including Google Chrome, Mozilla Firefox, Apple Safari, Opera and Microsoft
    Edge, JAWS, and NVDA (screen readers), both for Windows and for MAC users.
  </p>
  <h2>Notes, comments, and feedback</h2>
  <p>
    Despite our very best efforts to allow anybody to adjust the website to
    their needs, there may still be pages or sections that are not fully
    accessible, are in the process of becoming accessible, or are lacking an
    adequate technological solution to make them accessible. Still, we are
    continually improving our accessibility, adding, updating, and improving its
    options and features, and developing and adopting new technologies. All this
    is meant to reach the optimal level of accessibility, following
    technological advancements. If you wish to contact us, please use the
    following email: <a href="mailto:legal@suzy.com">legal@suzy.com</a>
  </p>
</div>
