<div data-track="member-view-media-close">
  <div
    class="bg-black-light md:bg-transparent flex justify-between md:justify-end py-2 px-4 h-8 w-full"
  >
    <p class="text-light-white text-xxs font-semibold md:hidden">
      {{ 'general.lightboxMobileWarning' | translate }}
    </p>
    <button
      id="close-btn"
      type="button"
      class="flex text-light-white items-center cursor-pointer"
      aria-label="Close"
      (click)="closeDialog('Cross click')"
      data-track="member-view-media-image"
    >
      <span class="ft-x text-2xl"></span>
    </button>
  </div>
  <div class="lightbox-container">
    <section class="content-container text-center">
      <img
        [src]="imageUrl"
        (error)="doSomethingOnError()"
        class="sm:hidden block sm:opacity-0 relative sm:absolute"
      />

      <div class="img-container sm:flex hidden">
        <ng-container *ngIf="!isImageError; else notImage">
          <div
            [ngClass]="{ zoom: isZoom }"
            class="img_preview_container"
            data-scale="1.4"
            *ngIf="preroll_kind === preRollKindImage"
          >
            <div
              class="dslc-lightbox-image img_preview"
              id="img"
              #Img
              (event)="onMouseMove(); onMouseLeave()"
              (click)="isZoom = true; onMouseClick()"
              onContextMenu="return false;"
              [ngStyle]="{
                transform: transform,
                'transform-origin': transformOrigin,
                background: 'url(' + imageUrl + ') no-repeat 0 0 / auto'
              }"
            ></div>
          </div>
        </ng-container>
        <ng-template #notImage>
          <div>
            <span
              class="crowdtap-exclamation-circle text-blue-dark text-5xl"
            ></span>
            <p
              class="text-body-dark dark:text-body-light text-sm font-medium mt-3"
            >
              Unable to load image
            </p>
          </div>
        </ng-template>

        <div
          class="ms-browser-img"
          *ngIf="preroll_kind === preRollKindImage"
          [ngStyle]="{ backgroundImage: 'url(' + imageUrl + ')' }"
        ></div>
      </div>
      <div
        *ngIf="!isImageError && !isPreview"
        class="inline-block text-xxxs text-light-gray-3 font-normal"
      >
        {{ guid }}
      </div>
    </section>
  </div>
</div>
