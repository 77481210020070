import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  HostBinding
} from '@angular/core';
import { Subscription } from 'rxjs';
import { PipingTokenService } from './piping-token.service';

@Component({
  selector: 'app-piping-token',
  templateUrl: './piping-token.component.html',
  styleUrls: ['./piping-token.component.scss']
})
export class PipingTokenComponent implements OnInit, OnChanges, OnDestroy {
  label: string;

  @Input('data-caller-id') callerMissionId: string;
  @Input('data-mission-id') missionId: string;
  @Input('data-action-id') actionId: string;
  @Input('data-action-kind') actionKind: number = 0;
  @Input('data-hide-icon') hideIcon: boolean = false;
  @Input('data-hide-priority') hidePriority: boolean = false;
  @HostBinding('class.plain') @Input('data-plain') plain: boolean = false;
  @Input('data-view-id') viewActionId: string;
  @Input('data-tooltip') tooltip: string;

  @Output() edit = new EventEmitter<{
    missionId: string;
    actionId: string;
    actionKind: number;
  }>();

  viewStarted = false;
  labelSub: Subscription;
  refreshSub: Subscription;

  constructor(private pipingTokenService: PipingTokenService) {}

  getLabel(): void {
    if (!this.missionId || !this.actionId) {
      return;
    }

    if (this.labelSub) {
      this.labelSub.unsubscribe();
    }
    this.labelSub = this.pipingTokenService
      .getLabel$(
        this.missionId,
        this.actionId,
        this.callerMissionId,
        this.hidePriority
      )
      .subscribe((label: string) => {
        this.label = label;
      });
  }

  ngOnInit(): void {
    this.refreshSub = this.pipingTokenService.onRefresh$().subscribe(() => {
      this.getLabel();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes['missionId'] &&
      !changes['actionId'] &&
      !changes['callerMissionId']
    ) {
      return;
    }

    if (!this.missionId || !this.actionId) {
      this.label = '';
    } else {
      this.getLabel();
    }
  }

  ngOnDestroy(): void {
    if (this.labelSub) {
      this.labelSub.unsubscribe();
    }

    if (this.refreshSub) {
      this.refreshSub.unsubscribe();
    }
  }
}
