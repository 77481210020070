import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'biometric-policy-v20220912',
  templateUrl: './biometric-policy-v20220912.component.html',
  styleUrls: ['./biometric-policy-v20220912.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BiometricPolicyV20220912Component implements OnInit {
  showPhotoVerify = false;
  showFocusGroupDetails = false;
  showVOEDetails = false;

  constructor() {}

  ngOnInit(): void {}
}
