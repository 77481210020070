import { Injectable } from '@angular/core';

@Injectable()
export class DeviceService {
  isMobile(): any {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    );
  }

  isiOS(): any {
    return (
      navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)
    );
  }

  isPhone(): any {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    );
  }

  getDevice(): string {
    if (navigator.userAgent.match(/Android/i)) {
      return 'Android';
    } else if (navigator.userAgent.match(/iPhone/i)) {
      return 'iPhone';
    } else if (navigator.userAgent.match(/BlackBerry/i)) {
      return 'BlackBerry';
    } else if (navigator.userAgent.match(/Windows Phone/i)) {
      return 'Windows Phone';
    } else if (navigator.userAgent.match(/iPad/i)) {
      return 'iPad';
    } else {
      return 'unknown';
    }
  }
}
