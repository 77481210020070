import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FacebookService, UIParams, UIResponse } from 'ngx-facebook';
import { SharePlatform } from '@asksuzy/typescript-sdk';

@Component({
  selector: 'app-share-social-media',
  templateUrl: './share-social-media.component.html',
  styleUrls: ['./share-social-media.component.scss']
})
export class ShareSocialMediaComponent implements OnInit {
  @Input() hashtags = '';
  @Input() message = '';
  @Input() url = '';
  @Input() platforms: Array<any> = [];
  @Output() shared = new EventEmitter();

  isFacebookEnabled = false;
  isTwitterEnabled = false;

  get hashtagsArray(): Array<string> {
    return this.hashtags !== '' ? this.hashtags.split(',') : ['#sponsored'];
  }

  get sharingLink(): string {
    return this.url && this.url !== ''
      ? this.url
      : 'https://support.crowdtap.com/customer/portal/articles/2952255-shared-via-crowdtap';
  }

  constructor(private fb: FacebookService) {}

  ngOnInit(): void {
    this.platforms.forEach(platform => {
      if (platform.platform === SharePlatform.facebook) {
        this.isFacebookEnabled = true;
      }
      if (platform.platform === SharePlatform.twitter) {
        this.isTwitterEnabled = true;
      }
    });
  }

  shareToFacebook(): void {
    if (this.message === '') {
      return;
    }

    const hashtags = this.hashtagsArray;

    const params: UIParams = {
      method: 'share',
      href: this.sharingLink,
      hashtag: hashtags.splice(0, 1)[0],
      quote: `${this.message} ${hashtags.join(' ')}`
    };

    this.fb
      .ui(params)
      .then((res: UIResponse) => this.shared.next(true))
      .catch((e: any) => this.shared.next(false));
  }

  getTweetUrl(): string {
    const url = new URL('https://twitter.com/intent/tweet');
    url.searchParams.set('text', this.message);
    url.searchParams.set('hashtags', this.hashtags.replace(/#/g, ''));
    if (this.url && this.url !== '') {
      url.searchParams.set('url', this.sharingLink);
    }

    return url.href;
  }

  shareToTwitter(): void {
    this.shared.next();
  }
}
