import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@suzy/crowdtap/data-access/user';
import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import { Gender } from '@asksuzy/typescript-sdk';
import { Observable } from 'rxjs';

export interface ISignupUser {
  progressStatus?: number;
  first_name: string;
  email: string;
  last_name: string;
  password?: string;
  city: string;
  birthdate: string;
  state_id: string;
  zip_code: string;
  gender: Gender;
  ethnicity_id: string;
  facebook_access_token?: string;
  source: string;
  c_source: string;
  c_medium: string;
  c_name: string;
  c_terms: string;
  c_content: string;
  c_misc_one: string;
  c_misc_two: string;
  panel_token: string;
  referral_token: string;
  promotion_token: string;
}

@Injectable()
export class SignupService {
  _currentUser: ISignupUser = {
    progressStatus: 0,
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    city: '',
    birthdate: '',
    state_id: '',
    zip_code: '',
    gender: Gender.unknown,
    ethnicity_id: '',
    facebook_access_token: '',
    source: '',
    c_source: '',
    c_medium: '',
    c_name: '',
    c_terms: '',
    c_content: '',
    c_misc_one: '',
    c_misc_two: '',
    panel_token: '',
    referral_token: '',
    promotion_token: ''
  };

  _source: string;
  isFirstSMSVerifyLoad = false;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private sdk: SuzySdkService
  ) {
    const visitor = this.authService.getVisitor();
    if (visitor) {
      this._currentUser = visitor;
    }

    if (
      !this.authService.isAuthenticated() &&
      !this.router.url.startsWith('/auth/login') &&
      !this.router.url.startsWith('/') &&
      this._currentUser.progressStatus === 0
    ) {
      this.router.navigate(['auth/account-create-email'], {
        queryParamsHandling: 'merge'
      });
    }
  }

  getUser(): ISignupUser {
    const user = sessionStorage.getItem('signupUser');
    if (user !== undefined) {
      this._currentUser = { ...this._currentUser, ...JSON.parse(user) };
    }

    return this._currentUser;
  }

  setUser(user: ISignupUser): void {
    this._currentUser = {
      ...this._currentUser,
      ...user
    };
    sessionStorage.setItem('signupUser', JSON.stringify(this._currentUser));
  }

  signup(user: ISignupUser): Observable<any> {
    return this.sdk.ProtocolRegister.register(user);
  }

  checkAvailableOpt(): Observable<any> {
    let token = 'sms-opt-in';
    return this.sdk.ProtocolEngage.getLegalAvailable(token);
  }

  verifyChecked(legalId: string): Observable<any> {
    return this.sdk.ProtocolEngage.optIn(legalId);
  }

  verifyUnchecked(legalId: string): Observable<any> {
    return this.sdk.ProtocolEngage.optOut(legalId);
  }

  getSMSOptIn(token: string): Observable<any> {
    return this.sdk.ProtocolEngage.getLegalNotice(token);
  }

  clearSession(): void {
    sessionStorage.removeItem('signupUser');
  }
}
