import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppAlertNotifyComponent } from './app-alert-notify.component';

interface alertData {
  title?: string;
  message?: string;
  accept?: string;
}

@Injectable()
export class AppAlertService {
  constructor(private modals: NgbModal) {}

  notify(data: alertData = {}): Promise<any> {
    data.title = data.title || 'Notice';
    data.message = data.message || '';
    data.accept = data.accept || 'OK';

    const modal = this.modals.open(AppAlertNotifyComponent);
    (<AppAlertNotifyComponent>modal.componentInstance).data = data;

    return modal.result;
  }
}
