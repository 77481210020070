import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ModalQueueService {
  private currentModalSubject$ = new BehaviorSubject<string | undefined>(
    undefined
  );
  private modalQueue: string[] = [];
  currentModal$ = this.currentModalSubject$.asObservable();

  queueModal(id: string): void {
    if (this.currentModalSubject$.value === undefined) {
      this.currentModalSubject$.next(id);
    } else {
      if (
        this.currentModalSubject$.value !== id &&
        !this.modalQueue.includes(id)
      ) {
        this.modalQueue.push(id);
      }
    }
  }

  modalClosed(id?: string): void {
    if (id === undefined || this.currentModalSubject$.value === id) {
      if (this.modalQueue.length > 0) {
        this.currentModalSubject$.next(this.modalQueue.shift());
      } else if (this.currentModalSubject$.value !== undefined) {
        this.currentModalSubject$.next(undefined);
      }
    } else {
      this.removeFromQueue(id);
    }
  }

  removeFromQueue(id: string): boolean {
    const index = this.modalQueue.indexOf(id);
    if (index > -1) {
      this.modalQueue.splice(index, 1);

      return true;
    } else {
      return false;
    }
  }
}
