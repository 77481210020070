import { Component } from '@angular/core';

@Component({
  selector: 'app-generic-sharing-mission',
  templateUrl: './generic-sharing-mission.component.html',
  styleUrls: ['./generic-sharing-mission.component.scss']
})
export class GenericSharingMissionComponent {
  el_wrapper: HTMLElement;

  onBackToTop(): void {
    this.el_wrapper.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    });
  }
}
