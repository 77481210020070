import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { AppInputValidators } from '../../../shared/app-input-validators';
import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import { AuthenticationService } from '@suzy/crowdtap/data-access/user';
import { Title } from '@angular/platform-browser';
import { SegmentService } from '@suzy/shared/data-access/tracking';

@Component({
  selector: 'app-sms-authentication',
  templateUrl: './sms-authentication.component.html',
  styleUrls: ['./sms-authentication.component.scss']
})
export class SMSAuthenticationComponent implements OnInit, AfterViewInit {
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef;
  // Send Phone Form
  sendPhoneForm: UntypedFormGroup;
  isSubmittedPhone = false;
  isValidPhone = false;

  // Send Code Form
  sendCodeForm: UntypedFormGroup;
  isSubmittedCode = false;
  isValidCode = false;

  // General
  isLoading = false; // TODO: Add after all logic defiend.
  isPassVerification = false;
  isFirst = true;
  subject: Subject<any>;
  el_wrapper: HTMLElement;
  skipLinkPathContent: string;
  skipLinkPathFooter: string;

  isMobile$ = this.route.data.pipe(
    startWith({ mobile: false }),
    map(data => data.mobile)
  );

  constructor(
    private fb: UntypedFormBuilder,
    private sdk: SuzySdkService,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private router: Router,
    private titleService: Title,
    private segmentService: SegmentService
  ) {}

  ngOnInit(): void {
    this.setHeadTitle();
    this.skipLinkPathContent = `${this.router.url}#main-content`;
    this.skipLinkPathFooter = `${this.router.url}#main-footer`;
    this.route.queryParams.subscribe(params => {
      this.isFirst = !params['returning'];
    });
    this.createSendPhoneForm();
    this.createSendCodeForm();
    this.subject = new Subject<any>();
  }

  ngAfterViewInit(): void {
    this.el_wrapper = this.wrapper.nativeElement as HTMLElement;
  }

  setHeadTitle(): any {
    this.titleService.setTitle('Crowdtap® - Confirm your phone');
  }

  onBackToTop(): void {
    this.el_wrapper.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    });
  }

  createSendPhoneForm(): void {
    this.sendPhoneForm = this.fb.group({
      phone: ['', [Validators.required, AppInputValidators.phone]]
    });
  }

  createSendCodeForm(): void {
    this.sendCodeForm = this.fb.group({
      code: ['', [Validators.required]]
    });
  }

  fieldErrors(name: string): any {
    const control_phone = this.sendPhoneForm.get(name);
    const control_code = this.sendCodeForm.get(name);

    // Send Phone Form Error
    if (control_phone && control_phone.errors) {
      this.isValidPhone = false;

      return control_phone.errors;
    } else if (control_phone && !control_phone.errors) {
      this.isValidPhone = true;
    }

    // Send Code Form Error

    if (control_code && control_code.errors) {
      this.isValidCode = false;

      return control_code.errors;
    } else if (control_code && !control_code.errors) {
      this.isValidCode = true;
    }

    return undefined;
  }

  isPhoneTouched(): boolean {
    return this.sendPhoneForm.get('phone').touched;
  }

  isCodeTouched(): boolean {
    return this.sendCodeForm.get('code').touched;
  }

  onChangePhoneNumber(): void {
    this.sendPhoneForm.get('phone').markAsUntouched();
    this.sendPhoneForm.get('phone').setValue('');
    this.isSubmittedPhone = false;
  }

  onSubmitPhone(): void {
    this.segmentService.track('SMS Verification Submitted');
    this.sdk.ProtocolRegister.verifyPhoneStart({
      mobile_phone: this.preparePhoneNumber(
        this.sendPhoneForm.get('phone').value
      )
    }).subscribe((res: any) => {
      const message = !res.success ? { server: res.message } : {};
      this.sendPhoneForm.get('phone').setErrors(message);
      this.isSubmittedPhone = res.success;
    });
  }

  onResendCode(): void {
    this.sendCodeForm.get('code').markAsUntouched();
    this.sendCodeForm.get('code').setValue('');
    this.sdk.ProtocolRegister.verifyPhoneStart({
      mobile_phone: this.preparePhoneNumber(
        this.sendPhoneForm.get('phone').value
      )
    }).subscribe((res: any) => {
      if (!res.success) {
        this.sendCodeForm.get('code').markAsTouched();
        this.sendCodeForm.get('code').setErrors({ server: res.message });
      }
    });
  }

  onSubmitCode(): void {
    this.sdk.ProtocolRegister.verifyPhoneComplete(
      this.sendCodeForm.get('code').value
    ).subscribe((res: any) => {
      if (res.success) {
        this.segmentService.identifySMS(this.sendPhoneForm.get('phone').value);
        this.segmentService.track('SMS Verification Success');
      }
      const message = !res.success ? { server: res.message } : {};
      this.sendCodeForm.get('code').setErrors(message);
      this.auth.reloadUser();
      this.isPassVerification = res.success;
      this.isSubmittedCode = res.success;
    });
  }

  preparePhoneNumber(phone: string): string {
    return `+1 ${phone.replace(/\(|\)/g, '').replace('-', ' ')}`;
  }

  showSubmitCode(): void {
    this.isSubmittedPhone = true;
  }
}
