<div class="card bg-body-light dark:bg-box-dark">
  <h1 class="text-black dark:text-white">
    {{ 'cookies.doYouLikeCookies' | translate }}
  </h1>
  <p
    class="text-black dark:text-white"
    [innerHTML]="'cookies.cookiesExplanation' | translate"
  ></p>
  <p class="text-black dark:text-white">
    {{ 'cookies.pleaseSeeOur' | translate }}
    <a routerLink="/cookie-policy" target="_blank">{{
      'cookies.cookiesPolicy' | translate
    }}</a>
    {{ 'cookies.forMoreInformation' | translate }}
  </p>
  <button class="submit-btn" (click)="onGotItClick()">
    {{ 'general.gotIt' | translate }}
  </button>
</div>
