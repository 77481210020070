<!-- Action Modal Tooltip Starts -->
<div class="action-modal-tooltip">
  <ng-template #tipContent
    >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis fermentum
    ligula pulvinar sapien fermentum, in ultricies est aliquet. Sed rhoncus
    risus ac lacinia ornare.</ng-template
  >
  <span>{{ 'general.selectOneOfTheFollowingOptions' | translate }}.</span>
  <span class="tooltip-icon tooltip-icon-info" [ngbTooltip]="tipContent">
    <i>i</i>
  </span>
</div>
<!-- Action Modal Tooltip Ends -->
