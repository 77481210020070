<button
  (click)="skipToMainContent()"
  class="block m-1 text-white sr-only bg-body-dark dark:bg-body-light dark:text-black focus:ring-4 focus:not-sr-only"
>
  Skip to main content
</button>

<div class="mobile-content h-screen dark:text-white" *ngIf="isMobileLayout">
  <app-general-header></app-general-header>
  <div class="inner-content p-4">
    <router-outlet></router-outlet>
    <app-profile-menu class="block sm:hidden"></app-profile-menu>
  </div>
  <app-sidebar></app-sidebar>
</div>

<div class="flex-col h-screen hidden sm:flex" *ngIf="!isMobileLayout">
  <app-general-header> </app-general-header>
  <div
    tabindex="0"
    class="relative overflow-y-auto content-wrapper sm:pb-16"
    #mainPanel
  >
    <div #top></div>
    <div class="container flex mx-auto sm:pt-8 dark:text-white">
      <div class="flex">
        <app-sidebar></app-sidebar>
      </div>
      <div class="relative w-full">
        <main id="main-content">
          <router-outlet></router-outlet>
          <app-profile-menu class="block sm:hidden"></app-profile-menu>
        </main>
        <footer class="absolute hidden w-full my-4 sm:flex -bottom-16">
          <div
            class="w-full p-2 ml-4 sm:flex sm:bg-box-light sm:dark:bg-box-dark rounded-xl"
          >
            <div class="self-center flex-1 text-xs">
              © 2015 - {{ currentYear }}
              <span [innerHTML]="'general.suzy' | translate"></span>
              {{ 'general.allRightsReserved' | translate }}
            </div>
            <div class="flex">
              <a
                class="w-6 h-6 mr-4 text-center rounded bg-active-link dark:bg-active-link-dark"
                href="http://facebook.com/Crowdtap"
                target="_blank"
              >
                <span class="fa fa-facebook"></span>
              </a>
              <a
                class="w-6 h-6 mr-4 text-center rounded bg-active-link dark:bg-active-link-dark"
                href="http://twitter.com/Crowdtap"
                target="_blank"
              >
                <span class="fa fa-twitter"></span>
              </a>
              <a
                class="w-6 h-6 text-center rounded bg-active-link dark:bg-active-link-dark"
                href="http://instagram.com/crowdtap"
                target="_blank"
              >
                <span class="fa fa-instagram"></span>
              </a>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</div>
<div class="backtotop hide" #backtotop data-track="dash-btn-back-to-top">
  <span class="ft-arrow-up"></span> {{ 'nav.backToTop' | translate }}
</div>
<div></div>
