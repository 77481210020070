import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { AsYouType } from 'libphonenumber-js';

@Directive({
  selector: '[appPhoneInput]',
  exportAs: 'appPhoneInput'
})
export class PhoneInputDirective implements OnInit {
  private nativeEl;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.nativeEl = this.el.nativeElement;
  }

  @HostListener('keyup', ['$event'])
  onKeyUp(e: KeyboardEvent): boolean {
    if (this.nativeEl.value.length > 4) {
      this.nativeEl.value = new AsYouType('US').input(this.nativeEl.value);
    }

    return true;
  }
}
