<a [href]="skipLinkPathContent" class="sr-only">Skip to main content</a>

<div #wrapper [ngClass]="{ 'dark-mode': isMobile$ | async }">
  <app-general-header
    [headerPosition]="'relative'"
    [viewKind]="'login'"
    [isMobile]="isMobile$ | async"
    (openSignupModal)="onOpenSignUpModal($event)"
  >
  </app-general-header>

  <section aria-label="main" role="main" id="main-content">
    <a [href]="skipLinkPathFooter" class="sr-only">Skip to footer</a>
    <article
      class="flex flex-col w-full px-4 m-auto mt-6 max-w-auth sm:max-w-sm-auth"
    >
      <div class="center-column">
        <h1
          class="mt-6 mb-8 text-2xl font-bold text-center text-light-black dark:text-white sm:text-4xl"
        >
          {{ 'auth.welcomeBack' | translate }}
        </h1>

        <div>
          <ul class="flex items-center justify-center mt-4 space-x-3">
            <!-- <li class="flex items-center justify-center rounded-md bg-active-link w-14 h-14 dark:bg-active-link-dark">
              <a href="https://accounts.google.com/">
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M20.6659 8.58923H19.8389V8.54662H10.5989V12.6533H16.4011C15.5546 15.0439 13.28 16.76 10.5989 16.76C7.19699 16.76 4.43884 14.0018 4.43884 10.6C4.43884 7.19809 7.19699 4.43994 10.5989 4.43994C12.1692 4.43994 13.5978 5.03232 14.6855 5.99996L17.5895 3.09602C15.7558 1.38713 13.3031 0.333252 10.5989 0.333252C4.92907 0.333252 0.332153 4.93017 0.332153 10.6C0.332153 16.2698 4.92907 20.8667 10.5989 20.8667C16.2687 20.8667 20.8656 16.2698 20.8656 10.6C20.8656 9.91158 20.7947 9.23962 20.6659 8.58923Z"
                    fill="#FFC107" />
                  <path
                    d="M1.51599 5.82132L4.88912 8.29509C5.80183 6.03538 8.01225 4.43994 10.599 4.43994C12.1692 4.43994 13.5979 5.03232 14.6856 5.99996L17.5896 3.09602C15.7559 1.38713 13.3032 0.333252 10.599 0.333252C6.65551 0.333252 3.23567 2.55959 1.51599 5.82132Z"
                    fill="#FF3D00" />
                  <path
                    d="M10.6 20.867C13.2519 20.867 15.6615 19.8522 17.4833 18.2018L14.3058 15.513C13.275 16.2937 11.9937 16.7604 10.6 16.7604C7.92965 16.7604 5.66225 15.0576 4.80806 12.6814L1.46008 15.2609C3.15922 18.5858 6.60987 20.867 10.6 20.867Z"
                    fill="#4CAF50" />
                  <path
                    d="M20.6665 8.58936H19.8395V8.54675H10.5995V12.6534H16.4017C15.9952 13.8018 15.2565 14.792 14.3037 15.5132C14.3042 15.5127 14.3047 15.5127 14.3053 15.5122L17.4828 18.2011C17.258 18.4054 20.8662 15.7334 20.8662 10.6001C20.8662 9.91171 20.7954 9.23976 20.6665 8.58936Z"
                    fill="#1976D2" />
                </svg>
              </a>
            </li> -->
            <li
              class="flex items-center justify-center rounded-md bg-active-link w-14 h-14 dark:bg-active-link-dark"
            >
              <button
                class="flex items-center justify-center w-full h-full"
                [disabled]="isLoading"
                (click)="socialSignIn('facebook')"
              >
                <svg
                  width="15"
                  height="27"
                  viewBox="0 0 15 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.9655 0.766663H10.9482C9.17235 0.766663 7.46929 1.4427 6.21361 2.64606C4.95793 3.84942 4.2525 5.48152 4.2525 7.18333V11.0333H0.235107V16.1667H4.2525V26.4333H9.60902V16.1667H13.6264L14.9655 11.0333H9.60902V7.18333C9.60902 6.84297 9.75011 6.51655 10.0012 6.27588C10.2524 6.0352 10.593 5.9 10.9482 5.9H14.9655V0.766663Z"
                    fill="#4092FF"
                  />
                </svg>
              </button>
            </li>
          </ul>
          <p
            class="pt-6 pb-4 text-center text-active-link dark:active-link-drak line"
          >
            <span
              class="px-2 font-semibold bg-transparent text-light-black dark:text-white"
            >
              {{ 'auth.or' | translate }}
            </span>
          </p>
        </div>

        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="error" *ngIf="isInvalid || isInvalidCredentials">
            <div class="x" (click)="hideSelf()">
              <img
                src="/assets/img/svg/icons/ic-x-circle-red.svg"
                alt="Error icon"
              />
            </div>
            <div class="txt">
              {{ 'auth.credentialsDontMatch' | translate }}
            </div>
          </div>

          <div class="mb-6 form-row">
            <label class="font-medium text-light-black dark:text-white">{{
              'auth.emailAddress' | translate
            }}</label>
            <input
              type="email"
              class="block w-full p-2 mt-2 font-medium border rounded-sm h-11 text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
              formControlName="user"
              placeholder="{{ 'auth.emailAddress' | translate }}"
              data-track="member-login-email-text"
            />
            <small
              *ngIf="submitted && f.user.errors"
              class="form-text text-muted danger"
            >
              <span *ngIf="f.user.errors.required">
                {{ 'auth.emailIsRequired' | translate }}
              </span>
              <span
                *ngIf="f.user.errors.email"
                class="form-text text-muted danger"
              >
                {{ 'auth.invalidEmailAddress' | translate }}
              </span>
            </small>
          </div>

          <div class="mb-2 form-row">
            <label class="font-medium text-light-black dark:text-white">{{
              'auth.password' | translate
            }}</label>
            <div class="relative passwordField" #passwordFields>
              <span
                *ngIf="isChange"
                class="ic-eye"
                (click)="toggleVisibility($event)"
              ></span>
              <input
                [attr.type]="!eyeView ? 'password' : 'text'"
                class="block w-full p-2 mt-2 font-medium border rounded-sm h-11 text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
                formControlName="password"
                placeholder="{{ 'auth.password' | translate }}"
                (keyup)="onChangeHandler($event)"
                autocomplete="none"
                data-track="member-login-password-text"
              />
              <span
                class="absolute z-10 w-4 h-4 cursor-pointer right-3 top-3"
                (click)="eyeView = !eyeView"
              >
                <svg
                  width="21"
                  height="21"
                  class="text-black dark:text-white"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.5">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19.1796 10.15C17.4121 6.04625 14.0871 3.5 10.4996 3.5C6.91213 3.5 3.58713 6.04625 1.81963 10.15C1.72223 10.3732 1.72223 10.6268 1.81963 10.85C3.58713 14.9537 6.91213 17.5 10.4996 17.5C14.0871 17.5 17.4121 14.9537 19.1796 10.85C19.277 10.6268 19.277 10.3732 19.1796 10.15ZM10.5001 15.75C7.7264 15.75 5.1014 13.7463 3.58765 10.5C5.1014 7.25375 7.7264 5.25 10.5001 5.25C13.2739 5.25 15.8989 7.25375 17.4126 10.5C15.8989 13.7463 13.2739 15.75 10.5001 15.75ZM10.5005 7C8.56749 7 7.00049 8.567 7.00049 10.5C7.00049 12.433 8.56749 14 10.5005 14C12.4335 14 14.0005 12.433 14.0005 10.5C14.0005 9.57174 13.6317 8.6815 12.9754 8.02513C12.319 7.36875 11.4287 7 10.5005 7ZM10.5005 12.25C9.53399 12.25 8.75049 11.4665 8.75049 10.5C8.75049 9.5335 9.53399 8.75 10.5005 8.75C11.467 8.75 12.2505 9.5335 12.2505 10.5C12.2505 11.4665 11.467 12.25 10.5005 12.25Z"
                      fill="currentColor"
                    />
                    <path
                      *ngIf="!eyeView"
                      d="M21 1L11 10.75L1 20.5"
                      stroke="currentColor"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </span>
            </div>
            <small
              *ngIf="submitted && f.password.errors"
              class="form-text text-muted danger"
            >
              <span *ngIf="f.password.errors.required">
                {{ 'auth.passwordIsRequired' | translate }}
              </span>
            </small>
          </div>

          <div class="flex justify-end mb-8 text-tiny">
            <a
              class="font-medium support-link text-blue-dark"
              routerLink="/auth/forgot-password"
              data-track="member-login-forgot-password-btn"
              >{{ 'auth.forgotYourPassword' | translate }}</a
            >
          </div>

          <div
            *ngIf="isLocked"
            class="text-light-black dark:text-white flex pb-6 mx-2"
          >
            <div class="px-2">
              <span class="crowdtap-info-circle text-2xl"></span>
            </div>
            <div
              class="text-tiny"
              [innerHTML]="'auth.lockedOut' | translate : { time: lockTimer }"
            ></div>
          </div>

          <div class="text-center">
            <button
              type="submit"
              data-track="member-login-login-btn"
              class="relative mb-4 font-bold text-white rounded-full w-52 h-14 bg-blue-dark"
              [ngClass]="{ disable: isLocked }"
              [disabled]="isLocked"
            >
              {{ 'auth.signIn' | translate }}
              <div class="absolute left-8 button-spinner top-7">
                <suzy-spinner
                  *ngIf="isLoading"
                  size="small"
                  button="true"
                  [round]="10"
                  [color]="'#ffffff'"
                  [overlay]="false"
                >
                </suzy-spinner>
              </div>
            </button>
          </div>
        </form>
      </div>
    </article>
  </section>

  <app-general-footer
    id="main-footer"
    [footerPosition]="'relative'"
    [isMobile]="isMobile$ | async"
    (backToTop)="onBackToTop()"
    (openSignupModal)="onOpenSignUpModal($event)"
  >
  </app-general-footer>
</div>
