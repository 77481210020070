import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-signup-sampling',
  templateUrl: './signup-sampling.component.html',
  styleUrls: ['./signup-sampling.component.scss']
})
export class SignupSamplingComponent {
  el_wrapper: HTMLElement;

  constructor(private modalService: NgbModal) {}

  aboutClick(content): void {
    this.modalService.open(content, { centered: true });
  }

  onBackToTop(): void {
    this.el_wrapper.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    });
  }
}
