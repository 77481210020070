import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PipeKind } from '@asksuzy/typescript-sdk';
import { filter } from 'jszip';

interface Prop {
  caller?: string;
  view?: string;
  secure?: boolean;
  hideIcon?: boolean;
  plain?: boolean;
  filter?: boolean;
  filterData?: any;
  filterActionId?: string;
  selectCallback?: Function;
  tooltip?: string;
  hidePriority?: boolean;
}

const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
// Use `em` for IE and `i` otherwise
const italicToken =
  msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./) ? 'i' : 'em';
// Use `strong` for IE and `b` otherwise
const boldToken =
  msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./) ? 'strong' : 'b';
// Use `underline` for IE and `u` otherwise
const underlineToken =
  msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)
    ? 'underline'
    : 'u';
const breakLine = '<br />';

@Pipe({
  name: 'markdown2html'
})
export class Markdown2HtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  static get LINK_TOKEN_PATTERN(): RegExp {
    return /\[(.+?)\]\(((?:https?|ftp):\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=\*!$\,;']*))\)/gi;
  }

  transform(value: string, props?: Prop): any {
    if (value === undefined || value === null) {
      return '';
    }

    props = props || {};
    const pipeToken = `<app-pipe-token-element 
    class="app-pipe-token-element" 
    contenteditable="false" 
    data-mission-id="$1" 
    data-action-id="$2" data-pipe-kind="$3"
    ${props.caller ? ` data-caller-id="${props.caller}"` : ''}
    ${props.view ? ` data-view-id="${props.view}"` : ''}
    ${props.hideIcon ? ' data-hide-icon="true"' : ''}
    ${props.hidePriority ? ' data-hide-priority="true"' : ''}
    ${props.plain ? ' data-plain="true"' : ''}
    ${props.tooltip ? ` data-tooltip="${props.tooltip}"` : ''}
    ></app-pipe-token-element>`;

    let html = '';
    let index = 0;

    const matches = [];
    let match;
    let temp = value;
    while ((match = Markdown2HtmlPipe.LINK_TOKEN_PATTERN.exec(temp))) {
      matches.push({
        0: match[0],
        1: match[1],
        2: match[2],
        index: index + match.index
      });

      index = index + match.index + match[0].length;
      temp = temp.substring(index);
    }

    index = 0;
    for (const match of matches) {
      if (match.index > index) {
        html += this.processMarkdown(value.substring(index, match.index), {
          pipeToken
        });
      }

      const url = decodeURI(match[2]).replace(/&/g, '&amp;');
      const text = this.processMarkdown(decodeURIComponent(match[1]));

      html += `<a href="${url}" target="_blank" class="editor-link">${text}</a>`;
      index = match.index + match[0].length;
    }

    if (value.length > index) {
      html += this.processMarkdown(value.substring(index), { pipeToken });
    }

    return html;
  }

  private replacePipingToken(markdown: string, token: string): string {
    const pipingTokenPattern = /`([^:`]+):([^:]+):([^:`]+)`/gi;
    const pipingTokenChunksPattern = /([^:`]+)/gi;
    const guidPattern =
      /(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}/;
    const pipeKinds = [
      PipeKind.selected_responses,
      PipeKind.non_selected_responses
    ];

    const matches = markdown.match(pipingTokenPattern);
    if (!matches) {
      return markdown;
    }

    matches.map(match => {
      const chunks = match.match(pipingTokenChunksPattern);
      if (!chunks || chunks.length !== 3) {
        return;
      }
      if (!guidPattern.test(chunks[0])) {
        return;
      }
      if (!guidPattern.test(chunks[1])) {
        return;
      }
      if (pipeKinds.findIndex(t => t.toString() === chunks[2]) < 0) {
        return;
      }

      const transformed = match.replace(pipingTokenPattern, token);
      markdown = markdown.replace(match, transformed);
    });

    return markdown;
  }

  private processMarkdown(
    value: string,
    params: { pipeToken?: string } = {}
  ): string {
    value = value
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/\\\*/gi, '--asterisk--')
      .replace(/\\`/gi, '--backtick--')
      .replace(/\\_/gi, '--underscore--')
      .replace(/\*{2}([^\*].*?)\*{2}/gis, `<${boldToken}>$1</${boldToken}>`)
      .replace(/\*(.*?)\*/gis, `<${italicToken}>$1</${italicToken}>`)
      .replace(/__([^_].*?)__/gis, `<${underlineToken}>$1</${underlineToken}>`)
      .replace(/\r?\n[\ |\u00A0]/g, `\n&nbsp;`)
      .replace(/[\ |\u00A0]\r?\n/g, `&nbsp;\n`)
      .replace(/\r?\n/g, `${breakLine}`)
      .replace(/--asterisk--/gi, '*')
      .replace(/--backtick--/gi, '`')
      .replace(/--underscore--/gi, '_')
      .replace(/[\ |\u00A0]([\ |\u00A0]+)/g, x => {
        let result = ' ';
        for (let i = 1; i < x.length; i++) {
          result += '&nbsp;';
        }

        return result;
      });

    if (!params.pipeToken) return value;
    return this.replacePipingToken(value, params.pipeToken);
  }
}
