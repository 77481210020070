import {
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
  AfterViewInit,
  HostListener
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LaunchDarklyService } from '@suzy/shared/data-access/feature-flag';

declare const $: any;
@Component({
  selector: 'suzy-lightbox',
  templateUrl: './lightbox.component.html',
  styleUrls: ['./lightbox.component.scss']
})
export class LightboxComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('Img') Img: ElementRef;
  el_img: HTMLElement;

  imageUrl: string;
  preroll_kind: string;
  preRollKindImage: string;
  startTime: number;
  isImageError: boolean = false;
  isZoom: boolean = false;
  transformOrigin;
  transform;
  guid: string;
  showGuid = false;
  isPreview: boolean = false;
  isGlobal: boolean = false;

  getGuid: Promise<string>;
  constructor(
    private renderer: Renderer2,
    private launchDarklyService: LaunchDarklyService,
    public activeModal: NgbActiveModal
  ) {
    this.getGuid = new Promise(resolve => {
      this.generateGUID();
      resolve(this.guid);
    });
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(e) {
    let box = document.querySelector('.img_preview');
    this.transformOrigin =
      ((e.pageX - box.getBoundingClientRect().left) / box.clientWidth) * 100 +
      '% ' +
      ((e.pageY - box.getBoundingClientRect().top) / box.clientHeight) * 100 +
      '%';
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave() {
    this.transform = 'scale(1)';
    this.isZoom = false;
  }

  onMouseClick() {
    this.transform = 'scale(1.4)';
  }

  ngOnInit(): void {
    this.isPreview = document.body.classList.contains('isPreview');
    this.isGlobal = document.body.classList.contains('isGlobal');

    this.showGuid = this.launchDarklyService.getCWDT803Flag();
    if (!this.isPreview) {
      this.generateGUID();
    }
    this.startTime = new Date().getTime();
    this.renderer.addClass(document.body, 'isLightBox');
    window['noPrint'] = true;
    window['noCopy'] = true;
    window['noScreenshot'] = true;
  }

  generateGUID(): void {
    let time = new Date().getTime(),
      currentPerformance =
        (typeof performance !== 'undefined' &&
          performance.now &&
          performance.now() * 1000) ||
        0;
    this.guid = 'xxxx-xxxx-4xxx-yxxx'.replace(/[xy]/g, item => {
      let randomNumber = Math.random() * 16;
      if (time > 0) {
        randomNumber = (time + randomNumber) % 16 | 0;
        time = Math.floor(time / 16);
      } else {
        randomNumber = (currentPerformance + randomNumber) % 16 | 0;
        currentPerformance = Math.floor(currentPerformance / 16);
      }
      return (item == 'x' ? randomNumber : (randomNumber & 0x7) | 0x8).toString(
        16
      );
    });
  }

  ngAfterViewInit(): void {
    this.el_img = this.Img.nativeElement as HTMLElement;
  }

  doSomethingOnError() {
    this.isImageError = true;
  }

  closeDialog(text = ''): void {
    this.activeModal.dismiss(text);
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'isLightBox');
    window['noPrint'] = false;
    window['noCopy'] = false;
    window['noScreenshot'] = false;
  }
}
