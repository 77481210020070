import {
  Component,
  AfterViewInit,
  OnDestroy,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ChangeDetectionStrategy
} from '@angular/core';
import { ArabicNumberPipe } from 'libs/shared/tools/url/src/lib/convert-to-eastern-arabic-number.pipe';
import { GlobalService } from '../../global/global.service';

@Component({
  selector: 'ending-loader',
  templateUrl: './ending-loader.component.html',
  styleUrls: ['./ending-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EndingLoaderComponent implements AfterViewInit, OnDestroy {
  @Output() CompleteEvent: EventEmitter<string> = new EventEmitter();
  @ViewChild('countdownCounterRef', { read: ElementRef }) counter: ElementRef;
  countdown: number;
  interval: any;
  showLoader: boolean = true;
  currentLanguage: string = '';
  isRtl: boolean = false;

  constructor(
    private renderer: Renderer2,
    private arabicNumberPipe: ArabicNumberPipe,
    private globalService: GlobalService
  ) {}

  ngOnInit() {
    this.currentLanguage = this.globalService.getCurrentMissionLanguage();
    this.isRtl = this.currentLanguage === 'he' || this.currentLanguage === 'ar';
  }

  setPropertyUsingRender(count: string | number): void {
    this.renderer.setProperty(this.counter.nativeElement, 'innerHTML', count);
  }

  setInterval() {
    this.interval = setInterval(() => {
      --this.countdown;
      if (this.countdown <= 0) {
        clearInterval(this.interval);
        this.showLoader = false;
        this.CompleteEvent.emit('completed');
      } else if (this.countdown <= 5) {
        this.CompleteEvent.emit('redirect');
      }
      this.setPropertyUsingRender(this.getCountBasedOfDirection());
    }, 1000);
  }

  getCountBasedOfDirection(): string | number {
    return this.isRtl
      ? this.arabicNumberPipe.transform(this.countdown)
      : this.countdown;
  }

  ngAfterViewInit() {
    this.countdown = 10;
    this.setPropertyUsingRender(this.getCountBasedOfDirection());
    this.setInterval();
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }
}
