import { AbstractControl, ValidationErrors } from '@angular/forms';

const UPPERCASE_REGEX = /^(.*[A-Z]).*$/;
const LOWERCASE_REGEX = /^(.*[a-z]).*$/;
const NON_LETTER_REGEX = /^.*[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?].*$/;

export class PasswordValidators {
  static password(control: AbstractControl): ValidationErrors | undefined {
    if (!control.value) {
      return undefined;
    }

    if (!UPPERCASE_REGEX.test(control.value)) {
      return { uppercase: { value: control.value } };
    } else if (!LOWERCASE_REGEX.test(control.value)) {
      return { lowercase: { value: control.value } };
    } else if (!NON_LETTER_REGEX.test(control.value)) {
      return { nonletter: { value: control.value } };
    } else {
      return undefined;
    }
  }

  static matchPasswords(
    control: AbstractControl
  ): ValidationErrors | undefined {
    const password = control.get('password');
    const confirmPassword = control.get('confirmPassword');

    if (password.value !== confirmPassword.value) {
      confirmPassword.setErrors({ matchpassword: true });

      return { matchpassword: { value: true } };
    } else {
      return undefined;
    }
  }
}
