import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class FileUploadService {
  constructor(private http: HttpClient) {}

  postFile(fileToUpload: File, presignedData: any = {}): Observable<boolean> {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    const headers = new HttpHeaders({
      'Content-Type': presignedData.mime_type
    });

    return this.http
      .put(presignedData.signed_url, fileToUpload, { headers })
      .pipe(
        map(() => {
          return true;
        })
      );
  }
}
