import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Observer, Subject } from 'rxjs/';
import { takeUntil } from 'rxjs/operators';
import { ScriptLoaderService } from './script-loader.service';

@Injectable()
export class SnareServices implements OnDestroy {
  blackBoxString: string;
  blackBoxTimer: any;
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private scriptLoader: ScriptLoaderService) {}

  init(): any {
    window['io_install_stm'] = false; // do not install Active X
    window['io_exclude_stm'] = 12; // do not run Active X
    window['io_install_flash'] = false; // do not install Flash
    window['io_min_flash_version'] = 9999; // disable Flash
    window['io_enable_rip'] = true; // collect Real IP information
    window['io_bb_callback'] = (blackBoxString, isComplete) => {
      if (isComplete) {
        this.blackBoxString = blackBoxString;
      }
    };

    this.scriptLoader
      .load({
        name: 'iesnare',
        src: 'https://mpsnare.iesnare.com/snare.js',
        loaded: false
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          if (!data) {
            console.error('script is not loaded but return as loaded', data);
          }
        },
        error => {
          console.error(error);
        }
      );
  }

  getBlackBoxString(): Observable<string> {
    return new Observable<string>((observer: Observer<string>) => {
      const useBlackBoxString = () => {
        if (typeof window['ioGetBlackbox'] !== 'function') {
          return;
        }
        const bbData = window['ioGetBlackbox']();
        if (bbData.finished) {
          clearTimeout(this.blackBoxTimer);
          this.blackBoxString = bbData.blackbox;
          observer.next(this.blackBoxString);
          observer.complete();
        }
      };

      if (this.blackBoxString) {
        observer.next(this.blackBoxString);
        observer.complete();
      } else {
        this.blackBoxTimer = setInterval(useBlackBoxString, 500);
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
