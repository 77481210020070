<h1>Crowdtap® Cookie Policy</h1>
<p>Updated: July 19, 2023</p>
<p>
  This Cookie Policy (“Policy”) describes how Suzy, Inc., the owner of
  Crowdtap®, uses cookies on the
  <a href="http://www.crowdtap.com/">www.crowdtap.com</a> domain, sub-domains,
  and pages, the Crowdtap platform, the Crowdtap mobile application, and any
  other websites, mobile applications, or platforms that display this Policy
  (collectively, the “Sites,” and any, a "Site").
</p>
<p>
  Throughout this Policy, we will use the terms “Suzy”, "us", "we", or "our" to
  collectively mean Suzy, Inc. and its subsidiaries and affiliates.
</p>
<p>
  This Policy supplements and forms part of the
  <a href="https://www.crowdtap.com/privacy-policy">Crowdtap Privacy Policy</a>.
</p>
<h2>Table of Contents</h2>
<div class="toc">
  <ul>
    <li>
      <a routerLink="./" fragment="what-are-cookies">What are Cookies?</a>
    </li>
    <li>
      <a routerLink="./" fragment="what-are-first-party-cookies"
        >What are First Party Cookies?</a
      >
    </li>
    <li>
      <a routerLink="./" fragment="what-are-third-party-cookies"
        >What are Third Party Cookies?</a
      >
    </li>
    <li>
      <a
        routerLink="./"
        fragment="what-are-session-cookies-vs-persistent-cookies"
      >
        What are Session Cookies vs. Persistent Cookies?
      </a>
    </li>
    <li>
      <a routerLink="./" fragment="how-does-suzy-use-cookies"
        >How Does Suzy Use Cookies?</a
      >
    </li>
    <li>
      <a routerLink="./" fragment="cookie-list">Cookie List</a>
    </li>
    <li>
      <a routerLink="./" fragment="customer-use-of-cookies"
        >Customer Use of Cookies</a
      >
    </li>
    <li>
      <a routerLink="./" fragment="contact-us">Contact Us</a>
    </li>
    <li>
      <a routerLink="./" fragment="prior-versions-of-this-policy"
        >Changes to this Policy</a
      >
    </li>
    <li>
      <a routerLink="./" fragment="prior-versions-of-this-policy"
        >Prior Versions of this Policy</a
      >
    </li>
  </ul>
</div>

<h2 id="what-are-cookies">What are Cookies?</h2>
<p>
  A cookie is a small text file placed on your device (computer, tablet,
  smartphone, or any other mobile device). When you access a website, a cookie
  is placed on your device, and it will send information to the party that
  placed the cookie. Each cookie expires after a certain period of time
  depending on what it is used for. In this Policy, we use the word “cookies” as
  a catchall term to include not only cookies, but also other tracking
  technologies such as device identifiers, geolocation data, pixels, web
  beacons, and page tags.
</p>
<h2 id="what-are-first-party-cookies">What are First Party Cookies?</h2>
<p>
  The difference between a first-party cookie and a third-party cookie relates
  to who places the cookie on your device. When visiting the Sites, first-party
  cookies are cookies set by and controlled by us. These cookies enable us to
  operate an efficient service and to track patterns of user behavior to our
  Sites.
</p>
<h2 id="what-are-third-party-cookies">What are Third Party Cookies?</h2>
<p>
  Third party cookies are cookies set by someone other than us for purposes like
  collecting information on user behavior, demographics, or personalized
  marketing. Third-party cookies are also used to report usage statistics of the
  Sites and to deliver advertisements on and through the Sites.
</p>
<p>
  We do not have control over the placement of cookies by third parties, even if
  you are directed to them from our Sites. We do not have control over the
  information supplied by the cookies, nor do we retain access to this
  information. This information is controlled wholly by that third party,
  according to the respective privacy policy of the third party.
</p>
<h2 id="what-are-session-cookies-vs-persistent-cookies">
  What are Session Cookies vs. Persistent Cookies?
</h2>
<p>
  Cookies can be "persistent" or "session" cookies. Persistent cookies remain on
  your device when you go offline, while session cookies expire as soon as you
  close your web browser or end your browser session.
</p>
<h2 id="how-does-suzy-use-cookies">How Does Suzy Use Cookies?</h2>
<p>
  Like most commercial websites, we use cookies on our Sites. We use both
  session and persistent cookies on the Sites. We use first-party cookies and
  allow third parties to place cookies on your device.
</p>
<p>We use cookies for the following purposes:</p>
<ul>
  <li>Assisting you in navigating the Sites;</li>
  <li>To make your next visit to the Sites easier and more useful to you;</li>
  <li>Enabling certain functions of the Sites;</li>
  <li>Authentication;</li>
  <li>
    Server Affinity (which means that we use cookies to speed load times by
    sending traffic to the optimal server);
  </li>
  <li>
    UserIQ (which that use a third party cookie to provide communications and
    tutorials to users like you);
  </li>
  <li>
    Spam prevention (in other words, keeping bad actors from acting badly);
  </li>
  <li>
    To provide internal analytics, which we use to monitor and improve the Sites
    and features;
  </li>
  <li>To provide social media features; and</li>
  <li>
    To share information about your use of the Sites with our social media,
    advertising, and analytics partners, who may combine it with other
    information you’ve provided to them or that they’ve collected from your use
    of their services.
  </li>
</ul>

<p>We differentiate between:</p>

<ol>
  <li>
    <strong>Required cookies -</strong> These cookies are necessary for core
    features of our Sites to operate properly, remain secure, enable essential
    Site features, and stabilize the Sites. Examples include a cookie used for
    login authentication. We have to load required cookies for legitimate
    interests pursued by us in delivering essential functionality of our Sites
    to you. Because they are needed for Site operation, these cookies are always
    set to "Active".
  </li>
  <li>
    <strong> Functional cookies - </strong>These cookies allow us to analyze
    Site usage in order to evaluate and improve its performance. They are also
    used to provide a better user experience on the Sites, such as by measuring
    interactions with particular content or remembering settings. For example,
    these cookies show us which are the most frequently visited pages on the
    Sites, allow us to present Sites according to the settings you have selected
    (like your language preferences), help us record any difficulties you have
    with the Sites, and allow us to measure and analyze Site traffic. These
    cookies may be set by us or by third party providers whose services we have
    added. If you do not allow these cookies, then some or all parts of the
    Sites will not function properly.
  </li>
  <li>
    <strong>Advertising cookies - </strong>These cookies are used to inform and
    serve personalized ads more relevant to your interests and to track user
    browsing behavior. These cookies also may facilitate sharing information
    with social networks or recording your interactions with particular ads.
    This information is used to measure and improve our Sites, to test new
    advertisements, pages, features, or functionality to see how users react to
    them, and to track the effectiveness of our marketing efforts so that we can
    tailor our advertising more effectively. These cookies may be set by us,
    third party providers whose services we have added, or by our advertising
    partners. If you do not allow these cookies, you will still see basic
    advertising on your browser that is generic but not based on your interests.
  </li>
</ol>
<p>We also use other tracking technologies, such as:</p>
<ol>
  <li>
    <strong>Geolocation and Device Identifiers -</strong> If you access the
    Sites from a mobile or other device, we may collect a unique device
    identifier assigned to that device ("UDID"), geolocation data, or other
    transactional information for that device. We may collect information about
    your geolocation when your computer or mobile or other type of device is set
    to provide geolocation information or from other information such as your IP
    address, GPS, or WiFi information. For example, when your computer or
    device's GPS signal allows us to show you our nearby stores or to otherwise
    collect your geolocation information to the city you are located in when you
    visit or use the Sites.
  </li>
  <li>
    <strong>Web Beacons -</strong>
    We may use web beacons (sometimes called "tracking pixels" or "clear gifs")
    from time to time to recognize or track visitors to the Sites. Web beacons
    are tiny graphics files that contain a unique identifier that enable us to
    recognize when someone has visited our Sites or opened an email that we have
    sent them. This allows us, for example, to monitor the traffic patterns of
    users from one page within our Sites to another, to deliver or communicate
    with cookies, to understand whether you have come to our Sites from an
    online advertisement displayed on a third-party website, to improve site
    performance, and to measure the success of email marketing campaigns. In
    many instances, these technologies are reliant on cookies to function
    properly, and so declining cookies will impair their functioning.
  </li>
</ol>
<h2 id="cookie-list">Cookie List</h2>
<p>
  We want you to be in a position to make an informed decision about cookies.
  You can review our full list of cookies on our
  <a href="https://tdp.trustarc.com/?cmId=xvu4wu">Tracker Details page</a>. To
  accept or reject certain cookies, use our Cookie Preferences manager. Please
  note that rejecting, clearing, or disabling cookies might impact your ability
  to use the Sites effectively--particularly your ability to use the Crowdtap
  platform effectively. For example, enabling cookies ensures a smoother
  survey-taking experience.
</p>
<h2 id="customer-use-of-cookies">
  <strong>Customer Use of Cookies</strong>
</h2>
<p>
  If you are a third party provider of ours and you separately use cookies or
  similar technologies on your site in conjunction with a Suzy product, you are
  independently responsible for management of the data collected through those
  cookies and for compliance with all laws related to usage of these
  technologies.
</p>
<h2 id="contact-us">
  <strong>Contact Us</strong>
</h2>
<p>You can contact us by:</p>

<ul>
  <li>
    submitting a
    <a href="https://crowdtap.formcrafts.com/privacyrequest"> Privacy Request</a
    >;
  </li>
  <li>customizing your Cookie Preferences;</li>
  <li>
    contacting <a href="https://support.crowdtap.com/">Crowdtap Support</a>;
  </li>
  <li>e-mailing <a href="mailto:trust@suzy.com">trust@suzy.com</a>; or</li>
  <li>
    writing to: Suzy, Inc., Attn: Legal, 228 Park Avenue South, PMB 85529
    Broadway, New York, NY 10003.
  </li>
</ul>
<p>
  If you choose to reject certain cookies, you may still use our Sites, but your
  access to some functionality and areas will be reduced or restricted.
</p>
<h2 id="changes-to-this-policy">Changes to this Policy</h2>
<p>
  We may update or revise this Policy as needed from time to time in light of,
  for example, changing business practices, technology, or legal requirements.
  When we make changes to this Policy, we will amend the “updated” date at the
  top of this page. We encourage users to visit this page periodically to review
  any updates and remain informed about how we are processing and protecting
  data.
</p>
<h2>Prior Versions of this Notice</h2>
<ul>
  <li><a routerLink="/cookie-policy/2019-01-19">January 19, 2019</a></li>
  <li><a routerLink="/cookie-policy/2022-09-15">September 15, 2022</a></li>
</ul>
