<div
  class="success-animation bg-body-light dark:bg-body-dark"
  #successAnimation
>
  <h1>
    <img
      class="hidden dark:block"
      src="../../../../../assets/img/crowdtap-logo-white.svg"
      alt="White Crowdtap logo"
    />
    <img
      class="block dark:hidden"
      src="../../../../../assets/img/crowdtap-logo-blue.svg"
      alt="Crowdtap"
    />
  </h1>

  <div class="success-animation-inner">
    <div class="success-icon">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 130.2 130.2"
      >
        <circle class="circle" cx="65.1" cy="65.1" r="62.1" />
        <path class="check" d="M2,17 L15,30 C15,30 39.8559912,5 43,2"></path>
      </svg>
    </div>
    <div class="success-message" *ngIf="isMobile">
      <h3 class="text-light-black dark:text-white">
        {{ 'auth.great' | translate }}
      </h3>
      <p class="text-light-black dark:text-white">
        {{ 'auth.nextProtectYourAccount' | translate }}
      </p>
      <p class="text-light-black dark:text-white">
        {{ 'general.click' | translate }}
        <a href="javascript:;" [routerLink]="getRedirectLink()">{{
          'general.here' | translate
        }}</a>
        {{ 'auth.ifNothingHappensIn5Seconds' | translate }}
      </p>
    </div>
    <div class="success-message" *ngIf="!isMobile">
      <h3 class="text-light-black dark:text-white">
        {{ 'auth.success' | translate }}
      </h3>
      <p
        class="text-light-black dark:text-white"
        [innerHTML]="'auth.timeToGetStartedOnCrowdtap' | translate"
      ></p>
      <p class="text-light-black dark:text-white">
        {{ 'general.click' | translate }}
        <a href="javascript:;" routerLink="/dashboard">{{
          'general.here' | translate
        }}</a>
        {{ 'auth.ifNothingHappensIn5Seconds' | translate }}
      </p>
    </div>
  </div>
</div>
