import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import { Observable } from 'rxjs';
import { DeviceService } from '@suzy/shared/tools/device';

// TODO: Move it to shared location
export interface IPhotoResponse {
  response_value: string;
  response_display: string;
  id: string;
  mime_type: string;
  url: string;
}

@Component({
  selector: 'suzy-action-forms-photo-acquisition',
  templateUrl: './photo-acquisition.component.html',
  styleUrls: ['./photo-acquisition.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhotoAcquisitionComponent implements OnInit {
  uploadedPhotoUrl: string;
  photoCaption = '';
  uploadedPhotos: Array<IPhotoResponse> = [];
  timeToAction: number;
  timeToActionIsUpdated = false;

  @Input() action: any;
  @Input() isLoading: boolean;
  @Input() isSuccess: boolean;
  @Input() isErrorMessage: string;
  @Input() disableSkip: boolean;
  @Input() disableButton: Boolean = false;
  @Input() isSkipping: boolean;
  @Input() isSubmitting: boolean;

  @Output() timeToActionUpdate = new EventEmitter<number>();
  @Output() skip = new EventEmitter<Event>();
  @Output() mouseEvent = new EventEmitter<MouseEvent>();
  @Output() paFormSubmit = new EventEmitter<any>();

  roundValue: number;

  constructor(
    private sdk: SuzySdkService,
    private deviceService: DeviceService
  ) {}

  ngOnInit(): void {
    let screenName = this.deviceService.getDevice();
    this.roundValue = 10;
  }

  getUploadUrl = (name): Observable<any> => {
    return this.sdk.Asset.getUploadInfoAsync(
      'image',
      'action_response',
      name,
      this.action.action_id,
      null,
      'PUT',
      'auto'
    );
  };

  onUploadComplete(uploadedFile): void {
    this.uploadedPhotos.push({
      response_value: this.photoCaption,
      response_display: this.photoCaption,
      id: uploadedFile.id,
      mime_type: uploadedFile.mime_type,
      url: uploadedFile.url
    });
    this.uploadedPhotoUrl = uploadedFile.url;
  }

  updateTimeToAction(): void {
    if (!this.timeToActionIsUpdated) {
      this.timeToAction = Date.now();
      this.timeToActionUpdate.emit(this.timeToAction);
    }
    this.timeToActionIsUpdated = true;
  }

  onSkip(event: Event) {
    this.skip.emit(event);
  }

  onMouseMoveEvent(event: MouseEvent) {
    this.mouseEvent.emit(event);
  }

  onSubmit() {
    const userResponse = {
      action_id: this.action.action_id,
      answer_id: this.action.photo.answer_id,
      photos: this.uploadedPhotos,
      response_value: this.photoCaption,
      response_display: this.photoCaption
    };

    this.paFormSubmit.emit(userResponse);
  }
}
