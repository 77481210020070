<div #wrapper>
  <a [href]="skipLinkPathContent" class="sr-only">Skip to main content</a>

  <app-general-header> </app-general-header>
  <!-- [Note] When both steps are completed, below success animation plays and direct a user to Dashboard -->
  <app-sms-success-animation
    *ngIf="isPassVerification"
  ></app-sms-success-animation>

  <section aria-label="main" role="main" id="main-content">
    <a [href]="skipLinkPathFooter" class="sr-only">Skip to footer</a>
    <article class="flex flex-col w-full px-4 m-auto sm:w-3/5 md:w-2/5">
      <div class="center-column">
        <!---
        [Note] There are two steps in this component. Each step is within a parent div named as below, respectively.
        .send-phone (step for send a phone number)
        .send-code (step for send a confirmation code)

        [Note] There are two forms in this component. Each form is within a parent div named as below, respectively.
        .send-phone-form (form for send a phone number)
        .send-code-form (form for send a confirmation code)
        -->

        <!-- START: Step 1 - Sned Phone  -->
        <ng-container *ngIf="!isSubmittedPhone">
          <section>
            <h1 class="text-light-black dark:text-white">
              {{ 'auth.confirmYourPhone' | translate }}
            </h1>

            <form [formGroup]="sendPhoneForm">
              <div class="text-light-black dark:text-white text-center">
                <label>{{ 'auth.mobilePhoneNumber' | translate }}</label>
                <span>
                  {{ 'auth.confirmationCodeWillBeSent' | translate }}
                </span>
                <div class="input-container">
                  <img
                    src="assets/img/svg/icons/ic-temp-us-flag.svg"
                    alt="USA flag"
                  />
                  <span>+ 1</span>
                  <input
                    appPhoneInput
                    type="tel"
                    placeholder="Enter your phone number"
                    formControlName="phone"
                    autocomplete="off"
                    required
                    data-track="member-sign-up-phone-verification"
                    class="text-light-black bg-input border-active-link dark:text-white dark:bg-black-light dark:border-active-link-dark"
                  />
                </div>

                <small *ngIf="fieldErrors('phone')">
                  <span *ngIf="fieldErrors('phone').server && isPhoneTouched()">
                    {{ fieldErrors('phone').server }}
                  </span>
                  <span
                    *ngIf="
                      fieldErrors('phone').invalidPhone && isPhoneTouched()
                    "
                  >
                    *{{ 'auth.pleaseEnterAValidUSMobileNumber' | translate }}
                  </span>
                </small>
              </div>

              <div class="actions">
                <button
                  type="submit"
                  data-track="member-sign-up-get-code"
                  [disabled]="!isValidPhone || isLoading"
                  (click)="onSubmitPhone()"
                  class="submit-btn"
                >
                  {{ 'auth.getCode' | translate }}
                  <div class="absolute left-8 button-spinner top-7">
                    <suzy-spinner
                      *ngIf="isLoading"
                      size="small"
                      button="true"
                      [round]="10"
                      [color]="'#ffffff'"
                      [overlay]="false"
                    >
                    </suzy-spinner>
                  </div>
                </button>

                <div class="link-container">
                  <p class="text-light-black dark:text-white">
                    {{ 'auth.alreadyHaveCode' | translate }}
                  </p>
                  <button (click)="showSubmitCode()">
                    {{ 'auth.goHere' | translate }}
                  </button>
                </div>
              </div>
            </form>

            <p class="text-active-link dark:active-link-dark line">
              <span
                class="text-light-black dark:text-white bg-body-light dark:bg-body-dark"
              >
                {{ 'support.needHelp' | translate }}
              </span>
            </p>

            <div class="help-container">
              <p class="text-light-black dark:text-white">
                {{ 'auth.havingYourMobileNumber' | translate }}
              </p>
              <p class="text-light-black dark:text-white">
                {{ 'support.gotQuestionsReadOur' | translate }}
                <a routerLink="/terms-of-service" target="_blank">{{
                  'nav.termsOfService' | translate
                }}</a>
                {{ 'general.and' | translate }}
                <a routerLink="/privacy-policy" target="_blank">{{
                  'nav.privacyPolicy' | translate
                }}</a>
                {{ 'support.forMoreInfo' | translate }}
              </p>
            </div>
          </section>
        </ng-container>
        <!-- END: Step 1 - Send Phone -->

        <!-- START: Step 2 - Send Code  -->
        <ng-container *ngIf="isSubmittedPhone">
          <section>
            <h1 class="text-light-black dark:text-white">
              {{ 'auth.enterYourCode' | translate }}
            </h1>
            <p
              class="text-light-black dark:text-white"
              [innerHTML]="
                'auth.weJustSentAConfirmationCodeViaSMSTo'
                  | translate: { phone: sendPhoneForm.get('phone').value }
              "
            ></p>
            <form [formGroup]="sendCodeForm">
              <div class="text-light-black dark:text-white text-center">
                <div class="input-container">
                  <input
                    type="tel"
                    class="text-light-black bg-input border-active-link dark:text-white dark:bg-black-light dark:border-active-link-dark"
                    formControlName="code"
                    autocomplete="off"
                    required
                    data-track="member-sign-up-enter-code"
                    placeholder="Type your 5-digit code"
                  />
                </div>

                <small
                  *ngIf="fieldErrors('code')"
                  class="form-text text-muted danger"
                >
                  <span
                    *ngIf="fieldErrors('code').server && isCodeTouched()"
                    class="server-error"
                  >
                    {{ fieldErrors('code').server }}
                  </span>
                  <span
                    *ngIf="fieldErrors('code').resend && isCodeTouched()"
                    class="invalid-input"
                  >
                    {{ fieldErrors('code').resend }}
                  </span>
                  <span
                    *ngIf="fieldErrors('code').required && isCodeTouched()"
                    class="invalid-input"
                  >
                    *{{ 'auth.confirmationCodeIsRequired' | translate }}
                  </span>
                </small>
              </div>

              <div class="actions">
                <button
                  type="submit"
                  data-track="member-sign-up-verify"
                  class="submit-btn"
                  [disabled]="!isValidCode || isLoading"
                  (click)="onSubmitCode()"
                >
                  {{ 'general.submit' | translate }}
                  <div class="button-spinner" *ngIf="isLoading">
                    <suzy-spinner
                      size="small"
                      button="true"
                      [overlay]="false"
                    ></suzy-spinner>
                  </div>
                </button>

                <div class="link-container">
                  <p class="text-light-black dark:text-white">
                    {{ 'auth.didntGetAnything' | translate }}
                  </p>
                  <button
                    (click)="showSubmitCode()"
                    data-track="member-sign-up-resend-code"
                  >
                    {{ 'auth.sendANewCode' | translate }}
                  </button>
                </div>
              </div>
            </form>

            <p class="text-active-link dark:active-link-dark line">
              <span
                class="text-light-black dark:text-white bg-body-light dark:bg-body-dark"
              >
                {{ 'support.needHelp' | translate }}
              </span>
            </p>

            <div class="help-container">
              <p class="text-light-black dark:text-white">
                {{ 'auth.doYouHaveANewNumber' | translate }}
                <a
                  (click)="onChangePhoneNumber()"
                  data-track="member-sign-up-change-phone-number"
                  >{{ 'auth.clickHereToChangeYourPhoneNumber' | translate }}</a
                >
              </p>

              <p class="text-light-black dark:text-white">
                {{ 'support.stillHavingTrouble' | translate }}
                <a
                  href="http://support.crowdtap.com/"
                  target="_blank"
                  data-track=""
                  >{{ 'support.supportTeam' | translate }}</a
                >.
              </p>
            </div>
          </section>
        </ng-container>
        <!-- END: Step 2 - Send Code -->
      </div>
    </article>
  </section>
  <app-general-footer
    id="main-footer"
    [isMobile]="isMobile$ | async"
    (backToTop)="onBackToTop()"
  >
  </app-general-footer>
</div>
