import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { PointsService } from '@suzy/crowdtap/tools/points';

@Component({
  selector: 'app-sidebar-profile',
  templateUrl: './sidebar-profile.component.html',
  styleUrls: ['./sidebar-profile.component.scss']
})
export class SidebarProfileComponent implements OnInit {
  points$ = this.pointsService.points$;

  constructor(private pointsService: PointsService) {}

  ngOnInit(): void {
    this.pointsService.refreshPoints();
  }
}
