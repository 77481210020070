import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'privacy-policy-v20230719',
  templateUrl: './privacy-policy-v20230719.component.html',
  styleUrls: ['./privacy-policy-v20230719.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivacyPolicyV20230719Component {
  section3 = false;
  exampleOfTargeting = false;

  toggle(property: string) {
    this[property] = !this[property];
  }
}
