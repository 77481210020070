import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '@suzy/crowdtap/data-access/user';
import { Gender, OnboardingStatus } from '@suzy/shared/data-access/suzy-sdk';
import {
  CampaignKeysName,
  PanelKeysName,
  PromoKeysName,
  ReferralKeysName,
  SegmentService
} from '@suzy/shared/data-access/tracking';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import swal from 'sweetalert2';
import { DateNativeAdapter } from '../../../../core/services/date-native-adapter.service';
import { GrecaptchaService } from '../../../../core/services/grecaptcha.service';
import {
  ISignupUser,
  SignupService
} from '../../../../views/anonymous/signup/signup.service';

@Component({
  selector: 'app-getting-to-know-you',
  templateUrl: './getting-to-know-you.component.html',
  styleUrls: ['./getting-to-know-you.component.scss']
})
export class GettingToKnowYouComponent implements OnInit, AfterViewInit {
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef;
  form: UntypedFormGroup;
  submitted = false;
  Gender = Gender;
  stateOptions = [];
  user: ISignupUser;
  minBirthdate: NgbDateStruct = { year: 1910, month: 1, day: 1 };
  submitting = false;
  currentSource: any;
  campaignKeysName: CampaignKeysName;
  currentCampaignKeys: any;
  sourceUrl: string;
  recaptchaToken: string;
  currentPanelToken: any;
  currentPanelKeys: any;
  panelKeysName: PanelKeysName;
  isLoading: boolean;
  el_wrapper: HTMLElement;
  referralKeysName: ReferralKeysName;
  promoKeysName: PromoKeysName;
  currentReferralToken: any;
  currentReferralKeys: any;
  currentPromoToken: any;
  currentPromoKeys: any;
  isFbRegistered: boolean;
  skipLinkPathContent: string;
  skipLinkPathFooter: string;

  private storage: Storage;

  constructor(
    private fb: UntypedFormBuilder,
    private signupService: SignupService,
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private dateAdapter: DateNativeAdapter,
    private gtm: Angulartics2GoogleTagManager,
    private grecaptchaService: GrecaptchaService,
    private titleService: Title,
    private segmentService: SegmentService
  ) {}

  ngOnInit(): void {
    this.setHeadTitle();
    this.skipLinkPathContent = `${this.router.url}#main-content`;
    this.skipLinkPathFooter = `${this.router.url}#main-footer`;
    this.storage = localStorage;
    this.user = this.signupService.getUser();
    if (
      this.user.facebook_access_token === '' ||
      this.user.facebook_access_token === undefined
    ) {
      this.isFbRegistered = false;
    } else {
      this.isFbRegistered = true;
    }

    this.stateOptions = this.route.snapshot.data.states;
    this.createForm();
    this.rebuildForm(this.user);
    this.checkCampaignKeys();
    this.checkPanelToken();
    this.checkReferralToken();
    this.checkPromoToken();
    this.grecaptchaService.execute('signup', token => {
      this.recaptchaToken = token;
    });
  }

  ngAfterViewInit(): void {
    this.el_wrapper = this.wrapper.nativeElement as HTMLElement;
  }

  setHeadTitle(): any {
    this.titleService.setTitle('Crowdtap® - Getting to know you');
  }

  checkCampaignKeys(): void {
    this.campaignKeysName = new CampaignKeysName();
    this.currentSource = this.authService.getLocalKey(
      this.campaignKeysName.utmSource
    );
    this.sourceUrl = this.authService.getLocalKey(this.campaignKeysName.source);

    this.currentSource = this.authService.getLocalKey(
      this.campaignKeysName.utmSource
    );

    if (!this.sourceUrl) {
      const snapshot: RouterStateSnapshot = this.router.routerState.snapshot;

      if (snapshot.url.includes('?')) {
        this.authService.setLocalKey(
          this.campaignKeysName.source,
          snapshot.url.substring(snapshot.url.indexOf('?'))
        );
      }
    }

    if (!this.currentSource) {
      this.route.queryParams.subscribe(params => {
        if (params[this.campaignKeysName.utmSource]) {
          for (const prop in params) {
            if (params[prop]) {
              this.authService.setLocalKey(prop, params[prop]);
            }
          }
          this.currentSource = params[this.campaignKeysName.utmSource];
        }
      });
    }
  }

  checkPanelToken(): void {
    this.panelKeysName = new PanelKeysName();
    this.currentPanelToken = this.authService.getLocalKey(
      this.panelKeysName.panelToken
    );

    if (!this.currentPanelToken) {
      this.route.queryParams.subscribe(params => {
        if (params[this.panelKeysName.panelToken]) {
          for (const prop in params) {
            if (params[prop]) {
              this.authService.setLocalKey(prop, params[prop]);
            }
          }
          this.currentPanelKeys = this.authService.getCurrentPanelKeys();
        }
      });
    } else {
      this.currentPanelKeys = this.authService.getCurrentPanelKeys();
    }
  }

  checkReferralToken(): void {
    this.referralKeysName = new ReferralKeysName();
    this.currentReferralToken = this.authService.getLocalKey(
      this.referralKeysName.referralToken
    );

    if (!this.currentReferralToken) {
      this.route.queryParams.subscribe(params => {
        if (params[this.referralKeysName.referralToken]) {
          for (const prop in params) {
            if (params[prop]) {
              this.authService.setLocalKey(prop, params[prop]);
            }
          }
          this.currentReferralKeys = this.authService.getCurrentReferralKeys();
        }
      });
    } else {
      this.currentReferralKeys = this.authService.getCurrentReferralKeys();
    }
  }

  checkPromoToken(): void {
    this.promoKeysName = new PromoKeysName();
    this.currentPromoToken = this.authService.getLocalKey(
      this.promoKeysName.promoToken
    );

    if (!this.currentPromoToken) {
      this.route.queryParams.subscribe(params => {
        if (params[this.promoKeysName.promoToken]) {
          for (const prop in params) {
            if (params[prop]) {
              this.authService.setLocalKey(prop, params[prop]);
            }
          }
          this.currentPromoKeys = this.authService.getCurrentPromoKeys();
        }
      });
    } else {
      this.currentPromoKeys = this.authService.getCurrentPromoKeys();
    }
  }

  createForm(): void {
    this.form = this.fb.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: [this.user.email, Validators.required],
      city: ['', Validators.required],
      birthdate: ['', Validators.required],
      state_id: ['', Validators.required],
      zip_code: ['', Validators.required],
      gender: ['', Validators.required],
      accepted: ['', Validators.requiredTrue]
    });
  }

  rebuildForm(user: any): void {
    this.form.reset({
      ...user,
      birthdate:
        user.birthdate !== ''
          ? this.dateAdapter.fromModel(new Date(user.birthdate))
          : '',
      gender: user.gender === Gender.unknown ? '' : user.gender
    });
  }

  onSubmit(): void {
    this.submitted = true;
    this.isLoading = true;

    if (this.form.valid && !this.submitting) {
      this.submitting = true;
      const user = this.prepareSave();
      this.signupService.signup(user).subscribe(response => {
        this.isLoading = false;

        this.submitting = false;
        if (response.success) {
          this.signupService.clearSession();
          this.identifySegmentUser(user, response);
          this.gtm.setUsername(response.item.user_id);
          const userInformation = {
            ...response.item,
            is_fb_registered: this.isFbRegistered,
            is_email_registered: !this.isFbRegistered
          };
          delete userInformation.api_key;
          delete userInformation.api_secret;
          this.gtm.eventTrack('home-register-success', {
            event: 'home-register-success',
            gtmCustom: {
              userInformation
            }
          });
          if (this.currentSource) {
            this.authService.purgeCampaignKeys();
          }

          if (response.meta && response.meta.message_kind === 'panel_notice') {
            this.storage.setItem(
              'panel_message',
              JSON.stringify(response.meta.message)
            );

            this.authService.purgePanelKeys();
          }

          this.authService.login(response.item);
          this.router.navigate(['/auth/sms-authentication']);

          // this.successAnimation();
        } else {
          this.gtm.eventTrack('home-register-failed', {
            event: 'home-register-failed',
            gtmCustom: {
              userEmail: user.email,
              errorMessage: response.message
            }
          });
          this.isLoading = false;
          this.segmentService.trackAuthError('Sign Up Error', {
            error_message: response.message,
            error_source: 'backend',
            error_ui_message: response.message,
            method: this.isFbRegistered ? 'facebook' : 'email'
          });

          this.handleServerErrors(response);
        }
      });
    }
  }

  identifySegmentUser(user: any, response: any): void {
    this.segmentService.identify(response.item.user_id, {
      email: user.email,
      birthdate: response.item.profile.birthdate,
      city: user.city,
      gender_name: user.gender === '2' ? 'male' : 'female',
      has_profile_photo: false,
      postal_code: user.zip_code,
      state: response.item.profile.state_short,
      first_name: user.first_name,
      last_name: user.last_name,
      sign_up_method: this.isFbRegistered ? 'facebook' : 'email'
    });
    this.segmentService.track('Signed Up');
  }

  getOnboardingStatus(status: number): string {
    switch (status) {
      case OnboardingStatus.completed:
        return 'Completed';
      case OnboardingStatus.grandfathered:
        return 'Grandfathered';
      case OnboardingStatus.onboarding:
        return 'Onboarding';
      case OnboardingStatus.trapped:
        return 'Trapped';
      default:
        return 'Unknown';
    }
  }

  calculateAge(birthday: string): number {
    const today = new Date();
    const bday = new Date(birthday);
    let age = today.getFullYear() - bday.getFullYear();
    const month = today.getMonth() - bday.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < bday.getDate())) {
      age--;
    }

    return age;
  }

  prepareSave(): ISignupUser {
    const newUser = this.form.value;
    if (this.currentSource) {
      this.currentCampaignKeys = this.authService.getCurrentCampaignKeys();
      newUser.source = this.currentCampaignKeys.source;
      newUser.c_source = this.currentCampaignKeys.utmSource;
      newUser.c_medium = this.currentCampaignKeys.utmMedium;
      newUser.c_name = this.currentCampaignKeys.utmName;
      newUser.c_terms = this.currentCampaignKeys.utmTerm;
      newUser.c_content = this.currentCampaignKeys.utmContent;
      newUser.c_misc_one = this.currentCampaignKeys.miscOne;
      newUser.c_misc_two = this.currentCampaignKeys.miscTwo;
    }

    if (this.currentPanelToken) {
      this.currentPanelKeys = this.authService.getCurrentPanelKeys();
      newUser.panel_token = this.currentPanelKeys.panelToken;
    }

    if (this.currentReferralToken) {
      this.currentReferralKeys = this.authService.getCurrentReferralKeys();
      newUser.referral_token = this.currentReferralKeys.referralToken;
    }

    if (this.currentPromoToken) {
      this.currentPromoKeys = this.authService.getCurrentPromoKeys();
      newUser.promotion_token = this.currentPromoKeys.promoToken;
    }

    if (newUser.birthdate.day) {
      newUser.birthdate = this.dateAdapter
        .toModel(newUser.birthdate)
        .toISOString();
    } else {
      newUser.birthdate = undefined;
    }

    newUser.recaptcha_token = this.recaptchaToken;

    this.signupService.setUser(newUser);

    return this.signupService.getUser();
  }

  handleServerErrors(response): void {
    if (response.errors) {
      const serverErrors = response.errors;
      const fields = Object.keys(serverErrors || {});
      fields.forEach(field => {
        const control = this.form.get(field);
        control.setErrors({ server: serverErrors[field] });
      });
    } else {
      swal.fire({
        buttonsStyling: false,
        confirmButtonText: 'Continue',
        confirmButtonClass: 'btn-confirm',
        customClass: 'general-modal',
        showConfirmButton: true,
        text: response.message,
        title: 'Error',
        type: 'warning'
      });
    }
  }

  onBackToTop(): void {
    this.el_wrapper.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    });
  }

  fieldErrors(name: string): any {
    const control = this.form.get(name);

    if (control && (control.touched || this.submitted) && control.errors) {
      return control.errors;
    } else {
      return undefined;
    }
  }
}
