import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs/';
import { map, catchError, retry } from 'rxjs/operators';
import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import { Router } from '@angular/router';
import { AuthenticationService } from '@suzy/crowdtap/data-access/user';
import { TranslateService } from '@ngx-translate/core';

const SAFENUMBER = 200000;

export interface DemographicOption {
  id: string;
  name: string;
}

@Injectable()
export class DemographicService {
  constructor(
    private sdk: SuzySdkService,
    private router: Router,
    private translate: TranslateService,
    private auth: AuthenticationService
  ) {}

  getDemographicOptions(
    endpoint: any,
    prefix: string
  ): Observable<Array<DemographicOption>> {
    return endpoint.findAsync(0, SAFENUMBER).pipe(
      map((response: Response | any) => {
        let output = '';
        response.items.forEach(item => {
          output += `"${item.token}": "${item.parenting_name}",`;
        });

        return response['items']
          .filter(item => item.token !== undefined)
          .map(item => {
            return {
              id: item[`${prefix}_id`],
              name: item['token']
            };
          });
      }),
      retry(5),
      catchError(error => {
        window.location.href = this.router
          .parseUrl(this.router.url)
          .root.children['primary'].segments.map(it => it.path)
          .join('/');

        return of({ error });
      })
    );
  }

  getStateOptions(countryId: string): Observable<Array<any>> {
    return this.sdk.State.getStateByCountryAsync(
      countryId,
      0,
      SAFENUMBER,
      'state_short.sort',
      false
    ).pipe(
      map((response: any) => {
        return response['items'].map(item => {
          return {
            id: item['state_id'],
            name: `${item['token']}`,
            short: item['state_short']
          };
        });
      })
    );
  }

  getEthnicityOptions(): Observable<Array<any>> {
    return this.getDemographicOptions(this.sdk.Ethnicity, 'ethnicity');
  }

  getEducationOptions(): Observable<Array<any>> {
    return this.getDemographicOptions(this.sdk.Education, 'education');
  }

  getEmploymentOptions(): Observable<Array<any>> {
    return this.getDemographicOptions(this.sdk.Employment, 'employment');
  }

  getHouseholdOptions(): Observable<Array<any>> {
    return this.getDemographicOptions(this.sdk.Household, 'household');
  }

  getIncomeLevelOptions(): any {
    return this.getDemographicOptions(this.sdk.IncomeLevel, 'income_level');
  }

  getRelationshipOptions(): any {
    return this.getDemographicOptions(this.sdk.Relationship, 'relationship');
  }

  getParentingOptions(): any {
    return this.getDemographicOptions(this.sdk.Parenting, 'parenting');
  }

  getIndustryOptions(): any {
    return this.getDemographicOptions(this.sdk.Industry, 'industry');
  }

  getIndustryTitleOptions(industryId: string): Observable<Array<any>> {
    return this.sdk.IndustryTitle.getIndustryTitleByIndustryAsync(
      industryId,
      0,
      SAFENUMBER,
      'industry_title_name.sort',
      false
    ).pipe(
      map(response => {
        return response['items'];
      })
    );
  }

  getChildStatusOptions(): any {
    return this.getDemographicOptions(this.sdk.ChildStatus, 'child_status');
  }

  getState(stateId): string {
    return this.sdk.State.getState(stateId).subscribe(data => {
      if (data.success) {
        return data.state;
      }
    });
  }
}
