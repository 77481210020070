<button
  type="button"
  class="btn btn-block btn-social btn-facebook"
  [attr.data-track]="trackAttr"
  [disabled]="isLoading"
  (click)="socialSignIn('facebook')"
>
  <span class="fa fa-facebook"></span>
  <div class="button-spinner" *ngIf="isLoading">
    <suzy-spinner size="small" button="true" [overlay]="false"></suzy-spinner>
  </div>
  <span>{{ label }}</span>
</button>
