<div
  class="hidden md:block absolute bg-box-light dark:bg-box-dark"
  style="z-index: 2"
  [style.width]="cornerPosition.width"
  [style.height]="cornerPosition.height"
  [style.top]="cornerPosition.top"
  [style.left]="cornerPosition.left"
  [ngClass]="{ invisible: !showColumnHeader }"
>
  &nbsp;
</div>
<!-- Sticky Column Header -->
<div
  class="hidden md:block absolute overflow-hidden"
  style="z-index: 1"
  [style.width]="viewPosition.width"
  [style.height]="viewPosition.height"
  [style.top]="viewPosition.top"
  [style.left]="viewPosition.left"
  [ngClass]="{ invisible: !showColumnHeader }"
>
  <div
    class="absolute top-0"
    [style.left]="headerScrollX + 'px'"
    [style.width]="tableWidth + 'px'"
  >
    <table class="w-full grid-action-table">
      <tr class="header-row">
        <th
          class="z-10 bg-box-light dark:bg-box-dark"
          [style.width]="cornerPosition.width"
        ></th>
        <th
          class="break-word bg-box-light dark:bg-box-dark"
          *ngFor="let item of rows[0]; let i = index"
          [ngClass]="{ error: colErrors[i] }"
        >
          <div
            class="break-words text-box-dark dark:text-box-light"
            [class.whitespace-nowrap]="item.col.answer_text.length <= 25"
            [class.wide]="item.col.answer_text.length > 25"
          >
            {{ item.col.answer_text }}
          </div>
        </th>
      </tr>
    </table>
  </div>
</div>

<form novalidate="" (ngSubmit)="onSubmit()" #f="ngForm" class="h-full">
  <!-- Form Content Starts -->
  <div
    [ngClass]="{ isMobile: isMobile }"
    class="relative w-full h-full bg-body-light md:py-4 md:pt-10 form md:bg-box-light dark:bg-box-dark"
    (window:resize)="divResized($event)"
  >
    <div class="form-group form-filling" #divFormGroup>
      <!-- Sticky Row Header -->
      <div
        class="hidden md:block md:pt-10 pb-4 absolute top-0 left-0"
        style="z-index: 1"
      >
        <table class="grid-action-table">
          <tr class="header-row">
            <th
              class="z-10 bg-box-light dark:bg-box-dark"
              [style.height]="viewPosition.height"
            >
              &nbsp;
            </th>
          </tr>
          <tr *ngFor="let item of rows; let i = index">
            <td
              class="pr-1 row-header text-box-dark bg-box-light dark:text-box-light dark:bg-box-dark"
              [ngClass]="{ error: rowErrors[i] }"
            >
              <div
                class="break-words"
                style="min-height: 1.5rem"
                [class.whitespace-nowrap]="item[0].row.answer_text.length <= 25"
                [class.wide]="item[0].row.answer_text.length > 25"
              >
                {{ item[0].row.answer_text }}
              </div>
            </td>
          </tr>
        </table>
      </div>

      <!-- Form fields : Container for Radio/Checkbox Starts -->
      <div
        id="fake-corner"
        [style.width]="fakeCorner.width"
        [style.height]="fakeCorner.height"
      >
        &nbsp;
      </div>
      <!-- desktop view -->
      <ng-scrollbar
        [autoHeightDisabled]="false"
        [track]="'all'"
        trackClass="ngx-custom-scrollbar"
        thumbClass="ngx-custom-scrollbar-thumb"
      >
        <div class="hidden form-field-container md:block pb-4">
          <table #gridTable class="w-full grid-action-table">
            <tr class="header-row">
              <th
                class="z-10 bg-box-light dark:bg-box-dark"
                id="original-corner"
                #originalCorner
              ></th>
              <th
                class="break-word bg-box-light dark:bg-box-dark"
                *ngFor="let item of rows[0]; let i = index"
                [ngClass]="{ error: colErrors[i] }"
              >
                <div
                  class="break-words text-box-dark dark:text-box-light"
                  [class.whitespace-nowrap]="item.col.answer_text.length <= 25"
                  [class.wide]="item.col.answer_text.length > 25"
                  [attr.data-track]="'member-custom-grid-column-header-' + i"
                >
                  {{ item.col.answer_text }}
                </div>
              </th>
            </tr>
            <tr *ngFor="let item of rows; let i = index">
              <td
                class="pr-1 row-header text-box-dark bg-box-light dark:text-box-light dark:bg-box-dark"
                [ngClass]="{ error: rowErrors[i] }"
              >
                <div
                  class="break-words invisible"
                  [class.whitespace-nowrap]="
                    item[0].row.answer_text.length <= 25
                  "
                  [class.wide]="item[0].row.answer_text.length > 25"
                  [attr.data-track]="'member-custom-grid-row-header-' + i"
                >
                  {{ item[0].row.answer_text }}
                </div>
              </td>
              <td *ngFor="let row of item; let j = index">
                <ng-container
                  *ngIf="
                    action.grid.open_row_min === 1 &&
                      action.grid.open_row_max === 1;
                    else multiSelect
                  "
                >
                  <label
                    for="{{ 'radio-' + i + '-' + j }}"
                    [attr.aria-labelledby]="
                      'radio-' + i + '-' + j + '-label-container'
                    "
                    class="flex items-center"
                    tabindex="0"
                  >
                    <input
                      class="sr-only"
                      name="options"
                      type="checkbox"
                      [checked]="row.isSelected"
                      (change)="
                        resetAll(item); selectChoice(row, item, $event, i)
                      "
                      [attr.data-track]="
                        'member-custom-grid-radio-btn-' + i + '-' + j
                      "
                      id="{{ 'radio-' + i + '-' + j }}"
                    />
                    <div
                      *ngIf="!row.isSelected"
                      class="w-6 h-6 mx-auto rounded-full border-3 border-active-link mr-4 ltr:mr-4 rtl:ml-4"
                    ></div>
                    <div
                      *ngIf="row.isSelected"
                      class="flex items-center justify-center w-6 h-6 mx-auto rounded-full bg-blue-dark mr-4 ltr:mr-4 rtl:ml-4"
                    >
                      <span class="text-xs text-white fa fa-check"></span>
                    </div>
                  </label>
                </ng-container>
                <ng-template #multiSelect>
                  <label
                    for="{{ 'check-' + i + '-' + j }}"
                    [attr.aria-labelledby]="
                      'check-' + i + '-' + j + '-label-container'
                    "
                    class="flex items-center"
                    tabindex="0"
                  >
                    <input
                      class="sr-only"
                      type="checkbox"
                      name="options"
                      [checked]="row.isSelected"
                      (change)="selectChoice(row, item, $event, i)"
                      [attr.data-track]="
                        'member-custom-grid-checkbox-btn-' + i + '-' + j
                      "
                      id="{{ 'check-' + i + '-' + j }}"
                    />
                    <div
                      *ngIf="!row.isSelected"
                      class="w-6 h-6 mx-auto border-3 border-active-link mr-4 ltr:mr-4 rtl:ml-4"
                    ></div>
                    <div
                      *ngIf="row.isSelected"
                      class="flex items-center justify-center w-6 h-6 mx-auto bg-blue-dark mr-4 ltr:mr-4 rtl:ml-4"
                    >
                      <span class="text-xs text-white fa fa-check"></span>
                    </div>
                  </label>
                </ng-template>
              </td>
            </tr>
          </table>
        </div>
      </ng-scrollbar>
      <p class="px-1 my-6 text-base font-light text-error" *ngIf="errorMessage">
        <svg
          class="inline-block mr-2 ltr:mr-2 rtl:ml-2"
          width="24"
          height="24"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.49992 4.04162C8.06269 4.04162 7.70825 4.39606 7.70825 4.83329V7.99996C7.70825 8.43718 8.06269 8.79162 8.49992 8.79162C8.93714 8.79162 9.29158 8.43718 9.29158 7.99996V4.83329C9.29158 4.39606 8.93714 4.04162 8.49992 4.04162ZM9.22825 10.8658C9.21092 10.8154 9.18696 10.7675 9.157 10.7233L9.062 10.6046C8.83435 10.38 8.4939 10.3144 8.19908 10.4383C8.10314 10.4784 8.01478 10.5347 7.93783 10.6046C7.78964 10.754 7.70703 10.9562 7.70825 11.1667C7.7095 11.2701 7.73101 11.3723 7.77158 11.4675C7.84356 11.6664 8.00019 11.823 8.19908 11.895C8.3907 11.9797 8.60913 11.9797 8.80075 11.895C8.99964 11.823 9.15627 11.6664 9.22825 11.4675C9.26881 11.3723 9.29033 11.2701 9.29158 11.1667C9.29547 11.114 9.29547 11.061 9.29158 11.0083C9.27795 10.9579 9.25659 10.9098 9.22825 10.8658ZM8.49992 0.083313C4.12766 0.083313 0.583252 3.62773 0.583252 7.99998C0.583252 12.3722 4.12766 15.9166 8.49992 15.9166C12.8722 15.9166 16.4166 12.3722 16.4166 7.99998C16.4166 5.90035 15.5825 3.88671 14.0978 2.40205C12.6132 0.917388 10.5995 0.083313 8.49992 0.083313ZM8.49993 14.3333C5.00213 14.3333 2.1666 11.4978 2.1666 7.99995C2.1666 4.50215 5.00213 1.66662 8.49993 1.66662C11.9977 1.66662 14.8333 4.50215 14.8333 7.99995C14.8333 9.67966 14.166 11.2906 12.9783 12.4783C11.7905 13.666 10.1796 14.3333 8.49993 14.3333Z"
            fill="#FF5F57"
          />
        </svg>

        <span class="align-middle text-error"> {{ errorMessage }}</span>
      </p>
      <!-- mobile view -->
      <div class="block md:hidden">
        <ul class="bg-body-light dark:bg-box-dark">
          <li
            class="px-6 cursor-pointer"
            [ngClass]="
              isActiveTab == i ? 'activeTab bg-box-light dark:bg-body-dark' : ''
            "
            *ngFor="
              let item of rows;
              let i = index;
              let last = last;
              let first = first
            "
          >
            <div
              class="row-header text-box-dark dark:text-box-light"
              [ngClass]="{ error: rowErrors[i] }"
            >
              <div
                [ngClass]="{ 'pb-6': last }"
                [class.wide]="item[0].row.answer_text.length > 25"
                [attr.data-track]="'member-custom-grid-row-header-' + i"
              >
                <div
                  (click)="item.isTouched = true; gridHasError(); toggle(i)"
                  [ngClass]="{ 'border-b': isActiveTab != i }"
                  class="flex justify-between py-4 text-base font-bold dark:border-white-line border-black-line"
                >
                  <div>
                    <p class="flex">
                      <span class="mr-2"> {{ i + 1 }}</span>
                      <span class="break-words">
                        {{ item[0].row.answer_text }}
                      </span>
                    </p>

                    <p
                      class="ml-4 text-xs font-semibold leading-loose text-error"
                      *ngIf="isActiveTab != i && rowErrors[i] && item.isTouched"
                    >
                      Please make a selection
                    </p>
                    <p class="ml-4 truncate w-72">
                      <ng-container
                        *ngFor="
                          let row of getSelectedRow(item);
                          let j = index;
                          let last = last
                        "
                      >
                        <span
                          class="text-xs break-words opacity-50 font-semiBold"
                        >
                          {{ row.col.answer_text }} <span *ngIf="!last">,</span>
                        </span>
                      </ng-container>
                    </p>
                  </div>
                  <ng-container *ngIf="isActiveTab != i; else down">
                    <svg
                      class="inline-block mt-2"
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.415 0.237785L6 4.82279L10.585 0.237785L12 1.65279L6 7.65279L0 1.65279L1.415 0.237785Z"
                        fill="white"
                        class="fill-current dark:black"
                      />
                    </svg>
                  </ng-container>
                  <ng-template #down>
                    <svg
                      class="inline-block mt-2"
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.585 7.65279L6 3.06779L1.415 7.65279L1.23703e-07 6.23779L6 0.237792L12 6.23779L10.585 7.65279Z"
                        fill="white"
                        class="fill-current dark:black"
                      />
                    </svg>
                  </ng-template>
                </div>

                <div *ngFor="let row of item; let j = index">
                  <div
                    [ngClass]="isActiveTab == i ? 'block mt-3 pb-7' : 'hidden'"
                  >
                    <ng-container
                      *ngIf="
                        action.grid.open_row_min === 1 &&
                          action.grid.open_row_max === 1;
                        else multiSelect
                      "
                    >
                      <label
                        for="{{ 'radio-' + i + '-' + j }}"
                        [attr.aria-labelledby]="
                          'radio-' + i + '-' + j + '-label-container'
                        "
                        class="flex items-center"
                        tabindex="0"
                      >
                        <input
                          class="sr-only"
                          name="options"
                          type="checkbox"
                          [checked]="row.isSelected"
                          (change)="
                            resetAll(item); selectChoice(row, item, $event, i)
                          "
                          [attr.data-track]="
                            'member-custom-grid-radio-btn-' + i + '-' + j
                          "
                          id="{{ 'radio-' + i + '-' + j }}"
                        />
                        <div
                          *ngIf="!row.isSelected"
                          class="w-6 h-6 rounded-full min-w-6 border-3 border-active-link mr-4 ltr:mr-4 rtl:ml-4"
                        ></div>
                        <div
                          *ngIf="row.isSelected"
                          class="flex items-center justify-center w-6 h-6 rounded-full bg-blue-dark mr-4 ltr:mr-4 rtl:ml-4"
                        >
                          <span class="text-xs text-white fa fa-check"></span>
                        </div>
                        <div
                          *ngFor="let items of rows[i]; let l = index"
                          [ngClass]="{ error: colErrors[i] }"
                        >
                          <div
                            *ngIf="l == j"
                            class="text-sm font-semibold break-words text-box-dark dark:text-box-light"
                            [class.wide]="items.col.answer_text.length > 25"
                            [attr.data-track]="
                              'member-custom-grid-column-header-' + l
                            "
                          >
                            {{ items.col.answer_text }}
                          </div>
                        </div>
                      </label>
                    </ng-container>
                    <ng-template #multiSelect>
                      <label
                        for="{{ 'check-' + i + '-' + j }}"
                        [attr.aria-labelledby]="
                          'check-' + i + '-' + j + '-label-container'
                        "
                        class="flex items-center"
                        tabindex="0"
                      >
                        <input
                          class="sr-only"
                          type="checkbox"
                          name="options"
                          [checked]="row.isSelected"
                          (change)="selectChoice(row, item, $event, i)"
                          [attr.data-track]="
                            'member-custom-grid-checkbox-btn-' + i + '-' + j
                          "
                          id="{{ 'check-' + i + '-' + j }}"
                        />
                        <div
                          *ngIf="!row.isSelected"
                          class="w-6 h-6 min-w-6 border-3 border-active-link mr-4 ltr:mr-4 rtl:ml-4"
                        ></div>
                        <div
                          *ngIf="row.isSelected"
                          class="flex items-center justify-center w-6 h-6 bg-blue-dark mr-4 ltr:mr-4 rtl:ml-4"
                        >
                          <span class="text-xs text-white fa fa-check"></span>
                        </div>
                        <div
                          *ngFor="let items of rows[i]; let l = index"
                          [ngClass]="{ error: colErrors[i] }"
                        >
                          <p
                            *ngIf="colErrors[i] && l == j"
                            class="text-xs font-semibold text-error"
                          >
                            Please make a selection
                          </p>
                          <div
                            *ngIf="l == j"
                            class="text-sm font-semibold break-words text-box-dark dark:text-box-light"
                            [class.wide]="items.col.answer_text.length > 25"
                            [attr.data-track]="
                              'member-custom-grid-column-header-' + l
                            "
                          >
                            {{ items.col.answer_text }}
                          </div>
                        </div>
                      </label>
                    </ng-template>
                  </div>
                </div>

                <div
                  *ngIf="
                    action.grid.open_row_min !== 1 ||
                    action.grid.open_row_max !== 1
                  "
                >
                  <div
                    *ngIf="!last"
                    class="pb-2"
                    [ngClass]="isActiveTab === i ? 'block' : 'hidden'"
                  >
                    <button
                      type="button"
                      (click)="
                        item.isTouched = true; gridHasError(); toggle(i + 1)
                      "
                      class="relative block w-32 px-4 m-auto my-6 text-white rounded-full h-11 bg-blue-dark"
                    >
                      {{ 'general.next' | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- Form fields : Container for Radio/Checkbox Ends -->
    </div>

    <div
      class="relative flex items-center justify-center py-6 space-x-8 align-middle md:space-x-2 form-group"
    >
      <div *ngIf="!disableSkip">
        <button
          class="text-sm font-bold text-blue-dark md:text-base skip md:w-36"
          (click)="onSkip($event)"
          data-track="member-custom-grid-skip"
          (mousemove)="onMouseMoveEvent($event)"
          [ngClass]="{ loading: isSkipping }"
          [disabled]="isSkipping"
        >
          <span class="btn-label"> {{ 'general.skip' | translate }}</span>
          <span class="dot dot-1"></span>
          <span class="dot dot-2"></span>
          <span class="dot dot-3"></span>
        </button>
      </div>
      <div>
        <button
          class="animation-submit-btn"
          [ngClass]="{
            primarySection: !isLoading && !isSuccess,
            loadingSection: isLoading,
            successSection: isSuccess
          }"
          data-track="member-custom-grid-continue"
          (mousemove)="onMouseMoveEvent($event)"
          [disabled]="isSkipping || isSubmitting || isLoading"
        >
          <div class="primaryTitle text submit-label">
            {{ 'general.submit' | translate }}
          </div>
          <div class="primaryTitle text continue-label">
            {{ 'general.continue' | translate }}
          </div>
          <div class="loadingTitle text">
            <suzy-spinner
              class="absolute left-0 right-0 button-spinner top-3"
              size="small"
              button="true"
              [round]="7"
              [color]="'#ffffff'"
              [overlay]="false"
            >
            </suzy-spinner>
          </div>
          <div class="successTitle text">
            <svg class="animated-check" viewBox="0 0 24 24">
              <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />
            </svg>
          </div>
        </button>
      </div>
    </div>
  </div>
  <!-- Form Content Ends -->
</form>
