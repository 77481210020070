import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs/';
import { map } from 'rxjs/operators';
import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';

@Injectable()
export class StatesResolver implements Resolve<Observable<any>> {
  constructor(private sdk: SuzySdkService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this.sdk.ProtocolRegister.stateFind(
      0,
      100,
      '',
      'state_short.sort',
      false
    ).pipe(
      map((response: any) => {
        if (response.success) {
          return response['items'].map(item => {
            return {
              id: item.state_id,
              short: item.state_short
            };
          });
        } else {
          return response;
        }
      })
    );
  }
}
