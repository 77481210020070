<h1>Crowdtap® Terms of Service</h1>
<p>
  These terms of service (“terms”) apply to the websites, mobile applications,
  membership programs, and online services (collectively, and any, the “site” or
  “SITES”) that are owned or controlled by Suzy, Inc., its subsidiaries, or
  affiliates (collectively, “SUZY,” “we,” “OUR,” or “us”) that post a link to
  these terms, whether accessed via computer, mobile device, or otherwise.
</p>
<p>
  YOUR ACCESS to AND USE OF THE SITE IS SUBJECT TO THE FOLLOWING TERMS OF
  SERVICE (THE "TERMS") AND ALL APPLICABLE LAWS. BY ACCESSING OR USING ANY PART
  OF THE SITE, YOU ACCEPT, WITHOUT LIMITATION OR QUALIFICATION, THESE TERMS. IF
  YOU DO NOT AGREE WITH ALL OF THE TERMS SET FORTH BELOW, YOU MAY NOT USE ANY
  PORTION OF THE SITE.
</p>
<p>
  When using particular services or features of the site, both these Terms and a
  separate contract, terms of service, or a similar agreement (“Additional
  Terms”) may apply to your use of that service or feature. To the extent there
  is a conflict between these Terms and any Additional Terms, the Additional
  Terms will control unless the Additional Terms expressly state otherwise.
</p>
<p>
  1. Authorized Use of Site. The Site is provided for your personal and
  non-commercial use and for informational purposes only. Any other use of the
  Site requires Our prior written consent.
</p>
<p>
  2. Unauthorized Usage of Site. You may not use spiders, robots, screen
  scrapers, data mining techniques or other automated devices, programs, or
  technologies to access, catalog, download or otherwise reproduce, store or
  distribute content available on the Site. Further, you may not use any such
  automated means to manipulate the Site, such as automating what are otherwise
  or reasonably may be manual or one-off procedures. You may not take any action
  to interfere with, or disrupt, the Site or any other user's use of the Site,
  including, without limitation, via means of overloading, “flooding”,
  “mail-bombing” or “crashing” the Site, circumventing security or user
  authentication measures or attempting to exceed the limited authorization and
  access granted to you under these Terms. You may not resell use of, or access
  to, the Site to any third party without our prior written consent.
</p>
<p>
  3. Registration and Passwords. Use of the Site, or certain areas of the Site,
  such as the Program, may require registration or may otherwise ask or require
  you to provide information to use certain features or services. You may have
  only one membership account in the Site. You may not authorize other persons
  to use or access your account. Duplicate accounts are subject to cancellation,
  and all Points or Rewards accumulated in the original and duplicate accounts
  will be immediately forfeited. When you choose to provide information to the
  Site, you agree to provide only truthful, accurate, current, and complete
  information. You agree to update any of your information if it becomes
  inaccurate. We reserve the right to request that you verify any information
  you have provided to us, such as by requesting proof of address. You may not
  use any aliases or other means to mask your true identity. Any access codes,
  passwords, or credentials to a third-party website or online service, used to
  access the Site should be safeguarded at all times. You are responsible for
  the security of your access codes, passwords, and third-party credentials and
  will be solely liable for any use or unauthorized use under such access codes,
  passwords, or third-party credentials. We reserve the right to review your
  registration before permitting access, to deny access to the Site, and to
  terminate your access to the Site, for any reason or no reason, in our sole
  discretion without notice and without liability.
</p>
<p>
  4. Membership Program; Points and Rewards . The provisions of this Section 4
  apply to the rewards program offered through the Site (the “Program”) and the
  Crowdtap social media pages operated in connection with the Program (together
  with the Site, the “Services”) and to individuals who are or who become a
  member of the Program (any, a “Member”).
</p>
<p>
  4.1. Eligibility Requirements. Membership in the Program is open to
  individuals only (not corporations or other legal entities) located in the
  fifty (50) states of the United States and the District of Columbia. You must
  be 13 years of age or older to join the Program. If you are at least 13 years
  of age but under the age of majority in your state of residence (18, except in
  Alabama and Nebraska where it is 19, and in Mississippi where it is 21), you
  must have permission from your parent or legal guardian to join the Program.
  We have the right, in our sole discretion, to request parental or legal
  guardian consent forms at any time from any minor who wishes to join the
  Program. We may prohibit minors from participating in, or may require
  parental/legal guardian consent for minors to participate in, certain
  activities or events.
</p>
<p>
  By joining the Program or otherwise providing us with any of your personal
  information, you represent and warrant that: (i) you are at least 13 years of
  age and (ii) if you are a minor in your state of residence, you have
  permission from your parent or legal guardian to participate in the Program
  and your parent or legal guardian accepts the provisions of these Terms. You
  further represent and warrant that you will abide by the provisions of these
  Terms, and that you will use the Program and the Services solely for your
  personal, noncommercial use, in a manner consistent with these Terms and any
  and all applicable laws and regulations.
</p>
<p>
  4.2. Joining the Platform. You may join the Program through the Site or such
  other means as we provide. Upon joining the Program, you may have the option
  (but not obligation) to receive free samples and information from us. Certain
  content on the Site or in connection with the Program may be associated with
  certain brands (“Brands”). After joining the Program, you will have access to
  your account page and should update your profile page and account settings.
  You must provide complete and accurate registration information to us,
  including on your profile page, and we reserve the right to terminate a
  membership if misleading or intentionally incorrect registration information
  is provided or maintained.
</p>
<p>4.3. Actions, Points, and Rewards.</p>
<p>
  4.3.1. As a Member, you may earn points(“Points”) by participating in polls,
  missions, and certain other conduct that we may specify (any, an “Action,”
  and, together, “Actions”). Actions may include, but are not limited to,
  creating and updating your profile page; reviewing products/samples/services;
  completing surveys, reviews or polls; joining programs; attending parties or
  events; participating in online discussions; and uploading, creating, and/or
  submitting drawings, sketches, artwork, presentations, writings, photography,
  images, videos, films, audio, recordings, digital materials, ideas, concepts,
  designs, text, plans, comments, enhancements, and/or other materials
  (collectively, “Content”) to the Site or Program and/or to Brand websites or
  social media pages (collectively, “Brand Platforms”) and/or to third party
  websites or social media pages (collectively, “Third Party Platforms”) and/or
  to your own websites and social media profiles/pages, blogs and other
  platforms (collectively, “Your Platforms”).
</p>
<p>
  4.3.2. Points provide the opportunity for You (i) to receive certain rewards
  that may be offered through the Program or (ii) donate towards charitable
  causes (“Rewards”), as set forth in more detail in Section 4.7 below. Please
  note that you are under no obligation to participate in any Actions. However,
  if you wish to earn Points associated with an Action, you must click on and
  fully complete the applicable Action. Earning Points, Rewards or prizes for
  special promotions, such as sweepstakes and contests, are subject to the
  specific terms and conditions and/or official rules of the particular
  promotion.
</p>
<p>
  4.3.3. Available Actions may change from time to time, with or without notice,
  in Our sole discretion, and will be set forth on your account page or
  elsewhere on the Site. We may change or remove any of the Actions currently
  recognized, at any time, with or without notice to you. Should you decide to
  undertake any Action, the Action shall be performed at your own direction, on
  your own schedule, and you may elect the hours during which you put effort
  towards the Actions in accordance with Section 4.13 below; provided that all
  Actions must be completed within the time frame set forth in the applicable
  Action messaging. YOUR PARTICIPATION IN THE PROGRAM AND/OR ANY ACTIONS IS
  ENTIRELY VOLUNTARY. MEMBERS ARE UNDER NO OBLIGATION TO PARTICIPATE IN ANY
  ACTIONS AND DO SO AT THEIR OWN DISCRETION. We reserve the right to remove a
  Member from any Action, campaign, task, poll, survey, contest, sweepstakes, or
  similar activity, at any time for any reason or for no reason. If you are
  removed from an Action, we will notify you.
</p>
<p>
  4.4. Your Content. You are solely responsible for all Content that you post
  through the Services and/or in connection with the Program and you hereby
  agree that you will not hold Licensees responsible or liable for any Content
  provided by you or other Members in connection with the Program. If Content
  depicts any person other than yourself, you must have permission from that
  person or, if that person is a minor, permission from that person's parent or
  legal guardian, before you post the Content. You may be required to provide
  proof of such permission to us.
</p>
<p>
  You represent and warrant that any Content that you submit or post in
  connection with the Program: (i) shall be original to you and created solely
  by you, or that you have been given permission by the owner of the Content to
  post it; (ii) does not violate or infringe upon the intellectual property or
  any other rights of any other person or entity, including without limitation
  copyright, trademark, trade secret, patent, and the right of privacy and
  publicity; and (iii) does not violate these Terms, including Section 8 below
  regarding prohibited content.
</p>
<p>4.5. Our Rights to Your Content; License.</p>
<p>4.5.1. By participating in the Program, you:</p>
<p>
  4.5.1.1 Agree that any Content posted, uploaded or otherwise sent by you in
  connection with the Program, and/or any portions or excerpts thereof
  (including, but not limited to, Content posted to the Services, Brand
  Platforms, Third Party Platforms and/or Your Platforms, or on any other media
  now known or hereinafter invented) shall be deemed “works made for hire” for
  the benefit of Us and you hereby assign Us all right, title and interest in
  and to such Content. You further waive all moral or other rights and to such
  Content.
</p>
<p>
  4.5.1.2 Grant to Us, the Brands, and each of their respective parent
  companies, subsidiaries, and affiliates, and their respective employees,
  officers, directors, representatives, agents, licensees, successors and
  assigns (collectively, “Licensees”), the non-exclusive, irrevocable,
  royalty-free right and license to use, exhibit, display, broadcast, copy,
  reproduce, encode, compress, encrypt, incorporate data into, rebroadcast,
  transmit, record, publicly perform, create derivative works of, distribute and
  synchronize in timed relation to visual elements, and otherwise exploit your
  name, user name, image, photograph, voice, biographical material, social media
  handles and/or other likeness (collectively, “Your Likeness”), in connection
  with the Content, in any manner, for any purpose whatsoever (including, but
  not limited to, editorial, educational, advertising, promotion, merchandising,
  publicity and/or trade, including in connection with Us, Our Brands and/or
  other Licensees and their respective products or services), an unlimited
  number of times, in perpetuity, throughout the world, without any additional
  liability, obligation or additional payment of any kind to you or any third
  party, in any and all media now known or hereafter devised. The foregoing
  license includes, but is not limited to, usage on the Internet (on the
  Services, Brand Platforms, Third Party Platforms and any and all social media
  websites, e.g., YouTube, Facebook, Twitter, Pinterest, Instagram, etc.) as
  well as in other forms of media.
</p>
<p>
  4.5.2. You hereby waive any and all rights that you may be afforded by any
  applicable statute, law or regulation in any way relating to the foregoing,
  including but not limited to, any right to inspect or approve any use of the
  foregoing or the materials in or on which they may appear, any right of
  privacy or publicity, and any copyright or moral right.
</p>
<p>
  4.5.3. FOR THE SAKE OF CLARITY, YOU UNDERSTAND AND AGREE THAT WE AND/OR BRANDS
  ARE AUTHORIZED TO USE YOUR LIKENESS AND CONTENT ON ANY AND ALL OF THEIR
  WEBSITES AND/OR SOCIAL MEDIA PAGES AND POSTS, WITHOUT FURTHER PERMISSION OR
  AUTHORIZATION FROM YOU. THAT BEING SAID, WE AND/OR BRANDS MAY REACH OUT TO YOU
  FROM TIME TO TIME, IN THEIR SOLE AND ABSOLUTE DISCRETION, REGARDING SUCH
  USAGE, BUT ARE UNDER NO OBLIGATION TO DO SO.
</p>
<p>
  4.6. Right to Offer Opportunities to Select Members. Opportunities to
  participate in certain Actions will be offered to Members based on various
  demographic and psychographic parameters, as well as previous performance
  and/or certain scores determined by Us. If you qualify for an Action, you will
  have the ability to join it by selecting it from your account on the Site, and
  following the instructions provided. You may also be contacted directly via
  email, direct message, or SMS when an Action is launched and you consent to
  receive such communications.
</p>
<p>
  4.7. Points and Rewards. As a Member, you are eligible to earn Points toward
  Rewards. Your Points will determine the Rewards for which you are eligible.
  Available Points may differ amongst different Actions, at different times, and
  for different Members. Points have no cash or gift certificate value. Points
  and Rewards are not considered to be property and, as such, you cannot sell,
  transfer, or assign your Points or Rewards to any other person, under any
  circumstances, without Our written permission. You may not transfer or assign
  to any other person or permit anyone to make use of your membership in the
  Program, accumulated Points or Rewards, or privileges or access to the Site,
  without our written permission. We do not warrant or represent that Members
  will earn any specific number of Points or Rewards, or that any specific
  products, prizes or services will be available for redemption. Unless
  otherwise expressly stated in the applicable Action messaging or at the time
  of award, all Points earned by Members on or after May 19, 2018 will
  automatically expire on a date that is one (1) year from the last day of the
  month in which the applicable Points were earned. (By way of example, if a
  Member earns 5 points on June 1, 2018, those points would automatically expire
  on June 30, 2019). All points earned through the Program prior to May 20, 2018
  will automatically expire on May 20, 2019. Points must be redeemed for
  Rewards, in accordance with the redemption rules and procedures, before they
  expire. Use them or lose them. We reserve the right to change the expiration
  dates for Points at any time in Our sole discretion, with or without notice.
</p>
<p>
  We may, in our sole and absolute discretion, determine the Points provided to
  any Member for any activity, or cancel, change, suspend, or modify all or part
  of the Points and Rewards value structure and offers at any time, without
  notice. We reserve the right in our sole discretion to prohibit any Member
  from participating in any aspect of the Program pursuant to Section 4.16
  below, and any decision we make relating to termination or suspension of any
  Member's participation shall be final and binding in all respects.
</p>
<p>
  4.8. Rewards Redemption. Your current Points balance will be shown when you
  are logged in to your account. Your Points balance will decline when Points
  are redeemed and when Points expire. You may view a full list of redemption
  options and the respective terms and conditions associated with each
  redemption option via the Site. We are not in any way responsible for third
  party gift certificates, gift cards, merchandise or services. Upon Rewards
  redemption or selection, we may send you via email or mail any gift
  certificates, gift cards or other merchandise that you have won or redeemed.
  We may require you to verify your identity before allowing a reward redemption
  or before processing a reward redemption, and we may use a third-party service
  provider to do so. We may deny or void any redemption where we are unable to
  verify your identity to our reasonable satisfaction. Members must activate
  e-gift cards by clicking on the e-gift card code emailed to address on record
  and following the instructions provided. Other Rewards redemption methods may
  be added or substituted in the future in our sole discretion. We reserve the
  right to change the merchandise, services and gift cards/certificates,
  charitable causes, and other items redeemable for Rewards at Our discretion
  and without notification. Any items received as Rewards or through the
  redemption of Points may not be exchanged, returned or redeemed for cash
  value. Points are non-negotiable and may only be redeemed through Us in
  exchange for Rewards, in accordance with the redemption criteria specified on
  the Site. We make no representations or warranties, express or implied, with
  regard to any merchandise or services obtained by Members as Rewards or
  through the redemption of Points. Only those guarantees, warranties, and
  representations, if any, offered by the manufacturers of merchandise or
  suppliers of services may be relied upon by Members.
</p>
<p>
  4.9. Samples. Product samples will be limited to one (1) per household unless
  otherwise specified. Samples must be used as directed, and may not be resold
  or used for any commercial purpose. We make no representations or warranties,
  express or implied, with respect to any samples provided through the Program.
</p>
<p>
  4.10. Legal Compliance. You agree to comply with all requirements stated in
  connection with any Action, Points, or Rewards, or otherwise on the Site; and
  all federal, state, local and foreign laws, rules, regulations and court
  orders that are applicable to your participation in the Program or use of the
  Services. These include, without limitation, the Children's Online Privacy
  Protection Act (16 C.F.R. § 312) (“COPPA”), the FTC's Guides Concerning the
  Use of Endorsements and Testimonials in Advertising (“FTC Endorsement
  Guides”), the FTC's .com Disclosures and the Code of Ethics set forth by the
  Word of Mouth Marketing Association (“WOMMA Code of Ethics”) as it relates to
  representatives. Further information about our disclosure requirements can be
  found in Appendix 1 (
  <a routerLink="/">Disclosure Guidelines </a>). We reserve the right, in our
  sole and absolute discretion, to monitor Members' compliance with the
  Disclosure Guidelines, any other of our requirements and all applicable laws
  and to suspend, modify and/or terminate the membership of any Member who fails
  to comply with the same, in our sole and absolute discretion. You agree to
  cooperate with Us and Brands in removing or modifying any inappropriate
  content relating to Us, any Brand or their respective products from your
  social pages or any other media over which you have control.
</p>
<p>
  4.11. Taxes. You acknowledge that you shall be solely responsible for the
  reporting and payment of all federal, state and local income taxes, social
  security taxes, federal and state self-employment taxes, and other
  governmental obligations resulting from the receipt of compensation under
  these Terms and we shall not withhold or pay any amounts for such obligations.
  In accordance with current law, if we are required to file with the Internal
  Revenue Service a Form 1099-MISC, U.S. Information Return for Recipients of
  Miscellaneous Income, reflecting the gross annual compensation paid by us to
  you (including the value of any Rewards received) net of any reimbursed
  expenses incurred by you on behalf of us pursuant to these Terms, then you
  will be required to complete and submit a Form W-9 through the Site or via
  email. Should We or any Brand be required to issue you a Form 1099, the total
  amount of compensation reported will include the total value of all product
  samples; merchandise or services received in exchange for Rewards; prizes won
  in connection with any sweepstakes or contest; and any other source required
  by IRS guidelines. You hereby indemnify and hold Us and Brands harmless from
  any liability for any taxes, penalties or interest that may be assessed by any
  taxing authority with respect to compensation received by you as a Member.
</p>
<p>
  4.12. Confidentiality. You acknowledge that in accessing our Site or during
  your participation in the Program, you may have access to and become
  acquainted with various trade secrets, data, inventions, innovations,
  processes, information, records and specifications (“Confidential
  Information”) owned or licensed by Us and/or used by Us in connection with the
  operation of its business including, without limitation, our business and
  product processes, methods, customer lists, accounts and procedures. Further,
  you acknowledge that in accessing our Site or during your participation in the
  Program, you may have access to and become acquainted with Confidential
  Information owned or licensed by Brands. You agree that you will not disclose
  any of the aforesaid Confidential Information, directly or indirectly, or use
  it in any manner, either during the term of these Terms or at any time
  thereafter, except as required in the course of your participation in the
  Program. Upon the expiration or earlier termination of these Terms, or
  whenever requested by us, you shall immediately deliver to us any Confidential
  Information in your possession or under your control.
</p>
<p>
  4.13. Independent Contractor Status. You and We acknowledge and agree that
  your relationship with Us under these Terms shall be that of an independent
  contractor. As an independent contractor, you shall not receive nor be
  entitled to a salary or other wages or any other benefits or privileges that
  we provide to our employees. Nothing in these Terms shall be construed or
  considered to create a relationship of employer and employee, principal and
  agent, partner, joint venturer or any other relationship other than that of an
  independent contractor between the parties hereto. Accordingly, you and we
  agree that:
</p>
<ul>
  <li>
    <p>
      You will be treated as an independent contractor with respect to your role
      as a Member of the Program for all purposes, including, federal, state,
      and local tax purposes. Accordingly, we shall not withhold or pay on your
      behalf any amounts relating to federal, state or local income taxes,
      unemployment compensation, workers' compensation, social security or other
      taxes or assessments.
    </p>
  </li>
  <li>
    <p>
      If requested to do so, you shall supply your social security number to us
      for proper reporting of compensation to the Internal Revenue Service.
    </p>
  </li>
  <li>
    <p>
      You will never be asked to perform tasks, duties, or otherwise, which will
      render your services to be that of an employee.
    </p>
  </li>
</ul>
<p>
  The following outlines your terms of engagement as a Member of the Program:
</p>
<p>
  o None of Our employees or agents or Brands have the right to engage you in
  any way other than as specified herein in connection with any Actions you
  perform in connection with the Program. If you feel that your independent
  contractor status has been in any way violated or modified, please cease all
  work and activities being performed for us and promptly contact us for further
  direction.
</p>
<p>
  o You will not be provided great detail or strict guidelines about how to
  complete Actions.
</p>
<p>
  o You will rely on your own expertise to accomplish all necessary tasks within
  the parameters and objectives stated for each Action, although best practices
  and background knowledge may be shared with you to increase your chance of
  success. You may be given direction as to the results to be accomplished by
  the Actions, but not as to the details and means by which the results are
  accomplished.
</p>
<p>o You will be rewarded on a per-Action or per-completion basis.</p>
<p>
  o You will be responsible for providing all tools necessary to complete any
  Actions, including a computer, Internet access and cell phone, unless directed
  otherwise.
</p>
<p>
  4.14. Member Liability. The Site is only a venue and we do not screen or
  censor the profiles or Content of Members. We generally do not control the
  submitted Content or information provided by Members that is made available
  through the Program. As a result, we have no control over the truth or
  accuracy of the information submitted by Members. We make no representations
  about the accuracy, reliability, completeness or timeliness of any profile or
  Content of Members. We and Brands do not endorse any specific claims made by
  Members and are not responsible for the accuracy of these claims. In addition,
  note that there are risks, including, but not limited to, the risk of physical
  harm, from dealing with strangers, foreign nationals, underage persons or
  people acting under false pretenses. You assume all risks associated with
  dealing with other Members with whom you come in contact through the Program.
  By its very nature, other people's information may be offensive, harmful or
  inaccurate, and in some cases will be mislabeled or deceptively labeled. We
  expect that you will use caution and common sense when using the Program
  and/or the Services. Because Member authentication on the Internet is
  difficult, we cannot and does not confirm that each Member is who he or she
  claims to be. You are solely responsible for your interactions with other
  Members. We reserve the right, but has no obligation, to monitor disputes
  between you and other Members.
</p>
<p>
  4.15. Contact with Members. We have the right, but not the obligation, to
  periodically contact Members with information, updates, and special offers via
  email. If there are any inaccuracies in such correspondences, we will not be
  held responsible.
</p>
<p>
  4.16. Termination . We may terminate your membership in the Program or suspend
  your access to all or part of the Services for any reason or for now reason,
  at any time, in its sole discretion, including the cancellation of some or all
  of your accumulated Points and/or Rewards for any or no reason, including,
  without limitation, breach of these Terms or taking actions that are
  inconsistent with the intent of these Terms. We shall be the sole determiner
  in cases of suspected abuse, fraud or breach of these Terms or intent of these
  Terms. If we terminate your membership in the Program because you have
  breached these Terms or taken actions that are inconsistent with the intent of
  these Terms, you will not be entitled to any previously accumulated Points or
  Rewards. We reserve the right to suspend and/or cancel your membership or any
  account you may have, permanently expel you from the Program and/or put a
  “hold” on, revoke and/or delete Points or Rewards from your account, if we
  determine, in our and absolute discretion, that you: (a) abused the privileges
  of the Program ; (b) breached these Terms; (c) made a misrepresentation,
  including, without limitation, submitting false or fictitious personal
  information; (d) received merchandise that will be used for resale or
  commercial use and/or any unauthorized commercial purpose; (e) obtained Points
  or Rewards as a result of any fraudulent, dishonest, misleading, unfair,
  illegitimate or illegal act, or were otherwise improperly credited Points or
  Rewards; or (f) failed to meet the requirements established for the Program or
  for Actions. We reserve the right, in our sole discretion, to pursue all of
  its legal remedies, including, but not limited to, cancellation of your
  account, profile and/or some or all of your accumulated Points or Rewards, and
  the immediate termination of your membership and use of the Services, upon any
  breach by you of the terms of these Terms. Any decision we make relating to
  cancellation, termination or suspension of any Member's account and membership
  (including the cancellation of Points and Rewards) shall be final and binding.
  You may terminate your membership at any time for any reason by contacting us
  via the contact information provided in Section 23. We will not be liable to
  you or any third party for termination of the Program. We may terminate the
  Program at any time in our sole discretion. All of your Points and Rewards
  will be forfeited upon termination. Even after your membership is terminated,
  these Terms will remain in full force and effect.
</p>
<p>
  5. Security of the Site. You shall not violate or attempt to violate the
  security of the Site, or attempt to gain unauthorized access to the Site,
  including without limitation, by (a) accessing data not intended for
  non-members or logging into a server without Our express permission, (b)
  attempting to probe, scan or test the vulnerability of a system or network or
  to breach security or authentication measures or using any device, software,
  or routine to interfere or attempt to interfere with the proper working of the
  Site, (c) interfering with, or attempting to interfere with, the availability
  of the Site to any representative, host or network, including, without
  limitation, via means of submitting a virus to the Site, overloading,
  “flooding”, “spamming”, “mailbombing” “crashing” the Site, or otherwise
  imposes an unreasonable or disproportionately large loan or burden on the Site
  or Our infrastructure, (d) utilizing any the Site for the advertising of
  products or services, or (e) forging any TCP/IP packet header or any part of
  the header information in any e-mail or newsgroup posting, or (f) using a
  password or access code not expressly created by or issued to you. Violations
  of system or network security may result in civil or criminal liability. We
  may investigate occurrences which may involve such violations and may involve,
  and cooperate with, law enforcement authorities in identifying, investigating
  and/or prosecuting persons who are involved in such violations.
</p>
<p>
  6. Company Content and Client Content; Limited License. Our Content,
  advertisements, information and any of our other materials, any of our clients
  and this Site, and of all other websites under our control, whether partial or
  otherwise, such as text, surveys, polls, graphics, images, audio, video,
  logos, trademarks, service marks, copyrighted material, button icons,
  software, designs, reports, advertising copy, domain names, and other content
  (collectively, “Company Content”) and the compilation (meaning the collection,
  arrangement and assembly) and “look and feel” of all Company Content, are
  protected under copyright, trademark and other laws. As between you and us,
  all Company Content is our exclusive property. Unauthorized use of Company
  Content may violate copyright, trademark, and other laws. Except as expressly
  set forth in these Terms, no license is granted to you and no rights are
  conveyed by virtue of accessing or using the Site. All rights not granted
  under these Terms are reserved by us. Subject to your strict compliance with
  these Terms and any Additional Terms, We grant you a limited, personal,
  non-exclusive, revocable, non-assignable and non-transferable license to
  access and view the Company Content (excluding source and object code, other
  than as made available to access and use via standard web browsers to enable
  display on your device). Except as expressly provided in these Terms, you may
  not sell, copy, reproduce, catalog publish, distribute, modify, create
  derivative works of, upload, download, display, transmit, or otherwise use,
  the Company Content in any way, without the prior written permission of a duly
  authorized Suzy employee. When you access or view the Company Content, you
  must: (a) keep intact all copyright and other proprietary notices; (b) make no
  modifications to the Company Content; and (c) not copy or adapt any object
  code associated with the Site, reverse engineer, modify or attempt to discover
  any source code associated with the Site, nor allow or assist any third party
  (whether or not for your benefit) to do so. You shall not retain any copies of
  the Company Content without Our prior written permission.
</p>
<p>
  7. Comments or Materials Posted By You. Certain pages on the Site may allow
  you to post Content. Unless otherwise specified, you may only post Content to
  the Site if you are at least 13 years of age or older. You may only post
  Content that you created or which the owner of the Content has given you
  permission to post. If Content depicts any person other than yourself, you
  must have permission from that person or, if that person is a minor,
  permission from that person's parent or legal guardian, before you post the
  Content. You may be required to provide proof of such permission to Company.
  You may not post or distribute Content that is illegal or that violates these
  Terms. By posting or distributing Content to the Site, you represent and
  warrant that (a) you own all the rights to the Content or are authorized to
  use and distribute the Content to the Site and (b) the Content does not and
  will not infringe any copyright, right of publicity, or any other third-party
  right nor violate any law or regulation.
</p>
<p>
  By submitting or posting Content to the Site, you grant Us the irrevocable,
  perpetual, worldwide, assignable, royalty-free, right and license to use,
  reproduce, display, perform, distribute, modify, adapt, promote, and create
  derivative works based upon, the Content for any purpose, in any medium now
  known or hereafter developed, with no liability to Us. Once you submit or post
  Content to the Site, We do not need to give you any further right to inspect
  or approve uses of such Content or to compensate you for any such uses. We own
  all right, title, and interest in any compilation, collective work, or other
  derivative work created by Us using or incorporating Content posted to the
  Sites. You are solely responsible for anything you may post on the Site and
  the consequences of posting anything on the Site.
</p>
<p>
  8. Activities Prohibited by Site. The following is a partial list of the types
  of activities that are prohibited on the Site. We reserve the right to
  investigate and take appropriate action, which may include termination of
  access to the Site, against anyone who, in Our sole discretion, engages in any
  of the prohibited activities. Without limitation, you agree that you will not
  post or transmit to other users anything that contains Content that, in our
  sole and absolute discretion:
</p>
<p>o is defamatory, abusive, obscene, vulgar, profane or offensive;</p>
<p>o contains pornography or nudity;</p>
<p>o is inaccurate, false or misleading in any way;</p>
<p>
  o is off-topic, repetitive, unrelated to the subject matter or topic of the
  area in which it is posted, or otherwise constitutes spam;
</p>
<p>o disparages or criticizes any person or entity;</p>
<p>
  o infringes or violates another party's intellectual property rights (such as
  music, videos, photos or other materials for which you do not have written
  authority from the owner of such materials to post on the Site), including,
  without limitation, copyright, trademark, trade secret, and patent rights;
</p>
<p>o violates any party's right of publicity or right of privacy;</p>
<p>
  o invades anyone's privacy by attempting to harvest, collect, store, or
  publish private or personally identifiable information, such as passwords,
  account information, credit card numbers, addresses, or other contact
  information without their knowledge and willing consent;
</p>
<p>
  o is threatening, harassing or that promotes racism, bigotry, hatred or
  physical harm of any kind against any group or individual, including on the
  basis of age, gender, disability, ethnicity, sexual orientation, race, or
  religion, or is intended to incite or encourage anyone else to do so;
</p>
<p>o promotes or encourages violence;</p>
<p>
  o is illegal or promotes any illegal activities, including any activity that
  violates any local and national laws that apply to your location, including
  but not limited to child pornography, illegal drugs, copyright material and
  intellectual property not belonging to you;
</p>
<p>o is intended to harm or exploit minors in any way;</p>
<p>
  o is designed to solicit or collect personally identifiable information of
  anyone under 13 years old, including, but not limited to name, email address,
  home address, phone number, or school name;
</p>
<p>
  o intends to harm or disrupt another user's computer or allows others to
  illegally access software or bypass security on websites or servers, including
  but not limited to spamming or impersonation of another individual or entity;
</p>
<p>
  o promotes illegal or unauthorized copying of another person's copyrighted
  work or links to them or providing information to circumvent security
  measures;
</p>
<p>
  o contains software viruses or any other computer code, files or programs
  designed to interrupt, destroy or limit the functionality of any computer
  software or hardware or telecommunications equipment;
</p>
<p>
  o attempts to impersonate a Suzy or Brand employee, agent, manager or host,
  another Member or any other person through any means;
</p>
<p>o contains “masked” profanity (i.e., F*@&amp;#);</p>
<p>
  o contains software viruses, spyware, malware, or any other computer code,
  files or programs designed to interrupt, destroy, limit, or surreptitiously
  use the functionality of any computer software or hardware or
  telecommunications equipment; or
</p>
<p>
  o contains any advertising, promotional materials, "junk mail," "spam," "chain
  letters," "pyramid schemes," or any other form of solicitation.
</p>
<p>
  o provides or creates links to external websites that violate Sections 4.10 or
  8 of these Terms;
</p>
<p>
  o is inappropriate or inconsistent with the standards of any Brand to which it
  relates;
</p>
<p>
  o in our sole judgment, is objectionable, restricts or inhibits any other
  person's use of the Site, or may expose us or other individuals to any harm or
  liability of any type.
</p>
<p>
  We are under no obligation to screen or monitor Content, but may review
  Content from time to time at our sole discretion. We will make all
  determinations as to what Content is appropriate in our sole discretion. We
  may edit or remove any Content at any time without notice.
</p>
<p>
  9. No Ideas Accepted. We do not accept any unsolicited ideas including,
  without limitation, suggestions about advertising, promotion or merchandising
  of our products, additions or changes to our product lines, services, source
  code, technologies, or methods of doing business. We may already be working on
  or may in the future work on a similar idea. This policy eliminates concerns
  about ownership of such ideas. If, notwithstanding this policy, you submit an
  unsolicited idea to this Site, you understand and acknowledge that such idea
  is not submitted in confidence and We assume no obligation, expressed or
  implied, by considering it. You further understand that We shall exclusively
  own all known or hereafter existing rights to the idea everywhere in the
  world, and that such idea is hereby irrevocably assigned to Us. Without
  limiting the foregoing, to the extent any such assignment is deemed
  unenforceable, you hereby grant Us an irrevocable, perpetual, world-wide
  license to use the idea in any manner, in any medium now known or hereafter
  developed, without compensation to you.
</p>
<p>
  10. Copyrights and Trademarks. All materials included on the Site including
  but not limited to text, design, graphics, interfaces, or code and the
  selection and arrangements thereof is copyrighted as a collective work under
  the United States and other copyright laws, and is the intellectual property
  of Us or Our clients, unless otherwise noted or attributed. Crowdtap, Suzy,
  AskSuzy, Crowdtap Rewards, AskSuzy Rewards, and the Crowdtap, Suzy, and
  AskSuzy logos are trademarks and service marks of Suzy. You may not use,
  download, upload, copy, print, display, perform, reproduce, publish, license,
  post, transmit, distribute, modify, remove or create derivative works of any
  information from the Site in whole or in part without Our prior written
  permission.
</p>
<p>11. Limitation of Liability.</p>
<p>
  11.1. Suzy, its Clients, its service providers, BRANDS, and any of their
  respective parent companies, subsidiaries, affiliates, directors, officers,
  PARTNERS, shareholders, members, investors, managers, employees, contractors,
  agents, suppliers, licensors, licensees, assignees, predecessors and
  successors in interest (collectively, “Released Parties”) shall not be liable
  for any direct, indirect, incidental, consequential, special, economic,
  exemplary, punitive, or other damages, whether in an action of contract or
  tort, arising out of, or in any way connected with: (a) the Site; (b) THE
  PROGRAM OR YOUR MEMBERSHIP OR PARTICIPATION, OR INABILITY TO PARTICIPATE,
  THEREIN (INCLUDING WITH RESPECT TO ANY ACTIONS OR DISPUTES WITH ANY OTHER
  MEMBERS OF THE PROGRAM); (c) your use of, or inability to use, the Site, or
  your activities in connection with the Site; (d) possession, acceptance and/or
  use or misuse of any reward and/or any brand products or samples; (e) your
  content, including with respect to any claims involving copyright, trademark,
  trade secrets, patents, or the right of privacy or publicity; (f) any action
  taken by US in connection with any investigation by US or law enforcement
  authorities regarding your use of the Site; (g) any action taken in connection
  with copyright or other intellectual property owners; (h) any errors or
  omissions in the technical operation of the Site; or (i) any damage to any
  person's device, hardware, software, or other equipment or technology,
  including without limitation, damage from any security breach from any virus,
  bugs, tampering, fraud, error, omission, or malfunction, even if foreseeable
  or even if WE had been advised of the possibility of such damages.
</p>
<p>
  11.2. WHILE WE USE REASONABLE EFFORTS TO INCLUDE UP-TO-DATE INFORMATION ON THE
  SITE, WE MAKE NO WARRANTIES OR REPRESENTATIONS AS TO ITS ACCURACY OR
  COMPLETENESS. WE ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY ERRORS OR
  OMISSIONS IN THE CONTENT ON THE SITE. YOUR USE OF THE SITE IS AT YOUR OWN
  RISK. THE SITE, INCLUDING ALL CONTENT MADE AVAILABLE ON OR ACCESSED THROUGH
  THE SITE, IS PROVIDED "AS IS" AND WE MAKE NO REPRESENTATIONS OR WARRANTIES OF
  ANY KIND WHATSOEVER FOR THE CONTENT ON THE SITE. FURTHER, TO THE FULLEST
  EXTENT PERMISSIBLE BY LAW, WE DISCLAIM ANY EXPRESS OR IMPLIED WARRANTIES,
  INCLUDING, WITHOUT LIMITATION, NON-INFRINGEMENT, TITLE, MERCHANTABILITY OR
  FITNESS FOR A PARTICULAR PURPOSE. WE DO NOT WARRANT THAT THE FUNCTIONS
  CONTAINED IN THE SITE OR ANY MATERIALS OR CONTENT CONTAINED THEREIN WILL BE
  UNINTERRUPTED OR ERROR FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SITE
  OR THE SERVER THAT MAKES IT AVAILABLE IS FREE OF VIRUSES, MALWARE, OR OTHER
  HARMFUL COMPONENTS.
</p>
<p>
  11.3. WE SHALL NOT BE LIABLE FOR THE USE OF THE SITE, INCLUDING, WITHOUT
  LIMITATION, THE CONTENT AND ANY ERRORS CONTAINED THEREIN. IN NO EVENT WILL WE
  BE LIABLE UNDER ANY THEORY OF TORT, CONTRACT, STRICT LIABILITY OR OTHER LEGAL
  OR EQUITABLE THEORY FOR ANY LOST PROFITS, LOST DATA, LOST OPPORTUNITIES, COSTS
  OF COVER, EXEMPLARY, PUNITIVE, PERSONAL INJURY/WRONGFUL DEATH, SPECIAL,
  INCIDENTAL, INDIRECT OR OTHER CONSEQUENTIAL DAMAGES, OR FOR ANY DIRECT
  DAMAGES, EACH OF WHICH IS HEREBY EXCLUDED BY AGREEMENT OF THE PARTIES
  REGARDLESS OF WHETHER OR NOT EITHER PARTY HAS BEEN ADVISED OF THE POSSIBILITY
  OF SUCH DAMAGES. IF ANY PART OF THESE WARRANTY DISCLAIMERS OR LIMITATIONS OF
  LIABILITY IS FOUND TO BE INVALID OR UNENFORCEABLE FOR ANY REASON OR IF WE ARE
  OTHERWISE FOUND TO BE LIABLE TO YOU IN ANY MANNER, THEN OUR AGGREGATE
  LIABILITY FOR ALL CLAIMS UNDER SUCH CIRCUMSTANCES FOR LIABILITIES SHALL NOT
  EXCEED THE GREATER OF ONE DOLLAR ($1) OR THE TOTAL AMOUNT PAID BY YOU, IF ANY,
  FOR ACCESSING THIS SITE OR PARTICIPATING IN THE PROGRAM. YOU AGREE THAT IN THE
  EVENT YOU INCUR ANY DAMAGES, LOSSES, OR INJURIES THAT ARISE OUT OF OR ARE IN
  ANY WAY RELATED TO THE SITE OR OUR ACTS OR OMISSIONS, THE DAMAGES, IF ANY,
  CAUSED TO YOU ARE NOT IRREPARABLE OR SUFFICIENT TO ENTITLE YOU TO AN
  INJUNCTION PREVENTING ANY EXPLOITATION OF ANY OF OUR SITES OR THE PROGRAM, AND
  YOU WILL HAVE NO RIGHTS TO ENJOIN OR RESTRAIN THE DEVELOPMENT, PRODUCTION,
  DISTRIBUTION, ADVERTISING, EXHIBITION, OR EXPLOITATION OF ANY OF OUR SITES OR
  THE PROGRAM.
</p>
<p>
  11.4. Further, the Released Parties are not responsible for technical,
  hardware, software, telephone or other communications malfunctions, errors or
  failures of any kind, lost or unavailable network connections, website,
  Internet, or ISP availability, unauthorized human intervention, traffic
  congestion, incomplete or inaccurate capture of information related to Actions
  (regardless of cause) or failed, incomplete, garbled, jumbled or delayed
  computer transmissions, including any injury or damage to participant's or any
  other person's computer relating to or resulting from participating in the
  PROGRAM.
</p>
<p>
  11.5. YOU UNDERSTAND THAT YOU MAY BE WAIVING RIGHTS WITH RESPECT TO CLAIMS
  THAT ARE AT THIS TIME UNKNOWN OR UNSUSPECTED, AND IN ACCORDANCE WITH THE
  FOREGOING WAIVERS, YOU ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND, AND
  HEREBY EXPRESSLY WAIVE, THE BENEFITS OF SECTION 1542 OF THE CIVIL CODE OF
  CALIFORNIA, AND ANY SIMILAR LAW OF ANY STATE OR TERRITORY, WHICH PROVIDES AS
  FOLLOWS: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES
  NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE
  RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER
  SETTLEMENT WITH THE DEBTOR.”
</p>
<p>
  12. Indemnification. You agree to indemnify, hold harmless and defend the
  Released Parties against any and all claims, demands, liabilities, costs or
  expenses, including attorney's fees and costs, arising from, or in any way
  related to, (a) any breach by you of any of these Terms; (b) your use of the
  Site or Program, or activities in connection with the Site or Program; (c) any
  negligent, reckless or intentional acts or omissions committed by you in
  connection with the entry into or performance of these Terms, your use of the
  Service or your involvement in the Program; (d) your Content or any other
  materials that you have provided in connection with the Program, including
  Claims that your Content infringes the patent, copyright, trademark, trade
  secret or other intellectual property or other rights of any third party,
  including, but not limited to claims of libel, false advertisement, slander,
  defamation, copyright infringement, infringement of moral rights, trademark
  infringement, false designation of origin, disparagement, violation of
  privacy, publicity, identity or other proprietary rights, piracy or
  plagiarism; (e) your violation of any law, rule, regulation, code, statute,
  ordinance or order of any governmental and quasi-governmental authorities,
  including, without limitation, all regulatory, administrative and legislative
  authorities; (f) information or material transmitted through your computer,
  even if not submitted by you, that infringes, violates or misappropriates any
  copyright, trademark, trade secret, trade dress, patent, publicity, privacy or
  other right of any person or defames any person; (g) any misrepresentation
  made by you; (h) any assertion that you are not an independent contractor with
  respect to any of the Released Parties; or (i) Our use of your information as
  permitted under these Terms, the Additional Terms, the Privacy Policy, or any
  other written agreement between you any of the Released Parties.
  Notwithstanding the foregoing, you shall not enter into any settlement that
  materially adversely affects our rights or interests without our prior
  approval, and we shall have the right, in our sole discretion, to participate
  in the defense at our own expense with a counsel of our choosing.
</p>
<p>
  13. Links to Third Party Content &amp; Interactions. This Site may contain
  links to other web sites or enable interactions with web sites or online
  services not maintained by Us. For example, you may be to “log in” to your
  Crowdtap account using an account held with, or otherwise link your Crowdtap
  account to, a third party web site or online service. Other web sites may also
  reference or link to our Site. We encourage you to be aware when you leave our
  Site and to read the terms and conditions and privacy statements of each and
  every web site that you visit. You acknowledge and agree that by establishing
  a linkage between a Crowdtap account and an account with a third-party website
  or online service: (a) such linkage may cause interactions with, and the
  pushing and pulling of data, which may include personal information, to and
  from the account with the third party web site or online service; (b) you
  consent to our sharing of personal information with the third-party website or
  online service, and to the third-party website or online service sharing
  information with us. If you establish a linkage between your Crowdtap account
  and an account on a third party website or online service, you do so at your
  own risk. You must read all log-in boxes and other pop-up boxes closely. We
  are not responsible for the practices or the content of such other web sites.
</p>
<p>
  14. International Access and Jurisdictional Issues. We control the Site from
  offices located in the United States of America. The information provided on
  the Site is not intended for distribution to or use by any person or entity in
  any jurisdiction or country where such distribution or use would be contrary
  to law or regulation or which would subject Us to any registration requirement
  within such jurisdiction or country. The laws of other countries may differ
  regarding the access and use of the Site. We make no representations regarding
  the legality of this Site in any other country and it is your responsibility
  to ensure that your use complies with all applicable laws outside of the
  U.S.A. Anyone using or accessing the Site from other locations does so on
  their own initiative and is responsible for compliance with United States' and
  local laws regarding online conduct and acceptable content, if and to the
  extent such laws are applicable. We reserve the right to limit the
  availability of the Site or any portion of the Site, to any person, geographic
  area, or jurisdiction, at any time and in our sole discretion, and to limit
  the quantities of any content, program, product, service or other feature that
  we provide. Software related to or made available by the Site may be subject
  to United States export controls. Thus, neither the Site nor software from the
  Site may be downloaded, exported or re-exported: (a) into (or to a national or
  resident of) any country to which the United States government has embargoed
  goods or designated as a “terrorist supporting country”; or (b) to anyone on
  the U.S. Treasury Department's list of Specially Designated Nationals, the
  U.S. Commerce Department's Table of Deny Orders, or any other U.S. Government
  list of prohibited or restricted parties. By downloading any software related
  to the Site, you represent and warrant that you are not located in, under the
  control of, or a national or resident of, any such country or on any such
  list.
</p>
<p>
  15. Digital Millennium Copyright Act ("DMCA") Notice. Content or Materials may
  be made available via the Site by third parties not within our control. We are
  under no obligation to, and do not, scan content posted on the Site for the
  inclusion of illegal or impermissible content. However, we respect the
  copyright interests of others. It is our policy not to permit materials known
  by us to infringe another party's copyright to remain on the Site. If you
  believe any materials on the Site infringe a copyright, you should provide us
  with written notice that at a minimum contains:
</p>
<p>
  i. A physical or electronic signature of a person authorized to act on behalf
  of the owner of an exclusive right that is allegedly infringed;
</p>
<p>
  ii. Identification of the copyrighted work claimed to have been infringed, or,
  if multiple copyrighted works at a single online site are covered by a single
  notification, a representative list of such works at that site;
</p>
<p>
  iii. Identification of the material that is claimed to be infringing or to be
  the subject of infringing activity and that is to be removed or access to
  which is to be disabled, and information reasonably sufficient to permit us to
  locate the material;
</p>
<p>
  iv. Information reasonably sufficient to permit us to contact the complaining
  party, such as an address, telephone number, and, if available, an electronic
  mail address at which the complaining party may be contacted;
</p>
<p>
  v. A statement that the complaining party has a good faith belief that use of
  the material in the manner complained of is not authorized by the copyright
  owner, its agent, or the law; and
</p>
<p>
  vi. A statement that the information in the notification is accurate, and
  under penalty of perjury, that the complaining party is authorized to act on
  behalf of the owner of an exclusive right that is allegedly infringed.
</p>
<p>All DMCA notices should be sent to our designated agent as follows:</p>
<p>Suzy, Inc.</p>
<p>Designated Copyright Agent</p>
<p>625 Broadway, 9th Floor</p>
<p>New York, NY 10012</p>
<p>Email: legal@Suzy.com</p>
<p>
  16. Special Terms When Our Mobile Application is Obtained Through Apple . When
  any of our mobile applications (any, an “App”) are provided to you through
  Apple, Inc. or its affiliates (collectively, “Apple”), the following
  provisions apply in addition to all the other provisions of these Terms:
</p>
<ul>
  <li>
    <p>
      These Terms are concluded between the parties, and not with Apple. Apple
      is not responsible for the App or its content; the App and its content are
      solely governed by these Terms.
    </p>
  </li>
  <li>
    <p>
      Notwithstanding anything to the contrary hereunder, you may use the App
      only on an Apple-branded device that you own or control, subject to
      Apple's App Store Terms of Service.
    </p>
  </li>
  <li>
    <p>
      The parties acknowledge that Apple has no obligation to furnish any
      maintenance or support services with respect to the App.
    </p>
  </li>
  <li>
    <p>
      We make no warranties regarding the App. But in the event of any failure
      of the App to conform to any applicable warranty that is not effectively
      disclaimed, you may notify Apple, and Apple will refund the purchase price
      for the App (if any) to you. Except for the foregoing, to the maximum
      extent permitted by applicable law, Apple will have no other warranty
      obligation whatsoever with respect to the App, and any other claims,
      losses, liabilities, damages, costs or expenses attributable to any
      failure to conform to any warranty will be governed by this Agreement.
    </p>
  </li>
  <li>
    <p>
      Any (i) product liability claims relating to the App, (ii) claims that the
      App fails to conform to any applicable legal or regulatory requirement and
      (iii) claims arising under consumer protection or similar legislation are
      governed by these Terms, and Apple is not responsible for such claims.
    </p>
  </li>
  <li>
    <p>
      Any third party claim that the App or your possession and use of the App
      infringes that third party's intellectual property rights will be governed
      by these Terms, and Apple will not be responsible for the investigation,
      defense, settlement and discharge of any such intellectual property
      infringement claim.
    </p>
  </li>
  <li>
    <p>
      Apple and Apple's subsidiaries are third party beneficiaries to these
      Terms and may enforce these Terms against you.
    </p>
  </li>
</ul>
<p>17. Arbitration Agreement</p>
<p>
  17.1. You and Suzy agree that any dispute, claim or controversy arising out of
  or relating in any way to the Crowdtap Platform, Points, Rewards, these Terms
  of Service and this Arbitration Agreement, shall be determined by binding
  arbitration or in small claims court. Arbitration is more informal than a
  lawsuit in court. Arbitration uses a neutral arbitrator instead of a judge or
  jury, allows for more limited discovery than in court, and is subject to very
  limited review by courts. Arbitrators can award the same damages and relief
  that a court can award and nothing in this Arbitration Agreement shall be
  interpreted as limiting any non-waivable statutory rights. You agree that, by
  agreeing to these Terms of Use, the U.S. Federal Arbitration Act governs the
  interpretation and enforcement of this provision, and that you and Suzy are
  each waiving the right to a trial by jury or to participate in a class action.
  This arbitration provision shall survive termination of this Agreement and the
  termination of your Crowdtap account.
</p>
<p>
  17.2. If you elect to seek arbitration or file a small claim court action, you
  must first send to Suzy, via email, a written Notice of your claim ("Notice").
  The Notice to Suzy must be sent to legal@suzy.com ("Notice Address"). If Suzy
  initiates arbitration, it will send a written Notice to the email address used
  for your Crowdtap account. A Notice, whether sent by you or by Suzy, must (a)
  describe the nature and basis of the claim or dispute; and (b) set forth the
  specific relief sought ("Demand"). If Suzy and you do not reach an agreement
  to resolve the claim within 30 days after the Notice is received, you or Suzy
  may commence an arbitration proceeding or file a claim in small claims court.
</p>
<p>
  17.3. You may download or copy a form Notice and a form to initiate
  arbitration at www.adr.org. If you are required to pay a filing fee, after
  Suzy receives notice via the Notice Address that you have commenced
  arbitration, Suzy will reimburse you for your payment of the filing fee,
  unless your claim is for greater than US$10,000, in which event you will be
  responsible for filing fees.
</p>
<p>
  17.4. The arbitration will be governed by the Consumer Arbitration Rules (the
  "AAA Rules") of the American Arbitration Association ("AAA"), as modified by
  this Agreement, and will be administered by the AAA. The AAA Rules and Forms
  are available online at www.adr.org, by calling the AAA at 1-800-778-7879, or
  by writing to the Notice Address. The arbitrator is bound by the terms of this
  Agreement. All issues are for the arbitrator to decide, including issues
  relating to the scope and enforceability of this arbitration agreement. Unless
  Suzy and you agree otherwise, any arbitration hearings will take place in the
  county (or parish) of your residence. The arbitrator's award shall be final
  and binding on all parties, except (1) for judicial review expressly permitted
  by law or (2) if the arbitrator's award includes an award of injunctive relief
  against a party, in which case that party shall have the right to seek
  judicial review of the injunctive relief in a court of competent jurisdiction
  that shall not be bound by the arbitrator's application or conclusions of law.
</p>
<p>
  17.5. If your claim is for US$10,000 or less, we agree that you may choose
  whether the arbitration will be conducted solely on the basis of documents
  submitted to the arbitrator, through a telephonic hearing, or by an in-person
  hearing as established by the AAA Rules. If your claim exceeds US$10,000, the
  right to a hearing will be determined by the AAA Rules. Regardless of the
  manner in which the arbitration is conducted, the arbitrator shall issue a
  reasoned written decision explaining the essential findings and conclusions on
  which the award is based. If the arbitrator issues you an award that is
  greater than the value of Suzy's last written settlement offer made before an
  arbitrator was selected (or if Suzy did not make a settlement offer before an
  arbitrator was selected), then Suzy will pay you the amount of the award or
  US$5,000, whichever is greater. Except as expressly set forth herein, the
  payment of all filing, administration and arbitrator fees will be governed by
  the AAA Rules.
</p>
<p>
  17.6. YOU AND SUZY AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN
  YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
  PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, unless both you and
  Suzy agree otherwise, the arbitrator may not consolidate more than one
  person's claims with your claims, and may not otherwise preside over any form
  of a representative or class proceeding. If this specific provision is found
  to be unenforceable, then the entirety of this arbitration provision shall be
  null and void.
</p>
<p>
  18. Governing Law. The laws of the State of New York shall govern these Terms
  without regard to any conflicts of law principles. YOU HEREBY EXPRESSLY
  CONSENT TO EXCLUSIVE JURISDICTION AND VENUE IN THE COURTS LOCATED IN NEW YORK
  COUNTY, NEW YORK FOR ALL MATTERS ARISING IN CONNECTION WITH THESE TERMS OR
  YOUR ACCESS OR USE OF THE SITE.
</p>
<p>
  19. Changes. All information posted on the Site is subject to change without
  notice. In addition, these Terms may be changed at any time without prior
  notice. We will make such changes by posting them on the Site. You should
  check the Site for such changes frequently. The changed terms will be
  effective as of the time of posting, or such later date as may be specified in
  the changed terms. Your continued access of the Site after such changes
  conclusively demonstrates your acceptance of those changes.
</p>
<p>
  20. Termination. We reserve the right, without notice or liability and at our
  sole discretion, to suspend or terminate these Terms and/or your ability to
  access or use the Site (or any portion of the Site), and to block or prevent
  future access to and use of the Site for any reason, including, without
  limitation, your breach of these Terms or other conduct by you that we
  consider inappropriate. We reserve the right at any time and from time to time
  to modify or discontinue, temporarily or permanently, the Site with or without
  notice. You agree that no Released Party shall be liable to you or to any
  third party for any modification, suspension or discontinuation of the Site.
  You acknowledge and agree that termination, suspension, or cancellation of
  these Terms or your access to the Site will not affect any right or relief to
  which we may be entitled, at law or in equity, nor shall they affect any
  obligation you have to us, including but not limited to the payment of fees.
  Upon termination of these Terms, all rights granted to you will automatically
  terminate and immediately revert to us and our licensors. Upon termination of
  your access to the Site, or upon our demand, you must destroy all Company
  Content and all related documentation. Any provisions of these Terms, which,
  by their terms, ought to survive, shall survive any termination of these
  Terms.
</p>
<p>
  21. Severability. If any part of these Terms shall be held or declared to be
  invalid or unenforceable for any reason by any court of competent
  jurisdiction, such provision shall be ineffective but shall not affect any
  other part of these Terms, and in such event, such provision shall be changed
  and interpreted so as to best accomplish the objectives of such unenforceable
  or invalid provision within the limits of applicable law or applicable court
  decisions.
</p>
<p>
  22. Waiver; Remedies. Our failure to partially or fully exercise any rights or
  Our waiver of any breach of these Terms by you shall not prevent a subsequent
  exercise of such right by Us or be deemed a waiver by Us of any subsequent
  breach by you of the same or any other term of these Terms. The rights and
  remedies of Us under these Terms and any other applicable agreement between
  you and Us shall be cumulative, and the exercise of any such right or remedy
  shall not limit Our right to exercise any other right or remedy.
</p>
<p>
  23. Assignment. These Terms and any rights herein, if any, are not assignable,
  transferable or sub-licensable by you except with the prior written consent of
  a duly authorized Suzy employee. We may assign these Terms and any rights
  contained herein without your prior notice or consent.
</p>
<p>
  24. Comments, Complaints and Questions . We welcome your feedback about the
  Site. However, any comments, ideas, notes, messages, suggestions or other
  communications sent to the Site shall be and remain Our exclusive property,
  and we may use all such communications in any manner, including reproducing,
  disclosing and publishing such communications, all without compensation to
  you. Should you have any questions or complaints regarding these terms and
  conditions, please feel free to contact us at support@crowdtap.com.
</p>
<p>
  25. Privacy. Use of the Site is also governed by our
  <a routerLink="/privacy-policy">privacy policy</a>.
</p>
<p>
  Effective Date: These Terms are effective and were last updated September 15,
  2022.
</p>
<br />
