import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  OnInit,
  OnDestroy,
  ElementRef
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { DeviceService } from '@suzy/shared/tools/device';
import {
  ActionStructureMultipleChoiceKind,
  MissionKind
} from '@suzy/shared/data-access/suzy-sdk';
declare var $;
export interface AnswerChoice {
  col: any;
  row: any;
  isSelected: boolean;
  hasError: boolean;
}

// TODO: (TG) Remove duplicate instance from multiple-choice component inside the app
export interface MultipleChoiceAction {
  helpText: string;
  includeOther: boolean;
  noneText: string;
  otherText: string;
  otherValue: string;
  minChoices: number;
  maxChoices: number;
  options: Array<any>;
  question: string;
  variants: Array<any>;
  has_monadic: boolean;
}

@Component({
  selector: 'suzy-action-forms-multiple-choice',
  templateUrl: './multiple-choice.component.html',
  styleUrls: ['./multiple-choice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultipleChoiceComponent implements OnInit, OnDestroy {
  multipleChoiceKind = ActionStructureMultipleChoiceKind;
  radioInput: any;
  checkboxInput: any;
  isDarkMode: boolean;
  sliderTouched = false;
  missionKind = MissionKind;

  @Input() isLoading: boolean;
  @Input() isSuccess: boolean;
  @Input() action: any;
  @Input() multipleChoice: MultipleChoiceAction;
  @Input() isTrapQuestion: boolean;
  @Input() disableSkip: boolean;
  @Input() errorMessage: string;
  @Input() disableButton: Boolean = false;
  @Input() isSkipping: boolean;
  @Input() isAutoAssignOnPreview: boolean;
  @Input() selectedRadio: any;
  @Input() isSubmitting: boolean = false;
  @Input() isLegalAction: boolean = false;
  @Input() mission: any;

  @Output() radioChange = new EventEmitter<any>();
  @Output() checkboxChange = new EventEmitter<any>();
  @Output() skip = new EventEmitter<Event>();
  @Output() mouseEvent = new EventEmitter<MouseEvent>();
  @Output() otherValueChange = new EventEmitter<string>();
  @Output() mcFormSubmit = new EventEmitter<NgForm>();
  @Output() mcFormSubmitPrerequisite = new EventEmitter<NgForm>();
  @Output() viewOptionImageEvent = new EventEmitter<any>();

  @ViewChild('f') responseForm: NgForm;
  @ViewChild('range') rangeInput: ElementRef;

  resizeSub: Subscription;
  roundValue: number;

  get selectedValue(): string {
    if (!this.action?.multiple_choice?.options?.length) {
      return '';
    }

    const index = this.action.multiple_choice.options.findIndex(
      (x: any) => x.isSelected
    );
    if (index === -1) {
      return '';
    }

    return (index + 1).toString();
  }
  constructor(private deviceService: DeviceService) {}

  ngOnInit(): void {
    let screenName = this.deviceService.getDevice();
    this.roundValue = 10;
    this.isDarkMode = $('html').hasClass('dark');
    this.resizeSub = fromEvent(window, 'resize').subscribe(() => {
      this.fixRangeValuePosition();
    });
  }

  ngOnDestroy(): void {
    if (this.resizeSub) {
      this.resizeSub.unsubscribe();
    }
  }

  // TODO: applay similar fix to GridScale
  fixRangeValuePosition(): void {
    if (!this.rangeInput?.nativeElement) {
      return;
    }

    const element = this.rangeInput?.nativeElement as HTMLInputElement;
    const slider = element.closest('.slider');

    const elementWidth = Number(
      getComputedStyle(element).width.replace('px', '')
    );
    const containerWidth = Number(
      getComputedStyle(slider).width.replace('px', '')
    );

    const sideWidth = (containerWidth - elementWidth) / 2;
    const selectedValue = this.calculateOptionsValue(element.value) * 100;
    const maxValue = (this.action.multiple_choice.options.length - 1) * 100;

    const thumbSize = 44;
    const thumbSizeRation =
      this.action.multiple_choice.options.length > 1
        ? 1 / (this.action.multiple_choice.options.length - 1)
        : 0;
    const thumbPositionFix =
      5 - (selectedValue / 100) * thumbSize * thumbSizeRation;

    const output = element.nextElementSibling as HTMLElement;
    output.style.left = `${
      (elementWidth / maxValue) * selectedValue + sideWidth + thumbPositionFix
    }px`;
  }

  chooseExactlyOneChoice(): boolean {
    return this.multipleChoice.maxChoices === 1;
  }

  chooseExactlyXChoices(): boolean {
    return (
      !this.chooseExactlyOneChoice() &&
      this.multipleChoice.minChoices === this.multipleChoice.maxChoices
    );
  }

  chooseAtLeastXChoices(): boolean {
    return (
      this.multipleChoice.minChoices > 1 &&
      this.multipleChoice.maxChoices >= this.multipleChoice.options.length &&
      this.multipleChoice.minChoices < this.multipleChoice.options.length
    );
  }

  chooseUpToXChoices(): boolean {
    return (
      this.multipleChoice.minChoices === 1 && this.multipleChoice.maxChoices > 1
    );
  }

  updateOtherValue(type, option, event, index): void {
    const value = event.target.value;
    this.multipleChoice.otherValue = value;
    this.otherValueChange.emit(value);

    let customEvent = {
      target: {
        checked: true
      }
    };
    if (!option.isSelected && type === 'checkbox') {
      this.onCheckBoxChange(option, customEvent, index);
    }
    if (value.length >= 1 && type === 'radio') {
      this.selectedRadio = option.answer_id;
      this.onRadioChange(option, event, index);
    }
  }

  chooseBetweenXandYChoices(): boolean {
    return (
      !this.chooseAtLeastXChoices() &&
      this.multipleChoice.minChoices > 1 &&
      this.multipleChoice.maxChoices < this.multipleChoice.options.length &&
      this.multipleChoice.minChoices !== this.multipleChoice.maxChoices
    );
  }

  onRadioChange(option, event, index): void {
    this.radioChange.emit({
      option,
      event,
      index
    });
    if (!option.is_other) {
      this.radioInput = '';
      this.multipleChoice.otherValue = '';
      this.otherValueChange.emit('');
    }
  }

  onCheckBoxChange(option, event, index): void {
    this.checkboxChange.emit({
      option,
      event,
      index
    });
    if (this.checkboxInput?.length) {
      this.multipleChoice.options.map(item => {
        if (item.is_other && !item.isSelected) {
          this.checkboxInput = '';
        }
      });
    }
  }

  onSkip(event: Event): void {
    this.skip.emit(event);
  }

  onMouseMoveEvent(event: MouseEvent): void {
    this.mouseEvent.emit(event);
  }

  calculateOptionsValue(value: string): number {
    const valueNum = parseInt(value, 10);
    const newValue = Math.round(valueNum / 100);

    return newValue;
  }

  updateOptionsValue(
    element: HTMLInputElement,
    options: Array<AnswerChoice>
  ): void {
    const value = this.calculateOptionsValue(element.value);
    options.map((item, index) => {
      item.isSelected = `${index}` === `${value}` ? true : false;
    });
  }

  onSliderGrab(event: Event, options: Array<AnswerChoice>): void {
    const element = event.target as HTMLInputElement;
    const slider = element.parentElement.parentElement;
    this.sliderTouched = true;

    slider.classList.add('grabbed');
    $('div').removeClass('hideColor');
    const value = this.calculateOptionsValue(element.value);
    this.updateOptionsValue(element, options);
    element.value = `${value * 100}`;
    element.blur();
  }

  onSliderUpdate(event: Event, options: Array<AnswerChoice>): void {
    const element = event.target as HTMLInputElement;

    this.updateOptionsValue(element, options);
  }

  onSliderChange(event: Event, options: Array<AnswerChoice>): void {
    const element = event.target as HTMLInputElement;

    const value = this.calculateOptionsValue(element.value);
    this.updateOptionsValue(element, options);
    element.value = `${value * 100}`;
    element.blur();
  }

  onSliderRelease(event: Event, options: Array<AnswerChoice>): void {
    const element = event.target as HTMLInputElement;
    const slider = element.closest('.slider');

    slider.classList.add('touched');
    slider.classList.remove('grabbed');
    this.fixRangeValuePosition();
  }

  onSubmit(): void {
    this.mcFormSubmit.emit(this.responseForm);
  }

  onSubmitPrerequisite(): void {
    this.mcFormSubmitPrerequisite.emit(this.responseForm);
  }

  viewOptionImage(option: any, index?: number): void {
    this.viewOptionImageEvent.emit({ option, index });
  }
}
