import { Injectable } from '@angular/core';
import { ActionKind, ActionStructureGridKind } from '@asksuzy/typescript-sdk';
import { MultipleChoiceComponent } from './multiple-choice/multiple-choice.component';
import { OpenEndedComponent } from './openended/open-ended.component';
import { GridOpenComponent } from './grid-open/grid-open.component';
import { GridScaleComponent } from './grid-scale/grid-scale.component';
import { MaxDiffComponent } from './maxDiff/maxDiff.component';
import { GridRankComponent } from './grid-rank/grid-rank.component';
import { PhotoAcquisitionComponent } from './photo-acquisition/photo-acquisition.component';
import { AddressComponent } from '../actions/address/address.component';
import { AcceptComponent } from '../actions/accept/accept.component';
import { PhotoShareComponent } from '../actions/photo-share/photo-share.component';
import { BazaarvoiceReviewComponent } from '../actions/bazaarvoice-review/bazaarvoice-review.component';
import { TextShareComponent } from '../actions/text-share/text-share.component';
import { LinkShareComponent } from '../actions/link-share/link-share.component';
import { TurfComponent } from './turf/turf.component';

@Injectable()
export class ActionsResolverService {
  resolveActionType(action): any {
    switch (action?.action_kind) {
      case ActionKind.multiplechoice:
      case ActionKind.auto_assign:
        return MultipleChoiceComponent;
      case ActionKind.openended:
        return OpenEndedComponent;
      case ActionKind.grid:
      case ActionKind.gridcustom:
      case ActionKind.gridrankscale:
        switch (action.grid.grid_kind) {
          case ActionStructureGridKind.scale:
            return GridScaleComponent;
          case ActionStructureGridKind.rank:
            return GridRankComponent;
          default:
            return GridOpenComponent;
        }
      case ActionKind.photoacquisition:
        return PhotoAcquisitionComponent;
      case ActionKind.address:
        return AddressComponent;
      case ActionKind.accept:
        return AcceptComponent;
      case ActionKind.sharephoto:
        return PhotoShareComponent;
      case ActionKind.bazaarvoice:
        return BazaarvoiceReviewComponent;
      case ActionKind.sharetext:
        return TextShareComponent;
      case ActionKind.sharelink:
        return LinkShareComponent;
      case ActionKind.maxdiff:
        return MaxDiffComponent;
      case ActionKind.turf:
        return TurfComponent;
      case undefined:
        return undefined;
      default:
        throw new ErrorEvent(
          `Action type ${action.action_kind} is not supported`
        );
    }
  }

  resolveResultType(action): any {
    switch (action) {
      case 'survey':
        return MultipleChoiceComponent;

      default:
        throw new ErrorEvent(
          `Action type ${action.action_kind} is not supported`
        );
    }
  }
}
