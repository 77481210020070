<div class="wrapper" #wrapper>
  <app-general-header> </app-general-header>

  <section class="anonymous" aria-label="main" role="main">
    <article class="full-wrapper center-content">
      <div
        *ngIf="externalSurveyData.status == 'success'"
        class="center-column left-content"
      >
        <h2>{{ 'survey.surveyComplete' | translate }}</h2>
        <p
          [innerHTML]="
            'survey.surveyCompleteCongrats'
              | translate: { points: externalSurveyData.surveyPoint }
          "
        ></p>
        <p
          [innerHTML]="
            'survey.clickTheButtonBelowForRedirect'
              | translate: { seconds: maxCounter - counter }
          "
        ></p>
        <div class="actions-cta">
          <a
            (click)="closeWindow()"
            class="type-button"
            [innerHTML]="'general.returnToCrowdtap' | translate"
          >
          </a>
        </div>
      </div>
      <div
        *ngIf="externalSurveyData.status != 'success'"
        class="center-column left-content"
      >
        <h2>{{ 'survey.surveyIncomplete' | translate }}</h2>
        <p>
          {{ 'survey.surveyIncompleteText' | translate }}
        </p>
        <p
          [innerHTML]="
            'survey.clickTheButtonBelowForRedirect'
              | translate: { seconds: maxCounter - counter }
          "
        ></p>
        <div class="actions-cta">
          <a
            (click)="closeWindow()"
            class="type-button"
            [innerHTML]="'general.returnToCrowdtap' | translate"
          >
          </a>
        </div>
      </div>
    </article>
  </section>

  <app-general-footer (backToTop)="onBackToTop()"> </app-general-footer>
</div>
