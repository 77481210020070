import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { SettingsService } from '../../../core/services/settings.service';
import { ProfileMenuService } from './profile-menu.service';
import {
  SegmentService,
  segment_theme
} from '@suzy/shared/data-access/tracking';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileMenuComponent {
  constructor(
    public settingsService: SettingsService,
    public profileMenuService: ProfileMenuService,
    private segmentService: SegmentService
  ) {}

  toggleDisplay(): void {
    this.profileMenuService.toggleDisplay();
  }

  toggleDarkMode(): void {
    this.settingsService.toggleDarkMode();

    this.segmentService.trackThemeUpdated({
      theme: this.settingsService.isDarkModeSelected.getValue()
        ? segment_theme.dark_mode
        : segment_theme.light_mode
    });
  }

  display$(): Observable<boolean> {
    return this.profileMenuService.display$;
  }

  isDarkModeSelected$(): Observable<boolean> {
    return this.settingsService.isDarkModeSelected$;
  }
}
