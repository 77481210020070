import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragulaModule } from 'ng2-dragula';
import { NgDragDropModule } from 'ng-drag-drop';

import { SharedUiSpinnerModule } from '@suzy/shared/ui/spinner';
import { SharedFeatureFileUploadModule } from '@suzy/shared/feature/file-upload';

import { OpenEndedComponent } from './open-ended/open-ended.component';
import { MultipleChoiceComponent } from './multiple-choice/multiple-choice.component';
import { PhotoAcquisitionComponent } from './photo-acquisition/photo-acquisition.component';
import { GridRankComponent } from './grid-rank/grid-rank.component';
import { GridScaleComponent } from './grid-scale/grid-scale.component';
import { MaxDiffComponent } from './maxDiff/maxDiff.component';
import { GridOpenComponent } from './grid-open/grid-open.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { TurfComponent } from './turf/turf.component';
import { SharedToolsUrlModule } from '@suzy/shared/tools/url';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedFeatureFileUploadModule,
    SharedUiSpinnerModule,
    NgbModule,
    DragulaModule,
    DragDropModule,
    NgDragDropModule.forRoot(),
    NgScrollbarModule,
    SharedToolsUrlModule
  ],
  declarations: [
    OpenEndedComponent,
    MultipleChoiceComponent,
    PhotoAcquisitionComponent,
    GridRankComponent,
    GridScaleComponent,
    MaxDiffComponent,
    GridOpenComponent,
    TurfComponent
  ],
  exports: [
    SharedUiSpinnerModule,
    SharedFeatureFileUploadModule,
    OpenEndedComponent,
    MultipleChoiceComponent,
    PhotoAcquisitionComponent,
    GridRankComponent,
    GridScaleComponent,
    MaxDiffComponent,
    GridOpenComponent,
    TurfComponent
  ]
})
export class SharedActionsUiActionFormsModule {}
