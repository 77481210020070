import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/';
import { HttpClient } from '@angular/common/http';
import { GlobalRequest, GlobalEndpoints } from '@asksuzy/global-isolate-sdk';
import { DeviceService } from '@suzy/shared/tools/device';
import {
  Sdk,
  SdkService,
  SdkHelper,
  ApiCredentials
} from '@suzy/shared/data-access/sdk';
export * from '@asksuzy/global-isolate-sdk';

@Injectable({
  providedIn: 'root'
})
export class GlobalIsolateSdkService extends GlobalEndpoints implements Sdk {
  baseUrl: string;
  apiKeys: ApiCredentials;
  sdkHelper: SdkHelper;
  defaultError =
    'The app is experiencing performance issues - Please try again later.';
  preview: any;
  SharedLink: any;

  constructor(private http: HttpClient, private deviceService: DeviceService) {
    super();
    this.sdkHelper = new SdkHelper(this, this.http, this.deviceService);
  }

  getApiCredentials(): ApiCredentials {
    return this.sdkHelper.getApiCredentials();
  }

  setApiCredentials(apiKey: string, apiSecret: string): void {
    const apiCredentials = {
      api_key: apiKey,
      api_secret: apiSecret
    };

    this.sdkHelper.setApiCredentials(apiCredentials);
  }

  clearApiCredentials(): void {
    this.sdkHelper.clearApiCredentials();
  }

  initialize(baseUrl = '/api'): SdkService {
    return this.sdkHelper.initialize(baseUrl);
  }

  execute(request: GlobalRequest): Observable<any> {
    return this.sdkHelper.execute(request);
  }

  setLanguage(language: string) {
    this.sdkHelper.setLanguage(language);
    // Disabled translations for Global Survey on Preview
    this.sdkHelper.disableTranslations = language === 'en' ? true : false;
  }
}
