import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'privacy-policy-current',
  templateUrl: './privacy-policy-current.component.html',
  styleUrls: ['./privacy-policy-current.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivacyPolicyCurrentComponent {
  section3 = false;
  exampleOfTargeting = false;

  toggle(property: string) {
    this[property] = !this[property];
  }
}
