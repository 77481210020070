export class CampaignKeys {
  source?: string;
  utmSource?: string;
  utmMedium?: string;
  utmName?: string;
  utmTerm?: string;
  utmContent?: string;
  miscOne?: string;
  misTwo?: string;
}

export class CampaignKeysName {
  source = 'source';
  utmSource = 'utm_source';
  utmMedium = 'utm_medium';
  utmName = 'utm_campaign';
  utmTerm = 'utm_term';
  utmContent = 'utm_content';
  miscOne = 'misc_one';
  miscTwo = 'misc_two';
}
