<div class="flex items-center justify-center h-screen action-modal-container">
  <div>
    <div
      class="flex flex-col items-center justify-center h-full pt-24 modal-body action-modal-body"
    >
      <div>
        <div>
          <svg
            class="m-auto"
            width="60"
            height="60"
            viewBox="0 0 52 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.9999 51.5979C40.1384 51.5979 51.5999 40.1364 51.5999 25.9979C51.5999 11.8595 40.1384 0.397949 25.9999 0.397949C11.8614 0.397949 0.399902 11.8595 0.399902 25.9979C0.399902 40.1364 11.8614 51.5979 25.9999 51.5979Z"
              fill="#68C97F"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M38.6919 16.2178C38.332 15.855 37.8421 15.6509 37.331 15.6509C36.82 15.6509 36.3301 15.855 35.9702 16.2178L21.691 30.5162L15.6919 24.4978C15.1886 24.0116 14.4641 23.8309 13.7914 24.0237C13.1188 24.2165 12.6 24.7535 12.4306 25.4324C12.2612 26.1114 12.4669 26.8291 12.9702 27.3153L20.3302 34.6753C20.6901 35.0382 21.18 35.2423 21.691 35.2423C22.2021 35.2423 22.692 35.0382 23.0519 34.6753L38.6919 19.0353C39.0852 18.6725 39.3089 18.1617 39.3089 17.6266C39.3089 17.0914 39.0852 16.5807 38.6919 16.2178Z"
              fill="white"
              class="text-black fill-current dark:text-white"
            />
          </svg>
        </div>
        <div>
          <h1
            class="pt-6 pb-4 text-black dark:text-white text-base text-center md:text-xl"
            [innerHTML]="
              'globalEnding.yourProgressSurvey' | translate: { type: type }
            "
          ></h1>
          <div class="card-points-notification">
            <div class="pb-2 text-xl font-semibold text text-orange">
              <span>{{ points }}</span> {{ 'ending.pointsEarneds' | translate }}
            </div>
          </div>

          <div class="my-4 text-base text-center actions">
            <button
              class="h-12 px-8 font-medium text-white rounded-full bg-blue-dark disabled:opacity-50 text-uppercase"
              (click)="closeModal()"
              [attr.data-track]="'member-' + type + '-end-next-action'"
            >
              {{ 'general.close' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
