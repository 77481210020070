import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { AuthenticationService } from '@suzy/crowdtap/data-access/user';
import { AddressKind, SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import { MissionService } from '@suzy/shared/tools/mission';
import { Subject } from 'rxjs';
import swal from 'sweetalert2';
import { DemographicService } from '../../../core/services/demographic.service';
import { AppInputValidators } from '../../../shared/app-input-validators';
import { Mission } from '../../../views/actions/mission.model';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit, OnDestroy {
  action: any = {};
  mission: Mission;
  subject: Subject<any>;
  currentUser: any;
  addressList: Array<any>;
  addressForm: UntypedFormGroup;
  isFormVisible = false;
  submitted = false;
  states: Array<any>;
  countries: Array<any>;
  selectedAddress: any = {};
  currentUserAddress: any = {};
  isSubmitting = false;
  allowSkipping = false;
  disableSkip: boolean;

  constructor(
    private sdk: SuzySdkService,
    private auth: AuthenticationService,
    private fb: UntypedFormBuilder,
    private missionService: MissionService,
    private demographicService: DemographicService
  ) {
    this.subject = new Subject<any>();
  }

  ngOnInit(): void {
    this.createForm();
    this.loadCountries();
    document.getElementsByTagName('body')[0].classList.add('isProfile'); // The background color needs to change in Profile form
  }

  ngOnDestroy(): void {
    this.subject.complete();
    this.subject = undefined;
  }

  init(): void {
    this.auth.ensureUser().subscribe(user => {
      this.currentUser = user;

      this.loadAddressList();
    });

    if (this.mission.first_action && this.mission.first_action.prevent_skip) {
      this.disableSkip = this.mission.first_action.prevent_skip;
    }
  }

  loadAddressList(): void {
    this.sdk.UserAddress.getUserAddressByUserAsync(
      this.currentUser.user_id
    ).subscribe(response => {
      if (response.success) {
        this.addressList = response.items;
      } else {
        swal.fire({
          buttonsStyling: false,
          confirmButtonText: 'Continue',
          confirmButtonClass: 'btn-confirm',
          customClass: 'general-modal',
          showConfirmButton: true,
          text: response.message,
          title: 'Error',
          type: 'warning'
        });
      }
    });
  }

  createForm(): void {
    this.addressForm = this.fb.group({
      address1: ['', Validators.required],
      address2: [''],
      zip_code: ['', Validators.required],
      city: ['', Validators.required],
      country_id: ['', Validators.required],
      state_id: ['', Validators.required],
      phone: ['', [Validators.required, AppInputValidators.phone]]
    });

    this.addressForm.get('country_id').valueChanges.subscribe(countryId => {
      this.loadStates(countryId);
      this.addressForm.get('state_id').setValue('');
    });
  }

  rebuildForm(userAddress): void {
    this.addressForm.reset(userAddress);
  }

  fieldErrors(name: string): any {
    const control = this.addressForm.get(name);

    if (control && (control.touched || this.submitted) && control.errors) {
      return control.errors;
    } else {
      return undefined;
    }
  }

  loadCountries(): void {
    this.sdk.Country.findAsync().subscribe(response => {
      if (response.success) {
        this.countries = response.items;
      } else {
        throw new Error(response.message);
      }
    });
  }

  loadStates(countryId: string): void {
    if (countryId) {
      this.demographicService.getStateOptions(countryId).subscribe(states => {
        this.states = states;
      });
    } else {
      this.states = [];
    }
  }

  addAddress(): void {
    this.currentUserAddress = {
      address1: '',
      address2: '',
      zip_code: '',
      city: '',
      state_id: '',
      phone: ''
    };

    this.rebuildForm(this.currentUserAddress);
    this.showForm();
  }

  showForm(): void {
    this.isFormVisible = true;
  }

  editAddress(address: any): void {
    this.currentUserAddress = address;
    this.rebuildForm(address);
    this.showForm();
  }

  saveAddress(): void {
    this.submitted = true;

    if (!this.addressForm.valid) {
      return;
    }

    this.isSubmitting = true;

    const address = {
      user_id: this.currentUser.user_id,
      address_kind: AddressKind.shipping,
      ...this.currentUserAddress,
      ...this.addressForm.value
    };

    if (address.user_address_id) {
      this.sdk.UserAddress.updateUserAddress(
        address.user_address_id,
        address
      ).subscribe(response => {
        if (response.success) {
          this.loadAddressList();
          this.selectedAddress = response.item;
          this.isFormVisible = false;
        } else {
          swal.fire({
            buttonsStyling: false,
            confirmButtonText: 'Continue',
            confirmButtonClass: 'btn-confirm',
            customClass: 'general-modal',
            showConfirmButton: true,
            text: response.message,
            title: 'Error',
            type: 'warning'
          });
        }

        this.isSubmitting = false;
      });
    } else {
      this.sdk.UserAddress.createUserAddress(address).subscribe(response => {
        if (response.success) {
          this.loadAddressList();
          this.selectedAddress = response.item;
          this.isFormVisible = false;
        } else {
          swal.fire({
            buttonsStyling: false,
            confirmButtonText: 'Continue',
            confirmButtonClass: 'btn-confirm',
            customClass: 'general-modal',
            showConfirmButton: true,
            text: response.message,
            title: 'Error',
            type: 'warning'
          });
        }

        this.isSubmitting = false;
      });
    }
  }

  cancel(): void {
    this.isFormVisible = false;
  }

  isCurrentAddress(address: any): boolean {
    return this.selectedAddress.user_address_id === address.user_address_id;
  }

  selectAddress(address: any): void {
    this.selectedAddress = address;
  }

  submit(): void {
    if (!this.selectedAddress.user_address_id) {
      return;
    }

    this.isSubmitting = true;

    const answer = {
      action_id: this.action.action_id,
      answer_id: this.action.answer_id,
      response_value: this.selectedAddress.user_address_id
    };

    this.sdk.ProtocolRespond.respond(
      this.mission.brand_id,
      this.mission.mission_id,
      answer
    ).subscribe(
      data => {
        if (data.success) {
          this.missionService.onMissionAnswered(
            this.mission.mission_id,
            data.meta
          );
          this.subject.next(data);
        } else {
          let message = data.message;
          if (data.errors) {
            for (const error of Object.keys(data.errors)) {
              message += `\n - ${data.errors[error]}`;
            }
          }
          swal.fire({
            buttonsStyling: false,
            confirmButtonText: 'Continue',
            confirmButtonClass: 'btn-confirm',
            customClass: 'general-modal',
            showConfirmButton: true,
            text: message,
            title: 'Error',
            type: 'warning'
          });
        }
      },
      error => {
        swal.fire({
          buttonsStyling: false,
          confirmButtonText: 'Continue',
          confirmButtonClass: 'btn-confirm',
          customClass: 'general-modal',
          showConfirmButton: true,
          text: error,
          title: 'Error',
          type: 'warning'
        });
      },
      () => {
        this.isSubmitting = false;
      }
    );
  }

  skip(evt: any): void {
    // Not yet implemented
  }
}
