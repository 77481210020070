import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import { AuthenticationService } from '@suzy/crowdtap/data-access/user';
import { AppAlertService } from '../../../core/services/app-alert/app-alert.service';

@Component({
  selector: 'app-impersonate',
  templateUrl: './impersonate.component.html',
  styleUrls: ['./impersonate.component.scss']
})
export class ImpersonateComponent {
  @ViewChild('f', { static: true }) loginForm: NgForm;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sdk: SuzySdkService,
    private auth: AuthenticationService,
    private alerts: AppAlertService
  ) {}

  onSubmit(): void {
    const input = {
      user: this.loginForm.value.inputEmail,
      password: this.loginForm.value.inputPass,
      brands: false
    };
    this.sdk.ProtocolAuthorize.impersonate(input).subscribe(
      data => {
        if (data.success) {
          this.auth.login(data.item);
          this.router.navigate(['dashboard']);
        } else {
          this.alerts.notify({ title: 'Error', message: data.message });
        }
      },
      error => {
        this.alerts.notify({ title: 'Error', message: error });
      }
    );
  }

  onForgotPassword(): void {
    this.router.navigate(['lost-password'], { relativeTo: this.route.parent });
  }

  onRegister(): void {
    this.router.navigate(['signup'], { relativeTo: this.route.parent });
  }
}
