<nav
  class="navbar navbar-expand-lg navbar-light bg-faded"
  [ngClass]="{ 'dark-mode': isMobile }"
>
  <div class="container-fluid">
    <div class="navbar-header">
      <!-- Dynamic Page Header Starts -->
      <div class="logo" *ngIf="!isMobile; else showWhiteLogo">
        <a [routerLink]="['/']" class="logo-text">
          <span
            class="sr-only"
            [innerHTML]="'general.crowdtapCopy' | translate"
          ></span>
        </a>
      </div>
      <ng-template #showWhiteLogo>
        <div class="logo">
          <div class="logo-text">
            <span
              class="sr-only"
              [innerHTML]="'general.crowdtapCopy' | translate"
            ></span>
          </div>
        </div>
      </ng-template>
      <div class="navbar-page-header">
        <!-- Back button for dynamic header, currently disabled -->
        <!-- <a class="btn-back" href="/profile">
          <svg version="1.1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <g fill="none">
              <path transform="translate(1 8)" d="m14 0h-14" stroke-linecap="round" stroke-linejoin="round" />
              <path transform="translate(1 1)" d="m7 14l-7-7 7-7" stroke-linecap="round" stroke-linejoin="round" />
            </g>
          </svg>
        </a> -->
        <!-- <h1>Dynamic Header</h1> -->
      </div>
      <!-- Dynamic Page Header Starts -->
      <button
        *ngIf="!isMobile"
        type="button"
        class="navbar-toggle d-lg-none float-left"
        data-toggle="collapse"
      >
        <span class="sr-only">{{ 'nav.toggleNav' | translate }}</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li
            class="navbar-item"
            *ngIf="{ points: points$ | async } as ctPoints"
          >
            <span
              class="nav-link"
              *ngIf="showTapsBar && ctPoints.points !== undefined"
            >
              <span class="navbar-points">
                <img
                  src="/assets/img/svg/icons/ic-coin.svg"
                  width="28"
                  height="28"
                />{{ ctPoints.points }}
              </span>
            </span>
          </li>
          <li
            class="nav-item"
            ngbDropdown
            display="dynamic"
            placement="bottom-right"
          >
            <a class="nav-link" id="dropdownBasic3" ngbDropdownToggle>
              <img
                class="ic-avatar"
                src="{{ profilePhoto }}"
                *ngIf="profilePhoto"
                alt="Profile photo"
              />
              <!-- If No User Profile Photo -->
              <!-- for male:  <img class="ic-avatar male" src="/assets/img/svg/app-default/profile-male.svg" alt="Avatar"> -->
              <!-- for female: -->
              <img
                class="ic-avatar non-binary"
                src="/assets/img/svg/app-default/profile-non-binary.svg"
                *ngIf="!profilePhoto"
                alt="Avatar"
              />

              <!-- Notice Dot: Blue, simply insert here -->
              <!-- <i class="nav-icon ic-dot ic-dot--blue"></i> -->
              <!-- Notice Dot: Red, simply insert here -->
              <!-- <i class="nav-icon ic-dot ic-dot--red"></i> -->
              <p class="d-none">{{ 'nav.userSettings' | translate }}</p>
            </a>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic3">
              <a
                class="dropdown-item py-1"
                routerLink="/profile/account"
                data-track="member-drop-down-my-account"
              >
                <i class="ft-user mr-2"></i>
                <span>{{ 'nav.myAccount' | translate }}</span>
              </a>
              <a
                class="dropdown-item py-1"
                routerLink="/profile/demographic"
                data-track="member-drop-down-profile"
              >
                <i class="ft-target mr-2"></i>
                <span>{{ 'nav.profile' | translate }}</span>
              </a>
              <!--
              <a class="dropdown-item py-1" routerLink="/profile">
                <i class="ft-settings mr-2"></i>
                <span>Settings</span>
              </a>
              -->
              <a
                class="dropdown-item py-1"
                href="http://support.crowdtap.com/"
                target="_blank"
                data-track="member-drop-down-support"
              >
                <i class="ft-life-buoy mr-2"></i>
                <span>{{ 'nav.support' | translate }}</span>
              </a>
              <div class="dropdown-divider"></div>
              <a
                class="dropdown-item logout"
                href="javascript:;"
                (click)="doLogout()"
                data-track="member-drop-down-logout"
              >
                <i class="ft-log-out mr-2"></i>
                <span>{{ 'nav.logout' | translate }}</span>
              </a>
            </div>
          </li>
          <!-- <li class="nav-item">
            <a href="javascript:" class="nav-link notification-sidebar-toggle">
              <i class="nav-icon ic-notifications"></i> -->
          <!-- Notice Dot: Blue, simply insert here -->
          <!-- <i class="nav-icon ic-dot ic-dot--blue"></i> -->
          <!-- Notice Dot: Red, simply insert here -->
          <!-- <i class="nav-icon ic-dot ic-dot--red"></i> -->
          <!-- </a>
          </li> -->
          <!-- <li class="nav-item">
            <a class="nav-link notification-sidebar-toggle">
              <i class="nav-icon ic-notification-sidebar"></i>
              <p class="d-none">Notifications Sidebar</p>
            </a>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</nav>
