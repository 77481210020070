<div class="flex flex-col h-full action-modal-container">
  <div class="flex p-6 md:py-8 dark:text-white justify-end">
    <div class="modal-header">
      <div></div>

      <button
        (click)="closeModal()"
        [attr.data-track]="'member-beginning-close'"
        type="button"
        aria-label="Close"
        class="close-modal-btn w-8 h-8 relative"
      >
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.4099 12.5002L19.7099 6.21019C20.1021 5.81806 20.1021 5.18231 19.7099 4.79019C19.3178 4.39806 18.6821 4.39806 18.2899 4.79019L11.9999 11.0902L5.70994 4.79019C5.31782 4.39806 4.68206 4.39806 4.28994 4.79019C3.89782 5.18231 3.89782 5.81806 4.28994 6.21019L10.5899 12.5002L4.28994 18.7902C4.10063 18.978 3.99414 19.2335 3.99414 19.5002C3.99414 19.7668 4.10063 20.0224 4.28994 20.2102C4.47771 20.3995 4.7333 20.506 4.99994 20.506C5.26658 20.506 5.52217 20.3995 5.70994 20.2102L11.9999 13.9102L18.2899 20.2102C18.4777 20.3995 18.7333 20.506 18.9999 20.506C19.2666 20.506 19.5222 20.3995 19.7099 20.2102C19.8993 20.0224 20.0057 19.7668 20.0057 19.5002C20.0057 19.2335 19.8993 18.978 19.7099 18.7902L13.4099 12.5002Z"
            fill="white"
            class="text-black fill-current dark:text-white"
          />
        </svg>
      </button>
    </div>
  </div>
  <div
    class="items-center justify-center flex-1 p-6 text-center sm:px-20 md:px-24 md:py-8 dark:text-white bg-box-light dark:bg-box-dark"
  >
    <div class="modal-body action-modal-body">
      <div class="modal-content">
        <svg
          class="mx-auto"
          width="41"
          height="40"
          viewBox="0 0 41 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="white"
            class="fill-current dark:black"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.83337 5C6.07195 5 3.83337 7.23858 3.83337 10V30C3.83337 32.7614 6.07195 35 8.83337 35C11.5948 35 13.8334 32.7614 13.8334 30V10C13.8334 7.23858 11.5948 5 8.83337 5ZM10.5001 29.9998C10.5001 30.9203 9.75387 31.6665 8.8334 31.6665C7.91292 31.6665 7.16673 30.9203 7.16673 29.9998V9.99979C7.16673 9.07932 7.91292 8.33313 8.8334 8.33313C9.75387 8.33313 10.5001 9.07932 10.5001 9.99979V29.9998ZM34.9667 16.0831L23.8333 9.43313C22.4758 8.63313 20.7909 8.63313 19.4333 9.43313C18.0437 10.2432 17.1826 11.7247 17.1667 13.3331V26.6665C17.1625 28.3022 18.0334 29.8153 19.45 30.6331C20.8046 31.444 22.4954 31.444 23.85 30.6331L34.9667 23.9665C36.3345 23.1351 37.1695 21.6504 37.1695 20.0498C37.1695 18.4492 36.3345 16.9645 34.9667 16.1331V16.0831ZM33.3 21.0831L22.2 27.7498C21.8771 27.9445 21.4729 27.9445 21.15 27.7498C20.7454 27.5412 20.4937 27.1216 20.5 26.6665V13.3331C20.4901 12.8946 20.7201 12.4856 21.1 12.2665C21.2582 12.179 21.436 12.1331 21.6167 12.1331C21.8047 12.1345 21.9889 12.1863 22.15 12.2831L33.25 18.9498C33.6136 19.1827 33.8336 19.5847 33.8336 20.0165C33.8336 20.4482 33.6136 20.8503 33.25 21.0831H33.3Z"
            fill="white"
          />
        </svg>

        <h2 class="text-xl md:text-2xl font-medium md:font-bold my-4">
          {{ 'general.skipThisSurvey' | translate }}
        </h2>
        <p class="text-xs font-semibold md:text-sm md:font-medium">
          {{ 'general.youWillNotBe' | translate }}
        </p>
      </div>

      <div class="relative flex justify-center space-x-2 md:space-x-16 actions">
        <button
          class="text-sm font-bold w-36 md:text-base btn btn-secondary text-blue-dark"
          (click)="backModal()"
        >
          <span class="btn-label"> {{ 'general.back' | translate }}</span>
        </button>

        <button
          class="animation-submit-btn"
          [ngClass]="{
            primarySection: !isLoading && !isSuccess,
            loadingSection: isLoading,
            successSection: isSuccess
          }"
          [disabled]="isLoading || isSuccess"
          (click)="skip()"
        >
          <div class="primaryTitle text submit-label">
            {{ 'general.skip' | translate }}
          </div>
          <div class="loadingTitle text">
            <suzy-spinner
              class="absolute left-0 right-0 button-spinner top-3"
              size="small"
              button="true"
              [round]="7"
              [color]="'#ffffff'"
              [overlay]="false"
            >
            </suzy-spinner>
          </div>

          <div class="successTitle text">
            <svg class="animated-check" viewBox="0 0 24 24">
              <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />
            </svg>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
