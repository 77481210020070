import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
@Component({
  selector: 'suzy-action-forms-open-ended',
  templateUrl: './open-ended.component.html',
  styleUrls: ['./open-ended.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenEndedComponent implements OnInit {
  @Input() isErrorMessage: string;
  @Input() action;
  @Input() isSuccess: boolean;
  @Input() isLoading: boolean;
  @Input() isSpecialMission: boolean;
  @Input() isValid;
  @Input() disableSkip;
  @Input() disableButton: Boolean = false;
  @Input() isSkipping: boolean;
  @Input() isSubmitting: boolean;

  @Output() responseKeyup = new EventEmitter();
  @Output() submitClick = new EventEmitter();
  @Output() formMouseMove = new EventEmitter();
  @Output() formSkip = new EventEmitter();
  @Output() formSubmit = new EventEmitter();

  response = '';
  roundValue: number;

  ngOnInit(): void {
    this.response = this.isSpecialMission ? 'special mission' : '';
    this.roundValue = 10;
  }

  onResponseKeyup(): void {
    this.responseKeyup.emit();
  }

  onMouseMove(event): void {
    this.formMouseMove.emit(event);
  }

  onSubmitClick(): void {
    this.submitClick.emit(this.response);
  }

  onFormSkip(event): void {
    this.formSkip.emit(event);
  }

  onFormSubmit(event): void {
    this.formSubmit.emit(event);
  }
}
