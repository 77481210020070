import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-country-not-supported',
  templateUrl: './country-not-supported.component.html',
  styleUrls: ['./country-not-supported.component.scss']
})
export class CountryNotSupportedComponent implements AfterViewInit {
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef;
  el_wrapper: HTMLElement;

  ngAfterViewInit(): void {
    this.el_wrapper = this.wrapper.nativeElement as HTMLElement;
  }

  onBackToTop(): void {
    this.el_wrapper.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    });
  }
}
