import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule],
  exports: [FileUploadComponent],
  declarations: [FileUploadComponent]
})
export class SharedFeatureFileUploadModule {}
