import { BreakpointObserver } from '@angular/cdk/layout';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ProfileMenuService } from '../../../shared/components/profile-menu/profile-menu.service';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import {
  AuthenticationService,
  IncentiveProgramService,
  UserProfileService,
  VerificationService,
  VerificationUserStatus
} from '@suzy/crowdtap/data-access/user';
export type Link = {
  copy: string;
  title: string;
  iconClass?: string;
  url?: string;
  imgSrc?: string;
  selected?: boolean;
  mobileOnly?: boolean;
};
import { NavigationService } from '@suzy/crowdtap/tools/navigation';
import { TranslateService } from '@ngx-translate/core';
import { LaunchDarklyService } from '@suzy/shared/data-access/feature-flag';
import { SegmentService } from '@suzy/shared/data-access/tracking';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  host: {
    '[class.flex]': 'true'
  }
})
export class SidebarComponent implements OnInit, OnDestroy {
  isProfileMenuDisplayed = false;
  isEmailValid: boolean;
  // tslint:disable-next-line:variable-name
  elem_code: HTMLElement;
  timerCopied: any;
  showSidebarProfile = false;
  menuItems: Array<any>;
  unsubscribe$: Subject<void> = new Subject<void>();
  currentUrl: string;
  showRAF = false;

  links: Array<Link> = [
    {
      copy: 'nav.actions',
      title: 'Actions',
      iconClass: 'th-large',
      url: '/dashboard',
      selected: true,
      mobileOnly: false
    },
    {
      copy: 'nav.inProgress',
      title: 'In Progress',
      url: '/in-progress',
      iconClass: 'sync-alt',
      selected: false,
      mobileOnly: false
    },
    {
      copy: 'nav.rewards',
      title: 'Rewards',
      iconClass: 'gift',
      url: '/rewards',
      selected: false,
      mobileOnly: false
    },
    {
      copy: 'nav.profile',
      title: 'Profile',
      url: '/profile',
      imgSrc: '/assets/img/profile.jpeg',
      selected: false,
      mobileOnly: true
    }
  ];
  profilePhoto: string;
  constructor(
    private auth: AuthenticationService,
    private userProfileService: UserProfileService,
    private breakpointObserver: BreakpointObserver,
    private translateService: TranslateService,
    private profileMenuService: ProfileMenuService,
    private navigation: NavigationService,
    private verificationService: VerificationService,
    private router: Router,
    private launchDarklyService: LaunchDarklyService,
    private incentiveProgramService: IncentiveProgramService,
    private segmentService: SegmentService
  ) {}
  ngOnInit(): void {
    this.currentUrl = this.router.url;
    this.profileMenuService.display.subscribe(value => {
      this.isProfileMenuDisplayed = value;
    });

    this.router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: RouterEvent) => {
        this.currentUrl = event.url;
      });

    if (
      this.currentUrl === '/profile' &&
      !this.profileMenuService.display.value
    ) {
      this.profileMenuService.toggleDisplay();
    }

    // should this just be take(1)?
    var checkedIncentivePrograms = false;
    this.auth.userUpdated.subscribe(res => {
      if (res) {
        this.profilePhoto = this.userProfileService.getUserProfileThumbnail();
        const user = res;

        if (!checkedIncentivePrograms) {
          checkedIncentivePrograms = true;
          this.incentiveProgramService
            .getIncentivePrograms()
            .subscribe(response => {
              const data = response;
              var programCount = false;

              if (data.success) {
                const programs = data.items.filter(
                  program =>
                    program.enabled &&
                    program.member_visible &&
                    this.incentiveProgramService.isRafProgram(
                      program.incentive_program_id
                    )
                );
                programCount = programs.length > 0;
                this.showRAF =
                  this.launchDarklyService.getRAFFlag() && programCount;
                this.segmentService.identify(user.user_id, {
                  raf_eligible: programCount
                });
              }
            });
        }
      }
    });
    // this.auth
    //   .ensureUser()
    //   .pipe(takeUntil(this.unsubscribe$))
    //   .subscribe((user: any) => {
    //     this.profilePhoto = this.userProfileService.getUserProfileThumbnail();
    //   });
    this.isEmailValid = true;
    $.getScript('./assets/js/app-sidebar.js');
    this.navigation.menuItems$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(menuItems => {
        this.menuItems = menuItems;
      });

    // this.checkVerifiedItems();
  }

  handleClick(event: MouseEvent | KeyboardEvent, link: Link): void {
    event.preventDefault();
    event.stopPropagation();
    const linkTitle = link.title?.toLowerCase();
    if (
      (!this.profileMenuService.display.value && linkTitle === 'profile') ||
      (linkTitle !== 'profile' && this.profileMenuService.display.value)
    ) {
      this.profileMenuService.toggleDisplay();
    }
  }

  checkVerifiedItems(): void {
    this.auth
      .ensureUser()
      .pipe(
        map(res => {
          const user = res;
          const isOnboardUser = this.verificationService.isOnboardUser(user);
          const verificationUserStatus = new VerificationUserStatus();
          const userVerification = this.verificationService.getUserVerification(
            isOnboardUser,
            user
          );
        }),
        take(1)
      )
      .subscribe();
  }

  isSelected(link: Link): boolean {
    const isSmallScreen = this.breakpointObserver.isMatched(
      '(max-width: 599px)'
    );
    const linkTitle = link.title?.toLowerCase();
    if (isSmallScreen) {
      return (
        (link.selected && !this.isProfileMenuDisplayed) ||
        (linkTitle === 'profile' && this.isProfileMenuDisplayed)
      );
    }

    return link.selected;
  }

  handleReferClick(event: MouseEvent | KeyboardEvent): void {
    this.router.navigate(['/refer-a-friend']);
  }
  ngOnDestroy(): void {
    if (this.timerCopied) {
      clearTimeout(this.timerCopied);
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  doLogout(): void {
    this.auth.logout(true);
  }

  isCurrentUrl(url): boolean {
    if (this.currentUrl?.indexOf(url) > -1) {
      return true;
    }

    return false;
  }
}
