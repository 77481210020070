import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertMarkdownToHtml'
})
export class ConvertMarkdownToHtmlPipe implements PipeTransform {
  transform(value: string): string {
    return value
      .replace(/<.*?>/g, '')
      .replace(/&nbsp;/g, ' ')
      .replace(/&amp;/g, '&')
      .trim()
      .replace(/\*\*(.*?)\*\*/g, x =>
        x.replace('**', '<strong>').replace('**', '</strong>')
      )
      .replace(/\*(.*?)\*/g, x => x.replace('*', '<em>').replace('*', '</em>'));
  }
}
