<div class="modal-container">
  <a class="close-btn close" aria-label="Close" (click)="close(currentView)">
    <span aria-hidden="true">&times;</span>
  </a>
  <ng-container *ngIf="verificationStep !== 3 && !verificationError">
    <div class="img-container">
      <div class="img-container">
        <svg
          width="100%"
          height="100%"
          class="h-36"
          viewBox="0 0 116 116"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="58" cy="58" r="58" fill="#fff" />
          <circle cx="58" cy="58" r="58" fill="#000" fill-opacity=".05" />
          <path
            d="M40.233 36.407c0-4.528 1.82-8.871 5.06-12.073 3.24-3.202 7.633-5.001 12.214-5.001 4.582 0 8.975 1.799 12.215 5a16.974 16.974 0 0 1 5.06 12.074v5.15a3.884 3.884 0 0 1-1.34 2.336 3.969 3.969 0 0 1-2.549.925 3.969 3.969 0 0 1-2.55-.925 3.884 3.884 0 0 1-1.338-2.336v-5.15c0-2.49-1.001-4.878-2.782-6.638a9.554 9.554 0 0 0-6.716-2.75 9.554 9.554 0 0 0-6.715 2.75 9.333 9.333 0 0 0-2.782 6.638v17.411h-7.777V36.407Z"
            fill="#CECECE"
          />
          <path
            d="M35.426 91.406V54.474c0-.463.064-.908.176-1.235.113-.328.266-.511.425-.511h-3.813c-.153 0-.3.176-.41.49-.108.313-.17.74-.171 1.186v37.072c.001.445.063.872.172 1.186.108.314.256.49.409.49h3.813c-.16 0-.312-.184-.425-.512-.112-.327-.175-.771-.175-1.234Z"
            fill="#1E6FDB"
          />
          <path
            d="M81.522 52.727h-45.3c-.517 0-1.013.184-1.378.512a1.66 1.66 0 0 0-.57 1.235v36.932c0 .463.205.907.57 1.234.365.328.86.512 1.377.512h45.301c.518 0 1.015-.184 1.383-.51.368-.328.576-.772.58-1.236V54.474a1.675 1.675 0 0 0-.58-1.236 2.085 2.085 0 0 0-1.383-.51Z"
            fill="#3589F9"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M80.093 24.605a3.64 3.64 0 0 0 3.64 3.64 3.64 3.64 0 0 0-3.64 3.64 3.64 3.64 0 0 0-3.64-3.64 3.64 3.64 0 0 0 3.64-3.64ZM87.12 34.27a8.035 8.035 0 0 0 8.034 8.034 8.035 8.035 0 0 0-8.034 8.034 8.035 8.035 0 0 0-8.034-8.034 8.035 8.035 0 0 0 8.034-8.034Z"
            fill="#F8BB16"
          />
          <path
            d="M47.176 69.203c.026-.076.055-.152.084-.228-.029.076-.058.152-.084.228ZM47.563 68.234l.104-.222a5.366 5.366 0 0 0-.105.222ZM47.79 67.763c.036-.072.075-.144.114-.216-.04.072-.076.144-.115.216ZM48.047 67.304ZM46.879 70.21c.018-.079.04-.155.061-.233a4.99 4.99 0 0 0-.061.232ZM46.762 70.72c.014-.075.032-.154.049-.23-.017.079-.035.155-.05.23ZM47.016 69.703ZM47.36 68.713c.03-.076.06-.15.094-.227-.033.076-.064.153-.095.227ZM48.316 66.856c.043-.068.089-.136.134-.204l-.134.204ZM66.074 79.66a4.707 4.707 0 0 1-.125.159l.125-.159ZM65.617 80.21c.04-.041.074-.087.11-.128a6.921 6.921 0 0 1-.11.128ZM66.395 79.238c-.041.058-.082.116-.126.173.044-.057.085-.115.126-.173ZM48.621 66.418c.047-.066.094-.132.142-.195l-.142.195ZM57.225 83.803c-.086-.002-.174-.002-.26-.006.088.004.174.004.26.006ZM49.273 65.583c.052-.057.1-.117.154-.175-.053.058-.102.118-.154.175ZM48.938 65.992l.147-.187-.148.187ZM46.57 72.292c.002-.06.009-.117.013-.177-.002.06-.009.118-.013.177ZM56.036 83.708c-.045-.006-.092-.01-.138-.017.048.007.093.01.138.017ZM55.478 83.618c-.039-.008-.078-.012-.115-.02.037.006.076.012.115.02ZM46.617 71.763c.006-.07.017-.14.025-.212-.008.072-.019.142-.025.212ZM51.514 82.01l-.073-.045.072.045ZM51.178 81.782c-.059-.043-.12-.082-.178-.126.057.041.119.083.178.126ZM56.62 83.772c-.063-.006-.127-.008-.19-.014.063.006.127.008.19.014ZM46.672 71.24c.01-.074.025-.15.037-.224-.013.074-.027.15-.037.224Z"
            fill="#FBBC05"
          />
          <path
            d="M65.95 79.82c.043-.052.084-.105.125-.159a8.928 8.928 0 0 0 .318-.422c.037-.054.078-.105.115-.159l-.015.015c-.094.1-.188.202-.287.3a10.928 10.928 0 0 1-7.74 3.22h-.002c-.1 0-.201-.004-.3-.008-.08-.002-.162-.002-.242-.006a11.19 11.19 0 0 1-.369-.027c-.055-.004-.112-.006-.168-.012-.17-.017-.338-.04-.506-.064l-.03-.004a10.767 10.767 0 0 1-1.03-.206l-.021-.004a10.948 10.948 0 0 1-5.074-2.887 11.02 11.02 0 0 1-3.195-8.263l.008-.153c.006-.117.014-.234.026-.35l.015-.16c.014-.128.028-.258.047-.385a9.37 9.37 0 0 1 .098-.585l.027-.123c.025-.12.051-.24.08-.359l.039-.156c.029-.113.062-.227.094-.34l.04-.136c.046-.154.096-.306.151-.459l.023-.061c.047-.132.098-.264.151-.394.02-.043.04-.09.06-.138.045-.107.092-.212.14-.317.022-.047.042-.094.065-.14.06-.125.12-.249.186-.37l.033-.064c.076-.142.156-.284.238-.422.023-.04.047-.078.072-.118.062-.1.125-.201.19-.302l.089-.134.06-.09.006-.01-.037.042c-.054.058-.103.118-.154.175-.066.075-.13.149-.193.225-.051.062-.098.123-.148.187-.06.076-.119.15-.176.229-.05.064-.094.13-.142.195-.055.076-.108.155-.161.233a9.424 9.424 0 0 0-.41.649 9.23 9.23 0 0 0-.253.459 9.81 9.81 0 0 0-.343.726 9.763 9.763 0 0 0-.195.488c-.028.076-.057.153-.083.229a10.35 10.35 0 0 0-.16.498c-.027.09-.052.183-.076.274l-.062.232-.063.282c-.017.077-.035.153-.05.231a12.68 12.68 0 0 0-.05.294c-.013.075-.027.149-.038.225a14.77 14.77 0 0 0-.039.313c-.008.07-.018.14-.024.212l-.027.35c-.004.06-.01.117-.012.177a22.59 22.59 0 0 0-.008.529 11.009 11.009 0 0 0 4.436 8.832l.178.125c.086.062.172.126.26.184.023.016.048.029.072.045a10.918 10.918 0 0 0 3.858 1.587c.039.008.078.013.115.02.137.028.275.052.414.073l.138.016c.13.019.264.037.398.05.063.006.127.008.19.014.115.01.232.02.347.027.086.004.172.004.26.006.095.002.189.006.283.006h.002a10.924 10.924 0 0 0 8.1-3.598c.038-.042.073-.087.11-.128.074-.084.152-.173.226-.261Z"
            fill="#F89601"
          />
          <path
            d="M49.236 65.6c-.066.1-.13.2-.19.303l-.073.117c-.082.14-.162.28-.237.422-.013.02-.023.044-.033.064-.064.124-.127.247-.187.37-.02.048-.04.095-.063.143-.05.105-.095.21-.14.317-.02.047-.041.094-.06.142-.053.13-.102.261-.151.393-.008.02-.017.041-.023.062-.053.152-.104.305-.151.459-.015.045-.027.09-.04.136l-.094.34c-.014.051-.026.105-.039.156-.029.12-.055.239-.08.358l-.026.124c-.031.158-.06.319-.084.48-.007.034-.009.07-.015.104a7.84 7.84 0 0 0-.047.385c-.006.054-.01.108-.014.161-.01.117-.019.233-.027.35l-.008.152a11.009 11.009 0 0 0 3.195 8.264 10.929 10.929 0 0 0 5.074 2.886l.02.005c.341.084.685.154 1.032.205l.029.004c.168.025.336.046.506.064.056.007.113.009.169.013.123.01.246.02.369.027.08.004.162.004.242.006.1.002.199.008.3.008h.001a10.925 10.925 0 0 0 8.027-3.52l.009-.013a11.018 11.018 0 0 0-.298-15.228 10.914 10.914 0 0 0-16.743 1.519l-.06.09-.09.132Z"
            fill="#FBBC05"
          />
          <path
            d="M64.364 69.41a.585.585 0 0 0-.466-.403l-3.45-.54-1.514-3.153a.582.582 0 0 0-.52-.331h-.034c-.21.01-.399.13-.492.32l-1.578 3.12-3.462.464a.585.585 0 0 0-.334.993l2.476 2.469-.627 3.44a.582.582 0 0 0 .84.624l3.11-1.593 3.074 1.66c.096.052.2.075.303.071a.579.579 0 0 0 .549-.674l-.555-3.453 2.527-2.414a.59.59 0 0 0 .153-.6Z"
            fill="#fff"
          />
        </svg>
      </div>
    </div>
    <h3>
      {{ 'verification.unlockFullExperience' | translate }}
    </h3>
    <p>
      {{ 'verification.unlockFullExperienceDescriptionModal' | translate }}
    </p>
    <p class="note" *ngIf="currentView !== 'dashboard'">
      {{ 'verification.unlockFullExperienceNote' | translate }}
    </p>
    <div class="recaptcha-container">
      <re-captcha
        (resolved)="resolved($event)"
        formControlName="recaptchaReactive"
        [siteKey]="siteKey"
        [theme]="isMobile ? 'dark' : 'light'"
      ></re-captcha>
    </div>
  </ng-container>
  <ng-container *ngIf="verificationStep === 3 && !verificationError">
    <div class="reward-modal">
      <button
        *ngIf="isModal"
        type="button"
        class="close reward-modal-close"
        aria-label="Close"
        (click)="closeModal(currentView)"
        tabindex="-1"
      >
        <span class="sr-only" aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="img-container">
      <svg
        width="100%"
        height="100%"
        class="h-36"
        viewBox="0 0 116 116"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="58" cy="58" r="58" fill="#fff" />
        <circle cx="58" cy="58" r="58" fill="#000" fill-opacity=".05" />
        <path
          d="M40.233 36.407c0-4.528 1.82-8.871 5.06-12.073 3.24-3.202 7.633-5.001 12.214-5.001 4.582 0 8.975 1.799 12.215 5a16.974 16.974 0 0 1 5.06 12.074v5.15a3.884 3.884 0 0 1-1.34 2.336 3.969 3.969 0 0 1-2.549.925 3.969 3.969 0 0 1-2.55-.925 3.884 3.884 0 0 1-1.338-2.336v-5.15c0-2.49-1.001-4.878-2.782-6.638a9.554 9.554 0 0 0-6.716-2.75 9.554 9.554 0 0 0-6.715 2.75 9.333 9.333 0 0 0-2.782 6.638v17.411h-7.777V36.407Z"
          fill="#CECECE"
        />
        <path
          d="M35.426 91.406V54.474c0-.463.064-.908.176-1.235.113-.328.266-.511.425-.511h-3.813c-.153 0-.3.176-.41.49-.108.313-.17.74-.171 1.186v37.072c.001.445.063.872.172 1.186.108.314.256.49.409.49h3.813c-.16 0-.312-.184-.425-.512-.112-.327-.175-.771-.175-1.234Z"
          fill="#1E6FDB"
        />
        <path
          d="M81.522 52.727h-45.3c-.517 0-1.013.184-1.378.512a1.66 1.66 0 0 0-.57 1.235v36.932c0 .463.205.907.57 1.234.365.328.86.512 1.377.512h45.301c.518 0 1.015-.184 1.383-.51.368-.328.576-.772.58-1.236V54.474a1.675 1.675 0 0 0-.58-1.236 2.085 2.085 0 0 0-1.383-.51Z"
          fill="#3589F9"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M80.093 24.605a3.64 3.64 0 0 0 3.64 3.64 3.64 3.64 0 0 0-3.64 3.64 3.64 3.64 0 0 0-3.64-3.64 3.64 3.64 0 0 0 3.64-3.64ZM87.12 34.27a8.035 8.035 0 0 0 8.034 8.034 8.035 8.035 0 0 0-8.034 8.034 8.035 8.035 0 0 0-8.034-8.034 8.035 8.035 0 0 0 8.034-8.034Z"
          fill="#F8BB16"
        />
        <path
          d="M47.176 69.203c.026-.076.055-.152.084-.228-.029.076-.058.152-.084.228ZM47.563 68.234l.104-.222a5.366 5.366 0 0 0-.105.222ZM47.79 67.763c.036-.072.075-.144.114-.216-.04.072-.076.144-.115.216ZM48.047 67.304ZM46.879 70.21c.018-.079.04-.155.061-.233a4.99 4.99 0 0 0-.061.232ZM46.762 70.72c.014-.075.032-.154.049-.23-.017.079-.035.155-.05.23ZM47.016 69.703ZM47.36 68.713c.03-.076.06-.15.094-.227-.033.076-.064.153-.095.227ZM48.316 66.856c.043-.068.089-.136.134-.204l-.134.204ZM66.074 79.66a4.707 4.707 0 0 1-.125.159l.125-.159ZM65.617 80.21c.04-.041.074-.087.11-.128a6.921 6.921 0 0 1-.11.128ZM66.395 79.238c-.041.058-.082.116-.126.173.044-.057.085-.115.126-.173ZM48.621 66.418c.047-.066.094-.132.142-.195l-.142.195ZM57.225 83.803c-.086-.002-.174-.002-.26-.006.088.004.174.004.26.006ZM49.273 65.583c.052-.057.1-.117.154-.175-.053.058-.102.118-.154.175ZM48.938 65.992l.147-.187-.148.187ZM46.57 72.292c.002-.06.009-.117.013-.177-.002.06-.009.118-.013.177ZM56.036 83.708c-.045-.006-.092-.01-.138-.017.048.007.093.01.138.017ZM55.478 83.618c-.039-.008-.078-.012-.115-.02.037.006.076.012.115.02ZM46.617 71.763c.006-.07.017-.14.025-.212-.008.072-.019.142-.025.212ZM51.514 82.01l-.073-.045.072.045ZM51.178 81.782c-.059-.043-.12-.082-.178-.126.057.041.119.083.178.126ZM56.62 83.772c-.063-.006-.127-.008-.19-.014.063.006.127.008.19.014ZM46.672 71.24c.01-.074.025-.15.037-.224-.013.074-.027.15-.037.224Z"
          fill="#FBBC05"
        />
        <path
          d="M65.95 79.82c.043-.052.084-.105.125-.159a8.928 8.928 0 0 0 .318-.422c.037-.054.078-.105.115-.159l-.015.015c-.094.1-.188.202-.287.3a10.928 10.928 0 0 1-7.74 3.22h-.002c-.1 0-.201-.004-.3-.008-.08-.002-.162-.002-.242-.006a11.19 11.19 0 0 1-.369-.027c-.055-.004-.112-.006-.168-.012-.17-.017-.338-.04-.506-.064l-.03-.004a10.767 10.767 0 0 1-1.03-.206l-.021-.004a10.948 10.948 0 0 1-5.074-2.887 11.02 11.02 0 0 1-3.195-8.263l.008-.153c.006-.117.014-.234.026-.35l.015-.16c.014-.128.028-.258.047-.385a9.37 9.37 0 0 1 .098-.585l.027-.123c.025-.12.051-.24.08-.359l.039-.156c.029-.113.062-.227.094-.34l.04-.136c.046-.154.096-.306.151-.459l.023-.061c.047-.132.098-.264.151-.394.02-.043.04-.09.06-.138.045-.107.092-.212.14-.317.022-.047.042-.094.065-.14.06-.125.12-.249.186-.37l.033-.064c.076-.142.156-.284.238-.422.023-.04.047-.078.072-.118.062-.1.125-.201.19-.302l.089-.134.06-.09.006-.01-.037.042c-.054.058-.103.118-.154.175-.066.075-.13.149-.193.225-.051.062-.098.123-.148.187-.06.076-.119.15-.176.229-.05.064-.094.13-.142.195-.055.076-.108.155-.161.233a9.424 9.424 0 0 0-.41.649 9.23 9.23 0 0 0-.253.459 9.81 9.81 0 0 0-.343.726 9.763 9.763 0 0 0-.195.488c-.028.076-.057.153-.083.229a10.35 10.35 0 0 0-.16.498c-.027.09-.052.183-.076.274l-.062.232-.063.282c-.017.077-.035.153-.05.231a12.68 12.68 0 0 0-.05.294c-.013.075-.027.149-.038.225a14.77 14.77 0 0 0-.039.313c-.008.07-.018.14-.024.212l-.027.35c-.004.06-.01.117-.012.177a22.59 22.59 0 0 0-.008.529 11.009 11.009 0 0 0 4.436 8.832l.178.125c.086.062.172.126.26.184.023.016.048.029.072.045a10.918 10.918 0 0 0 3.858 1.587c.039.008.078.013.115.02.137.028.275.052.414.073l.138.016c.13.019.264.037.398.05.063.006.127.008.19.014.115.01.232.02.347.027.086.004.172.004.26.006.095.002.189.006.283.006h.002a10.924 10.924 0 0 0 8.1-3.598c.038-.042.073-.087.11-.128.074-.084.152-.173.226-.261Z"
          fill="#F89601"
        />
        <path
          d="M49.236 65.6c-.066.1-.13.2-.19.303l-.073.117c-.082.14-.162.28-.237.422-.013.02-.023.044-.033.064-.064.124-.127.247-.187.37-.02.048-.04.095-.063.143-.05.105-.095.21-.14.317-.02.047-.041.094-.06.142-.053.13-.102.261-.151.393-.008.02-.017.041-.023.062-.053.152-.104.305-.151.459-.015.045-.027.09-.04.136l-.094.34c-.014.051-.026.105-.039.156-.029.12-.055.239-.08.358l-.026.124c-.031.158-.06.319-.084.48-.007.034-.009.07-.015.104a7.84 7.84 0 0 0-.047.385c-.006.054-.01.108-.014.161-.01.117-.019.233-.027.35l-.008.152a11.009 11.009 0 0 0 3.195 8.264 10.929 10.929 0 0 0 5.074 2.886l.02.005c.341.084.685.154 1.032.205l.029.004c.168.025.336.046.506.064.056.007.113.009.169.013.123.01.246.02.369.027.08.004.162.004.242.006.1.002.199.008.3.008h.001a10.925 10.925 0 0 0 8.027-3.52l.009-.013a11.018 11.018 0 0 0-.298-15.228 10.914 10.914 0 0 0-16.743 1.519l-.06.09-.09.132Z"
          fill="#FBBC05"
        />
        <path
          d="M64.364 69.41a.585.585 0 0 0-.466-.403l-3.45-.54-1.514-3.153a.582.582 0 0 0-.52-.331h-.034c-.21.01-.399.13-.492.32l-1.578 3.12-3.462.464a.585.585 0 0 0-.334.993l2.476 2.469-.627 3.44a.582.582 0 0 0 .84.624l3.11-1.593 3.074 1.66c.096.052.2.075.303.071a.579.579 0 0 0 .549-.674l-.555-3.453 2.527-2.414a.59.59 0 0 0 .153-.6Z"
          fill="#fff"
        />
      </svg>
    </div>
    <h3 class="dark:text-white">
      {{ 'verification.fullExperienceUnlocked' | translate }}
    </h3>
    <p class="dark:text-white">
      {{ 'verification.completeYourProfile' | translate }}
    </p>
    <div class="buttons-container">
      <button (click)="close('profile')">
        {{ 'verification.continueToProfile' | translate }}
      </button>

      <ng-container
        this.currentUser.onboarding_status="2;r"
        *ngIf="currentView === 'dashboard'; else reedem"
      >
        <button class="btn-text" (click)="close(currentView)">
          {{ 'verification.returnToActions' | translate }}
        </button>
      </ng-container>
      <ng-template #reedem>
        <button class="btn-text" (click)="close(currentView)">
          {{ 'verification.returnToRewards' | translate }}
        </button>
      </ng-template>
    </div>
  </ng-container>

  <ng-container *ngIf="verificationError">
    <div class="img-container">
      <svg
        width="147"
        height="147"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M51.1 42.204a22.075 22.075 0 0 1 6.58-15.7A22.6 22.6 0 0 1 73.564 20c5.959 0 11.673 2.34 15.886 6.503a22.075 22.075 0 0 1 6.58 15.701v21.258a5.052 5.052 0 0 1-1.741 3.038 5.161 5.161 0 0 1-3.316 1.203 5.161 5.161 0 0 1-3.316-1.203 5.052 5.052 0 0 1-1.741-3.038V42.204c0-3.237-1.301-6.343-3.618-8.632a12.426 12.426 0 0 0-8.734-3.576 12.425 12.425 0 0 0-8.733 3.576 12.137 12.137 0 0 0-3.618 8.632v22.644H51.1V42.204Z"
          fill="#CECECE"
        />
        <path
          d="M44.844 113.729v-48.03c0-.602.082-1.18.228-1.605.147-.426.345-.665.552-.665h-4.958c-.2 0-.39.229-.532.637-.142.408-.222.963-.224 1.543v48.211c.002.58.082 1.134.224 1.543.141.408.333.637.532.637h4.958c-.207 0-.405-.239-.552-.665-.146-.426-.228-1.003-.228-1.606Z"
          fill="#1E6FDB"
        />
        <path
          d="M104.784 63.429H45.871a2.69 2.69 0 0 0-1.791.665c-.475.426-.742 1.003-.742 1.605v48.03c0 .603.267 1.18.742 1.606.475.426 1.12.665 1.791.665h58.913c.674 0 1.321-.239 1.799-.664.478-.425.75-1.003.755-1.607v-48.03c-.005-.603-.277-1.181-.755-1.606a2.713 2.713 0 0 0-1.799-.664Z"
          fill="#3589F9"
        />
      </svg>
    </div>
    <h3>{{ 'verification.tryAgainLater' | translate }}</h3>
    <p>{{ 'verification.tooManyAttempts' | translate }}</p>
    <p>{{ 'verification.tryAgainIn' | translate }}</p>
    <div class="countdown">
      <span>{{ minutes }} : {{ seconds < 10 ? 0 : '' }}{{ seconds }}</span>
    </div>
    <div class="buttons-container">
      <a (click)="close('home')">{{
        'verification.returnToHome' | translate
      }}</a>
    </div>
  </ng-container>
</div>
