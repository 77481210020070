<a [href]="skipLinkPathContent" class="sr-only">Skip to main content</a>

<div #wrapper>
  <app-general-header (openSignupModal)="onOpenSignUpModal()">
  </app-general-header>
  <section aria-label="main" role="main" id="main-content">
    <a [href]="skipLinkPathFooter" class="sr-only">Skip to footer</a>
    <app-success-sent *ngIf="isPassSubmission"></app-success-sent>

    <article
      class="flex flex-col w-full max-w-lg px-4 m-auto lg:px-8 md:w-2/5"
      *ngIf="!isPassSubmission"
    >
      <h1 class="text-light-black dark:text-white">
        {{ 'auth.forgotYourPassword' | translate }}
      </h1>
      <p class="text-light-black dark:text-white">
        {{ 'auth.emailEmailYouAccount' | translate }}
      </p>

      <form [formGroup]="lostForm" (ngSubmit)="onSubmit()">
        <div class="form-row">
          <label class="font-medium text-light-black dark:text-white">{{
            'auth.emailAddress' | translate
          }}</label>
          <input
            type="email"
            placeholder="{{ 'auth.emailAddress' | translate }}"
            class="text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
            formControlName="email"
          />
          <small
            *ngIf="submitted && f.email.errors"
            class="form-text text-muted danger"
          >
            <span *ngIf="f.email.errors.required">
              {{ 'auth.emailIsRequired' | translate }}
            </span>
            <span
              *ngIf="f.email.errors.email"
              class="form-text text-muted danger"
            >
              {{ 'auth.invalidEmailAddress' | translate }}
            </span>
          </small>
        </div>

        <div class="mt-10 text-center">
          <button
            type="submit"
            data-track="member-login-login-btn"
            class="relative mb-4 font-bold text-white rounded-full w-60 h-14 bg-blue-dark"
          >
            {{ 'auth.resetPassword' | translate }}
            <div class="absolute left-8 button-spinner top-7">
              <suzy-spinner
                *ngIf="isLoading"
                size="small"
                button="true"
                [round]="10"
                [color]="'#ffffff'"
                [overlay]="false"
              >
              </suzy-spinner>
            </div>
          </button>
        </div>
      </form>
    </article>
  </section>

  <app-general-footer
    id="main-footer"
    (backToTop)="onBackToTop()"
  ></app-general-footer>
</div>
