<!-- Action Modal Starts -->
<div
  class="flex flex-col h-full action-modal-container"
  [ngClass]="{ 'legal-action': isLegalAction }"
>
  <app-warning-info
    *ngIf="action.is_prerequisite && isPreview"
    [message]="'surveyPreview.prerequisiteWarning' | translate"
  >
  </app-warning-info>
  <div class="head-container">
    <app-action-modal-header
      [mission]="mission"
      [action]="action"
      [metaData]="metaData"
      [disableButton]="mission?.isGlobal"
      [typeIndicatorColor]="'card-' + mission?.color"
    >
    </app-action-modal-header>

    <!-- Action Modal Question Starts -->
    <div
      *ngIf="!isLegalAction; else showLegalContent"
      class="p-6 bg-body-light dark:bg-body-dark pt-0 border-b-2 sm:px-20 md:px-24 md:py-8 md:pt-0 dark:text-white border-card-{{
        mission?.color
      }}"
    >
      <span
        class="block mb-4 text-base font-medium md:text-lg md:font-semibold lg:text-xl lg:font-medium"
        [innerHtml]="question | markdown2html : { secure: true }"
        data-track="member-multiple-choice-question-text"
      ></span>

      <div
        *ngIf="
          action.multiple_choice.multiple_choice_kind !==
          multipleChoiceKind.monadic
        "
      >
        <!--Single Selection -->
        <ng-container *ngIf="!action.multiple_choice.image_answers">
          <!-- Single Selection -->
          <ng-container *ngIf="chooseExactlyOneChoice()">
            <p class="mb-4 text-xs font-semibold">
              {{ 'multipleChoice.pleaseSelectOneAnswer' | translate }}
            </p>
          </ng-container>

          <ng-container *ngIf="!chooseExactlyOneChoice()">
            <!--Multiple Selection -->
            <p
              class="mb-4 text-xs font-semibold"
              *ngIf="chooseAtLeastXChoices()"
            >
              {{
                'multipleChoice.chooseAtLeastX'
                  | translate : { min: multipleChoice.minChoices }
              }}
            </p>

            <p
              class="mb-4 text-xs font-semibold"
              *ngIf="chooseExactlyXChoices()"
            >
              {{
                'multipleChoice.chooseExactlyX'
                  | translate : { max: multipleChoice.maxChoices }
              }}
            </p>

            <p class="mb-4 text-xs font-semibold" *ngIf="chooseUpToXChoices()">
              <ng-container
                *ngIf="
                  multipleChoice.maxChoices === multipleChoice.options.length;
                  else notEqual
                "
              >
                {{ 'multipleChoice.selectAllThatApply' | translate }}
              </ng-container>
              <ng-template #notEqual>
                {{
                  'multipleChoice.selectUpTo'
                    | translate : { max: multipleChoice.maxChoices }
                }}
              </ng-template>
            </p>

            <p
              class="mb-4 text-xs font-semibold"
              *ngIf="chooseBetweenXandYChoices()"
            >
              {{
                'multipleChoice.chooseBetweenX'
                  | translate
                    : {
                        min: multipleChoice.minChoices,
                        max: multipleChoice.maxChoices
                      }
              }}
            </p>
          </ng-container>

          <ng-container *ngIf="action.preroll_inline_enabled">
            <div>
              <section style="display: flex; justify-content: right">
                <suzy-preroll-secondary-trigger
                  *ngIf="hasPreroll"
                  [prerollKind]="preRollKind"
                  [prerollUrl]="preRollUrl"
                  [isInline]="true"
                  (imagePrerollClick)="onViewPrerollImage($event)"
                  (linkPrerollClick)="onViewPrerollLink()"
                >
                </suzy-preroll-secondary-trigger>
              </section>
              <section style="height: 35vh">
                <img
                  style="height: inherit; margin: 0 auto"
                  [src]="action.preroll.thumbs.full.url"
                  *ngIf="preroll_kind === preRollKindImage"
                  alt="Preview image"
                  onContextMenu="return false;"
                />
              </section>
            </div>
          </ng-container>
        </ng-container>
        <!-- Image As Answer Choices -->
        <ng-container *ngIf="action.multiple_choice.image_answers">
          <!--Single Selection -->

          <ng-container *ngIf="chooseExactlyOneChoice()">
            <p class="text-xs font-semibold">
              {{ 'multipleChoice.pleaseSelectOneAnswer' | translate }}
            </p>
          </ng-container>

          <ng-container *ngIf="!chooseExactlyOneChoice()">
            <!--Multiple Selection -->
            <p class="text-xs font-semibold" *ngIf="chooseAtLeastXChoices()">
              {{
                'multipleChoice.chooseAtLeastX'
                  | translate : { min: multipleChoice.minChoices }
              }}
            </p>
            <p class="text-xs font-semibold" *ngIf="chooseExactlyXChoices()">
              {{
                'multipleChoice.chooseExactlyX'
                  | translate : { max: multipleChoice.maxChoices }
              }}
            </p>
            <p class="text-xs font-semibold" *ngIf="chooseUpToXChoices()">
              <ng-container
                *ngIf="
                  multipleChoice.maxChoices === multipleChoice.options.length;
                  else notEqual
                "
              >
                {{ 'multipleChoice.selectAllThatApply' | translate }}
              </ng-container>
              <ng-template #notEqual>
                {{
                  'multipleChoice.selectUpTo'
                    | translate : { max: multipleChoice.maxChoices }
                }}
              </ng-template>
            </p>
            <p
              class="text-xs font-semibold"
              *ngIf="chooseBetweenXandYChoices()"
            >
              {{
                'multipleChoice.chooseBetweenX'
                  | translate
                    : {
                        min: multipleChoice.minChoices,
                        max: multipleChoice.maxChoices
                      }
              }}
            </p>
          </ng-container>
        </ng-container>
      </div>

      <suzy-preroll-secondary-trigger
        *ngIf="hasPreroll && prerollViewed && !action.preroll_inline_enabled"
        [prerollKind]="preRollKind"
        [prerollUrl]="preRollUrl"
        (imagePrerollClick)="onViewPrerollImage($event)"
        (linkPrerollClick)="onViewPrerollLink()"
      >
      </suzy-preroll-secondary-trigger>
    </div>
    <!-- Action Modal Question Ends -->

    <!-- Legal Content Starts -->
    <ng-template #showLegalContent>
      <div
        class="external-container mx-6 md:mx-24"
        [ngClass]="{
          showTopGradient: showTopGradient,
          showBottomGradient: showBottomGradient,
          scrollError: scrollErrorMessage
        }"
      >
        <div
          #externalContent
          class="external-content text-xs font-semibold"
          (scroll)="onScrollExternal($event)"
          [innerHtml]="question | markdown2html : { secure: true }"
          data-track="member-legal-text"
        ></div>
      </div>
      <p
        class="error-inline-msg text-error sm:mx-20 md:mx-24"
        *ngIf="scrollErrorMessage"
      >
        <img
          src="../../../../assets/img/svg/icons/ic_error_outline_red.svg"
          class="inline-block mr-2 ltr:mr-2 rtl:ml-2 w-5"
          alt="error icon"
        />
        {{ scrollErrorMessage }}
      </p>
    </ng-template>
    <!-- Legal Content Ends -->
  </div>

  <!-- Action Modal Body Starts -->
  <div
    class="flex-1 p-6 sm:px-20 md:px-24 md:py-8 dark:text-white action-body-container"
  >
    <suzy-preroll
      (prerollViewedEvent)="onPrerollViewedEvent($event)"
      *ngIf="
        hasPreroll &&
        !prerollViewed &&
        showPreroll &&
        !action.preroll_inline_enabled &&
        !(!action.preroll_inline_enabled && action.hidden_preroll_enabled)
      "
      [preRollCaption]="preRollCaption"
      [preRollKind]="preRollKind"
      [preRollUrl]="preRollUrl"
      [mission]="mission"
      [action]="action"
    >
    </suzy-preroll>
    <suzy-action-forms-multiple-choice
      [isLoading]="isLoading"
      [isSuccess]="isSuccess"
      [action]="action"
      [multipleChoice]="multipleChoice"
      [isTrapQuestion]="isTrapQuestion"
      [disableSkip]="disableSkip"
      [errorMessage]="errorMessage"
      [isSubmitting]="isSubmitting"
      [isAutoAssignOnPreview]="isAutoAssignOnPreview"
      [selectedRadio]="selectedRadio"
      (radioChange)="onRadioChange($event)"
      (otherValueChange)="onOtherUpdateChange($event)"
      (checkboxChange)="onCheckBoxChange($event)"
      (skip)="onSkip($event)"
      (mouseEvent)="onMouseMoveEvent($event)"
      (mcFormSubmit)="onSubmit($event)"
      (mcFormSubmitPrerequisite)="onSubmitPrerequisite($event)"
      (viewOptionImageEvent)="viewOptionImage($event)"
      *ngIf="
        !hasPreroll ||
        (hasPreroll && prerollViewed) ||
        action.preroll_inline_enabled ||
        (!action.preroll_inline_enabled && action.hidden_preroll_enabled)
      "
      [disableButton]="mission?.isGlobal === 'true' ? false : true"
      [isSkipping]="isSkipping"
      [isLegalAction]="isLegalAction"
      [mission]="mission"
    >
    </suzy-action-forms-multiple-choice>
  </div>
</div>
<!-- Action Modal Ends -->

<!-- Image Modal Begins -->
<div class="image-modal-container" *ngIf="imageModalIsActive">
  <button
    type="button"
    class="close"
    tabindex="-1"
    (click)="imageModalIsActive = false"
  >
    <span class="sr-only" aria-hidden="true">&times;</span>
  </button>
  <div class="image-wrapper">
    <span class="left"
      ><img
        src="/assets/img/svg/icons/ic-chevron-white-right.svg"
        alt="Previous Image Arrow"
        (click)="previousImage(currentChoiceIndex)"
    /></span>
    <img
      class="option-image"
      [src]="activeImageUrl"
      [alt]="activeImageAltText"
    />
    <span class="right"
      ><img
        src="/assets/img/svg/icons/ic-chevron-white-right.svg"
        alt="Next Image Arrow"
        (click)="nextImage(currentChoiceIndex)"
    /></span>
  </div>
  <div class="answer-choice-text-wrapper">
    <p>{{ activeAnswerChoiceText }}</p>
  </div>
  <div class="slider-navigation-wrapper">
    <ng-container *ngFor="let options of mcOptionsExcludingNone">
      <span
        (click)="viewOptionImage({ option: options })"
        [ngClass]="{ active: options.answer_text === activeAnswerChoiceText }"
      ></span>
    </ng-container>
  </div>
</div>
