import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';

@Component({
  selector: 'app-success-animation',
  templateUrl: './success-animation.component.html',
  styleUrls: ['./success-animation.component.scss']
})
export class SuccessAnimationComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('actionModalBox') actionModalBox: ElementRef;
  @ViewChild('successAimationBox')
  successAimationBox: ElementRef;

  // state
  isPassSubmission: boolean;
  isSppinner: boolean;
  isSuccessAnimationCompleted: boolean;
  isInitialMission: boolean;
  isResultScreen: boolean;
  isNextMission: boolean;

  // timer
  timerIsPassSubmission: any;
  timerIsCompleted: any;
  timerIsRemoveInitalForm: any;
  timerIsRemoveResultScreen: any;

  // variables for storing the element ref
  el_actionModalBox: HTMLElement;
  el_successAnimationBox: HTMLElement;

  ngOnInit(): void {
    // state initial value
    this.isSppinner = true;
    this.isInitialMission = true;
    this.isPassSubmission = false;
    this.isSuccessAnimationCompleted = false;
    this.isResultScreen = false;
    this.isNextMission = false;

    document.getElementsByTagName('body')[0].classList.add('isActionModal'); // code from Actiton Module append class in body
  }

  ngAfterViewInit(): void {
    // Initialize variabeles for element ref
    this.el_successAnimationBox = this.successAimationBox
      .nativeElement as HTMLElement;
    this.el_actionModalBox = this.actionModalBox.nativeElement as HTMLElement;

    /* Timer for Form Submission Passing State: (for demo purpose set delay as 1000)
     1: Hide spinner
     2: Show el_successAnimationBox
     3: Append a class(isAnimate) to el_successAnimationBox
        (Success Animation takes 2.85 secs to complete) */
    this.timerIsPassSubmission = setTimeout(() => {
      this.isSppinner = false;
      this.isPassSubmission = true;
      this.el_successAnimationBox.classList.add('isAnimate');
    }, 1000);

    /* Timer for success animation completion state: (giving extra padding and set delay as 3000)
     1: Show Result Screen
     2: Fade out el_successAnimationBox  - 0.85 secs to complete the animation */
    this.timerIsCompleted = setTimeout(() => {
      this.isSuccessAnimationCompleted = true;
      this.isResultScreen = true;

      /* Timer for removing the initial modal dialog: (giving extra padding for better trasition)
       - Hide Inital Form Modal */
      this.timerIsRemoveInitalForm = setTimeout(() => {
        this.isInitialMission = false;
      }, 3085);
    }, 3000);
  }

  // On Submit Event Handler for button
  onSubmitHandler(): void {
    this.nextMission();
    this.slideOut();
  }

  nextMission(): void {
    this.isNextMission = true;
  }

  slideOut(): void {
    /* Timer for removing the Result Screen after slide out animation completes:
    (Animatino takes 0.75 sec to complete) */
    this.timerIsRemoveResultScreen = setTimeout(() => {
      this.isResultScreen = false;
    }, 3100);
  }

  ngOnDestroy(): void {
    // Clears timer when unmount
    if (this.timerIsPassSubmission) {
      clearTimeout(this.timerIsPassSubmission);
    }
    if (this.timerIsCompleted) {
      clearTimeout(this.timerIsCompleted);
    }
    if (this.timerIsRemoveInitalForm) {
      clearTimeout(this.timerIsCompleted);
    }
    if (this.timerIsRemoveResultScreen) {
      clearTimeout(this.timerIsRemoveResultScreen);
    }
  }
}
