export const environment = {
  production: false,
  preview: false,
  global: true,
  local: false,
  externalLink: false,
  apiUrl: 'https://api-dev.asksuzy.io/member-dev',
  globalUrl: 'https://isolate-qa.asksuzy.io/api',
  signalRUrl: 'https://ws-dev.asksuzy.io',
  facebookAppId: 'xxxxxxxxxx',
  grecaptchaToken: '6LcaClsaAAAAAJWoziDMD8F3PhE9XyQFRTzMj1zK',
  grecaptchaV2Token: '6LfIk20aAAAAADSvzkhMxhB0t3L1faUCXm1AqExS',
  languagePlatform: 'crowdtap.v1',
  segmentToken: 'zCtsiCV6Em2I4aJWDRvBRHEl8mXXcnK6',
  sharedLinkRoot: 'https://preview-qa.suzy.com/preview',
  launchDarkly: '62c593e3a9ccc815c757b908',
  legalId: '1248700B-3E07-48DF-9427-1885E1FE8213'
};
