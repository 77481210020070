import { Injectable } from '@angular/core';
import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import { Observable, of } from 'rxjs';

declare global {
  interface Window {
    zE: any;
  }
}

class ZendeskJWT {
  item: string;
  success: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ZendeskService {
  private isGetting = false;
  private jwt: string | undefined;
  private loading: Promise<boolean>;
  constructor(private sdkService: SuzySdkService) {}

  initialize(): Promise<void> {
    this.loading = new Promise<boolean>((resolve, reject) => {
      if (document.getElementById('ze-snippet')) {
        resolve(true);
      } else {
        if (
          window.location.href.indexOf('/mobile') < 0 &&
          window.innerWidth >= 600
        ) {
          let script = document.createElement('script');
          script.id = 'ze-snippet';
          script.type = 'text/javascript';
          script.src =
            'https://static.zdassets.com/ekr/snippet.js?key=d358b0a3-ac1b-4006-934f-d668ad1e1d5b';
          script.onload = () => {
            resolve(true);
          };
          script.onerror = () => reject(false);
          document.getElementsByTagName('head')[0].appendChild(script);
        } else {
          resolve(false);
        }
      }
    });

    return new Promise<void>(resolve => resolve());
  }

  logIn(regenerate = false): void {
    if (window === undefined || window.zE === undefined) {
      return;
    }
    this.loading
      .then((loaded: boolean) => {
        if (loaded && window !== undefined && window.zE !== undefined) {
          if (!this.isGetting && (regenerate || this.jwt === undefined)) {
            this.isGetting = true;
            this.getJWT().subscribe(JWT => {
              this.isGetting = false;
              if (JWT && JWT.success) {
                this.jwt = JWT.item;
                this.zELogIn(JWT.item);
              }
            });
          } else {
            this.zELogIn(this.jwt);
          }
        }
      })
      .catch(() => {});
  }

  logOut(): void {
    if (window === undefined || window.zE === undefined) {
      return;
    }

    window.zE('messenger', 'logoutUser');
    this.isGetting = false;
  }

  private getJWT(): Observable<ZendeskJWT> {
    return this.sdkService.ProtocolAuthorize.generateZendeskToken();
  }

  private zELogIn(JWT: string): void {
    window.zE('messenger', 'loginUser', function (callback) {
      callback(JWT);
    });
  }
}
