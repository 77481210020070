import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoalService } from 'apps/crowdtap/src/app/core/services/goal.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { SignupService } from '../signup.service';
@Component({
  selector: 'set-reward-goal',
  templateUrl: './set-reward-goal.component.html',
  styleUrls: ['./set-reward-goal.component.scss']
})
export class SetRewardGoalComponent implements OnInit {
  goalInfo: any;
  isEditing = true;
  unsubscribe$: Subject<boolean> = new Subject();

  constructor(
    private goalService: GoalService,
    private router: Router,
    private signupService: SignupService
  ) {}

  ngOnInit(): void {
    this.goalService.goalInfo$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(goal => goal.item !== undefined)
      )
      .subscribe(goal => {
        this.goalInfo = goal;
        this.isEditing = false;
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      });
  }

  editGoal(type: string) {
    this.isEditing = true;
  }

  onCloseSuccessModal() {
    this.signupService.isFirstSMSVerifyLoad = true;
    this.router.navigate(['auth', 'get-verified']);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
