import { Component, ElementRef, OnInit, Renderer2, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionsService } from '../../../actions/actions.service';
import { PreviewService } from '../../../preview/preview.service';
import { SettingsService } from 'apps/crowdtap/src/app/core/services/settings.service';

@Component({
  selector: 'app-external-cover',
  templateUrl: './external-cover.component.html',
  styleUrls: ['./external-cover.component.scss']
})
export class ExternalCoverComponent implements OnInit {
  @Input() title: string;
  description: string;
  mission: any;
  kind: number;
  action: any;
  isLoading = false;
  actionService: ActionsService;
  modalSettings: any;
  isPreview: boolean;
  logoPath: string;
  isExternal: boolean;

  constructor(
    private renderer: Renderer2,
    public activeModal: NgbActiveModal,
    private hostElement: ElementRef,
    private previewService: PreviewService,
    private settingsServive: SettingsService
  ) {}

  ngOnInit(): void {
    this.previewService.disablePreviewControls = false;

    this.renderer.addClass(document.body, 'isActionModal');
    this.isPreview = this.settingsServive.isPreviewApp();
    this.isExternal = this.settingsServive.isExternalApp();
  }

  getStarted(): any {
    this.isLoading = true;
    const wrapper = this.hostElement.nativeElement.querySelector(
      '.action-modal-container'
    );
    wrapper?.classList.add('is-hidden');

    if (this.isPreview || this.isExternal) {
      this.isLoading = false;
      this.activeModal.dismiss();

      this.previewService.trackMissionStarted(
        'External Mission Started',
        this.mission,
        'external_cover_button'
      );
      setTimeout(() => {
        this.actionService
          .doAction(this.mission, this.action, undefined, this.modalSettings)
          .subscribe(result => {
            this.isLoading = false;
            this.activeModal.close(result);
          });
      }, 500);
    } else {
      setTimeout(() => {
        this.actionService
          .doAction(this.mission, this.action)
          .subscribe(result => {
            this.isLoading = false;
            this.activeModal.close(result);
          });
      }, 500);
    }
  }
}
