import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'cookie-policy-v20190119',
  templateUrl: './cookie-policy-v20190119.component.html',
  styleUrls: ['./cookie-policy-v20190119.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookiePolicyV20190119Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
