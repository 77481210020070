<h1>Crowdtap® Privacy Policy</h1>
<p>Updated: July 19, 2023</p>

<p>
  Suzy, Inc., the owner of Crowdtap®, respects your privacy. This Privacy Policy
  describes how Suzy, Inc. and its subsidiaries and affiliates (collectively,
  “Suzy”, “we”, “our”, or “us”) collects, uses, stores, and shares personal data
  in connection with (1) the
  <a href="http://www.crowdtap.com/">www.crowdtap.com</a> website, the Crowdtap
  desktop platform, the Crowdtap mobile application, and any related Crowdtap
  services; and (2) any other sites that display this Policy (collectively, the
  “Sites,” and any, a “Site”). It is important that you read this Policy
  together with any other privacy notices we may provide on specific occasions
  when we are collecting or processing personal data about you, so that you are
  fully informed of how and why we are using your data. This Policy supplements
  our other notices and is not intended to override them, except where
  specifically stated.
</p>
<p>
  This Policy applies solely to information collected via the Sites and does not
  apply to our platform for brand clients (<a href="http://www.suzy.com/"
    >Suzy.com</a
  >
  and related applications and sites), which has a
  <a href="https://app.suzy.com/privacy-policy">separate privacy policy</a>, nor
  our services for External Audiences, which have a separate privacy policy.
</p>

<div class="warning-message">
  <img src="/assets/icons/svg/error.svg" alt="" />
  <p class="extra-details">
    <strong>
      By using or accessing the Sites, you accept and unambiguously consent to
      this Policy and to our use of your Personal Data as described in this
      Policy. IF YOU DO NOT AGREE TO THE TERMS OF THIS POLICY, DO NOT USE THE
      SITES. For details on your privacy rights, see Section 18 below.
    </strong>
  </p>
</div>

<h2>Table of Contents</h2>
<div class="toc">
  <ul>
    <li><a routerLink="./" fragment="1-who-are-we">1. Who are we?</a></li>
    <li><a routerLink="./" fragment="2-who-are-you">2. Who are "you"?</a></li>
    <li>
      <a routerLink="./" fragment="3-what-is-personal-data"
        >3. What is Personal Data?</a
      >
    </li>
    <li>
      <a routerLink="./" fragment="4-who-is-the-data-controller"
        >4. Who is the data controller?</a
      >
    </li>
    <li>
      <a routerLink="./" fragment="5-how-is-personal-data-collected"
        >5. How is Personal Data collected?</a
      >
    </li>
    <li>
      <a routerLink="./" fragment="6-what-personal-data-do-we-use"
        >6. What Personal Data do we use?</a
      >
    </li>
    <li>
      <a routerLink="./" fragment="7-feature-specific-personal-data"
        >7. Feature-specific Personal Data</a
      >
      <ul class="inner-list">
        <li>
          <a routerLink="./" fragment="7a-creating-a-crowdtap-account"
            >(a) Creating a Crowdtap account</a
          >
        </li>
        <li>
          <a routerLink="./" fragment="7b-verifying-your-account"
            >(b) Verifying your account</a
          >
        </li>
        <li>
          <a routerLink="./" fragment="7c-completing-actions"
            >(c) Completing Actions</a
          >
        </li>
        <li>
          <a
            routerLink="./"
            fragment="7d-participating-in-live-interviews-or-focus-groups"
          >
            (d) Participating in live interviews or focus groups
          </a>
        </li>
        <li>
          <a routerLink="./" fragment="7e-providing-open-ended-text-responses">
            (e) Providing open-ended text responses
          </a>
        </li>
        <li>
          <a routerLink="./" fragment="7f-providing-open-ended-video-responses">
            (f) Providing open-ended video responses
          </a>
        </li>
        <li>
          <a routerLink="./" fragment="7g-testing-products"
            >(g) Testing products</a
          >
        </li>
      </ul>
    </li>

    <li>
      <a
        routerLink="./"
        fragment="8-who-do-we-disclose-sell-or-share-your-personal-data-to"
      >
        8. Who do we disclose, sell, or share your Personal Data to?
      </a>
      <ul class="inner-list">
        <li><a routerLink="./" fragment="8a-suzy">(a) Suzy</a></li>
        <li><a routerLink="./" fragment="clients">(b) Clients</a></li>
        <li>
          <a routerLink="./" fragment="8c-reward-partners"
            >(c) Reward partners</a
          >
        </li>
        <li>
          <a routerLink="./" fragment="8d-software-providers"
            >(d) Software providers</a
          >
        </li>
        <li>
          <a routerLink="./" fragment="8e-service-providers-and-contractors">
            (e) Service providers and contractors
          </a>
        </li>
        <li>
          <a
            routerLink="./"
            fragment="8f-technical-consultants-and-third-party-auditors"
          >
            (f) Technical consultants and third-party auditors
          </a>
        </li>
        <li>
          <a
            routerLink="./"
            fragment="8g-investors-successor-entities-purchasers"
          >
            (g) Investors, successor entities, or purchasers
          </a>
        </li>
        <li>
          <a routerLink="./" fragment="8h-law-enforcement"
            >(h) Law enforcement</a
          >
        </li>
        <li>
          <a
            routerLink="./"
            fragment="8i-anonymized-or-de-identified-information"
          >
            (i) Anonymized or de-identified information
          </a>
        </li>
        <li>
          <a routerLink="./" fragment="8j-aggregated-information"
            >(j) Aggregated information</a
          >
        </li>
        <li>
          <a routerLink="./" fragment="8k-vital-interests"
            >(k) Vital interests</a
          >
        </li>
        <li><a routerLink="./" fragment="8l-other">(l) Other</a></li>
      </ul>
    </li>

    <li>
      <a routerLink="./" fragment="9-are-survey-responses-anonymous"
        >9. Are survey responses anonymous?</a
      >
    </li>
    <li>
      <a routerLink="./" fragment="10-how-long-is-personal-data-retained">
        10. How long is Personal Data retained?
      </a>
    </li>
    <li>
      <a
        routerLink="./"
        fragment="11-where-is-personal-data-processed-and-stored"
      >
        11. Where is Personal Data processed and stored?
      </a>
    </li>
    <li><a routerLink="./" fragment="security">12. Security</a></li>
    <li><a routerLink="./" fragment="minors">13. Minors</a></li>
    <li>
      <a routerLink="./" fragment="links-to-other-websites"
        >14. Links to other websites</a
      >
    </li>
    <li>
      <a routerLink="./" fragment="bulletin-boards-and-chat-areas"
        >15. Bulletin boards and chat areas</a
      >
    </li>
    <li>
      <a routerLink="./" fragment="notice-of-financial-incentive"
        >16. Notice of Financial Incentive</a
      >
    </li>
    <li>
      <a routerLink="./" fragment="your-privacy-rights"
        >17. Your privacy rights</a
      >
    </li>
    <li>
      <a routerLink="./" fragment="exercising-your-privacy-rights"
        >18. Exercising your privacy rights</a
      >
    </li>
    <li>
      <a routerLink="./" fragment="your-privacy-controls"
        >19. Your privacy controls</a
      >
    </li>
    <li>
      <a routerLink="./" fragment="terms-of-service">20. Terms of Service</a>
    </li>
    <li>
      <a routerLink="./" fragment="how-can-you-contact-us"
        >21. How can you contact us?</a
      >
    </li>
    <li>
      <a routerLink="./" fragment="changes-to-this-policy"
        >22. Changes to this Policy</a
      >
    </li>
    <li>
      <a routerLink="./" fragment="prior-versions-of-this-policy">
        Prior Versions of this Policy
      </a>
    </li>
  </ul>
</div>

<h2 id="1-who-are-we">1. Who are we?</h2>
<p>
  The Sites are operated by Suzy, Inc., a company headquartered in New York, NY,
  USA.
</p>
<p>
  Suzy combines advanced research tools with an on-demand network of consumers
  to help companies make data-driven decisions with confidence. We are an
  end-to-end market research company. On the one end, we
  <a href="https://suzy.com/">license our software</a> and services to companies
  conducting market research (our <strong>“Clients”</strong>, a term that
  includes prospects and potential clients). On the other end, we run Crowdtap®
  for consumers like you. <a href="http://www.crowdtap.com/">Crowdtap</a>
  is a survey platform that rewards people for their opinions.
</p>

<h2 id="2-who-are-you">2. Who are "you"?</h2>
<p>
  Such a deep question! “You” are someone really important to us. Here is how we
  think of “you” with respect to this Policy:
</p>
<ul>
  <li>
    <strong>“Members”</strong>: The Sites are used by people who are interested
    in responding to surveys, interviews, focus groups, and the like
    (collectively, “Actions”) in exchange for financial incentives. (See Notice
    of Financial Incentives, below). You are an individual who has either (a)
    signed up for Crowdtap and agreed to respond to an Action, or (b) accepted
    an email invitation from Crowdtap to participate in an Action. The Crowdtap
    platform is only intended for residents of the United States.
  </li>
  <li>
    <strong>“Visitors”</strong>: You are just visiting our Sites because you are
    curious, or you have heard about us from our marketing or sales channels.
  </li>
</ul>

<h2 id="3-what-is-personal-data">What is Personal Data?</h2>
<p>
  <strong> Personal Data: </strong>
  When we use the term “Personal Data” in this Policy, we use it as a catchall
  term to mean any information that is linked or reasonably linkable to an
  identified or identifiable natural person. This includes information that
  relates to, describes, is reasonably capable of being associated with, or
  could reasonably be linked, directly or indirectly, with a particular person
  or household. Some states and countries refer to this as “personal
  information” or “personally identifiable information.”
</p>
<p>
  <strong>Sensitive Personal Data</strong>: Some Personal Data is so sensitive
  that it requires heightened care and protection. Different laws consider
  different types of personal data sensitive but generally include:
</p>
<ul>
  <li>Financial information</li>
  <li>
    Health information (physical health, mental health, and/or substance use
    information)
  </li>
  <li>Citizenship / immigration status</li>
  <li>Sexual orientation, sexual preferences, or sexual practices</li>
  <li>Driver’s license information</li>
  <li>
    Passport information or other government-issued identification information
  </li>
  <li>Zip code</li>
  <li>Genetic information</li>
  <li>Biometric information</li>
  <li>Data of children under the age of 13</li>
  <li>Racial or ethnic origin</li>
  <li>Religious or philosophical beliefs</li>
  <li>Political opinions or affiliations</li>
  <li>Trade union membership</li>
  <li>Criminal history / record information</li>
  <li>Precise geolocation information</li>
</ul>

<h2 id="4-who-is-the-data-controller">4. Who is the data controller?</h2>
<p>
  Suzy is the data controller of your Personal Data and will be responsible for
  holding and keeping it in compliance with privacy laws. In some instances, we
  act as joint controller or processor with the particular Client that initiated
  an Action. In those situations, Suzy still will be responsible for holding and
  keeping your Personal Data in compliance with privacy laws until onward
  transfer to the Client. Our “Suzy’s Roles” sheet – available on our
  <a href="https://suzy.com/trust-center">Trust Center</a> – explains when we
  act in what capacity.
</p>
<h2 id="5-how-is-personal-data-collected">
  5. How is Personal Data collected?
</h2>
<p>
  We use different methods to collect Personal Data from Members and Visitors,
  including through:
</p>
<ul>
  <li>
    <strong>Direct interactions</strong>. Often, we receive Personal Data
    directly from you. For example, you may give us your Personal Data by
    creating a Crowdtap account, filling in forms, following us on social media,
    or by corresponding with us.
  </li>
  <li>
    <strong>Automated technologies or interactions</strong>. As you interact
    with our Sites, we will automatically collect certain technical data about
    your equipment, browsing actions, and patterns. This includes collecting
    Personal Data by using cookies, web beacons, and other similar technologies.
    Read our Cookie Policy
    <a href="https://crowdtap.com/cookie-policy">here</a>.
  </li>
  <li>
    <strong>Third parties</strong>. We also receive Personal Data from third
    parties, such as technical or behavioral information from analytics
    providers or advertising networks and contact information from social media
    networks.
  </li>
</ul>

<h2 id="6-what-personal-data-do-we-use">6. What Personal Data do we use?</h2>
<p>
  We’re in the business of data, and transparency is important to us. Under
  “View Details” is a chart of the categories of Personal Data we use, analyze,
  or otherwise process about Members and Visitors.
</p>
<p>A few things to keep in mind while reviewing the chart:</p>
<ol>
  <li>
    <strong
      >What Personal Data we use depends on what features you choose to
      use</strong
    >. For example, if you choose to participate in live video interviews,
    additional nuances will apply. See
    <a routerLink="./" fragment="7-feature-specific-personal-data">Section 7</a>
    of this Policy for details on those feature-specific nuances.
  </li>
  <li>
    <strong>Providing your Personal Data is voluntary</strong>. You can always
    choose not to provide the Personal Data that we ask you for.
  </li>
  <li>
    <strong
      >However, your ability to use the Sites may be limited if you do not give
      certain information that we need in order to provide you with our
      services</strong
    >. Where we need to collect Personal Data by law, or under the terms of a
    contract we have with our Clients or with you, and you fail to provide that
    data when requested, we may not be able to perform the services required.
    For example:

    <ul class="inner-list">
      <li>
        f you do not provide enhanced profile information, we may be unable to
        service you with Actions or your chances of being chosen for an Action
        may be reduced.
      </li>
      <li>
        If you do not enable location tracking on your device at the device
        level, we cannot service you with location-based Actions.
      </li>
    </ul>
  </li>
</ol>
<div class="bordered-section" [class.expanded-panel-active]="section3">
  <div class="header-container">
    <img
      [class.rotate-arrow]="section3"
      src="/assets/icons/svg/ic-arrow-down-grey.svg"
      alt="Arrow icon"
    />
    <span (click)="toggle('section3')" class="expanded-panel-title"
      >View Details</span
    >
  </div>
  <ng-container *ngIf="section3">
    <div class="expanded-panel">
      <table>
        <tr>
          <th>Category of Personal Data</th>
          <th>Whose Data</th>
          <th>Use</th>
          <th>Lawful Basis, in addition to Consent</th>
        </tr>

        <tr>
          <td>
            <strong>Account Creation Information. </strong>
            This includes your name, phone number, password, and email address.
            It might also include your Facebook profile information, if you
            select to create your account using Facebook.
          </td>
          <td>Members</td>
          <td>
            To register for a Crowdtap account, we need to collect this
            information from you to ensure compliance with our terms of service
            and for security purposes. For example, we need your date of birth
            to ensure you are old enough to use Crowdtap.
          </td>
          <td>Performance of a contract</td>
        </tr>

        <tr>
          <td>
            <strong>Account Settings</strong>. You can set various preferences
            and personal details on pages like your account settings page. These
            may include, for example, your default language, time zone, and
            communication preferences (e.g., opting in or out of receiving
            marketing communications from us).
          </td>
          <td>Members</td>
          <td>
            We use this information to run your account and honor your service
            preferences. Depending on your company's underlying contract, we
            sometimes co-manage this information in conjunction with your
            organization.
          </td>
          <td>
            Performance of a contract; Necessary for our legitimate interests
          </td>
        </tr>

        <tr>
          <td>
            <strong>Basic Profile Information. </strong>
            This includes your date of birth, gender preference, and address
            (including city, state, and zip code), and other criteria you have
            voluntarily shared with us. You also have the option of providing a
            profile picture.
          </td>
          <td>Members</td>
          <td>
            We use your profile information to target relevant Actions to you
            and send you emails inviting you to take those surveys. For example,
            if the profile information you provide shows you are a 30-year-old
            female living in New York, we may send you an Action where a Brand
            Client is interested in the opinions of any person living in New
            York aged between 25-35 years. We also use profile information to
            provide you with a personalized service experience, to conduct spam
            moderation, as well as to make product, feature, and service
            recommendations to you so you can optimize the use of the services
            we offer. In some cases, we may require you to verify certain
            profile information you have provided to us, such as by presenting
            proof of address, in order to use certain features of the Site. Once
            you've completed your profile, the information is locked and can't
            be changed for 3 months. This is to help prevent fraud and abuse.
            Some information, like your age and date of birth, can't be changed
            per our terms of service. This is to protect the data of minors.
          </td>
          <td>
            Performance of a contract; Necessary for our legitimate interests
          </td>
        </tr>

        <tr>
          <td>
            <strong>Brand Panel Referral Information.</strong>
          </td>
          <td>Members</td>
          <td>
            If you were referred to join Crowdtap from another company you're a
            customer of (for example, StateFarm), we tag you in our systems to
            reflect that referral. That way, StateFarm can push Actions to you,
            a customer of theirs, and we can prioritize those Actions for you in
            your tiles.
          </td>
          <td>Performance of a contract</td>
        </tr>

        <tr>
          <td>
            <strong>Contact Information</strong>. (Your name, email, address,
            phone number)
          </td>
          <td>Members; Visitors</td>
          <td>
            We may send you marketing or other commercial emails or SMS/text
            messages from time to time. We use third party providers who process
            your contact information on our behalf. You may unsubscribe by
            following the instructions included in the email or message.
          </td>
          <td>
            Performance of a contract (to inform you of any changes to our terms
            and conditions); Necessary to comply with a legal obligation (to
            inform you of any changes to our privacy policy)
          </td>
        </tr>

        <tr>
          <td>
            <strong>Contact Information.</strong> (Your name, email, address,
            phone number)
          </td>
          <td>Members</td>
          <td>
            We use it when we need to contact Members about products and
            services (unless you unsubscribe) or to provide Members with account
            and transactional information and updates. (Although Members cannot
            unsubscribe from the latter, we try to contact you only when
            necessary). We use this information to communicate with Members
            about the products and services we offer, including to notify
            Members about new missions, rewards, and changes in our reward
            offerings. We may send you non-marketing communications from time to
            time (such as announcements when you become eligible for rewards,
            notices regarding changes in our terms of service, etc.). We may
            also contact you to correct account errors or to supply important
            information we deem relevant. We may use third party providers to
            process your contact information on our behalf. You are not able to
            unsubscribe from these announcements because they may contain very
            important information regarding their accounts. We will also respond
            back to Members if you contact us (for example, if you submit a
            contact form to our customer support teams).
          </td>
          <td>
            Performance of a contract; Necessary to comply with a legal
            obligation
          </td>
        </tr>

        <tr>
          <td>
            <strong>Contact Information</strong>. (Your name, email, address,
            phone number)
          </td>
          <td>Visitors</td>
          <td>
            We will respond to Visitors if you contact or provide information to
            our customer support or sales teams and for our own audience
            development. For example, we may contact Visitors in response to
            requests, inquiries, comments, or suggestions, or other information
            you send about Crowdtap.
          </td>
          <td>
            Necessary for our legitimate interests (for running our marketing
            outreach services)
          </td>
        </tr>

        <tr>
          <td>
            <strong>Cookies</strong>. For example, data we get from first and
            third party cookies, page tags, pixels and similar technology placed
            on your device.
          </td>
          <td>Members; Visitors</td>
          <td>
            Read our
            <a href="https://crowdtap.com/cookie-policy">Cookie Policy</a> for
            full details. We, our service providers, and third-party partners,
            may collect information about the use of our Sites by automated
            means, including via cookies, web beacons, and other technologies.
            This information may include information about the devices you use
            to access our Sites (such as IP address and browser and operating
            system type), dates and times of visits to, and use of our Sites,
            information about how our Sites are used (such as pages viewed, date
            and time of access, information about how users navigate on or
            between our webpages, or the features of our mobile applications
            that are used and how users navigate between screens on our mobile
            applications), the URLs that refer visitors to our Sites, and the
            search terms used to reach our Sites.
          </td>
          <td>
            Necessary for our legitimate interests (to define types of Members
            for our Sites, to keep our Sites updated and relevant, to develop
            our business, and to inform our marketing strategy)
          </td>
        </tr>

        <tr>
          <td>
            <strong>Data Quality Information</strong>. For example, IP
            addresses, email domains, phone numbers, survey responses,
            behavioral information, etc.
          </td>
          <td>Members</td>
          <td>
            We use this information to moderate for bots, to verify your
            identity, to prevent fraud and abuse, and to gauge whether you are a
            high quality respondent. This helps us preserve the security of our
            Sites and helps us provide quality assurance controls for our
            Clients. For example, we employ IP and email domain blocking to
            prevent fraud and abuse. We also employ various onboarding
            verification techniques to ensure new users are humans. We also use
            patented technology that identifies behavior patterns indicative of
            bots and progressively serves those accounts challenge questions
            that only a human could answer. We utilize third-party software and
            services to accomplish these goals.
          </td>
          <td>
            Necessary for our legitimate interests (to prevent fraud and abuse,
            ensure data quality, and help improve network security).
          </td>
        </tr>

        <tr>
          <td>
            <strong>Device and Browser Data</strong>. For example, IP address,
            device type, MAC ID, browser type.
          </td>
          <td>Members; Visitors</td>
          <td>
            We use this data for service optimization and troubleshooting for
            your specific device/browser of preference (in other words, we want
            you to see the best possible view of our Sites pages on your
            specific device). We also infer your location from your IP address.
          </td>
          <td>
            Necessary for our legitimate interests (to study usage of our
            products/services, to develop them, to grow our business and to
            inform our marketing strategy)
          </td>
        </tr>

        <tr>
          <td>
            <strong>Device and Browser Data</strong>. For example, IP address,
            device type, MAC ID, browser type.
          </td>
          <td>Members</td>
          <td>
            We receive data from the device you use to access the Sites, such as
            your operating system version, device manufacturer and model,
            carrier (i.e., mobile provider), system locale. We may also store
            any information collected by the core SDK, like device name (can be
            personalized by device owner) and user agent string. We also collect
            data through the use of cookies. Read our Cookie Policy for more
            information.
          </td>
          <td>
            Necessary for our legitimate interests (to study usage of our
            products/services, to develop them, to grow our business and to
            inform our marketing strategy)
          </td>
        </tr>

        <tr>
          <td>
            <strong>Device and Browser Data</strong>. For example, IP address,
            device type, MAC ID, browser type.
          </td>
          <td>Visitors</td>
          <td>
            We receive data from the device you use to access the Sites, such as
            your IP address and your browser type. We may also infer your
            geographic location based on your IP address.
          </td>
          <td>
            Necessary for our legitimate interests (to study usage of our
            products/services, to develop them, to grow our business and to
            inform our marketing strategy)
          </td>
        </tr>

        <tr>
          <td>
            <strong>Enhanced Profile Information</strong>. This may include
            things like the industry in which you work, ethnicity, religion,
            income level, education level, country of residence, political
            views, home ownership status, marital status, number of children,
            interests (for example, what activities you participate in and how
            often you participate), preferences (for example, what sort of food
            or drinks you like), and opinions.
          </td>
          <td>Members</td>
          <td>
            We build enhanced profiles to allow our Clients to target Members
            with specific characteristics while initiating Actions. We do this
            in part by using your answers to background / demographics
            questions.
          </td>
          <td>
            Performance of a contract; Necessary for our legitimate interests
          </td>
        </tr>

        <tr>
          <td>
            <strong>Inferred Data.</strong>
          </td>
          <td>Members</td>
          <td>
            We may infer information about you (e.g., your shopping preferences
            and habits) from all of the above categories of information which we
            collect about you. We may share this information with our Clients.
          </td>
          <td>Performance of a contract</td>
        </tr>

        <tr>
          <td>
            <strong>Inferred Data.</strong>
          </td>
          <td>Visitors</td>
          <td>
            We may infer information about you (e.g., your social media
            preferences and habits) from all of the above categories of
            information which we collect about you.
          </td>
          <td>
            Necessary for our legitimate interests (to study usage of our Sites,
            to develop them, to grow our business, and to inform our marketing
            strategy)
          </td>
        </tr>

        <tr>
          <td>
            <strong
              >Information from Third Parties and integration Partners</strong
            >. This includes your name and email address or IP address where you
            have given permission to those third parties to share that
            information with us or where that information is publicly available
            either online or through your device/browser data.
          </td>
          <td>Members</td>
          <td>
            To ensure you can sign-up to our service from a third party
            integration like Facebook, LinkedIn, Microsoft, Google, SSO; to
            personalize our Sites for you; to ensure you can use our Sites in
            conjunction with other services; and to facilitate sending Actions
            to you
          </td>
          <td>
            Performance of a contract; Necessary for our legitimate interests
            (provision of, and to improve, our services)
          </td>
        </tr>

        <tr>
          <td>
            <strong>Leaderboard Information</strong>. This may include your user
            name, points, profile photo, badges, and levels earned.
          </td>
          <td>Members</td>
          <td>
            This information may be shared with other Site users, including
            without limitation, publication in a live feed of ongoing Crowdtap
            activities and leaderboards. You may also see such information
            related to other Members when logged into the Crowdtap platform.
          </td>
          <td>
            Necessary for our legitimate interests (to improve our services, to
            facilitate user experience)
          </td>
        </tr>

        <tr>
          <td>
            <strong>Location Data and Sensor Data.</strong>
          </td>
          <td>Visitors</td>
          <td>
            If you have enabled location tracking on your mobile device, we may
            collect certain location and sensor data (geographic data like
            latitude and longitude and other sensor data such as speed, bearing,
            list of installed apps, accelerometer, and vertical accuracy) from
            your device. We would use that data to determine information about
            your device and to provide us information mapping your device to
            specific venues and locations, dates, times, and durations. We would
            use that information to send you location-specific Actions, such as
            in-store product sampling or to receive coupons from us to use in
            stores.
          </td>
          <td>
            Performance of a contract; Necessary for our legitimate interests
            (to deliver relevant Actions)
          </td>
        </tr>

        <tr>
          <td>
            <strong
              >Log Data or Log Files that record data each time a device
              accesses a server</strong
            >. This contains data about the nature of access, for example,
            originating IP addresses, Internet service providers, the files
            viewed on our site (like HTML pages, graphics, etc.), operating
            system versions, device type, and timestamps.
          </td>
          <td>Members; Visitors</td>
          <td>
            Log data can be used for a lot of different things but predominantly
            we use it for: monitoring abuse and troubleshooting site and
            security issues, improving the product functionality and creating
            new features, tracking behavior for content and services at an
            aggregate level (for example, to monitor service requests or service
            denial on our site overtime to ensure our site remains stable) and
            fixing bugs or functionality issues. We will also use log data to
            help us make recommendations to you or track your visits to our
            sites.
          </td>
          <td>
            Necessary for our legitimate interests (to prevent fraud and abuse
            and ensure network security)
          </td>
        </tr>

        <tr>
          <td>
            <strong>Matched Data. </strong>
          </td>
          <td>Members</td>
          <td>
            We utilize matching services (i.e., third parties who are
            specialized in data management, such as consumer behavior insights)
            to acquire additional information about you from public and private
            data sources (such as social networks, retailers, and content
            subscription services with whom you have an account) or to use your
            Personal Data as an aid to develop additional or new types of
            de-identified data sets (i.e., we compile your aggregate data with
            data from other consumers to create a new lifestyle segment). The
            matching service provider holds the Personal Data we share for a
            short time, uses it to assemble the additional information, and then
            return the combined information to us. Partners are contractually
            bound to delete the data we share with them and are not authorized
            to use it in any way other than for this specific purpose. From time
            to time, we may use third-party software for email list management
            and email distribution or may use third-party list management
            providers to match our Members to members of other lists or panels
            or to validate the accuracy of Personal Data you provide. These
            companies are authorized to use your personal data only as necessary
            to provide these services to us, pursuant to written instructions.
          </td>
          <td>Performance of a contract</td>
        </tr>

        <tr>
          <td>
            <strong>Member Profile Data.</strong>
          </td>
          <td>Members</td>
          <td>
            We use things like your Basic Profile Information, Enhanced Profile
            Information, Standard Attributes, Responses to Actions, Matched
            Data, and Inferred Data (defined later in this chart) to build a
            profile on you. We use that profile to target relevant Actions to
            you. We also use this information to provide Clients with a way to
            reach the target audiences they want. For example, you might provide
            us with your marital status, gender, state, and household income
            level. If a Client wanted to target married women from the Midwest
            region with a household income level between $100,000-$150,000, we
            or our Clients would use the information you provided to initiate
            Actions toward that target audience.
          </td>
          <td>
            Performance of a contract; Necessary for our legitimate interests
          </td>
        </tr>

        <tr>
          <td>
            <strong>Metadata.</strong>
          </td>
          <td>Members; Visitors</td>
          <td>
            We receive data from the device you use to access surveys, such as
            your operating system version, device manufacturer and model,
            carrier (i.e., mobile provider), system locale. We may also store
            any information collected by the core SDK, like device name (can be
            personalized by device owner) and user agent string.
          </td>
          <td>Necessary for our legitimate interests</td>
        </tr>

        <tr>
          <td>
            <strong>Referral Data.</strong> This is information about the place
            where you were before you came to our Sites – for example, if you
            were on social media before clicking on a link to a Site, we record
            information about the source that referred you to us.
          </td>
          <td>Members; Visitors</td>
          <td>
            We use this data to track the success of our integrations and
            referral processes and to plan further referrals.
          </td>
          <td>
            Necessary for our legitimate interests (to study how customers use
            our Sites, to develop them, to grow our business, and to inform our
            marketing strategy)
          </td>
        </tr>

        <tr>
          <td>
            <strong>Response Data.</strong> This is responses to Actions, which
            may include things like your occupation field, interests, opinions,
            or other information you voluntarily provide to us when you
            participate in an Action.
          </td>
          <td>Members</td>
          <td>
            We collect and receive the responses you provide to Actions, which
            may directly identify you or which, when used with other information
            which we hold about you, may indirectly identify you. We use the
            information for our brand research and insights. We also share your
            responses to Actions with the Client(s) who purchased license(s)
            with us and who initiated the Action(s). The Client(s) use your
            responses to Actions for their brand research and insights,
            marketing, and promotions.
          </td>
          <td>
            Performance of a contract; Necessary for our legitimate interests
          </td>
        </tr>

        <tr>
          <td>
            <strong>Response Rate Information.</strong> For example, page view
            data, response rates, response types, and survey type
          </td>
          <td>Members</td>
          <td>
            We use and analyze this information: To improve the user interface;
            To maintain a consistent and reliable Member experience; and To
            improve our Client services by looking at what questions Clients are
            asking and the quality of their responses and response rates so that
            we can enhance our existing features and build new ones to optimize
            question/answer rates for Clients.
          </td>
          <td>
            Performance of a contract; Necessary for our legitimate interests
            (to improve our Sites, to develop them, to grow our business, and to
            inform our product development and marketing strategy)
          </td>
        </tr>

        <tr>
          <td>
            <strong>Reward Redemption Information.</strong> (Contact
            information, profile information)
          </td>
          <td>Members</td>
          <td>
            We use this information to fulfill orders and prize redemptions,
            issue reward payouts and other payments, and/or to verify your
            identity in connection with redeeming points or obtaining a reward.
            We utilize third party providers, such as Tango Card, to facilitate
            the provision of gift cards, digital subscriptions, charity, etc. to
            you. You can learn about Tango Card’s privacy practices here.
          </td>
          <td>Performance of a contract</td>
        </tr>

        <tr>
          <td>
            <strong>Sensitive Personal Data.</strong> See the section in this
            Policy titled "What is Personal Data" for details on what is
            considered Sensitive Personal Data.
          </td>
          <td>Members</td>
          <td>
            You may be asked to provide Sensitive Personal Data as part of your
            profile data or in response to Crowdtap Actions. If we intend to
            disclose such information to a third party or use it for a purpose
            other than as described in this Policy, we will obtain your specific
            permission to do so prior to any such use or disclosure.
          </td>
          <td>Performance of a contract</td>
        </tr>

        <tr>
          <td>
            <strong>Standard Attributes.</strong> (for example, gender, age,
            state and/or region, ethnicity, household size, employment status,
            income level, parenting status, relationship status, education
            level)
          </td>
          <td>Members</td>
          <td>
            <p>
              When you respond to Actions, we automatically share this
              information with Clients, if you have provided the information.
              The asterisk items reflect information you were required to
              provide us as part of your Basic Profile Information. The
              non-asterisk items reflect Enhanced Profile Information that you
              have the option of providing.
            </p>

            <p>*Gender</p>
            <p>
              *Age – we use the date of birth you supplied while creating your
              account to populate this field, but we do not share the full date
              of birth with Clients. Instead, this field is displayed as a
              number (for example, “33”).
            </p>
            <p>
              *State and/or Region – we use the zip code you supplied while
              creating your account to populate this field, but we do not share
              the zip code with Clients. Instead, this field is displayed as,
              for example, “NY” or “New York” or “East Coast”.
            </p>
            <p>Ethnicity</p>
            <p>Household Size</p>
            <p>Employment Status</p>
            <p>Income Level</p>
            <p>Parenting Status</p>
            <p>Relationship Status</p>
            <p>Education Level</p>
            <p>
              Other demographics, interests, or preferences attributes (for
              example, primary member of household responsible for grocery
              shopping; dietary restrictions; etc)
            </p>
          </td>

          <td>
            Provision of a contract; Necessary for our legitimate interests (to
            improve our Sites, to develop them, to grow our business, and to
            inform our product development and marketing strategy)
          </td>
        </tr>

        <tr>
          <td>
            <strong>Tax Information.</strong> For example, your social security
            number.
          </td>
          <td>Members</td>
          <td>
            If you earn over a certain amount of money in gift cards, we must
            collect this information from you for tax recording and reporting
            purposes.
          </td>
          <td>Necessary for our legal obligations</td>
        </tr>

        <tr>
          <td>
            <strong>Usage Information.</strong> (how you use the services, what
            pages you click on, etc.)
          </td>
          <td>Members</td>
          <td>
            We receive usage data about you whenever you interact with the Sites
            through third party analytics provider(s). This may include which
            webpages you visit, what you click on, and which surveys you
            complete. Additionally, like most websites today, our web servers
            keep log files that record data each time a device accesses those
            servers. The log files may include Internet service provider (ISP),
            referring/exit pages, the files viewed on our site (e.g., HTML
            pages, graphics, etc.), operating system, date/time stamp, and/or
            clickstream data to analyze trends in the aggregate and administer
            the site.
          </td>
          <td>
            Necessary for our legitimate interests (to help us improve the user
            experience when answering surveys (so that questions are easier to
            answer), for machine learning purposes (we use the data to keep
            training our models and to build new ones), and to understand
            industry trends in and help improve the completion rates on,
            surveys/forms).
          </td>
        </tr>

        <tr>
          <td>
            <strong>Usage Information.</strong> (how you use the services, what
            pages you click on, etc.)
          </td>
          <td>Members; Visitors</td>
          <td>
            We collect usage information about you whenever you interact with
            our websites and services. This includes which webpages you visit,
            what you click on, when you perform those actions, what language
            preference you have, what you buy and so on. We use information
            about how you use our services to improve our services for you and
            all users. Examples: We collect information about the types of
            questions you answer via Aggregate Data, so we can examine patterns
            across survey respondents. We collect and use all this data for our
            legitimate interests like helping us improve the experience for you
            (so that questions are easier to answer), for training purposes, and
            to understand industry trends in and to help improve the completion
            rates on surveys/forms. We also will market to you (unless you
            unsubscribe or decline those cookie preferences).
          </td>
          <td>
            Necessary for our legitimate interests (to study how customers use
            our products/services, to develop them, to grow our business and to
            inform our marketing strategy)
          </td>
        </tr>

        <tr>
          <td>
            <strong>All of the above categories - Aggregate Data.</strong>
          </td>
          <td>Members; Visitors</td>
          <td>
            We may aggregate information you provide in a manner which does not
            identify any one person (“Aggregate Data”). We may collect and use
            data about access to and use of our Sites that we automatically
            collect as a form of Aggregate Data to determine how much time
            visitors spend on each page of our Site, how visitors navigate
            throughout the Site, and how we may tailor our web pages to better
            meet the needs of visitors. We may use your Aggregate Data for our
            own legitimate business purposes, including operating and enhancing
            our Site, for performing statistical analysis business planning, and
            for market research purposes.
          </td>
          <td>
            Performance of a contract; Necessary for our legitimate interests
          </td>
        </tr>

        <tr>
          <td>
            <strong
              >All of the above categories - Artificial Intelligence / Machine
              Learning.</strong
            >
          </td>
          <td>Members; Visitors</td>
          <td>
            <p>
              We use techniques like machine learning or artificial intelligence
              on Personal Data for ensuring compliance with legal and regulatory
              requirements, detecting quality to maintain reliable survey
              respondents, and to provide automated market research services to
              Clients like heatmapping, dynamic segmentation, data explorer, AI
              summaries, and the like. Clients have some controls over how we
              use Response Data and may have turned off our ability to apply
              machine learning to responses where it is linked to a specific
              product feature
            </p>
            <p>We also use automated processes and machine learning to:</p>
            <ul>
              <li>
                analyze Response Data, which in turn helps us to identify
                trends, build product features that optimize responses, make
                product recommendations, and provide guidance on which products
                and services work best in different scenarios
              </li>
              <li>
                extract and analyze usage patterns, which in turn helps us to
                improve our services and ease of use (for example, we might
                identify when respondents prefer multiple choice versus open
                text questions and make predictive response suggestions when
                certain question types are selected)
              </li>
              <li>
                undertake personalization for Clients and you (for example by
                customizing the page on our website which you see at the end of
                a survey)
              </li>
              <li>
                improve user experience (for example, by collecting and using
                device and browser information to improve how our service
                operates on those devices and in those browsers)
              </li>
              <li>identify insightful data trends (via Aggregate Data)</li>
              <li>
                to build features, improve our services, for fraud detection,
                and to develop Aggregate Data products
              </li>
            </ul>
          </td>
          <td></td>
        </tr>

        <tr>
          <td>
            <strong>All of the above categories - Legal and Security.</strong>
          </td>
          <td>Members; Visitors</td>
          <td>
            For legal and security purposes such as enforcing our agreements,
            preventing unlawful or abusive activity, responding to legal
            inquiries and lawful requests, and preventing fraud. For example, we
            sometimes inspect and use techniques like machine learning on
            responses to Crowdtap Actions to ensure compliance with our Terms of
            Service.
          </td>
          <td>
            Necessary to comply with a legal obligation; Necessary for our
            legitimate interests
          </td>
        </tr>

        <tr>
          <td>
            <strong>All of the above categories - Marketing.</strong>
          </td>
          <td>Members; Visitors</td>
          <td>
            To help improve our marketing by, for example, building user
            profiles to ensure our marketing materials are relevant to you and
            optimize our campaigns using machine learning.
          </td>
          <td>Necessary for our legitimate interests</td>
        </tr>

        <tr>
          <td>
            <strong>All of the above categories - Matching.</strong>
          </td>
          <td>Members</td>
          <td>
            Data we collect may be matched with data from other companies that
            recognize their own users or that collect data about consumers
            generally, such as social media companies, ecommerce companies, or
            companies that specialize in consumer behavior insights. These other
            companies provide us with aggregated and/or de-identified audience
            statistics (such as age and gender make-up of an audience). Data
            from these other sources is also used to enhance or calibrate the
            data collected from our software tools, and to enable more robust
            research and analyses.
          </td>
          <td>
            Performance of a contract; Necessary for our legitimate interests
          </td>
        </tr>

        <tr>
          <td>
            <strong>All of the above categories - Other.</strong>
          </td>
          <td>Members; Visitors</td>
          <td>
            We internally use the data we receive to improve the quality of our
            Sites. For example, we use and analyze usage data (such as page view
            data and response rates) to improve the Crowdtap user interface, to
            maintain a consistent and reliable user experience, to improve the
            quality of our Crowdtap panel, and to evaluate and improve the
            products and services we offer our Clients (including to research
            and develop new products and services).
          </td>
          <td>Necessary for our legitimate interests</td>
        </tr>

        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </table>
    </div>
  </ng-container>
</div>

<h2 id="7-feature-specific-personal-data">7. Feature-specific Personal Data</h2>
<p>
  In addition to the categories identified above, additional Personal Data or
  use nuances will apply in the following situations:
</p>
<p>
  <strong id="7a-creating-a-crowdtap-account"
    >(a) Creating a Crowdtap account</strong
  >
</p>
<p>
  We will corroborate the information you provided when creating an account in
  order to prevent fraud and abuse. We accomplish this by partnering with
  third-party applications that match your information with public records. Once
  you have earned a certain level of points, we may ask you to further verify
  your account by submitting additional information that our Know-Your-Customer
  (KYC) and ID verification third-party partners would use to ensure that no one
  else is using your identity to claim rewards.
</p>
<p>
  <strong id="7b-verifying-your-account">(b) Verifying your account</strong>
</p>
<p>
  If you choose to use our mobile application, you will be asked to verify your
  account shortly after joining. You have the option to verify your account
  using ReCAPTCHA or by taking selfies using your mobile device (“Photo
  Verify”). Both are used by us to protect our Sites from fraud and abuse.
</p>
<ul>
  <li>
    If you choose Photo Verify, your biometric data will be collected,
    processed, and shared with Microsoft via
    <a
      href="https://azure.microsoft.com/en-in/services/cognitive-services/computer-vision/#overview"
    >
      Azure Computer Vision
    </a>
    . For more details, read our
    <a routerLink="/biometric-data-privacy-notice">
      Biometric Data Privacy Notice
    </a>
    .
  </li>
  <li>
    If you choose
    <a href="https://www.google.com/recaptcha/about/">ReCAPTCHA</a>, your
    personal information will be collected, processed, and used by Google to
    protect against bots. For more details, read
    <a href="https://policies.google.com/privacy?hl=en">
      Google's privacy policy
    </a>
    . Google LLC is organized under the laws of the State of Delaware, USA and
    operating under the laws of the USA.
  </li>
</ul>

<p>
  <strong id="7c-completing-actions">(c) Completing Actions</strong>
</p>
<p>
  Members have the ability to earn rewards by completing Actions initiated by us
  or our Clients. You control which Actions you respond to, if any.
</p>
<p>
  As part of initiating an Action, we or our Clients will use Member Profile
  Data to target or re-target Actions to Members based on certain demographics
  or attributes. We do this, for example, by using things like: saved audiences
  (custom audiences created based on Member Profile Data and previous Action
  activity); panels (niche audiences created based on Member Profile Data,
  social media data, etc.); segments (subgroups of markets or consumers using
  Member Profile Data and previous Action activity); dynamic segmentation
  (cluster analysis by machine learning algorithm to derive segments); and
  persona development (machine learning algorithm that identifies attributes
  across segments predictive of specific behaviors, like product satisfaction).
</p>
<p>
  To help preserve your privacy, we typically require a Client’s targeting
  criteria to meet a certain minimum number of Members before we allow an Action
  to be conducted. That way, your responses can be “aggregated” with other
  Members' responses to reduce re-identification.
</p>
<div
  class="bordered-section"
  [class.expanded-panel-active]="exampleOfTargeting"
>
  <div class="header-container">
    <img
      [class.rotate-arrow]="exampleOfTargeting"
      src="/assets/icons/svg/ic-arrow-down-grey.svg"
      alt="Arrow icon"
    />
    <span (click)="toggle('exampleOfTargeting')" class="expanded-panel-title"
      >Example of Targeting</span
    >
  </div>
  <ng-container *ngIf="exampleOfTargeting">
    <div class="expanded-panel-content">
      <p>
        Here's an example of how we used Member Profile Data to help a Brand
        Client get insights from potential customers:
      </p>
      <p>
        A major pet food brand was experiencing a decline in sales at Walmart
        and hypothesized that it was the result of a recent packaging change. We
        used Member Profile Data, including Inferred Data and responses to
        previous Actions, to identify 500 shoppers who met the following
        criteria:
      </p>
      <ul>
        <li>cat owners who frequently buy kitty litter at Walmart,</li>
        <li>
          had purchased the brand’s bag of kitty litter in the last year, and
        </li>
        <li>could recall the brand’s recent packaging change.</li>
      </ul>
      <p>
        Actions were targeted to those 500 shoppers to gauge their opinions and
        behaviors related to the packaging change. 22% confirmed that they
        purchased the brand’s product less often and 5% stopped purchasing
        altogether. We shared these responses with the Client, who used the
        aggregated information to revise its product placement efforts.
      </p>
    </div>
  </ng-container>
</div>

<p>
  <strong id="7d-participating-in-live-interviews-or-focus-groups">
    (d) Participating in live interviews or focus groups
  </strong>
</p>
<p>
  You may be
  <a
    href="https://support.crowdtap.com/hc/en-us/articles/360050008911-Crowdtap-interviews"
  >
    invited to participate
  </a>
  in a Live In-Depth Interview (“IDI”) or Live Focus Group.
</p>
<ul>
  <li>IDIs are virtual 1-on-1 interviews</li>
  <li>
    Focus Groups are virtual interviews consisting of you and 3-4 other Crowdtap
    members
  </li>
</ul>
<p>
  If you choose to participate in either, your Personal Data – including
  Sensitive Personal Data – will be collected, processed, and shared with our
  Brand Clients. <em>Read our</em>
  <a routerLink="/biometric-data-policy">
    <em> Biometric Data Privacy Notice</em>
  </a>
  <em> for full details.</em>
</p>
<p>
  <strong id="7e-providing-open-ended-text-responses"
    >(e) Providing open-ended text responses</strong
  >
</p>
<p>
  From time to time, you will receive Actions that involve open-ended questions.
  An open-ended question is a question that cannot be answered with a "yes",
  "no", or static response. Instead, open-ended questions allow you to respond
  in an open format, so you can answer based on your complete knowledge,
  feeling, and understanding.
</p>
<ul>
  <li>
    An example of a close-ended question is:
    <em>Do you meditate weekly (yes or no)?</em>
  </li>
  <li>
    An example of an open-ended question is:
    <em>How do you typically deal with stress and anxiety?</em>
  </li>
</ul>
<p>
  If you choose to respond to an open-ended question, your responses will allow
  Clients to attain detailed and descriptive market research insights.
</p>
<p>
  What level of detail you provide about yourself in response to these questions
  determines whether or not Personal Data is involved. For example, let’s say
  you were asked the question, “How do you choose a restaurant to eat at?” If
  you answered, “By location! I eat at Happy Diner STL at the corner of Smith
  and Jones Street because I live in the apartment above it!”, your Personal
  Data would be implicated.
</p>
<p>
  <strong id="7f-providing-open-ended-video-responses"
    >(f) Providing open-ended video responses</strong
  >
</p>
<p>
  You may be asked to respond to open-ended questions with a video recording. We
  call this “Video Open End” or “VOE” for short. If you choose to participate in
  VOE, your Biometric Data will be collected, processed, and shared with our
  Brand Clients. <em>Read our</em>
  <a routerLink="/biometric-data-privacy-notice">
    <em> Biometric Data Privacy Notice</em>
  </a>
  <em> for full details.</em>
</p>

<p>
  <strong id="7g-testing-products">(g) Testing products</strong>
</p>
<p>
  You may be asked to participate in an in-home use test (“IHUT”), which is a
  way of allowing our Clients to collect and analyze feedback from consumers'
  hands-on product testing experiences in their own native environment (home,
  work, etc). Among the variety of reasons Clients use IHUTs, some of the more
  common ones include: so product development teams can validate how a product
  is used; to study product flaws in order to make improvements; or to collect
  real usage claims about a product, such as “9 in 10 women would recommend this
  product”.
</p>
<p>
  Just like other Actions, you’ll earn points or rewards for your participation.
  We’ll mail you a product or ask you to buy it in-store with a coupon and then
  you’ll answer questions about your experience with that product. In some
  cases, you may be asked to submit photos or videos using your device as part
  of the Action response. For example, you might be asked to video record
  yourself opening product packaging, to take a picture of yourself using the
  product, etc.
</p>
<p>
  Every IHUT opportunity has eligibility conditions based on Client needs.
  Members may be selected based on their answers to application questions
  (availability, familiarity with product or service, etc) and demographic
  information that you provided when you completed your profile, like date of
  birth, location, and gender.
</p>
<p>
  If you choose to participate in an IHUT, we will use your contact information
  to send you IHUT invitations, instructions, reminders, and and post-trial
  activation information. We will also use your name and mailing address to
  facilitate shipping and to send tracking information to you. We collect
  information from you via a designated Crowdtap email or via the Crowdtap
  platform.
</p>
<p>
  We will share your name and mailing address with third-party fulfillment
  centers for purposes of sending you the product. The fulfillment centers we
  partner with are located in the U.S.
</p>

<h2 id="8-who-do-we-disclose-sell-or-share-your-personal-data-to">
  8. Who do we disclose, sell, or share your Personal Data to?
</h2>
<p>
  We recognize that because you give us and allow us to use your Personal Data,
  we are entrusted with safeguarding the privacy of that information. Your
  continuing trust is very important to us, so we generally only disclose, sell,
  or share that data with third parties where we have given you prior notice
  (<em>e.g.</em>, in this Policy); obtained your consent ( <em>e.g.</em>, based
  on your Member account settings); or anonymized or de-identified the
  information in such a way that you or your household cannot reasonably be
  linked, directly or indirectly, by it.
</p>
<p>
  In addition to the sharing of your Personal Data noted elsewhere in this
  Policy, we disclose, share, or sell Personal Data to:
</p>
<p>
  <strong id="8a-suzy">(a) Suzy</strong>
</p>
<p>
  We will use your Personal Data for our brand research and insights and may
  share it with our subsidiaries and affiliates, including Suzy Home, LLC. We
  may also disclose aggregated, de-identified, or anonymized statistics about
  our Members. For example, we may post on our blog or include in our marketing
  materials statistics like the average response rate for Actions or what
  percentage of Members are male.
</p>

<p>
  <strong id="clients">(b) Clients</strong>
</p>
<p>
  We share your Action responses, Standard Attributes, and any other Personal
  Data you have voluntarily provided with the Client who purchased license(s) to
  Suzy’s insights platform and initiated the Action to which you responded, or
  to prospects or potential clients that Suzy wishes to engage. We share this
  information with the Client in our Suzy platform (i.e., it is viewable by any
  team members in the Client’s company with access to the Client’s dashboard).
  We also provide the Client with options to download the raw data with an excel
  spreadsheet or, in some instances, to download cluster, persona, or segment
  analysis in a PowerPoint deck. That Client will use the information for their
  brand research and insights.
</p>
<p>
  We may also use the Personal Data you provide to comply with regulatory
  monitoring and reporting obligations imposed by Clients in connection with
  adverse events. In such instances, we may require further information from you
  in addition to Personal Data to comply with such regulatory monitoring and
  reporting obligations. Such information may be provided to the relevant
  Client.
</p>
<p>
  <strong id="8c-reward-partners">(c) Reward partners</strong>
</p>
<p>
  We share Personal Data with third party partners who help us facilitate the
  redemption of your points for gift cards, digital subscriptions, or charity.
  For example, we may share your Personal Data with a vendor to verify your
  identity in connection with a reward redemption or to fulfill any incentive or
  reward which you have elected to receive, and that vendor may forward your
  email address to a third party to redeem any such rewards.
</p>

<p>
  <strong id="8d-software-providers">(d) Software providers</strong>
</p>
<p>
  We utilize several software-as-a-service providers to help facilitate our
  services. For example, we may:
</p>
<ul>
  <li>
    share your Contact Information, Account Creation Information, and Profile
    Information with third parties to facilitate sending Crowdtap Actions by
    email or text to Members. For example, we use Google Forms to gather Contact
    Information and to help schedule IDIs and Focus Groups.
  </li>
  <li>
    share your Contact Information, Account Creation Information, and Data
    Quality Information with third parties to help us facilitate fraud
    prevention.
  </li>
  <li>
    share your Log Data Information, Cookie Information, Usage Information,
    Device and Browser Data, and Inferred Data with third parties to deliver and
    help us track our marketing and advertising content
  </li>
  <li>
    share your Referral Information, Log Data Information, Cookie Information,
    Usage Information, Device and Browser Data, and Response Rate Information
    with third parties to help us track website conversion success metrics
  </li>
  <li>
    share your Contact Information, Profile Information, and Cookie Information
    with a third party support platform to help us manage our customer support
    services to you
  </li>
</ul>

<p>
  <strong id="8e-service-providers-and-contractors"
    >(e) Service providers and contractors</strong
  >
</p>
<p>
  We may provide your Personal Data to other companies or individuals we’ve
  hired to perform one or more services for us (such as quality assurance
  services, moderation services, translation services, targeting audiences,
  analyzing responses, matching data, etc.). Those companies or individuals are
  located both in the U.S. and abroad. These companies or individuals are
  authorized to use your Personal Data only as necessary to provide these
  services to us.
</p>
<p>
  Internally, our Managed Services team may process your Personal Data as part
  of the work they perform for Brand Clients (<em>i.e.</em>, building segments,
  targeting audiences, analyzing responses, etc).
</p>

<p>
  <strong id="8f-technical-consultants-and-third-party-auditors">
    (f) Technical consultants and third-party auditors
  </strong>
</p>
<p>
  We share Personal Data with technical consultants and third party auditors to
  ensure we meet our security, legal, and regulatory compliance requirements.
</p>

<p>
  <strong id="8g-investors-successor-entities-purchasers"
    >(g) Investors, successor entities, or purchasers</strong
  >
</p>
<p>
  We reserve the right to transfer your Personal Data, including profile
  information, responses to Actions, or other information to a successor entity
  upon a merger, consolidation, or other corporate reorganization in which Suzy
  participates or to a purchaser of all or substantially all of Suzy’s assets
  related to Crowdtap.
</p>

<p>
  <strong id="8h-law-enforcement">(h) Law enforcement</strong>
</p>
<p>
  We may disclose your Personal Data as required by law, including for purposes
  of law enforcement, or when we believe in good faith that disclosure is
  necessary to protect our rights, protect your safety or the safety of others,
  investigate fraud, and/or to comply with a judicial proceeding, court order,
  subpoena, or other legal process served on us. We may also disclose your
  Personal Data to investigate suspected violations of the Site rules or
  policies.
</p>

<p>
  <strong id="8i-anonymized-or-de-identified-information"
    >(i) Anonymized or de-identified information</strong
  >
</p>
<p>
  We may publish or disclose anonymized or de-identified information about our
  services, including responses to Actions. For example, we may post on our blog
  or otherwise include in our marketing emails interesting statistics like how
  many Actions were completed in a month.
</p>

<p>
  <strong id="8j-aggregated-information">(j) Aggregated information</strong>
</p>
<p>
  We use Aggregate Data to analyze aggregate trends both amongst Members and
  External Audiences or other survey respondents. We may use this information
  for our own business purposes or sell this aggregate, de-identified
  information to our Clients or other third parties for advertising,
  promotional, or other commercial purposes. Where data has been de-identified,
  we take appropriate technical and organizational measures to prevent future
  re-identification.
</p>
<p>
  <strong id="8k-vital-interests">(k) Vital interests</strong>
</p>
<p>
  In rare events, we may disclose your Personal Data where we believe in good
  faith that access or disclosure is necessary to protect the personal safety or
  vital interests of Suzy employees, customers, or the public.
</p>
<p>
  <strong id="8l-other">(l) Other</strong>
</p>
<p>
  Information obtained in connection with the Sites may be intermingled with and
  used by us in conjunction with information obtained through sources other than
  the Sites, including both offline and online sources.
</p>
<p>
  We may also disclose or share information about you with other third parties
  as part of provisioning and improving our services. We will not share or sell
  your Personal Data to any third parties for reasons outside of provisioning
  and improving our services and for the other purposes described in this Policy
  unless we have:
</p>
<ul>
  <li>obtained your consent; or</li>
  <li>
    anonymized or de-identified the information such that the information does
    not contain your Personal Data.
  </li>
</ul>

<h2 id="9-are-survey-responses-anonymous">
  9. Are survey responses anonymous?
</h2>
<p>
  <strong>To Suzy/us, the answer is no.</strong>
  We link your responses with your Member account, but never fear, we do not
  abuse or exploit this. We do this to provide Brand Clients with additional
  targeting criteria and to otherwise provide the services you have asked us to
  provide.
</p>
<p>
  <strong>
    To Clients and other third parties we share Personal Data with, the answer
    is maybe.
  </strong>
  It depends on what types of Actions you choose to participate in and what
  information you provide in your responses. Of course, if you disclose your
  name in a response, you won’t be anonymous. But even if you don’t, the
  information you do provide may be identifying, such as when combined with
  other information (like Standard Attributes).
</p>
<p>
  On the Sites, we, our Brand Clients, and some of our partners will have the
  ability to contact you regarding Actions for which you qualify. If you post
  your Personal Data on the Sites in a section of the Sites where it was not
  explicitly requested, or outside of the Sites where it may be viewable and/or
  accessible by Brand Clients (<em>e.g.</em>, contact information listed within
  feedback you provide, information on your Facebook profile linked to your
  account with us, or an email address you provided directly to a Brand Client),
  we cannot control how third parties (such as Brand Clients) may use that
  information.
</p>
<h2 id="10-how-long-is-personal-data-retained">
  10. How long is Personal Data retained?
</h2>
<p>
  Except as otherwise specified in this Policy, we retain each category of
  Personal Data for no longer than necessary to provide you or our Clients the
  services requested, to comply with our legal obligations, or to resolve
  disputes and enforce our agreements. Member Personal Data is anonymized /
  deleted upon request or upon a reasonable period after account abandonment
</p>

<h2 id="11-where-is-personal-data-processed-and-stored">
  11. Where is Personal Data processed and stored?
</h2>
<p>
  Suzy is based in the United States, our servers and systems are located in the
  United States, and many of our service providers are based in the United
  States.
</p>
<p>
  <em>
    By using the Sites, you freely and specifically give us your consent to
    export, process, and/or store your Personal Data in the United States. You
    understand that the United States may not have the same data protection /
    privacy laws as your country and that data stored in the United States may
    be subject to lawful requests by the courts or law enforcement authorities
    in the United States.
  </em>
</p>
<p>
  Sometimes, we transfer your Personal Data overseas to our Clients, service
  providers, and subcontractors in places with different laws and protections.
  We’ll use appropriate technical and organizational measures and safeguards to
  protect your data during international transfers and at all other times it is
  in our care
</p>
<p>
  If you are resident in or a visitor of the EEA, United Kingdom, or
  Switzerland, we will protect your Personal Data when it is transferred outside
  of such locations by processing it in a territory which the European
  Commission has determined provides an adequate level of protection for
  personal information or otherwise implementing appropriate safeguards to
  protect your Personal Data, including through the use of Standard Contractual
  Clauses or another lawful transfer mechanism approved by the European
  Commission.
</p>

<h2 id="security">12. Security</h2>
<p>
  The security of your Personal Data is very important to us. We put in place
  reasonable and appropriate technical and organizational measures to ensure
  your Personal Data is kept secure and protected from unauthorized access, use,
  disclosure, alteration or destruction, in accordance with applicable laws and
  regulations. When you enter sensitive information (such as login credentials),
  we encrypt the transmission of that information using Transport Layer Security
  (TLS). When we share your Personal Data with service providers,
  subcontractors, or other third parties, we base our selection on said parties
  having adequate safeguards in place that meet our data protection standards.
  We perform risk assessments or otherwise audit their compliance with such
  standards and incorporate applicable contractual provisions ensuring
  compliance with (i) such standards and (ii) applicable data privacy laws and
  regulations. For more information, visit our
  <a href="https://suzy.com/trust-center">Trust Center</a>.
</p>
<p>
  Notwithstanding the foregoing, transmissions over the Internet and/or a mobile
  network are not one hundred percent (100%) secure, and Suzy does not guarantee
  the security of transmissions. Suzy is not responsible for any errors by you
  in submitting Personal Data to us.
</p>
<h2 id="minors">13. Minors</h2>
<p>
  The Sites are not intended for and may not be used by minors.
  <strong>"Minors"</strong> are individuals under the age of 13 (or under a
  higher age if required by the laws of their residence). We do not knowingly
  collect nor intend to collect Personal Data from Minors or allow them to
  register for the Sites without verifiable parental consent. Where appropriate,
  we take reasonable measures to inform Minors not to submit such information to
  our Sites. If we learn that a Minor has provided us with Personal Data without
  verifiable parental consent, we will use all reasonable efforts to delete such
  information from our systems.
</p>

<h2 id="links-to-other-websites">14. Links to other websites</h2>
<p>
  We may provide links to websites and other third-party content that we do not
  own or operate. The websites and third-party content to which we link may have
  separate privacy policies. We are not responsible for the privacy practices of
  any entity that we do not own or control. To ensure the protection of your
  privacy, always review the privacy notice(s) of the websites you visit.
</p>

<h2 id="bulletin-boards-and-chat-areas">15. Bulletin boards and chat areas</h2>
<p>
  You should be aware that Personal Data which you voluntarily include and
  transmit online may be viewed and used by others. Users of the Site are solely
  responsible for the content of messages they post online, including on public
  forums. These forums include but are not limited to chat rooms on our Site or
  other websites, bulletin boards, blogs, social media web pages, or other
  publicly accessible forums which may be viewed and used by anyone with access
  to such forums. You should be aware that when you voluntarily disclose
  Personal Data within a public forum, your information may be collected and
  used by others. The Site is unable to control such uses of your Personal Data,
  and by using such services, you assume the risk that the Personal Data
  provided by you may be viewed and used by third parties.
</p>

<h2 id="notice-of-financial-incentive">16. Notice of Financial Incentive</h2>
<p>
  Suzy provides rewards and incentives for individuals to provide responses to
  Actions, which involve the Personal Data described in this Policy. You can opt
  in to the rewards or incentives by registering for a Crowdtap account and
  answering Actions using your account credentials, or by responding to an email
  inviting you to participate in an Action. Your participation is completely
  voluntary, and you have a right to withdraw from Crowdtap at any time or
  decline the Action invitation. If you decide you don’t want to participate,
  you can refrain from submitting responses to Actions, or you can close your
  Crowdtap account.
</p>
<p>
  The specific reward or incentive offered, if any, is made available to you
  when you provide responses to available Actions and may vary based on the type
  and length of the Action and other factors identified below. The monetary
  value of the reward or incentive is a reasonable approximation of the monetary
  value of your Action responses to us. We have arrived at this estimate based
  on consideration of multiple factors, including the following:
</p>
<ol>
  <li>
    revenue generated by Suzy in providing Action responses and insights to
    customers;
  </li>
  <li>expenses incurred by Suzy in operating the Actions; and</li>
  <li>
    our reasonable assessment of revenue we may generate as a result of Suzy
    providing aggregated and/or anonymized responses and insights to customers.
  </li>
</ol>

<h2 id="your-privacy-rights">17. Your privacy rights</h2>
<p>
  In certain circumstances, you have the following rights regarding your
  Personal Data. Your rights and choices may vary depending on the laws
  applicable to your Personal Data. Such laws may extend additional rights and
  choices to you or may limit or except the rights listed below.
</p>
<table>
  <tr>
    <th>
      <strong> Right </strong>
    </th>
    <th>
      <strong> Details </strong>
    </th>
  </tr>

  <tr>
    <td>
      <strong> Right of Access </strong>
    </td>
    <td>
      Find out what kind of Personal Data we process about you and request
      details of this information, including categories of recipients to whom
      the Personal Data have been or will be disclosed and purposes of
      processing.
    </td>
  </tr>

  <tr>
    <td>
      <strong> Right to Know </strong>
    </td>
    <td>
      Ask us for a notice identifying the categories of Personal Data that we
      collect (and from whom), disclose, or share (and to whom we disclose or
      share), as well as our business or commercial purposes for collecting,
      disclosing, or selling that Personal Data. In most respects, this Policy
      serves as such notice.
    </td>
  </tr>

  <tr>
    <td>
      <strong> Right to Rectify, also known as Right to Correct </strong>
    </td>
    <td>
      Ask for your Personal Data to be rectified, updated or, corrected. We may
      need to verify the accuracy of the new information you provide to us.
    </td>
  </tr>

  <tr>
    <td>
      <strong>
        Right to Transfer, also known as Right to Data Portability
      </strong>
    </td>
    <td>
      Ask us to package up your Personal Data in a structured, commonly used and
      machine-readable format, so you can move, copy, or transfer it to another
      organization in a secure manner and without interrupting the integrity and
      usability of the information.
    </td>
  </tr>

  <tr>
    <td>
      <strong> Right to Restrict or Object to Processing </strong>
    </td>
    <td>
      Object to certain types of processing of your Personal Data, including
      profiling, targeted advertising, direct marketing, and statistical,
      scientific, or historical research purposes.
    </td>
  </tr>

  <tr>
    <td>
      <strong> Right to not be Subject to Fully Automated Decisions </strong>
    </td>
    <td>
      Ask to not be subject to decisions with a legal or similarly significant
      effect (including profiling) that are based solely on the automated
      processing of your Personal Data, unless you have given us your explicit
      consent or where necessary for the performance of a contract with us.
    </td>
  </tr>

  <tr>
    <td>
      <strong> Right to Limit Use of Sensitive Information </strong>
    </td>
    <td>Tell us to limit or stop processing your Sensitive Personal Data.</td>
  </tr>

  <tr>
    <td>
      <strong> Right to Withdraw Consent at Any Time </strong>
    </td>
    <td>Withdraw any consent you may have previously given us.</td>
  </tr>

  <tr>
    <td>
      <strong>
        Right to Delete, also known as the Right to be Forgotten
      </strong>
    </td>
    <td>
      Request that your Personal Data be erased. Where required, we will delete
      your Personal Data. We will decline your request for deletion if
      processing of your Personal Data is necessary: (i) for us to comply with
      our legal obligations; (ii) for the establishment, exercise, or defense of
      legal claims; (iii) for the performance of a task in the public interest,
      or (iv) for us to perform certain actions in accordance with applicable
      laws, such as detecting security incidents and protecting against
      fraudulent activity.
    </td>
  </tr>

  <tr>
    <td>
      <strong>
        Right to Opt-Out of the Sale or Sharing of your Personal Data
      </strong>
    </td>
    <td>
      <p>Direct us not to sell your Personal Data to third parties.</p>
      <p>
        California residents: You have the right to tell us not to sell or share
        your Personal Data to third parties. This right is referred to as the
        “right to opt-out of sale or sharing.”
      </p>
    </td>
  </tr>
</table>

<h2 id="exercising-your-privacy-rights">18. Exercising your privacy rights</h2>
<p>You can exercise your rights by:</p>
<ul>
  <li>
    submitting a
    <a href="https://crowdtap.formcrafts.com/privacyrequest">Privacy Request</a
    >,
  </li>
  <li><a href="mailto:trust@suzy.com">e-mailing us</a>,</li>
  <li>
    contacting
    <a href="https://support.crowdtap.com/hc/en-us/requests/new">Support</a>, or
  </li>
  <li>
    writing to: Suzy, Inc. Attn: Legal, 228 Park Avenue South, PMB 85529
    Broadway, New York, NY 10003.
  </li>
  <li>
    If you are in the European Union, European Economic Area, or UK, you can
    <a href="https://suzy.com/s/SUZY01-Representative-contact-summary-2023.pdf"
      >contact our Article 27 Data Protection Representative</a
    >.
  </li>
</ul>

<p>
  We will not charge you fees in connection with the exercise of your rights,
  unless the request is manifestly unfounded or excessive (for example, because
  of its repetitive character). We will not discriminate against you for
  exercising your privacy rights. However, please note that honoring some of
  your rights may mean that we are unable to perform the actions necessary for
  you to use or take full advantage of the Sites.
</p>
<p class="text-bold">Response timing and format</p>

<p>
  We will respond to your request in a reasonably timely manner and typically
  within 30-60 days, depending on the laws applicable to you. We'll either
  fulfill your request or explain why we're not taking action. If we don't take
  action, and if the applicable laws so require, we'll also provide instructions
  on any rights to appeal our decision.
</p>

<p>
  In order to protect the security of your Personal Data, we will not honor a
  request if we cannot verify your identity or authority to make the request and
  confirm the Personal Data relates to you. The method used to verify your
  identity will depend on the type, sensitivity and value of the information,
  including the risk of harm to you posed by any authorized access or deletion.
  Generally speaking, verification will be performed by matching the identifying
  information provided by you to the Personal Data that we already have.
</p>

<p>
  If you are in the European Union and you are not satisfied with our response,
  you have the right to complain or seek advice from your local data protection
  supervisory authority and/or bring a claim against us in any court of
  competent jurisdiction.
</p>

<p>
  In so far as practicable, we will notify our Clients any third parties to whom
  we have disclosed your Personal Data with any correction, deletion, and/or
  restriction to the processing of your Personal Data.
</p>

<h2 id="your-privacy-controls">19. Your privacy controls</h2>
<p>
  In addition to your Privacy Rights, there are mechanisms you can use to
  control your Personal Data.
</p>
<table>
  <tr>
    <th><strong>Control</strong></th>
    <th><strong>Available to</strong></th>
    <th><strong>Details</strong></th>
  </tr>

  <tr>
    <td>
      <strong>Account Settings</strong>
    </td>
    <td>Members</td>
    <td>
      You can control your Personal Data directly within your account settings.
      You can view, edit, correct, update, delete, or download some of your data
      directly in your Crowdtap profile (by navigating to the information
      entered on your profile pages), or you can
      <a href="https://support.crowdtap.com/hc/en-us/requests/new"
        >contact our support team</a
      >
      to help do so.
    </td>
  </tr>

  <tr>
    <td>
      <strong>Terminating Membership</strong>
    </td>
    <td>Members</td>
    <td>
      You may terminate your membership by
      <a href="https://support.crowdtap.com/hc/en-us/requests/new"
        >contacting us</a
      >
      or by using the “terminate membership” function if available. Upon
      terminating your membership, we will remove the following Personal Data
      from Crowdtap’s active database: password, member profile, full name,
      address (except for zip code), email address, other contact information,
      Facebook account information, Twitter account information, and profile
      photo.
    </td>
  </tr>

  <tr>
    <td>
      <strong>Deleting the Crowdtap app from your device</strong>
    </td>
    <td>Members</td>
    <td>
      If you no longer wish to use the Crowdtap app or to receive notifications
      to complete Actions, you can delete the Crowdtap app from your device.
      However, if you wish to terminate your membership, please see “Terminating
      Membership”, above.
    </td>
  </tr>

  <tr>
    <td>
      <strong>Voluntary Action Participation</strong>
    </td>
    <td>Members</td>
    <td>
      You can always refuse to answer an Action. Your participation in any
      survey is entirely voluntary, and you may opt out of responding at any
      time by skipping the question, declining the invitation, or selecting
      “prefer not to say”, where applicable.
    </td>
  </tr>

  <tr>
    <td>
      <strong>Advertising Controls</strong>
    </td>
    <td>Members; Visitors</td>
    <td>
      <p>
        Some of the business partners that may collect information about your
        activities on our Sites may be members of organizations or programs that
        provide choices to individuals regarding the use of their browsing
        behavior for purposes of targeted advertising.
      </p>
      <ul>
        <li>
          For example, you may opt out of receiving targeted advertising through
          members of the Network Advertising Initiative by clicking
          <a href="http://optout.networkadvertising.org/">here</a> or the
          Digital Advertising Alliance by clicking
          <a href="http://www.aboutads.info/choices">here</a>.
        </li>

        <li>
          European users may opt out of receiving targeted advertising through
          members of the European Interactive Digital Advertising Alliance by
          clicking <a href="http://www.youronlinechoices.eu/">here</a>,
          selecting your country, and then clicking “Choices” (or
          similarly-titled link).
        </li>

        <li>
          Mobile app users may opt out of receiving targeted advertising in
          mobile apps through members of the Digital Advertising Alliance by
          installing the AppChoices mobile app, available
          <a href="https://www.youradchoices.com/appchoices">here</a>, and
          selecting the user’s choices.
        </li>

        <p>
          Please note that we may also work with companies that offer their own
          opt-out mechanisms and may not participate in the opt-out mechanisms
          that we linked to above.
        </p>
      </ul>
    </td>
  </tr>

  <tr>
    <td>
      <strong>Marketing Emails and Service Announcements Controls</strong>
    </td>
    <td>Members; Visitors</td>
    <td>
      Individuals may unsubscribe from receiving marketing or other commercial
      emails from us by following the instructions included in the email.
      However, even if an individual opts out of receiving such communications,
      we retain the right to send them non-marketing communications (such as
      announcements when they become eligible for rewards or notices regarding
      changes in our Terms of Service). Under certain circumstances, we may need
      to contact some or all Members in order to make an important announcement
      about the Site or Platform changes. Members are not able to unsubscribe or
      "opt out" of these announcements, because they may contain very important
      information regarding their accounts. Before these measures are taken,
      however, we will post information regarding changes directly on the Site.
      We may also contact Members to correct account errors or to supply
      important information we deem relevant.
    </td>
  </tr>

  <tr>
    <td>Do Not Track signals</td>
    <td>Members; Visitors</td>
    <td>
      "Do Not Track" is a feature enabled on some browsers that sends a signal
      to request that a website disable its tracking or cross-website user
      tracking. We treat any user-enabled global privacy controls, such as a
      browser plug-in or privacy setting, device setting, or other mechanism,
      that communicate or signal your choice to opt-out of the sale of your
      Personal Data as a valid request submitted pursuant to applicable privacy
      laws for that browser or device, or, if known, for the individual.
    </td>
  </tr>
</table>

<h2 id="terms-of-service">20. Terms of Service</h2>
<p>
  Use of our Sites is governed by, and subject to, the legal notices contained
  in
  <a href="https://www.crowdtap.com/terms-of-service">
    Crowdtap’s Terms of Service</a
  >. Your use, or access, of the Sites constitutes your agreement to be bound by
  these provisions.
</p>

<h2 id="how-can-you-contact-us">21. How can you contact us?</h2>
<p>You can contact us by:</p>

<ul>
  <li>
    <a href="mailto:trust@suzy.com">e-mailing us,</a>
  </li>
  <li>
    <a href="https://support.crowdtap.com/hc/en-us/requests/new"
      >contacting Customer Support, or</a
    >
  </li>
  <li>
    writing to: Suzy, Inc., Attn: Legal, 228 Park Avenue South, PMB 85529
    Broadway, New York, NY 10003
  </li>
  <li>
    If you are in the European Union, European Economic Area or UK, you can
    <a href="https://suzy.com/s/SUZY01-Representative-contact-summary-2023.pdf"
      >contact our Article 27 Data Protection Representative</a
    >.
  </li>
</ul>

<p>
  Suzy’s Legal Department is responsible for the organization’s compliance with
  this Policy.
</p>

<h2 id="changes-to-this-policy">22. Changes to this Policy</h2>
<p>
  We reserve the right to change, add, or remove portions from this Policy at
  any time. When we make any updates to this Policy that are deemed material
  under applicable legal requirements, we will notify you of such changes by
  updating the date of this Policy and providing other notification as required
  by applicable law. We may also provide notification of such changes to the
  Policy in other ways, such as via email or using other contact information
  provided to us. For all other changes, please review the Policy from time to
  time to stay informed of how we are processing personal data.
</p>
<em>
  <strong>
    By using the Sites following any Policy change, you freely and specifically
    give us your consent to collect, use, transfer, and disclose your Personal
    Data in the manner specified.
  </strong>
</em>
<h2>Prior Versions of this Notice</h2>
<ul>
  <li><a routerLink="/privacy-policy/2021-02-23">February 23, 2021</a></li>
  <li><a routerLink="/privacy-policy/2021-09-15">September 15, 2022</a></li>
  <li><a routerLink="/privacy-policy/2023-07-19">July 19, 2023</a></li>
</ul>
