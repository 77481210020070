import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ActionStructureGridKind } from '@suzy/shared/data-access/suzy-sdk';
import { DeviceService } from '@suzy/shared/tools/device';
import { NgScrollbar } from 'ngx-scrollbar';
import { take } from 'rxjs/operators';

//TODO: Move these interfaces to the shared location
interface FakeCorner extends ElementPosition {
  isSet: boolean;
}

interface ElementPosition {
  top: string;
  left: string;
  width: string;
  height: string;
}

export interface AnswerChoice {
  col: any;
  row: any;
  isSelected: boolean;
  hasError: boolean;
}

@Component({
  selector: 'suzy-action-forms-grid-open',
  templateUrl: './grid-open.component.html',
  styleUrls: ['./grid-open.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridOpenComponent implements OnInit {
  timeToAction: number;
  timeToActionIsUpdated = false;
  isActiveTab: number = 0;
  isMobile;

  @Input() action: any;
  @Input() isLoading: boolean;
  @Input() isSuccess: boolean;
  @Input() rows: any[];
  @Input() cols: any[];
  @Input() rowErrors: any[];
  @Input() colErrors: any[];
  @Input() disableSkip: boolean;
  @Input() fakeCorner: FakeCorner;
  @Input() errorMessage: string;
  @Input() disableButton: Boolean = false;
  @Input() isSkipping: boolean;
  @Input() isSubmitting: boolean;

  @Output() mouseEvent = new EventEmitter<MouseEvent>();
  @Output() timeToActionUpdate = new EventEmitter<number>();
  @Output() updateMetaDataValidationCount = new EventEmitter();
  @Output() errorMessageChange = new EventEmitter<string>();
  @Output() skip = new EventEmitter<Event>();
  @Output() goFormSubmit = new EventEmitter<any>();
  @Output() scrollEvent = new EventEmitter<Event>();

  @ViewChild('f') responseForm: NgForm;
  @ViewChild(NgScrollbar) scrollbarRef: NgScrollbar;
  @ViewChild('originalCorner') originalCorner: ElementRef<HTMLTableCellElement>;
  @ViewChild('gridTable') gridTable: ElementRef<HTMLTableElement>;
  @ViewChild('divFormGroup') divFormGroup: ElementRef<HTMLDivElement>;
  roundValue: number;
  gridScrollY = 0;
  headerScrollX = 0;
  showColumnHeader = false;
  tableWidth = 0;
  viewPosition: ElementPosition = {
    width: '0px',
    height: '0px',
    top: '0px',
    left: '0px'
  };
  cornerPosition: ElementPosition = {
    width: '0px',
    height: '0px',
    top: '0px',
    left: '0px'
  };

  constructor(
    private deviceService: DeviceService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.rows.map((item, index) => {
      index === 0 ? (item.isTouched = true) : (item.isTouched = false);
      item.temp = [];
    });

    this.isMobile = this.deviceService.isMobile();
    this.roundValue = 10;
  }

  ngAfterViewInit(): void {
    this.viewPosition.width =
      this.scrollbarRef.nativeElement.offsetWidth + 'px';
    this.viewPosition.height =
      this.originalCorner.nativeElement.offsetHeight + 'px';
    this.tableWidth = this.gridTable.nativeElement.offsetWidth;

    this.scrollbarRef.scrolled.subscribe(
      (event: Event & { target: HTMLElement }) => {
        this.headerScrollX = 0 - event.target.scrollLeft;
        this.scrollEvent.emit(event);
      }
    );
  }

  divResized(event: Event): void {
    this.viewPosition.width =
      this.scrollbarRef.nativeElement.offsetWidth + 'px';
    this.viewPosition.height =
      this.originalCorner.nativeElement.offsetHeight + 'px';
    this.tableWidth = this.gridTable.nativeElement.offsetWidth;
    this.cornerPosition.height = this.viewPosition.height;
  }

  onMouseMoveEvent(event: MouseEvent): void {
    this.mouseEvent.emit(event);
  }

  setHeaderPosition(top: number, left: number): void {
    this.viewPosition.top = top + 'px';
    this.viewPosition.left =
      this.divFormGroup.nativeElement.getBoundingClientRect().left -
      left +
      'px';
    this.tableWidth = this.gridTable.nativeElement.offsetWidth;
    this.cornerPosition.left = this.viewPosition.left;
    this.cornerPosition.top = this.viewPosition.top;
    this.cornerPosition.height = this.viewPosition.height;
    this.cornerPosition.width =
      this.originalCorner.nativeElement.offsetWidth + 'px';
  }

  divScrolled(event: Event & { target: HTMLElement }): void {
    this.gridScrollY = event.target.scrollTop;
    if (this.gridScrollY >= 72) {
      this.showColumnHeader = true;
    } else {
      this.showColumnHeader = false;
    }
    this.scrollEvent.emit(event);
  }

  updateTimeToAction(): void {
    if (!this.timeToActionIsUpdated) {
      this.timeToAction = Date.now();
      this.timeToActionUpdate.emit(this.timeToAction);
    }
    this.timeToActionIsUpdated = true;
  }

  resetAll(row) {
    row.forEach(val => {
      val.isSelected = false;
    });
  }

  selectChoice(choice: AnswerChoice, item: any, $event, i: number): void {
    choice.isSelected = !choice.isSelected;
    const none_prompt_columns = this.action.grid.none_prompt_columns;
    if (
      this.isMobile &&
      this.action.grid.open_row_min === 1 &&
      this.action.grid.open_row_max === 1
    ) {
      item.isTouched = true;
      this.gridHasError();
      this.toggle(i + 1);
    }
    if (this.action.grid.include_none_columns) {
      if (choice.isSelected && choice.col.is_none) {
        item.forEach(element => {
          if (!element.col.is_none) {
            element.isSelected = false;
          }
        });
      } else {
        item.forEach(element => {
          if (element.col.is_none) {
            element.isSelected = false;
          }
        });
        if (choice.isSelected) {
          item.temp.push(choice);
        } else {
          let el = item.temp.find(
            itm => itm.col.answer_id === choice.col.answer_id
          );
          if (el) item.temp.splice(item.temp.indexOf(el), 1);
        }
        if (
          this.action.grid.open_row_max <
          item.filter(v => v.isSelected === true).length
        ) {
          for (let x of item) {
            if (item.temp[0].col.answer_id === x.col.answer_id) {
              x.isSelected = false;
              break;
            }
          }
          item.temp.splice(0, 1);
          choice.isSelected = true;
        }
      }
    } else {
      if (choice.isSelected) {
        item.temp.push(choice);
      } else {
        let el = item.temp.find(
          itm => itm.col.answer_id === choice.col.answer_id
        );
        if (el) item.temp.splice(item.temp.indexOf(el), 1);
      }
      if (
        this.action.grid.open_row_max <
        item.filter(v => v.isSelected === true).length
      ) {
        for (let x of item) {
          if (item.temp[0].col.answer_id === x.col.answer_id) {
            x.isSelected = false;
            break;
          }
        }
        item.temp.splice(0, 1);
        choice.isSelected = true;
      }
    }
    this.updateTimeToAction();
    this.errorMessage = '';
  }

  getSelectedRow(item) {
    return item.filter(v => v.isSelected === true);
  }

  gridHasError(): boolean {
    let hasError = false;
    switch (this.action.grid.grid_kind) {
      case ActionStructureGridKind.open:
        const errorRow = this.validateOpenRow();
        let translateError;

        this.translate
          .get('general.mustSelect')
          .pipe(take(1))
          .subscribe(value => {
            translateError = value;
          });

        this.errorMessage = errorRow ? translateError : '';
        break;

      case ActionStructureGridKind.rank:
        if (this.validateRankQuestion()) {
          this.errorMessage = this.translate.instant('grid.gridMustSelectCol');
        }
        break;

      default:
        break;
    }
    if (this.errorMessage.length) {
      hasError = true;
      this.errorMessageChange.emit(this.errorMessage);
    }
    return hasError;
  }

  // validation for open type
  validateOpenRow(): any {
    const minAnswer = this.action.grid.open_row_min;
    const maxAnswer =
      this.action.grid.open_row_max || this.action.grid.rows.length;
    let hasError = false;

    this.rows.forEach((choice, idx) => {
      const selectedChoice = [];
      let isNoneOfAbove = false;
      choice.forEach(item => {
        if (item.isSelected === true) {
          selectedChoice.push(item);
          if (item.col.is_none) {
            isNoneOfAbove = true;
          }
        }
      });

      if (minAnswer === 0) {
        if (selectedChoice.length > maxAnswer && !isNoneOfAbove) {
          this.rowErrors[idx] = true;
          this.updateMetaDataValidationCount.emit();
          hasError = true;
        } else {
          this.rowErrors[idx] = false;
        }
      } else if (
        (selectedChoice.length < minAnswer ||
          selectedChoice.length > maxAnswer) &&
        !isNoneOfAbove
      ) {
        this.rowErrors[idx] = true;
        this.updateMetaDataValidationCount.emit();
        hasError = true;
      } else {
        this.rowErrors[idx] = false;
      }
    });

    return hasError;
  }

  validateOpenCol(): boolean {
    const minAnswer = this.action.grid.open_column_min;
    const maxAnswer =
      this.action.grid.open_column_max || this.action.grid.columns.length; // if the max value is 0, it meant it is optional
    let hasError = false;

    this.cols.forEach((choice, idx) => {
      const selectedChoice = [];

      choice.forEach(item => {
        if (item.isSelected === true) {
          selectedChoice.push(item);
        }
      });

      if (minAnswer === 0) {
        if (selectedChoice.length > maxAnswer) {
          this.colErrors[idx] = true;
          this.updateMetaDataValidationCount.emit();
          hasError = true;
        } else {
          this.colErrors[idx] = false;
        }
      } else if (
        selectedChoice.length < minAnswer ||
        selectedChoice.length > maxAnswer
      ) {
        this.colErrors[idx] = true;
        this.updateMetaDataValidationCount.emit();
        hasError = true;
      } else {
        this.colErrors[idx] = false;
      }
    });

    return hasError;
  }

  // validation for rank type
  validateRankQuestion(): any {
    let hasError = false;

    this.cols.forEach((choice, idx) => {
      const selectedChoice = [];
      choice.forEach(item => {
        if (item.isSelected === true) {
          selectedChoice.push(item);
        }
      });
      if (selectedChoice.length !== 1) {
        this.colErrors[idx] = true;
        this.updateMetaDataValidationCount.emit();
        hasError = true;
      } else {
        this.colErrors[idx] = false;
      }
    });

    return hasError;
  }

  onSkip(event: Event) {
    this.skip.emit(event);
  }

  onSubmit() {
    if (!this.gridHasError()) {
      this.rows.forEach(item => {
        delete item.isTouched;
        delete item.temp;
      });
      this.goFormSubmit.emit();
    } else {
      this.rows.map(item => {
        item.isTouched = true;
      });
    }
  }

  toggle(index: number) {
    index != this.isActiveTab
      ? (this.isActiveTab = index)
      : (this.isActiveTab = -1);
    document.getElementsByClassName('activeTab')[0].scrollIntoView();
  }
}
