import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-preview-cover-prerequisite',
  templateUrl: './preview-cover-prerequisite.component.html',
  styleUrls: ['./preview-cover-prerequisite.component.scss']
})
export class PreviewCoverPrerequisiteComponent {
  errorBarMessage: string;

  constructor(public modal: NgbActiveModal) {}

  getStarted(): void {
    this.modal.dismiss('start');
  }
}
