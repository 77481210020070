<div class="wrapper" #wrapper>
  <a [href]="skipLinkPathContent" class="sr-only">Skip to main content</a>
  <app-general-header [viewKind]="'signup'"></app-general-header>

  <ngb-progressbar [value]="0" class="progressbar-xs"></ngb-progressbar>

  <section class="anonymous" aria-label="main" role="main" id="main-content">
    <a [href]="skipLinkPathFooter" class="sr-only">Skip to footer</a>
    <article class="full-wrapper center-content">
      <div class="center-column">
        <h1>{{ 'auth.becomeAMember' | translate }}</h1>
        <p class="subheading">
          {{ 'auth.joinMoreThanOneMillion' | translate }}
        </p>
        <div class="actions-cta actions-top">
          <app-social-signin
            label="Sign up with Facebook"
            [currentCampaignKeys]="currentCampaignKeys"
            [currentPanelKeys]="currentPanelKeys"
          ></app-social-signin>
          <span class="divider"
            ><em>{{ 'general.or' | translate }}</em></span
          >
        </div>

        <form [formGroup]="form">
          <div class="form-row">
            <label>{{ 'auth.enterYourEmailAddress' | translate }}</label>
            <input
              formControlName="email"
              type="email"
              class="form-control"
              data-track="member-sign-up-enter-email"
            />
            <small
              *ngIf="fieldErrors('email')?.email"
              class="form-text text-muted danger"
            >
              {{ 'auth.pleaseEnterAValidEmailAddress' | translate }}
            </small>
          </div>
          <div #passwordFields>
            <div class="form-row">
              <label>{{ 'auth.createAPassword' | translate }}</label>
              <div class="passwordField">
                <span
                  *ngIf="isChange"
                  class="ic-eye"
                  (click)="toggleVisibility($event)"
                ></span>
                <input
                  formControlName="password"
                  type="password"
                  class="form-control"
                  (keyup)="onChangeHandler($event)"
                  data-track="member-sign-up-create-password"
                />
              </div>

              <div class="form-text hint-text">
                {{ 'auth.passwordHint' | translate }}
              </div>
            </div>
          </div>

          <div class="actions-cta">
            <button
              (click)="onSubmit()"
              type="submit"
              class="btn btn-primary"
              data-track="member-sign-up-continue"
              [disabled]="form.invalid || isLoading"
            >
              {{ 'general.continue' | translate }}
            </button>
            <p>
              <span
                [innerHTML]="'auth.alreadyACrowdtapMember' | translate"
              ></span>
              <a routerLink="/auth/login">{{ 'auth.login' | translate }}</a>
            </p>
          </div>
        </form>
      </div>
    </article>
  </section>
  <app-general-footer
    id="main-footer"
    [viewKind]="'signup'"
    (backToTop)="onBackToTop()"
  >
  </app-general-footer>
</div>
