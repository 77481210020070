import { Injectable } from '@angular/core';
import { DeviceService } from '@suzy/shared/tools/device';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { MetaData } from '../../core/models/meta-data.model';
import { DateFormatPipe } from 'ngx-moment';
import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import { AuthenticationService } from '@suzy/crowdtap/data-access/user';

@Injectable()
export class MetaDataService {
  constructor(
    private deviceService: DeviceService,
    private gtm: Angulartics2GoogleTagManager,
    private dateFormatPipe: DateFormatPipe,
    private sdk: SuzySdkService,
    private authService: AuthenticationService
  ) {}

  getWordCounts(words: string): number {
    return words.split(' ').length;
  }

  getCompletionTime(startDate: number, completedDate: number): string {
    const completed = Math.floor(completedDate / 1000);
    const start = Math.floor(startDate / 1000);
    const difference = completed - start;
    const minutes = Math.floor(difference / 60);
    const seconds = difference - minutes * 60;

    return `${minutes}:${seconds}`;
  }

  convertToDateTimeString(date: any): any {
    return this.dateFormatPipe.transform(date);
  }

  getDwellingTime(startTime: number, completedTime: number): number {
    return startTime - completedTime;
  }

  getUserId(): string {
    const userId = this.authService.getCurrentUserId();
    if (userId) {
      return userId;
    } else {
      return this.sdk.ProtocolAuthorize.getSelf(true).subscribe(user => {
        return user.item.user_id;
      });
    }
  }

  createMetaData(metaData: MetaData): void {
    metaData.userId = this.getUserId();
    metaData.completionTime = metaData.completedDateTime
      ? this.getCompletionTime(
          metaData.startDateTime,
          metaData.interactionDateTime
        )
      : null;
    metaData.startDateTime = metaData.startDateTime
      ? this.convertToDateTimeString(metaData.startDateTime)
      : null;
    metaData.completedDateTime = metaData.completedDateTime
      ? this.convertToDateTimeString(metaData.completedDateTime)
      : null;
    metaData.interactionDateTime = metaData.interactionDateTime
      ? this.convertToDateTimeString(metaData.interactionDateTime)
      : null;
    metaData.closedDateTime = metaData.closedDateTime
      ? this.convertToDateTimeString(metaData.closedDateTime)
      : null;
    metaData.skipDateTime = metaData.skipDateTime
      ? this.convertToDateTimeString(metaData.skipDateTime)
      : null;
    metaData.dwellingTime = metaData.dwellingTime
      ? this.getCompletionTime(
          metaData.startDateTime,
          metaData.completedDateTime
        )
      : null;
    metaData.deviceInfo = this.deviceService.getDevice();

    switch (metaData.type) {
      case 'open_ended':
        metaData.questionCounts = this.getWordCounts(metaData.text);
        metaData.responseCount = this.getWordCounts(metaData.response);
        break;
      case 'multiple_choice':
        metaData.numberOfOptions = metaData.numberOfOptions;
        metaData.optionPositions = metaData.optionPositions;
        break;
      default:
        break;
    }

    this.gtm.eventTrack(`js-${metaData.type}-question`, {
      event: `js-${metaData.type}-question`,
      gtmCustom: metaData
    });
  }
}
