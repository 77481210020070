import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@suzy/crowdtap/data-access/user';

@Component({
  selector: 'app-signout',
  templateUrl: './signout.component.html',
  styleUrls: ['./signout.component.scss']
})
export class SignoutComponent implements OnInit {
  constructor(private auth: AuthenticationService) {}

  ngOnInit(): void {
    this.auth.logout(true);
  }
}
