<div class="flex flex-col h-full">
  <div
    class="external-main-content items-center justify-center flex-1 p-6 text-center sm:px-20 md:px-24 md:py-8 dark:text-white bg-grey-FA dark:bg-box-dark"
  >
    <div class="modal-body action-modal-body">
      <div class="modal-content">
        <img
          *ngIf="logoPath"
          [src]="logoPath"
          [alt]="mission?.mission_logo_alt_text"
          class="logo"
        />
        <h2
          *ngIf="title"
          class="text-lg mb-2 font-semibold lg:font-bold lg:text-xl"
          [innerHTML]="title | markdown2html : { secure: true }"
        ></h2>
        <p
          *ngIf="description"
          [innerHTML]="description"
          class="text-base font-medium mb-6 md:mb-8"
        ></p>
        <button
          *ngIf="isPreview"
          [disabled]="isLoading"
          (click)="onRestartSurvey()"
        >
          Restart
        </button>
      </div>
    </div>
  </div>
</div>
