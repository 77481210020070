import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PreRollKind, MissionKind } from '@suzy/shared/data-access/suzy-sdk';
import { LightboxComponent } from '@suzy/shared/ui/lightbox';
import { MissionService } from '@suzy/shared/tools/mission';
declare var $;
@Component({
  selector: 'suzy-preroll',
  templateUrl: './preroll.component.html',
  styleUrls: ['./preroll.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrerollComponent {
  @Input() action;
  @Input() mission;
  @Input() preRollCaption;
  @Input() preRollKind;
  @Input() preRollUrl;
  @Output() prerollViewedEvent = new EventEmitter();
  @ViewChild('prerollLink') prerollLink: ElementRef;
  preRollKindImage: any;
  preRollKindLink: any;
  prerollViewCompleted: Boolean = false;
  prerollVisible: Boolean = false;
  isDarkMode: Boolean = false;
  guid: Promise<string>;

  constructor(
    private modals: NgbModal,
    private missionService: MissionService
  ) {
    this.preRollKindImage = PreRollKind.image;
    this.preRollKindLink = PreRollKind.link;
    this.isDarkMode = $('html').hasClass('dark');
  }

  onViewPrerollImage(): void {
    this.openLightbox();
    this.onPrerollViewed();
  }

  onPrerollViewed(): void {
    this.prerollViewCompleted = true;
    if (this.guid) {
      setTimeout(() => {
        this.guid.then((result: string) => {
          this.prerollViewedEvent.emit(result);
        });
      }, 2000);
    } else {
      this.prerollViewedEvent.emit();
    }
  }

  openLightbox(): void {
    let lightbox;

    if (document.body.classList.contains('isPreview')) {
      lightbox = this.modals.open(LightboxComponent, {
        windowClass: 'preview-mission-preroll',
        container: '#previewPreroll',
        backdrop: true
      });
    } else {
      lightbox = this.modals.open(LightboxComponent, { size: 'lg' });
    }

    const lightboxComponent = lightbox.componentInstance as LightboxComponent;
    lightboxComponent.imageUrl = this.preRollUrl;
    lightboxComponent.preroll_kind = this.preRollKind;
    lightboxComponent.preRollKindImage = this.preRollKindImage;
    this.guid = lightboxComponent.getGuid;
  }
}
