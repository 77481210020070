<div class="wrapper" #wrapper>
  <app-general-header viewKind="signup"></app-general-header>

  <section class="anonymous" aria-label="main" role="main" id="main-content">
    <article
      class="flex flex-col w-full px-4 m-auto mt-6 max-w-auth sm:max-w-sm-auth"
    >
      <div class="flex overflow-hidden text-xs rounded progress-bar bg-gray">
        <div
          [ngStyle]="{ width: user.progressStatus + '%' }"
          class="flex flex-col justify-center text-center text-white shadow-none whitespace-nowrap bg-blue-dark"
        ></div>
      </div>
      <div class="center-column">
        <h1
          class="mt-6 mb-8 text-4xl font-bold text-center text-light-black dark:text-white sm:text-3xl"
        >
          {{ 'gettingToKnowYou.letsGetToKnowYou' | translate }}
        </h1>
        <p
          class="mt-6 mb-8 text-center subtitle text-light-black dark:text-white"
        >
          {{ 'gettingToKnowYou.letsGetToKnowYouDescription' | translate }}
        </p>

        <form [formGroup]="getToKnowYouForm" (ngSubmit)="onSubmit()">
          <div class="mb-6 form-row">
            <label class="text-light-black dark:text-white">
              {{ 'profile.firstName' | translate }}
            </label>

            <div #firstName>
              <input
                type="text"
                class="block w-full p-2 mt-2 border rounded-sm input-edge text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
                formControlName="first_name"
                autocomplete="given-name"
                placeholder="{{ 'profile.firstName' | translate }}"
                data-track="member-sign-up-first-name"
              />
            </div>
            <small
              *ngIf="fieldErrors('first_name')"
              class="form-text text-muted danger"
            >
              <span *ngIf="fieldErrors('first_name').required">
                {{ 'general.required' | translate }}
              </span>
            </small>
          </div>
          <div class="mb-6 form-row">
            <label class="text-light-black dark:text-white">
              {{ 'profile.lastName' | translate }}
            </label>

            <div #lastName>
              <input
                type="text"
                class="block w-full p-2 mt-2 border rounded-sm input-edge text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
                formControlName="last_name"
                placeholder="{{ 'profile.lastName' | translate }}"
                data-track="member-sign-up-last-name"
              />
            </div>
            <small
              *ngIf="fieldErrors('last_name')"
              class="form-text text-muted danger"
            >
              <span *ngIf="fieldErrors('last_name').required">
                {{ 'general.required' | translate }}
              </span>
            </small>
          </div>

          <div class="mb-6 form-row">
            <label class="text-light-black dark:text-white">
              {{ 'auth.emailAddress' | translate }}
            </label>

            <div #email>
              <input
                type="text"
                class="block w-full p-2 mt-2 border rounded-sm input-edge text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
                formControlName="email"
                placeholder="{{ 'auth.yourEmailAddress' | translate }}"
                data-track="member-sign-up-user-email"
              />
            </div>
            <small
              *ngIf="fieldErrors('email')"
              class="form-text text-muted danger"
            >
              <span *ngIf="fieldErrors('email').email">
                {{ 'auth.pleaseEnterAValidEmailAddress' | translate }}
              </span>
              <span *ngIf="fieldErrors('email').server">
                {{ fieldErrors('email').server }}
              </span>
              <span *ngIf="fieldErrors('email').required">
                {{ 'general.required' | translate }}
              </span>
            </small>
          </div>

          <section class="flex justify-center mt-6 space-x-8 text-center">
            <button
              type="button"
              class="text-black action-btn dark:text-white max-w-38"
              (click)="returnToEmailCreate()"
            >
              {{ 'auth.back' | translate }}
            </button>
            <button
              type="submit"
              (click)="onSubmit()"
              data-track="member-sign-up-continue"
              class="relative pl-4 pr-4 font-bold text-white rounded-full bg-blue-dark h-14 action-btn"
              [disabled]="isLoading"
            >
              {{ 'general.next' | translate }}
              <div class="absolute left-8 button-spinner top-7">
                <suzy-spinner
                  *ngIf="isLoading"
                  size="small"
                  button="true"
                  [round]="10"
                  [color]="'#ffffff'"
                  [overlay]="false"
                >
                </suzy-spinner>
              </div>
            </button>
          </section>
        </form>
      </div>
    </article>
  </section>
  <section class="footer-contianer">
    <app-general-footer id="main-footer"></app-general-footer>
  </section>
</div>
