<a [href]="skipLinkPathContent" class="sr-only">Skip to main content</a>

<div class="wrapper" #wrapper>
  <app-general-header [headerPosition]="'relative'"></app-general-header>

  <ngb-progressbar [value]="50" class="progressbar-xs"></ngb-progressbar>

  <section class="anonymous" aria-label="main" role="main" id="main-content">
    <a [href]="skipLinkPathFooter" class="sr-only">Skip to footer</a>

    <article class="full-wrapper center-content">
      <div class="center-column">
        <h1>{{ 'gettingToKnowYou.tellUsAboutYourself' | translate }}</h1>
        <p class="subheading">
          {{ 'gettingToKnowYou.tellUsAboutYourselfSubheading' | translate }}
        </p>

        <form [formGroup]="form">
          <div class="form-row">
            <div class="half-col">
              <label>{{ 'profile.firstName' | translate }}</label>
              <input
                type="text"
                class="form-control"
                formControlName="first_name"
                autocomplete="given-name"
                data-track="member-sign-up-first-name"
              />
              <small
                *ngIf="fieldErrors('first_name')"
                class="form-text text-muted danger"
              >
                <span *ngIf="fieldErrors('first_name').required">
                  {{ 'general.required' | translate }}
                </span>
              </small>
            </div>

            <div class="half-col">
              <label>{{ 'profile.lastName' | translate }}</label>
              <input
                type="text"
                class="form-control"
                formControlName="last_name"
                autocomplete="family-name"
                data-track="member-sign-up-last-name"
              />
              <small
                *ngIf="fieldErrors('last_name')"
                class="form-text text-muted danger"
              >
                <span *ngIf="fieldErrors('last_name').required">
                  {{ 'general.required' | translate }}
                </span>
              </small>
            </div>
          </div>

          <div class="form-row">
            <label>{{ 'auth.email' | translate }}</label>
            <input
              type="email"
              class="form-control"
              formControlName="email"
              data-track="member-sign-up-user-email"
            />
            <small
              *ngIf="fieldErrors('email')"
              class="form-text text-muted danger"
            >
              <span *ngIf="fieldErrors('email').server">
                {{ fieldErrors('email').server }}
              </span>
              <span *ngIf="fieldErrors('email').required">
                {{ 'general.required' | translate }}
              </span>
            </small>
          </div>

          <div class="form-row">
            <div class="form-col-3">
              <label>{{ 'address.city' | translate }}</label>
              <input
                type="text"
                class="form-control"
                formControlName="city"
                data-track="member-sign-up-city"
              />
              <small
                *ngIf="fieldErrors('city')"
                class="form-text text-muted danger"
              >
                <span *ngIf="fieldErrors('city').server">
                  {{ fieldErrors('city').server }}
                </span>
                <span *ngIf="fieldErrors('city').required">
                  {{ 'general.required' | translate }}
                </span>
              </small>
            </div>

            <div class="form-col-3">
              <label>{{ 'address.state' | translate }}</label>
              <div class="custom-select">
                <select
                  class="form-control"
                  formControlName="state_id"
                  data-track="member-sign-up-state"
                >
                  <option></option>
                  <option *ngFor="let state of stateOptions" [value]="state.id">
                    {{ state.short }}
                  </option>
                </select>
              </div>
              <small
                *ngIf="fieldErrors('state_id')"
                class="form-text text-muted danger"
              >
                <span *ngIf="fieldErrors('state_id').server">
                  {{ fieldErrors('state_id').server }}
                </span>
                <span *ngIf="fieldErrors('state_id').required">
                  {{ 'general.required' | translate }}
                </span>
              </small>
            </div>

            <div class="form-col-3">
              <label>{{ 'address.zipCode' | translate }}</label>
              <input
                type="text"
                class="form-control"
                formControlName="zip_code"
                data-track="member-sign-up-zip-code"
              />
              <small
                *ngIf="fieldErrors('zip_code')"
                class="form-text text-muted danger"
              >
                <span *ngIf="fieldErrors('zip_code').server">
                  {{ fieldErrors('zip_code').server }}
                </span>
                <span *ngIf="fieldErrors('zip_code').required">
                  {{ 'general.required' | translate }}
                </span>
              </small>
            </div>
          </div>

          <div class="form-row">
            <div class="half-col">
              <label>{{ 'gettingToKnowYou.gender' | translate }} </label>
              <div class="custom-select">
                <select
                  class="form-control"
                  formControlName="gender"
                  data-track="member-sign-up-gender"
                >
                  <option value="{{ Gender.unknown }}">
                    {{ 'gettingToKnowYou.selectGender' | translate }}
                  </option>
                  <option value="{{ Gender.female }}">
                    {{ 'gettingToKnowYou.female' | translate }}
                  </option>
                  <option value="{{ Gender.male }}">
                    {{ 'gettingToKnowYou.male' | translate }}
                  </option>
                </select>
              </div>
              <small
                *ngIf="fieldErrors('gender')"
                class="form-text text-muted danger"
              >
                <span *ngIf="fieldErrors('gender').server">
                  {{ fieldErrors('gender').server }}
                </span>
                <span *ngIf="fieldErrors('gender').required">
                  {{ 'general.required' | translate }}
                </span>
              </small>
            </div>

            <div class="half-col">
              <label>{{ 'gettingToKnowYou.birthday' | translate }}</label>
              <input
                type="text"
                class="form-control"
                placeholder="yyyy-mm-dd"
                name="dp"
                formControlName="birthdate"
                ngbDatepicker
                #d="ngbDatepicker"
                [minDate]="minBirthdate"
                data-track="member-sign-up-birthday"
              />
              <button class="btn inner-btn" type="button" (click)="d.toggle()">
                <i class="ft-calendar"></i>
              </button>
              <small
                *ngIf="fieldErrors('birthdate')"
                class="form-text text-muted danger"
              >
                <span *ngIf="fieldErrors('birthdate').server">
                  {{ fieldErrors('birthdate').server }}
                </span>
                <span *ngIf="fieldErrors('birthdate').required">
                  {{ 'general.required' | translate }}
                </span>
              </small>
            </div>
          </div>
          <div class="form-row">
            <input type="checkbox" id="check-1" formControlName="accepted" />
            <label class="custom-checkbox" for="check-1">
              <span
                [innerHTML]="
                  'termsOfService.bySigningUpForCrowdtap' | translate
                "
              ></span>
              <a
                routerLink="/terms-of-service"
                target="_blank"
                data-track="member-sign-up-terms-of-service"
                >{{ 'nav.termsOfService' | translate }}
              </a>
              {{ 'general.and' | translate }}
              <a routerLink="/privacy-policy" target="_blank">
                {{ 'nav.privacyPolicy' | translate }}</a
              >.
              <span
                [innerHTML]="
                  'termsOfService.iUnderstandCrowdtapMayKeepMeInformed'
                    | translate
                "
              ></span>
              <span
                class="check-icon"
                [ngClass]="{ error: fieldErrors('accepted')?.required }"
              ></span>
            </label>
          </div>

          <div class="actions-cta">
            <button
              type="submit"
              (click)="onSubmit()"
              [disabled]="form.invalid || isLoading"
              class="btn"
              data-track="reg2-btn-finish"
            >
              {{ 'auth.createMyAccount' | translate }}
            </button>
          </div>
        </form>
      </div>
    </article>
  </section>

  <app-general-footer
    id="main-footer"
    (backToTop)="onBackToTop()"
  ></app-general-footer>
</div>
