<div #wrapper>
  <app-general-header> </app-general-header>
  <div class="legal">
    <!-- Main Content Starts -->
    <div class="main-content" aria-label="main" role="main">
      <section class="legal-copy">
        <!-- Copy Starts -->
        <h1>{{ legalData.caption }}</h1>
        <div [innerHTML]="legalData.content"></div>
        <!-- Copy Ends -->
        <a class="back-to-top-link" (click)="onBackToTop()" #backToTopLink>
          <span class="icon"></span>{{ 'nav.backToTop' | translate }}
        </a>
      </section>

      <div class="support-question">
        <h2>{{ 'support.supportAndQuestions' | translate }}</h2>
        <p>
          {{ 'support.respondToQuestions' | translate }}
        </p>
        <p>
          {{ 'support.whatYoureLookingFor' | translate }}
          <a
            href="mailto:support@crowdtap.com?subject=Privacy%20Policy%20Inquiry"
            >{{ 'general.contactUs' | translate }}</a
          >.
        </p>
      </div>
    </div>
    <!-- Main Content Ends -->
  </div>
  <app-general-footer (backToTop)="onBackToTop()"> </app-general-footer>
</div>
