<a [href]="skipLinkPathContent" class="sr-only">Skip to main content</a>

<div class="wrapper" #wrapper>
  <app-general-header> </app-general-header>

  <section aria-label="main" role="main" id="main-content">
    <ng-container *ngIf="isPassSubmission; else showForm">
      <app-success-changed></app-success-changed>
    </ng-container>
    <ng-template #showForm>
      <a [href]="skipLinkPathFooter" class="sr-only">Skip to footer</a>
      <article
        class="flex flex-col w-full max-w-lg px-4 m-auto lg:px-8 md:w-2/5"
      >
        <h1 class="text-light-black dark:text-white">
          {{ 'auth.resetPassword' | translate }}
        </h1>

        <form [formGroup]="recoverForm" (ngSubmit)="onSubmit()">
          <div class="hidden form-row d-none">
            <label>{{ 'auth.token' | translate }}</label>
            <input
              type="token"
              class="block w-full p-2 mt-2 border rounded-sm text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
              formControlName="token"
            />
          </div>
          <div class="form-row">
            <label class="text-light-black dark:text-white"
              >{{ 'auth.emailAddress' | translate }}
            </label>
            <input
              type="email"
              class="block w-full p-2 mt-2 border rounded-sm text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
              placeholder="Email address"
              formControlName="email"
            />
            <small class="form-text text-muted danger">
              <span *ngIf="fieldErrors('email')?.required">
                {{ 'auth.emailIsRequired' | translate }}
              </span>
              <span
                *ngIf="fieldErrors('email')?.email"
                class="form-text text-muted danger"
              >
                {{ 'auth.invalidEmailAddress' | translate }}
              </span>
            </small>
          </div>
          <div class="mt-6 password-fields" #passwordFields>
            <div class="form-row">
              <label class="text-light-black dark:text-white">
                {{ 'auth.newPassword' | translate }}
              </label>
              <div class="passwordField">
                <span
                  *ngIf="isChange"
                  class="ic-eye"
                  (click)="toggleVisibility($event)"
                ></span>
                <input
                  type="password"
                  class="block w-full p-2 mt-2 border rounded-sm text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
                  formControlName="password"
                  placeholder="{{ 'auth.enterPassword' | translate }}"
                  (keyup)="onChangeHandler($event)"
                />
                <small class="form-text text-muted danger">
                  <span *ngIf="fieldErrors('password')?.required">
                    {{ 'auth.passwordIsRequired' | translate }}
                  </span>
                  <span
                    *ngIf="
                      fieldErrors('password')?.uppercase ||
                      fieldErrors('password')?.lowercase ||
                      fieldErrors('password')?.nonletter ||
                      fieldErrors('password')?.minlength ||
                      fieldErrors('password')?.maxlength
                    "
                    class="form-text text-muted danger"
                  >
                    Invalid password
                  </span>
                </small>
              </div>
              <p class="mt-6 text-light-black dark:text-white">
                {{ 'auth.passwordHint' | translate }}
              </p>
            </div>
          </div>

          <div class="text-center actions-cta">
            <button
              type="submit"
              data-track="member-login-login-btn"
              class="relative w-64 mb-4 font-bold text-white rounded-full h-14 bg-blue-dark"
            >
              {{ 'auth.updatePassword' | translate }}
              <div class="absolute left-8 button-spinner top-7">
                <suzy-spinner
                  *ngIf="submitting"
                  size="small"
                  button="true"
                  [round]="10"
                  [color]="'#ffffff'"
                  [overlay]="false"
                >
                </suzy-spinner>
              </div>
            </button>
          </div>
        </form>
      </article>
    </ng-template>
  </section>

  <app-general-footer id="main-footer" (backToTop)="onBackToTop()">
  </app-general-footer>
</div>
