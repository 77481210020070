import { Injectable, OnDestroy } from '@angular/core';
import {
  Params,
  NavigationEnd,
  ActivatedRoute,
  Router,
  ActivatedRouteSnapshot
} from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs/';
import { filter, takeUntil } from 'rxjs/operators';

interface IRoutePart {
  title: string;
  params?: Params;
  url: string;
  urlSegments: Array<any>;
}
export interface IMenuItem {
  id: string;
  path: Array<string>;
  title: string;
  icon: string;
  class: string;
  badge?: string;
  badgeClass: string;
  isExternalLink?: boolean;
  submenu: Array<IMenuItem>;
  verifiedOnly: boolean;
  v1BetaOnly: boolean;
}

@Injectable()
export class NavigationService implements OnDestroy {
  menuItems$: Observable<Array<IMenuItem>>;
  routeParams: Params;
  unsubscribe$: Subject<void> = new Subject<void>();
  private defaultMenu: Array<IMenuItem>;
  private menuItemsSubject$: BehaviorSubject<Array<IMenuItem>>;

  constructor(private activeRoute: ActivatedRoute, private router: Router) {
    this.defaultMenu = [
      {
        id: 'home',
        path: this.DASHBOARD(),
        title: 'Home',
        icon: 'ic-home',
        class: '',
        badgeClass: '',
        submenu: []
      },
      // {
      //   id: 'history',
      //   path: this.HISTORY(),
      //   title: 'History',
      //   icon: 'ft-activity',
      //   class: '',
      //   badgeClass: '',
      //   submenu: []
      // },
      {
        id: 'rewards',
        path: this.REWARDS(),
        title: 'Rewards',
        icon: 'ic-gift',
        class: '',
        badgeClass: '',
        submenu: [],
        v1BetaOnly: true
      }
    ] as Array<IMenuItem>;

    this.menuItemsSubject$ = new BehaviorSubject<Array<IMenuItem>>(
      this.defaultMenu
    );
    this.menuItems$ = this.menuItemsSubject$.asObservable();
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        this.routeParams = this.generateParams(this.activeRoute.snapshot);
      });
  }

  DASHBOARD(): Array<string> {
    return ['/dashboard'];
  }

  PROFILE(): Array<string> {
    return ['/profile'];
  }

  REWARDS(): Array<string> {
    return ['/rewards/overview'];
  }

  NOTIFICATION(): Array<string> {
    return ['/notification'];
  }

  HISTORY(): Array<string> {
    return ['/history'];
  }

  FOCUSGROUP(): Array<string> {
    return ['/focusgroup'];
  }

  LOGOUT(): Array<string> {
    return ['/auth/signout'];
  }

  // Invoked by authorization service
  onUserChanged(user: any): void {
    const menu = this.defaultMenu;
    // do any user specific menu adjustments here
    this.menuItemsSubject$.next(menu);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.menuItemsSubject$.complete();
  }

  // used for cookie crumb, if implemented
  private generateRouteParts(
    snapshot: ActivatedRouteSnapshot
  ): Array<IRoutePart> {
    let routeParts = <IRoutePart[]>[];
    if (snapshot) {
      if (snapshot.firstChild) {
        routeParts = routeParts.concat(
          this.generateRouteParts(snapshot.firstChild)
        );
      }
      if (snapshot.data['title'] && snapshot.url.length) {
        routeParts.push({
          title: snapshot.data['title'],
          url: snapshot.url[0].path,
          urlSegments: snapshot.url,
          params: snapshot.params
        });
      }
    }

    return routeParts;
  }

  private generateParams(snapshot: ActivatedRouteSnapshot): Params {
    let result = {};
    if (snapshot) {
      if (snapshot.firstChild) {
        result = Object.assign(
          {},
          result,
          this.generateParams(snapshot.firstChild)
        );
      }
      if (snapshot.params) {
        result = Object.assign({}, result, snapshot.params);
      }
    }

    return result;
  }
}
