import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalIsolateSdkService } from '@suzy/shared/data-access/global-isolate-sdk';
import { environment } from 'apps/crowdtap/src/environments/environment';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ExternalEndingComponent } from './shared/external-ending/external-ending.component';
import { PreviewService } from '../preview/preview.service';
import { SegmentService } from '@suzy/shared/data-access/tracking';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class ExternalLinkService {
  constructor(
    private isolateSDK: GlobalIsolateSdkService,
    private modals: NgbModal,
    private previewService: PreviewService,
    private segmentService: SegmentService,
    private route: ActivatedRoute
  ) {}

  validateLink(params: any, anonymousId: string): Observable<any> {
    let sharedLinkUrl: string;
    if (environment.local) {
      const localId = this.route.snapshot.queryParamMap.get('id');
      sharedLinkUrl = `${environment.sharedLinkRoot}/${params.brand_id}/${params.shared_link_id}`;
      // Local Id to test the same mission multiple times
      if (localId) {
        anonymousId = `${Math.floor(Math.random() * 900000)}`;
      }
    } else {
      sharedLinkUrl = window.location.href;
    }
    return this.isolateSDK.Auth.validateExternalLinkAsync({
      brand_id: params.brand_id,
      mission_id: params.shared_link_id,
      external_link_url: sharedLinkUrl,
      fingerprint: anonymousId
    });
  }

  getAnonymousId(): Observable<any> {
    return this.segmentService.getAnonymousId();
  }

  getAvailableMissions(): Observable<any> {
    return this.isolateSDK.Mission.getAvailableMissionsAsync().pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  getFullMission(refresh = false): Observable<any> {
    return this.isolateSDK.Mission.getFullMission(refresh).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  showEndingComponent(
    data: any,
    mission: any,
    isPreview: boolean,
    isExternal: boolean
  ): Subject<any> {
    const subject = new Subject<any>();
    let containerId: string;
    if (isPreview) {
      containerId = '#previewMission';
      this.previewService.activeSurveyPanel = true;
    }

    if (isExternal) {
      containerId = '#externalMission';
    }

    let endingModal = this.modals.open(ExternalEndingComponent, {
      windowClass: 'preview-mission-container app-external-ending',
      container: containerId,
      backdrop: false
    });

    const endingComponent =
      endingModal.componentInstance as ExternalEndingComponent;
    const logoUrl = mission?.mission_logo?.thumbs?.admin?.url;
    const defaultLogo = '/assets/img/external/external-link-logo.svg';
    let description: string;
    let title: string;

    if (data.description) {
      description = data.description;
    } else {
      if (data.redirect_location) {
        description = `<a href="${data.redirect_location}">Click here</a> if you're not automatically redirected.`;
      }
    }

    if (data.title) {
      title = data.title;
    } else if (data.message) {
      title = data.message;
    }

    endingComponent.title = title ? title : 'Thank you for participating!';
    endingComponent.redirectUrl = data.redirect_location;
    endingComponent.logoPath = logoUrl ? logoUrl : defaultLogo;
    endingComponent.description = description;
    endingComponent.mission = mission;

    endingModal.result.then(
      data => {},
      reason => {
        if (reason) {
          subject.next('refresh');
        }
      }
    );
    return subject;
  }
}
