import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs/';
import { map } from 'rxjs/operators';
import {
  SuzySdkService,
  RewardCategory
} from '@suzy/shared/data-access/suzy-sdk';

@Injectable()
export class TrackRewardsResolver implements Resolve<Observable<any>> {
  category: number;
  constructor(private sdk: SuzySdkService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    switch (route.data.title) {
      case 'Gift Card':
        this.category = RewardCategory.gift_card;
        break;
      case 'Subscriptions':
        this.category = RewardCategory.subscription;
        break;
      case 'Charities':
        this.category = RewardCategory.charity;
        break;
      default:
        this.category = RewardCategory.gift_card;
        break;
    }

    return this.sdk.ProtocolRedeem.getCompositeRewardsAsync(this.category).pipe(
      map((response: any) => {
        if (response.success) {
          return response.items[0].rewards;
        } else {
          throw new Error(response.message);
        }
      })
    );
  }
}
