import { AppAlertService } from './app-alert.service';
import { NgModule } from '@angular/core';
import { AppAlertNotifyComponent } from './app-alert-notify.component';

@NgModule({
  imports: [],
  exports: [AppAlertNotifyComponent],
  declarations: [AppAlertNotifyComponent],
  providers: [AppAlertService]
})
export class AppAlertModule {}
