<!-- Action Modal Starts -->
<div
  class="flex flex-col h-full action-modal-container"
  [attr.data-track]="
    'member-custom-grid-col-' + cols.length + '-row-' + rows.length
  "
>
  <div class="head-container">
    <app-action-modal-header
      [mission]="mission"
      [metaData]="metaData"
      [disableButton]="mission?.isGlobal"
    ></app-action-modal-header>

    <!-- Action Modal Question Starts -->
    <div
      [ngClass]="{ 'pt-12': errorMessage && mission?.isGlobal }"
      class="border-b-2 dark:bg-body-dark bg-body-light border-card-{{
        activeColor
      }} dark:text-white md:pt-0 md:px-24 md:py-8 p-6 pt-0 sm:px-20"
    >
      <h3
        class="text-base font-medium md:text-lg md:font-semibold lg:text-xl lg:font-medium"
        [innerHTML]="action.grid.question | markdown2html : { secure: true }"
        data-track="member-custom-grid-question-text"
      ></h3>
      <div
        class="mt-4 text-xs font-semibold opacity-50 md:opacity-100 subtitle mb-4"
        *ngIf="chooseExactlyOneChoice()"
        data-track="member-custom-grid-subtitle"
      >
        {{ 'grid.chooseExactlyOne' | translate }}
      </div>
      <div
        class="mt-4 mb-4 text-xs font-semibold opacity-50 md:opacity-100 subtitle"
        *ngIf="chooseAtLeastXChoices() && !chooseBetweenXandYChoices()"
        data-track="member-custom-grid-subtitle"
      >
        {{
          'grid.chooseAtLeastX' | translate : { min: action.grid.open_row_min }
        }}
      </div>
      <div
        class="mt-4 mb-4 text-xs font-semibold opacity-50 md:opacity-100 subtitle"
        *ngIf="chooseExactlyXChoices()"
        data-track="member-custom-grid-subtitle"
      >
        {{
          'grid.chooseExactlyX' | translate : { max: action.grid.open_row_max }
        }}
      </div>
      <div
        class="mt-4 mb-4 text-xs font-semibold opacity-50 md:opacity-100 subtitle"
        *ngIf="chooseUpToXChoices() && !chooseBetweenXandYChoices()"
        data-track="member-custom-grid-subtitle"
      >
        {{ 'grid.chooseUpToX' | translate : { max: action.grid.open_row_max } }}
      </div>
      <div
        class="mt-4 mb-4 text-xs font-semibold opacity-50 md:opacity-100 subtitle"
        *ngIf="chooseBetweenXandYChoices()"
        data-track="member-custom-grid-subtitle"
      >
        {{
          'grid.chooseBetweenX'
            | translate
              : { min: action.grid.open_row_min, max: action.grid.open_row_max }
        }}
      </div>
      <suzy-preroll-secondary-trigger
        *ngIf="hasPreroll && prerollViewed"
        [prerollKind]="preRollKind"
        [prerollUrl]="preRollUrl"
        (imagePrerollClick)="onViewPrerollImage($event)"
        (linkPrerollClick)="onViewPrerollLink()"
      >
      </suzy-preroll-secondary-trigger>
    </div>
    <!-- Action Modal Question Ends -->
  </div>
  <!-- Action Modal Body Starts -->
  <div
    #modalBody
    class="flex-1 sm:px-20 md:px-24 md:py-8 dark:text-white action-body-container modal-body action-modal-body"
    (scroll)="divScrolled($event)"
  >
    <suzy-preroll
      (prerollViewedEvent)="onPrerollViewedEvent($event)"
      *ngIf="hasPreroll && !prerollViewed"
      [preRollKind]="preRollKind"
      [preRollCaption]="preRollCaption"
      [preRollUrl]="preRollUrl"
      [mission]="mission"
      [action]="action"
    ></suzy-preroll>

    <suzy-action-forms-grid-open
      id="gridOpen"
      #gridOpen
      [action]="action"
      [disableSkip]="
        disableSkip || mission.mission_kind === missionKind.external_link
      "
      [fakeCorner]="fakeCorner"
      [rows]="rows"
      [cols]="cols"
      [isLoading]="isLoading"
      [isSuccess]="isSuccess"
      [isSubmitting]="isSubmitting"
      [rowErrors]="rowErrors"
      [colErrors]="colErrors"
      (timeToActionUpdate)="onTimeToActionUpdate($event)"
      (updateMetaDataValidationCount)="onUpdateMetaDataValidationCount()"
      (skip)="onSkip($event)"
      (mouseEvent)="onMouseMoveEvent($event)"
      (goFormSubmit)="onSubmit()"
      [(errorMessage)]="errorMessage"
      *ngIf="!hasPreroll || (hasPreroll && prerollViewed)"
      [disableButton]="mission?.isGlobal"
      [isSkipping]="isSkipping"
      (scrollEvent)="onScrollEvent()"
    ></suzy-action-forms-grid-open>
  </div>
  <!-- Action Modal Body Ends -->
</div>
<!-- Action Modal Ends -->
