<!-- //////////////////////////////////////////////////////////////////////////// -->
<!-- START Notification Sidebar -->
<aside
  id="notification-sidebar"
  class="notification-sidebar d-none d-sm-none d-md-block"
>
  <a class="notification-sidebar-close">
    <svg viewBox="0 0 24 24" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.707 1.70703C14.0977 1.31665 14.0977 0.68335 13.707 0.292969C13.3164 -0.0976562 12.6836 -0.0976562 12.293 0.292969L7 5.58594L1.70703 0.292969C1.31641 -0.0976562 0.683594 -0.0976562 0.292969 0.292969C-0.0976562 0.68335 -0.0976562 1.31665 0.292969 1.70703L5.58594 7L0.292969 12.293C-0.0976562 12.6833 -0.0976562 13.3167 0.292969 13.707C0.683594 14.0977 1.31641 14.0977 1.70703 13.707L7 8.41406L12.293 13.707C12.6836 14.0977 13.3164 14.0977 13.707 13.707C14.0977 13.3167 14.0977 12.6833 13.707 12.293L8.41406 7L13.707 1.70703Z"
        transform="translate(5 5)"
      />
    </svg>
  </a>
  <div class="side-nav notification-sidebar-content">
    <h2>{{ 'notifications.notifications' | translate }}</h2>
    <div class="notification-sidebar-content-inner">
      <div class="temp-notice">
        <div class="img">
          <img
            src="/assets/img/svg/icons/ic-bell-gray-lg.svg"
            alt="Notification icon"
          />
        </div>
        <div class="text">
          <h6>{{ 'notifications.noNotifications' | translate }}</h6>
          <p>{{ 'notifications.checkBackSoon' | translate }}</p>
        </div>
      </div>
      <div class="notification-sidebar-footer">
        <a href="javascript:;">{{
          'notifications.clearNotifications' | translate
        }}</a>
      </div>
    </div>
  </div>
</aside>
<!-- END Notification Sidebar -->
