import swal from 'sweetalert2';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';
import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import { PasswordValidators } from '../../../views/anonymous/signup/account-creation-email/PasswordValidators';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-lost-password',
  templateUrl: './lost-password.component.html',
  styleUrls: ['./lost-password.component.scss']
})
export class LostPasswordComponent implements OnInit, AfterViewInit {
  @ViewChild('passwordFields', { static: true }) passwordFields: ElementRef;
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef;
  el_passwordFields: HTMLElement;
  el_wrapper: HTMLElement;

  submitted: boolean;
  submitting: boolean;
  token: string;
  recoverForm: UntypedFormGroup;
  isPassSubmission: boolean;
  isFailSubmission: boolean;
  isChange = false;
  skipLinkPathContent: string;
  skipLinkPathFooter: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sdk: SuzySdkService,
    private formBuilder: UntypedFormBuilder,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.setHeadTitle();
    this.skipLinkPathContent = `${this.router.url}#main-content`;
    this.skipLinkPathFooter = `${this.router.url}#main-footer`;
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
    });
    this.recoverForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(50),
          PasswordValidators.password
        ]
      ],
      token: [this.token, [Validators.required]]
    });
  }

  ngAfterViewInit(): void {
    this.el_passwordFields = this.passwordFields.nativeElement as HTMLElement;
    this.el_wrapper = this.wrapper.nativeElement as HTMLElement;
  }

  setHeadTitle(): any {
    this.titleService.setTitle('Crowdtap® - Lost Password');
  }

  onChangeHandler(e): void {
    this.checkInput(e);
  }

  fieldErrors(name: string): any {
    const control = this.recoverForm.get(name);

    if (control && (control.touched || this.submitted) && control.errors) {
      return control.errors;
    } else {
      return undefined;
    }
  }

  checkInput(e): void {
    if (e.target.value.length > 0) {
      this.el_passwordFields?.classList.add('ng-changed');
      this.isChange = true;
    } else {
      this.el_passwordFields?.classList.remove('ng-changed');
      this.isChange = false;
    }
  }

  toggleVisibility(e): void {
    const inputs = this.el_passwordFields.querySelectorAll('input');
    const icons = Array.from(
      this.el_passwordFields.querySelectorAll('.ic-eye')
    );
    if (e.target.nextElementSibling.type === 'password') {
      inputs[0].type = 'text';
      inputs[1].type = 'text';
      icons.forEach(icon => {
        icon.classList.add('isVisible');
      });
    } else {
      inputs[0].type = 'password';
      inputs[1].type = 'password';
      icons.forEach(icon => icon.classList.remove('isVisible'));
    }
  }

  successAnimation(): void {
    this.isPassSubmission = true;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.recoverForm.invalid) {
      return;
    }

    this.submitting = true;
    const input = this.recoverForm.value;

    this.sdk.ProtocolAuthorize.passwordResetComplete(input).subscribe(
      data => {
        if (data.success) {
          this.successAnimation();
        } else {
          this.submitting = false;
          swal.fire({
            buttonsStyling: false,
            confirmButtonText: 'Continue',
            confirmButtonClass: 'btn-confirm',
            customClass: 'general-modal',
            showConfirmButton: true,
            text: data.message,
            title: 'Error',
            type: 'warning'
          });
        }
      },
      error => {
        this.submitting = false;
        swal.fire({
          buttonsStyling: false,
          confirmButtonText: 'Continue',
          confirmButtonClass: 'btn-confirm',
          customClass: 'general-modal',
          showConfirmButton: true,
          text: error,
          title: 'Error',
          type: 'warning'
        });
      }
    );
  }

  onBackToTop(): void {
    this.el_wrapper.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    });
  }
}
