import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'accessibility-statement-policy-current',
  templateUrl: './accessibility-statement-policy-current.component.html',
  styleUrls: ['./accessibility-statement-policy-current.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccessibilityStatementPolicyCurrentComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
