import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs/';
import { LegalServices } from '../../core/services/legal.service';

@Injectable()
export class PrivacyResolver implements Resolve<Observable<any>> {
  constructor(private legalService: LegalServices) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    let language = navigator.language.toLowerCase();
    language = language.split('-')[0];
    if (language === 'es') {
      return this.legalService.getPublicLegal(
        'crowdtap-privacy-policy-spanish'
      );
    }

    return this.legalService.getPublicLegal('crowdtap-privacy-policy');
  }
}

@Injectable()
export class TermsResolver implements Resolve<Observable<any>> {
  constructor(private legalService: LegalServices) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    let language = navigator.language.toLowerCase();
    language = language.split('-')[0];
    if (language === 'es') {
      return this.legalService.getPublicLegal(
        'crowdtap-terms-of-service-spanish'
      );
    }

    return this.legalService.getPublicLegal('crowdtap-terms-of-service');
  }
}

@Injectable()
export class CookiesResolver implements Resolve<Observable<any>> {
  constructor(private legalService: LegalServices) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    let language = navigator.language.toLowerCase();
    language = language.split('-')[0];
    if (language === 'es') {
      return this.legalService.getPublicLegal('crowdtap-cookie-policy-spanish');
    }

    return this.legalService.getPublicLegal('crowdtap-cookie-policy');
  }
}

@Injectable()
export class ReferResolver implements Resolve<Observable<any>> {
  constructor(private legalService: LegalServices) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this.legalService.getPublicLegal('crowdtap-refer');
  }
}

@Injectable()
export class AccessibilityStatementResolver
  implements Resolve<Observable<any>> {
  constructor(private legalService: LegalServices) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    let language = navigator.language.toLowerCase();
    language = language.split('-')[0];
    if (language === 'es') {
      return this.legalService.getPublicLegal(
        'crowdtap-accessibility-statement-spanish'
      );
    }

    return this.legalService.getPublicLegal('crowdtap-accessibility-statement');
  }
}
