<div class="home-page-wrapper">
  <!-- Remove .home-page-wrapper div if themes need to be applied -->
  <ul class="m-menu" [ngClass]="{ 'show-menu': !!showMenu }">
    <li><a (click)="becomeMember()">Sign up</a></li>
    <li><a (click)="login()">Log in</a></li>
  </ul>
  <div class="header-wrapper">
    <header>
      <a routerLink="/" class="logo">
        <img
          data-track="member-anonymous-header-crowdtap-logo"
          src="../../../../../assets/img/crowdtap-logo-black.svg"
          alt="crowdtap"
        />
      </a>
      <a
        class="hamburger"
        [ngClass]="{ 'is-active': showMenu }"
        (click)="showMenu = !showMenu"
      >
        <div class="bar--1"></div>
        <div class="bar--2"></div>
        <div class="bar--3"></div>
      </a>
      <ul class="menu">
        <li>
          <a (click)="login()">{{ 'auth.login' | translate }}</a>
        </li>
        <li *ngIf="!this.currentReferralKeys.referralToken">
          <button class="btn-xs" (click)="becomeMember()">
            {{ 'auth.signUp' | translate }}
          </button>
        </li>
      </ul>
    </header>
  </div>

  <section *ngIf="this.currentReferralKeys.referralToken" class="w-full">
    <div>
      <img
        class="referral-image"
        src="../../../../assets/img/homepage/image.svg"
      />
    </div>
    <div>
      <h4 class="text-center text-blue-dark bold">
        {{ 'referFriends.inviter' | translate }}
      </h4>
      <h1 class="text-blue-dark" style="text-align: center">
        {{ 'homepage.reward' | translate }}
      </h1>
      <p style="text-align: center">
        {{ 'referFriends.referralLandingPage' | translate }}
      </p>
      <form [formGroup]="referralSignUpForm">
        <div class="mb-2 form-row">
          <div class="emailField relative" #emailField>
            <input
              type="email"
              class="block w-full p-2 mt-2 font-medium border rounded-sm h-11 text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
              formControlName="email"
              placeholder="{{ 'auth.emailAddress' | translate }}"
              data-track="member-sign-up-enter-email"
            />
            <span class="absolute z-10 w-4 h-4 cursor-pointer right-3 top-3">
              <svg
                width="21"
                height="22"
                viewBox="0 0 21 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.5">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.625 3.51758H4.375C2.92525 3.51758 1.75 4.69283 1.75 6.14258V14.8926C1.75 16.3423 2.92525 17.5176 4.375 17.5176H16.625C18.0747 17.5176 19.25 16.3423 19.25 14.8926V6.14258C19.25 4.69283 18.0747 3.51758 16.625 3.51758ZM4.375 5.26764H16.625C17.1082 5.26764 17.5 5.65939 17.5 6.14264L10.5 10.4126L3.5 6.14264C3.5 5.65939 3.89175 5.26764 4.375 5.26764ZM17.5 14.8926C17.5 15.3758 17.1082 15.7676 16.625 15.7676H4.375C3.89175 15.7676 3.5 15.3758 3.5 14.8926V8.13758L10.045 12.1363C10.3157 12.2926 10.6493 12.2926 10.92 12.1363L17.5 8.13758V14.8926Z"
                    fill="#1A152E"
                  />
                </g>
              </svg>
            </span>
          </div>
          <small
            *ngIf="fieldErrors('email')?.email"
            class="form-text text-muted danger"
          >
            {{ 'auth.pleaseEnterAValidEmailAddress' | translate }}
          </small>
          <small
            *ngIf="fieldErrors('email')"
            class="form-text text-muted danger"
          >
            <span
              *ngIf="fieldErrors('email').required && isFieldTouched('email')"
            >
              {{ 'auth.emailIsRequired' | translate }}
            </span>
          </small>
        </div>

        <div class="mb-6 form-row">
          <!-- <label class="font-medium text-light-black dark:text-white">{{
            'auth.password' | translate
          }}</label> -->
          <div class="relative passwordField" #passwordFields>
            <span
              class="ic-eye"
              *ngIf="isChange"
              (click)="toggleVisibility($event)"
            ></span>
            <input
              type="password"
              [attr.type]="!eyeView ? 'password' : 'text'"
              class="block w-full p-2 mt-2 font-medium border rounded-sm h-11 text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
              formControlName="password"
              placeholder="{{ 'auth.password' | translate }}"
              (keyup)="onChangeHandler($event)"
              autocomplete="none"
              data-track="member-sign-up-create-password"
            />
            <span
              class="absolute z-10 w-4 h-4 cursor-pointer right-3 top-3"
              (click)="eyeView = !eyeView"
            >
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                class="text-black dark:text-white"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.5">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M19.1796 10.15C17.4121 6.04625 14.0871 3.5 10.4996 3.5C6.91213 3.5 3.58713 6.04625 1.81963 10.15C1.72223 10.3732 1.72223 10.6268 1.81963 10.85C3.58713 14.9537 6.91213 17.5 10.4996 17.5C14.0871 17.5 17.4121 14.9537 19.1796 10.85C19.277 10.6268 19.277 10.3732 19.1796 10.15ZM10.5001 15.75C7.7264 15.75 5.1014 13.7463 3.58765 10.5C5.1014 7.25375 7.7264 5.25 10.5001 5.25C13.2739 5.25 15.8989 7.25375 17.4126 10.5C15.8989 13.7463 13.2739 15.75 10.5001 15.75ZM10.5005 7C8.56749 7 7.00049 8.567 7.00049 10.5C7.00049 12.433 8.56749 14 10.5005 14C12.4335 14 14.0005 12.433 14.0005 10.5C14.0005 9.57174 13.6317 8.6815 12.9754 8.02513C12.319 7.36875 11.4287 7 10.5005 7ZM10.5005 12.25C9.53399 12.25 8.75049 11.4665 8.75049 10.5C8.75049 9.5335 9.53399 8.75 10.5005 8.75C11.467 8.75 12.2505 9.5335 12.2505 10.5C12.2505 11.4665 11.467 12.25 10.5005 12.25Z"
                    fill="currentColor"
                  />
                  <path
                    *ngIf="!eyeView"
                    d="M21 1L11 10.75L1 20.5"
                    stroke="currentColor"
                    stroke-width="2"
                  />
                </g>
              </svg>
            </span>
          </div>
          <small
            *ngIf="
              fieldErrors('password')?.uppercase ||
              fieldErrors('password')?.lowercase ||
              fieldErrors('password')?.nonletter ||
              fieldErrors('password')?.minlength ||
              fieldErrors('password')?.maxlength
            "
            class="form-text text-muted danger"
          >
            {{ 'auth.pleaseEnterAValidPassword' | translate }}
          </small>
          <small
            *ngIf="fieldErrors('password')"
            class="form-text text-muted danger"
          >
            <span
              *ngIf="
                fieldErrors('password')?.required &&
                (isFieldTouched('password') || this.submitted)
              "
            >
              {{ 'auth.passwordIsRequired' | translate }}
            </span>
          </small>
        </div>

        <div class="text-center">
          <button
            type="submit"
            (click)="continueWithEmail()"
            data-track="member-sign-up-continue"
            class="relative mb-4 font-bold text-white rounded-full w-48 h-14 bg-blue-dark"
            [disabled]="isLoading"
          >
            {{ 'general.getStarted' | translate }}
            <div class="absolute left-8 button-spinner top-7">
              <suzy-spinner
                *ngIf="isLoading"
                size="small"
                button="true"
                [round]="10"
                [color]="'#ffffff'"
                [overlay]="false"
              >
              </suzy-spinner>
            </div>
          </button>
        </div>
      </form>
      <p style="text-align: center">or sign up with</p>
      <div>
        <ul class="flex items-center justify-center mt-4 space-x-5">
          <li
            class="flex items-center justify-center rounded-md bg-active-link w-14 h-14 dark:bg-active-link-dark"
          >
            <a
              class="flex items-center justify-center w-full h-full"
              [disabled]="isLoading"
              (click)="socialSignIn('facebook')"
            >
              <svg
                width="15"
                height="27"
                viewBox="0 0 15 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.9655 0.766663H10.9482C9.17235 0.766663 7.46929 1.4427 6.21361 2.64606C4.95793 3.84942 4.2525 5.48152 4.2525 7.18333V11.0333H0.235107V16.1667H4.2525V26.4333H9.60902V16.1667H13.6264L14.9655 11.0333H9.60902V7.18333C9.60902 6.84297 9.75011 6.51655 10.0012 6.27588C10.2524 6.0352 10.593 5.9 10.9482 5.9H14.9655V0.766663Z"
                  fill="#4092FF"
                />
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>

  <section
    *ngIf="!this.currentReferralKeys.referralToken"
    aria-label="main"
    role="main"
    id="main-content"
    class="hero"
  >
    <h3>{{ 'homepage.welcome' | translate }}</h3>
    <h1>{{ 'homepage.reward' | translate }}</h1>
    <img
      *ngIf="isMobile"
      [src]="
        isSpanish
          ? '../../../../assets/img/homepage/es/hero-m.png'
          : '../../../../assets/img/homepage/hero-m.png'
      "
      alt=""
    />
    <img
      *ngIf="!isMobile"
      [src]="
        isSpanish
          ? '../../../../assets/img/homepage/es/hero.png'
          : '../../../../assets/img/homepage/hero.png'
      "
      alt=""
    />
    <p>
      {{ 'homepage.joinPeople' | translate }}
    </p>

    <button
      (click)="becomeMember()"
      data-track="member-landing-section1-cta-btn"
      [innerHTML]="'homepage.becomeMember' | translate"
    ></button>
  </section>

  <section class="testimonials">
    <div class="testimonial-wrapper">
      <div class="testimonial">
        <p>&#8220;{{ 'homepage.testimonial1' | translate }}&#8221;</p>
        <p class="testimonial-by">
          &#8212; {{ 'homepage.testimonial1By' | translate }}
        </p>
      </div>
      <div class="testimonial">
        <p>&#8220;{{ 'homepage.testimonial2' | translate }}&#8221;</p>
        <p class="testimonial-by">
          &#8212; {{ 'homepage.testimonial2By' | translate }}
        </p>
      </div>
      <div class="testimonial">
        <p>&#8220;{{ 'homepage.testimonial3' | translate }}&#8221;</p>
        <p class="testimonial-by">
          &#8212; {{ 'homepage.testimonial3By' | translate }}
        </p>
      </div>
    </div>
    <img
      [src]="
        isSpanish
          ? '../../../../assets//img/homepage/es/members-love-us.png'
          : '../../../../assets//img/homepage/members-love-us.png'
      "
      alt=""
    />
  </section>

  <section class="not-like-other-sites">
    <h1>
      <span class="text-color-green">{{
        'homepage.notLikeOther' | translate
      }}</span>
      {{ 'homepage.goodThing' | translate }}
    </h1>

    <img
      [src]="
        isSpanish
          ? '../../../../assets/img/homepage/es/redeem-giftcard.png'
          : '../../../../assets/img/homepage/redeem-giftcard.png'
      "
      alt=""
    />

    <div class="content">
      <h1>
        <span class="text-color-green">{{
          'homepage.notLikeOther' | translate
        }}</span>
        {{ 'homepage.goodThing' | translate }}
      </h1>
      <p>
        {{ 'homepage.shortSuverys' | translate }}
      </p>
      <p class="rectangle color-green"></p>
      <p>
        {{ 'homepage.giveOpinions' | translate }}
      </p>
      <p class="rectangle color-green"></p>
      <button
        class="color-green"
        (click)="becomeMember()"
        data-track="member-landing-section1-cta-btn"
        [innerHTML]="'homepage.becomeMember' | translate"
      ></button>
    </div>
  </section>

  <section class="time-is-valuable">
    <h1>
      <span class="text-color-purple">{{
        'homepage.valuableTime' | translate
      }}</span>
      {{ 'homepage.valuableOpinions' | translate }}
    </h1>
    <img
      [src]="
        isSpanish
          ? '../../../../assets/img/homepage/es/valuable-time.png'
          : '../../../../assets/img/homepage/valuable-time.png'
      "
      alt=""
    />
    <div class="content">
      <h1>
        <span class="text-color-purple">{{
          'homepage.valuableTime' | translate
        }}</span>
        {{ 'homepage.valuableOpinions' | translate }}
      </h1>
      <p>
        {{ 'homepage.gotoSideHustle' | translate }}
      </p>
      <p class="rectangle color-purple"></p>
      <p>
        {{ 'homepage.insightHelp' | translate }}
      </p>
      <p class="rectangle color-purple"></p>
      <button
        class="color-purple"
        (click)="becomeMember()"
        data-track="member-landing-section1-cta-btn"
        [innerHTML]="'homepage.becomeMember' | translate"
      ></button>
    </div>
  </section>

  <section class="serious-security">
    <h1>
      <span class="text-color-pink">{{ 'homepage.security' | translate }}</span>
      {{ 'homepage.trustComesFirst' | translate }}
    </h1>
    <img src="../../../../assets/img/homepage/security.png" alt="" />
    <div class="content">
      <h1>
        <span class="text-color-pink">{{
          'homepage.security' | translate
        }}</span>
        {{ 'homepage.trustComesFirst' | translate }}
      </h1>
      <p>
        {{ 'homepage.workWithBiggestBrands' | translate }}
      </p>
      <p class="rectangle color-pink"></p>
      <p>
        {{ 'homepage.collectInformation' | translate }}
      </p>
      <p class="rectangle color-pink"></p>
      <button
        class="color-pink"
        (click)="becomeMember()"
        data-track="member-landing-section1-cta-btn"
        [innerHTML]="'homepage.becomeMember' | translate"
      ></button>
    </div>
  </section>

  <section class="ready-to-start">
    <h1>{{ 'homepage.readyToStart' | translate }}</h1>
    <img
      [src]="
        isSpanish
          ? '../../../../assets/img/homepage/es/crowdtap.png'
          : '../../../../assets/img/homepage/crowdtap.png'
      "
      alt=""
    />
    <div class="content">
      <h1>{{ 'homepage.readyToStart' | translate }}</h1>
      <p>
        {{ 'homepage.ourOffer' | translate }}
      </p>
      <button
        *ngIf="!this.currentReferralKeys.referralToken"
        class="btn-s"
        (click)="becomeMember()"
        data-track="member-landing-section1-cta-btn"
        [innerHTML]="'homepage.joinNow' | translate"
      ></button>
      <div class="mobile-icons">
        <a
          class="app-store"
          href="https://apps.apple.com/us/app/crowdtap-surveys-opinions/id1440330471"
          target="_blank"
          data-track="member-anonymous-app-store-link"
        >
          <img
            src="/assets/img/svg/store-icons/appstore.svg"
            alt="White Crowdtap logo"
          />
        </a>
        <a
          class="play-store"
          href="https://play.google.com/store/apps/details?id=com.suzy.droid&hl=en_US&gl=US"
          target="_blank"
          data-track="member-anonymous-google-play-link"
        >
          <img
            src="/assets/img/svg/store-icons/playstore.svg"
            alt="White Crowdtap logo"
          />
        </a>
      </div>
    </div>
  </section>

  <div class="footer-wrapper">
    <footer>
      <a routerLink="/" class="logo">
        <img
          data-track="member-anonymous-header-crowdtap-logo"
          src="../../../../../assets/img/crowdtap-logo-black.svg"
          alt="crowdtap"
        />
      </a>
      <div class="social-nav">
        <p>{{ 'homepage.followUs' | translate }}</p>
        <ul>
          <li>
            <a
              href="http://instagram.com/crowdtap"
              target="_blank"
              data-track="member-anonymous-footer-instagram-btn"
            >
              <svg
                width="23"
                height="22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.138 2.823c2.976 0 3.329.012 4.5.06 1.087.045 1.674.216 2.066.358.517.187.892.414 1.279.776.392.365.63.71.83 1.194.153.365.336.917.384 1.929.052 1.096.065 1.425.065 4.198 0 2.778-.013 3.107-.065 4.2-.048 1.014-.23 1.563-.383 1.928-.2.483-.444.833-.831 1.194a3.46 3.46 0 0 1-1.28.776c-.39.142-.982.312-2.066.357-1.175.049-1.527.061-4.499.061-2.976 0-3.328-.012-4.499-.061-1.087-.045-1.675-.215-2.066-.357a3.478 3.478 0 0 1-1.28-.776 3.186 3.186 0 0 1-.83-1.194c-.153-.365-.335-.918-.383-1.929-.052-1.096-.066-1.425-.066-4.199 0-2.777.014-3.106.066-4.198.048-1.016.23-1.564.383-1.93.2-.483.443-.832.83-1.193a3.46 3.46 0 0 1 1.28-.776c.391-.142.983-.313 2.066-.357 1.17-.049 1.523-.061 4.5-.061Zm0-1.872c-3.024 0-3.402.012-4.59.06-1.183.05-1.997.228-2.702.484a5.49 5.49 0 0 0-1.975 1.202c-.622.577-1 1.157-1.288 1.84-.274.661-.466 1.417-.518 2.521C.013 8.171 0 8.524 0 11.347c0 2.822.013 3.175.065 4.284.052 1.104.244 1.864.518 2.521a5.053 5.053 0 0 0 1.288 1.844c.618.577 1.24.934 1.97 1.198.71.256 1.52.434 2.703.483 1.188.049 1.566.061 4.59.061s3.402-.012 4.59-.06c1.184-.05 1.997-.228 2.702-.484a5.499 5.499 0 0 0 1.971-1.198 5.06 5.06 0 0 0 1.284-1.84c.274-.661.465-1.417.517-2.521.052-1.109.066-1.462.066-4.284 0-2.823-.014-3.176-.066-4.285-.052-1.104-.243-1.864-.518-2.521a4.845 4.845 0 0 0-1.274-1.848 5.5 5.5 0 0 0-1.971-1.198c-.71-.256-1.519-.434-2.702-.483C14.54.963 14.163.95 11.138.95Z"
                  fill="#1A152E"
                />
                <path
                  d="M11.138 6.007c-3.158 0-5.721 2.391-5.721 5.34 0 2.948 2.563 5.34 5.721 5.34 3.16 0 5.722-2.392 5.722-5.34 0-2.949-2.563-5.34-5.722-5.34Zm0 8.803c-2.049 0-3.71-1.55-3.71-3.463s1.661-3.464 3.71-3.464c2.05 0 3.712 1.55 3.712 3.464 0 1.912-1.662 3.463-3.712 3.463ZM18.421 5.795c0 .69-.6 1.247-1.335 1.247-.74 0-1.336-.56-1.336-1.247 0-.69.6-1.246 1.336-1.246.735 0 1.335.56 1.335 1.246Z"
                  fill="#1A152E"
                />
              </svg>
            </a>
          </li>
          <li>
            <a
              href="http://facebook.com/Crowdtap"
              target="_blank"
              data-track="member-anonymous-footer-facebook-btn"
            >
              <svg
                width="23"
                height="22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#a)">
                  <path
                    d="M22.004 11.347C22.004 5.605 17.202.95 11.278.95 5.355.95.553 5.605.553 11.347c0 5.188 3.922 9.49 9.05 10.269v-7.264H6.879v-3.005h2.724v-2.29c0-2.606 1.6-4.045 4.05-4.045 1.174 0 2.402.203 2.402.203v2.558h-1.353c-1.332 0-1.748.801-1.748 1.624v1.95h2.975l-.475 3.005h-2.5v7.264c5.128-.78 9.05-5.08 9.05-10.27Z"
                    fill="#1A152E"
                  />
                </g>
                <defs>
                  <clipPath id="a">
                    <path
                      fill="#fff"
                      transform="translate(.553 .95)"
                      d="M0 0h21.451v20.791H0z"
                    />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </li>
          <li>
            <a
              href="http://www.tiktok.com/tag/crowdtap"
              target="_blank"
              data-track="member-anonymous-footer-tiktok-btn"
            >
              <svg
                width="22"
                height="22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#a)">
                  <path
                    d="M15.541.95h-3.615v14.163c0 1.688-1.39 3.074-3.12 3.074-1.731 0-3.121-1.387-3.121-3.074 0-1.657 1.36-3.013 3.028-3.073V8.484c-3.677.06-6.644 2.983-6.644 6.63 0 3.675 3.028 6.628 6.767 6.628 3.739 0 6.767-2.983 6.767-6.629V7.851a8.547 8.547 0 0 0 4.79 1.567V5.862c-2.72-.09-4.852-2.26-4.852-4.911Z"
                    fill="#1A152E"
                  />
                </g>
                <defs>
                  <clipPath id="a">
                    <path
                      fill="#fff"
                      transform="translate(.281 .95)"
                      d="M0 0h21.451v20.791H0z"
                    />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </li>
          <li>
            <a
              href="http://twitter.com/Crowdtap"
              target="_blank"
              data-track="member-anonymous-footer-twitter-btn"
            >
              <svg
                width="23"
                height="22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#a)">
                  <path
                    d="M7.017 19.793c8.404 0 13.002-6.5 13.002-12.135 0-.183-.004-.37-.013-.553a8.962 8.962 0 0 0 2.28-2.208 9.518 9.518 0 0 1-2.624.67c.954-.533 1.668-1.372 2.01-2.359a9.595 9.595 0 0 1-2.901 1.035 4.686 4.686 0 0 0-2.593-1.288 4.862 4.862 0 0 0-2.898.45 4.407 4.407 0 0 0-2.009 2 4.01 4.01 0 0 0-.291 2.728A13.714 13.714 0 0 1 5.764 6.84a12.857 12.857 0 0 1-4.202-3.16 4.025 4.025 0 0 0-.5 3.106c.263 1.058.947 1.982 1.914 2.585a4.828 4.828 0 0 1-2.07-.532v.053c-.002.985.363 1.94 1.032 2.703a4.636 4.636 0 0 0 2.634 1.48 4.856 4.856 0 0 1-2.062.073c.292.847.86 1.588 1.625 2.12a4.795 4.795 0 0 0 2.642.844C5.16 17.3 3.16 17.942 1.101 17.94a9.81 9.81 0 0 1-1.092-.063 13.65 13.65 0 0 0 7.008 1.916Z"
                    fill="#1A152E"
                  />
                </g>
                <defs>
                  <clipPath id="a">
                    <path
                      fill="#fff"
                      transform="translate(.009 .95)"
                      d="M0 0h22.276v20.791H0z"
                    />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </li>
        </ul>
      </div>
      <hr />
      <div class="downloads">
        <p>{{ 'homepage.downloadCrowdtap' | translate }}</p>
        <ul>
          <li>
            <a
              href="https://apps.apple.com/us/app/crowdtap-surveys-opinions/id1440330471"
              target="_blank"
              data-track="member-anonymous-footer-app-store-link"
              >App Store</a
            >
          </li>
          <li>
            <a
              href="https://play.google.com/store/apps/details?id=com.suzy.droid&hl=en_US&gl=US"
              target="_blank"
              data-track="member-anonymous-footer-google-play-link"
              >Google Play</a
            >
          </li>
        </ul>
      </div>
      <hr class="learn-more-separator" />
      <div class="learn-more">
        <p>Learn more</p>
        <ul>
          <li>
            <a
              href="https://support.crowdtap.com/"
              target="_blank"
              data-track="member-anonymous-footer-help-link"
              >{{ 'nav.support' | translate }}</a
            >
          </li>
          <li>
            <a
              routerLink="/terms-of-service"
              target="_blank"
              data-track="member-anonymous-footer-terms-link"
              >{{ 'nav.termsOfService' | translate }}</a
            >
          </li>
          <li>
            <a
              routerLink="/privacy-policy"
              target="_blank"
              data-track="member-anonymous-footer-privacy-link"
              >{{ 'nav.privacyPolicy' | translate }}</a
            >
          </li>
          <li>
            <a
              routerLink="/cookie-policy"
              target="_blank"
              data-track="member-anonymous-footer-cookie-link"
              >{{ 'nav.cookiesPolicy' | translate }}</a
            >
          </li>
          <li>
            <a
              routerLink="/notice-of-financial-incentive"
              target="_blank"
              data-track="member-anonymous-footer-financial-incentive-link"
              >{{ 'nav.noticeOfFinancialIncentive' | translate }}</a
            >
          </li>
          <li>
            <a
              routerLink="/accessibility-statement"
              target="_blank"
              data-track="member-anonymous-footer-accessibility-link"
              >{{ 'nav.accessibilityStatement' | translate }}</a
            >
          </li>
          <li class="has-icon">
            <img src="/assets/icons/svg/privacy.svg" />
            <a
              target="_blank"
              href="https://crowdtap.formcrafts.com/privacyrequest"
              data-track="member-anonymous-footer-privacy-choices-link"
              >{{ 'nav.privacyChoices' | translate }}</a
            >
          </li>
        </ul>
      </div>

      <div
        class="rights-reserved"
        data-track="member-anonymous-footer-copyright-text"
        [innerHTML]="
          'general.footerCopyright' | translate: { year: currentYear }
        "
      ></div>
    </footer>
  </div>
</div>
