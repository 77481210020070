<div class="modal-backdrop">
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- *** Ignore Above: is usually generated by ngBoosStrap Modal *** -->

      <!-- ////////////// Action Modal Initial Mission Starts ////////////// -->
      <div
        class="action-modal-container"
        *ngIf="isInitialMission"
        [ngClass]="{
          isInitialMission: isInitialMission,
          isSuccessAnimationCompleted: isSuccessAnimationCompleted
        }"
        #actionModalBox
      >
        <suzy-spinner *ngIf="isSppinner"></suzy-spinner>
        <!-- Success Animation Starts -->
        <div class="action-modal-success" #successAimationBox>
          <div class="action-modal-success-inner">
            <div class="success-icon"></div>
          </div>
        </div>
        <!-- Success Animation Ends -->
        <!-- Action Modal Header Starts -->
        <div class="modal-header action-modal action-modal-header">
          <div class="modal-title action-modal-title">
            {{ 'successAnimation.technology' | translate }}
          </div>
          <button
            type="button"
            class="close action-modal-close"
            aria-label="Close"
          >
            <span class="sr-only" aria-hidden="true ">&times;</span>
          </button>
        </div>
        <!-- Action Modal Header Ends -->
        <!-- Action Modal Question Starts -->
        <div class="action-modal-question">
          <h3>
            {{
              'successAnimation.whereDoYouTypicallyBuyElectronics' | translate
            }}
          </h3>
        </div>
        <!-- Action Modal Question Ends -->
        <!-- Action Modal Body with Initial Mission Screen Starts -->
        <div class="modal-body action-modal-body">
          <form novalidate=" ">
            <!-- Form Content Starts -->
            <div class="form">
              <div
                class="form-group form-filling form-filling-with-bottom-link"
              >
                <textarea
                  type="text "
                  class="form-control h-144"
                  rows="6 "
                  placeholder="Write something... "
                  required
                ></textarea>
                <p class="char-counter">0 / 200</p>
              </div>
              <div class="bottom-link">
                <a ng-href="# " class="border-bottom">{{
                  'general.clickHere' | translate
                }}</a>
                {{ 'photo.readResponseGuidelines' | translate }}
              </div>
              <div class="form-group form-cta">
                <div class="row">
                  <div class="col-6">
                    <button
                      type="submit "
                      class="btn form-btn form-btn-secondary text-uppercase"
                    >
                      {{ 'general.skip' | translate }}
                    </button>
                  </div>
                  <div class="col-6">
                    <button
                      type="submit "
                      class="btn form-btn form-btn-primary form-btn-with-arrow text-uppercase"
                    >
                      {{ 'general.submit' | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- Form Content Ends -->
          </form>
        </div>
        <!-- Action Modal Body with Initial Mission Screen Ends -->
      </div>
      <!-- ////////////// Action Modal Inital Mission Ends ////////////// -->

      <!-- ////////////// Action Modal Result Screen Starts ////////////// -->
      <div
        class="action-modal-container"
        *ngIf="isResultScreen"
        [ngClass]="{
          isResultScreen: isResultScreen,
          isSlideOut: isNextMission
        }"
      >
        <!-- Action Modal Header Starts -->
        <div class="modal-header action-modal action-modal-header">
          <div class="modal-title action-modal-title">
            {{ 'successAnimation.technology' | translate }}
          </div>
          <button
            type="button"
            class="close action-modal-close"
            aria-label="Close"
          >
            <span class="sr-only" aria-hidden="true ">&times;</span>
          </button>
        </div>
        <!-- Action Modal Header Ends -->
        <!-- Action Modal Question Starts -->
        <div class="action-modal-question">
          <h3>
            {{
              'successAnimation.whereDoYouTypicallyBuyElectronics' | translate
            }}
          </h3>
        </div>
        <!-- Action Modal Question Ends -->
        <!-- Action Modal Body Starts -->
        <div class="modal-body action-modal-body action-modal-body">
          <!-- Result Screen Starts -->
          <div class="result-screen">
            <!-- Form Content Starts -->
            <div class="result-screen-inner">
              <div class="result-screen-body">
                <p>
                  {{
                    'successAnimation.heresHowYourAnswerStacksUp' | translate
                  }}
                </p>
                <div class="result-list">
                  <div class="item">
                    <div class="text-group">
                      <div class="text">
                        {{
                          'successAnimation.yesISendAtLeastOneChild' | translate
                        }}
                      </div>
                      <span class="percent">32%</span>
                    </div>
                    <div class="custom-progress">
                      <progress value="32" max="100"></progress>
                    </div>
                  </div>
                  <div class="item isSelected">
                    <div class="text-group">
                      <div class="text">
                        {{ 'successAnimation.noIDontHaveAChild' | translate }}
                      </div>
                      <span class="percent">100%</span>
                    </div>
                    <div class="custom-progress">
                      <progress value="62" max="100"></progress>
                    </div>
                  </div>
                  <div class="item">
                    <div class="text-group">
                      <div class="text">
                        {{
                          'successAnimation.yesISendAtLeastOneChild' | translate
                        }}
                      </div>
                      <span class="percent">12%</span>
                    </div>
                    <div class="custom-progress">
                      <progress value="12" max="100"></progress>
                    </div>
                  </div>
                </div>
              </div>
              <div class="result-screen-footer">
                <div class="row">
                  <div class="col-12">
                    <button
                      type="submit "
                      class="btn form-btn form-btn-light form-btn-with-arrow text-uppercase"
                      (click)="onSubmitHandler()"
                    >
                      {{ 'general.nextAction' | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- Form Content Ends -->
          </div>
          <!-- Result screen Ends -->
        </div>
        <!-- Action Modal Body Ends -->
      </div>
      <!-- ////////////// Action Modal Result Screen Ends ////////////// -->

      <!-- ////////////// Action Modal Next Mission Starts ////////////// -->
      <div
        class="action-modal-container"
        *ngIf="isNextMission"
        [ngClass]="{ isNextMission: isNextMission }"
      >
        <!-- Action Modal Header Starts -->
        <div class="modal-header action-modal action-modal-header">
          <div class="modal-title action-modal-title">
            {{ 'successAnimation.technology' | translate }}
          </div>
          <button
            type="button"
            class="close action-modal-close"
            aria-label="Close"
          >
            <span class="sr-only" aria-hidden="true ">&times;</span>
          </button>
        </div>
        <!-- Action Modal Header Ends -->

        <!-- Action Modal Question Starts -->
        <div class="action-modal-question">
          <h3>
            {{
              'successAnimation.whereDoYouTypicallyBuyElectronics' | translate
            }}
          </h3>
        </div>
        <!-- Action Modal Question Ends -->

        <!-- Action Modal Body Starts -->
        <div class="modal-body action-modal-body">
          <form novalidate=" ">
            <!-- Form Content Starts -->
            <div class="form">
              <div
                class="form-group form-filling form-filling-with-bottom-link"
              >
                <textarea
                  type="text "
                  class="form-control h-144"
                  rows="6 "
                  placeholder="Write something... "
                  required
                ></textarea>
                <p class="char-counter">0 / 200</p>
              </div>
              <div class="bottom-link">
                <a ng-href="# " class="border-bottom">{{
                  'general.clickHere' | translate
                }}</a>
                {{ 'photo.readResponseGuidelines' | translate }}
              </div>
              <div class="form-group form-cta">
                <div class="row">
                  <div class="col-6">
                    <button
                      type="submit "
                      class="btn form-btn form-btn-secondary text-uppercase"
                    >
                      {{ 'general.skip' | translate }}
                    </button>
                  </div>
                  <div class="col-6">
                    <button
                      type="submit "
                      class="btn form-btn form-btn-primary form-btn-with-arrow text-uppercase"
                    >
                      {{ 'general.submit' | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- Form Content Ends -->
          </form>
        </div>
        <!-- Action Modal Body Ends -->
      </div>
    </div>
  </div>
</div>
