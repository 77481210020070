import { Injectable } from '@angular/core';
import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import { BehaviorSubject, Observable, Subject, forkJoin } from 'rxjs';

@Injectable()
export class GoalService {
  constructor(private sdk: SuzySdkService) {}

  private goalInfo: any = {
    isSetGoal: false
  };

  public get getGoalInfo() {
    return this.goalInfo;
  }

  isCache: boolean;

  private goalInfoSubject$ = new BehaviorSubject({});
  goalInfo$ = this.goalInfoSubject$ as Observable<any>;
  private claimModalSubject$ = new BehaviorSubject<string>('');
  claimModal$ = this.claimModalSubject$ as Observable<string>;

  getGoalData(user_total_point: number): void {
    forkJoin([
      this.sdk.ProtocolRedeem.rewardSearch(
        0,
        1,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        user_total_point,
        'preventLessThanOrEqualTo'
      ),
      this.sdk.ProtocolRedeem.getGoalForUser()
    ]).subscribe(result => {
      this.goalInfo = result[1];
      this.goalInfo.isSetGoal = result[0]['items'].length > 0;
      this.goalInfoSubject$.next(this.goalInfo);
      this.isCache = true;
      setTimeout(() => {
        this.isCache = false;
      }, 120000);
    });
  }

  setGoalInfo(goalInfo: any): void {
    this.goalInfo = goalInfo;
    this.goalInfo.isSetGoal = goalInfo.isSetGoal ?? false;
    this.goalInfoSubject$.next(goalInfo);
  }

  setClaimModal(claimModal: string): void {
    this.claimModalSubject$.next(claimModal);
  }
}
