import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripMarkdown'
})
export class StripMarkdownPipe implements PipeTransform {
  transform(value: string): string {
    if (value === undefined) {
      return '';
    }

    return value
      .replace(/\*/g, '')
      .replace(/\*\*/g, '')
      .replace(/&nbsp;/g, ' ')
      .replace(/&amp;/g, '&');
  }
}
