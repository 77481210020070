import { animate, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding } from '@angular/core';

const enterTransition = transition(':enter', [
  style({
    opacity: 0
  }),
  animate(
    '0.5s ease-in',
    style({
      opacity: 1
    })
  )
]);

const leaveTrans = transition(':leave', [
  style({
    opacity: 1
  }),
  animate(
    '0.5s ease-out',
    style({
      opacity: 0
    })
  )
]);

const fadeIn = trigger('fadeIn', [enterTransition]);

const fadeOut = trigger('fadeOut', [leaveTrans]);
@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  animations: [fadeIn, fadeOut]
})
export class SnackbarComponent {
  @HostBinding('@fadeIn') fadeIn = true;
  @HostBinding('@fadeOut') fadeOut = true;
  snackbarContent: string;
  snackbarIcon: string;
}
