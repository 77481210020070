import { Injectable, OnDestroy, OnInit } from '@angular/core';
import {
  AuthenticationService,
  UserProfileService
} from '@suzy/crowdtap/data-access/user';
import { MissionService } from '@suzy/shared/tools/mission';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

enum PointSource {
  user = 1,
  mission = 2,
  refresh = 3
}

@Injectable()
export class PointsService {
  points$: Observable<number>;
  refresh$ = new Subject();
  user: any;

  constructor(
    private userService: UserProfileService,
    private missionService: MissionService,
    private authService: AuthenticationService
  ) {
    let pointSource: number;

    this.points$ = combineLatest([
      this.refresh$.pipe(
        tap(() => {
          pointSource = PointSource.refresh;
        })
      ),
      this.missionService.missionAnsweredMeta$().pipe(
        map((data: any) => data.total_points),
        tap(() => {
          pointSource = PointSource.mission;
        })
      ),
      this.userService.userUpdated$.pipe(
        map((data: any) => data.total_points),
        tap(() => {
          pointSource = PointSource.user;
        })
      )
    ]).pipe(
      map(([refresh, missionAnswered, userUpdated]) => {
        switch (pointSource) {
          case PointSource.refresh:
            return refresh;
          case PointSource.user:
            return userUpdated;
          case PointSource.mission:
            return missionAnswered;
          default:
            return undefined;
        }
      })
    );
  }

  refreshPoints(): void {
    this.authService.ensureUser(true).subscribe(user => {
      this.user = user;
      this.refresh$.next(user.total_points);
    });
  }
}
