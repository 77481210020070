import { Component, OnInit } from '@angular/core';
import { startWith, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import { AuthenticationService } from '@suzy/crowdtap/data-access/user';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';

@Component({
  selector: 'app-auto-login',
  templateUrl: './auto-login.component.html',
  styleUrls: ['./auto-login.component.scss']
})
export class AutoLoginComponent implements OnInit {
  apiKey: string;
  token: string;
  redirectTo: string;
  isMobile$ = this.route.data.pipe(
    startWith({ mobile: false }),
    map(data => data.mobile)
  );

  constructor(
    private route: ActivatedRoute,
    private suzySDK: SuzySdkService,
    private router: Router,
    private auth: AuthenticationService,
    private gtm: Angulartics2GoogleTagManager
  ) {
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
      this.apiKey = params['api_key'];
      this.redirectTo = params['redirect_to'];
    });
  }

  ngOnInit(): void {
    this.onAutoLogin();
  }

  onAutoLogin(): any {
    const input = {
      api_key: this.apiKey,
      token: this.token
    };
    this.auth.setLocalKey('isMobile', 'true');

    this.suzySDK.ProtocolAuthorize.tokenLogin(input).subscribe(
      data => {
        if (data.success) {
          this.auth.login(data.item);
          this.gtm.setUsername(data.item.user_id);
          const userInformation = {
            ...data.item,
            is_fb_registered: false,
            is_email_registered: true
          };
          delete userInformation.api_key;
          delete userInformation.api_secret;
          this.gtm.eventTrack('js-home-login-auth-success', {
            event: 'js-home-login-auth-success',
            gtmCustom: {
              userInformation
            }
          });

          this.redirectUser();
        } else {
          this.gtm.eventTrack('js-home-login-auth-failed', {
            event: 'js-home-login-auth-failed',
            gtmCustom: {
              error: data.message
            }
          });
          this.router.navigate(['/auth/login/mobile']);
        }
      },
      error => {
        this.router.navigate(['/auth/login/mobile']);
      }
    );
  }

  redirectUser(): void {
    const redirectUrl = this.auth.getMobileRouting(this.redirectTo);

    if (redirectUrl === '/close-browser/mobile') {
      window.location.href = redirectUrl;
    } else {
      this.router.navigate([redirectUrl]);
    }
  }
}
