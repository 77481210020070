import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-refer-a-friend',
  templateUrl: './refer-a-friend.component.html',
  styleUrls: ['./refer-a-friend.component.scss']
})
export class ReferAFriendComponent implements OnInit, AfterViewInit {
  @ViewChild('backToTopLink', { static: true }) backToTopLink: ElementRef;
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef;

  el_backToTopLink: HTMLElement;
  el_wrapper: HTMLElement;
  legalData: any;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.legalData = this.route.snapshot.data['legalData'];
  }
  ngAfterViewInit(): void {
    this.el_backToTopLink = this.backToTopLink.nativeElement as HTMLElement;
    this.el_wrapper = this.wrapper.nativeElement as HTMLElement;
    this.el_backToTopLink = this.backToTopLink.nativeElement as HTMLElement;
    this.el_wrapper = this.wrapper.nativeElement as HTMLElement;
  }

  onBackToTop(): void {
    this.el_wrapper.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    });
  }
}
