<h1>Notice of Financial Incentive</h1>
<p>
  Suzy provides rewards and incentives for individuals to provide responses to
  surveys, interviews, focus groups, and the like (<em>i.e.</em>, Actions),
  which involve the Personal Data described in this Privacy Policy. You can opt
  in to the rewards or incentives by registering for a Crowdtap account and
  answering Actions using your account credentials, or by responding to an email
  inviting you to participate in an Action. Your participation is completely
  voluntary, and you have a right to withdraw from Crowdtap at any time or
  decline the Action invitation. If you decide you don't want to participate,
  you can refrain from submitting responses to Actions, or you can close your
  Crowdtap account.
</p>
<p>
  The specific reward or incentive offered, if any, is made available to you
  when you provide responses to available Actions and may vary based on the type
  and length of the Action and other factors identified below. The monetary
  value of the reward or incentive is a reasonable approximation of the monetary
  value of your Action responses to us. We have arrived at this estimate based
  on consideration of multiple factors, including the following:
</p>
<ol>
  <li>
    revenue generated by Suzy in providing Action responses and insights to
    customers;
  </li>
  <li>expenses incurred by Suzy in operating the Actions; and</li>
  <li>
    our reasonable assessment of revenue we may generate as a result of Suzy
    providing aggregated and/or anonymized responses and insights to customers.
  </li>
</ol>
