<div class="auto-login" #successAimation>
  <div class="container" [ngClass]="{ 'dark-mode': isMobile$ | async }">
    <div class="header">
      <h1 *ngIf="!(isMobile$ | async); else showWhiteLogo">
        <a routerLink="/">
          <img
            src="../../../../../assets/img/crowdtap-logo-black.svg"
            alt="Blue Crowdtap logo"
          />
        </a>
      </h1>
      <ng-template #showWhiteLogo>
        <h1>
          <img
            src="../../../../../assets/img/crowdtap-logo-white.svg"
            alt="White Crowdtap logo"
          />
        </h1>
      </ng-template>
    </div>

    <div class="success-icon">
      <suzy-spinner size="large" button="true" [overlay]="false"></suzy-spinner>

      <svg class="circular">
        <circle
          class="path"
          attr.cx="90"
          attr.cy="90"
          attr.r="180"
          fill="none"
          attr.stroke-width="1"
          stroke-miterlimit="10"
        />
      </svg>
    </div>
  </div>
</div>
