import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileUploadService } from '../file-upload.service';

@Component({
  selector: 'suzy-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  @Input() getUploadUrl: Function;
  @Input() uploadCompleted: Function;
  @Input() fileTypes: string;
  @Input() auto: boolean;
  @Output() uploadComplete = new EventEmitter<any>();
  errorMessage: string;
  fileToUpload: File = undefined;
  imageName: string;

  constructor(private fileUploadService: FileUploadService) {}

  ngOnInit(): void {
    this.auto = true;
  }

  handleFileInput(files: FileList): any {
    this.fileToUpload = files.item(0);
    this.imageName = files.item(0).name;
    if (this.auto === true) {
      this.uploadFileToActivity();
    } else {
      return files;
    }
  }

  uploadFileToActivity(): void {
    // To do: restrict file by size
    // To do: add preview features
    this.getUploadUrl(this.fileToUpload.name).subscribe((response) => {
      if (response.success) {
        this.fileUploadService
          .postFile(this.fileToUpload, response.item)
          .subscribe(
            (result) => {
              this.uploadComplete.emit(response.item);
            },
            (error) => {
              return (this.errorMessage = error.statusText);
            }
          );
      }
    });
  }
}
