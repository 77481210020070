<div class="wrapper" #wrapper>
  <app-general-header viewKind="signup"> </app-general-header>

  <section class="anonymous" aria-label="main" role="main" id="main-content">
    <article class="signup px-4 m-auto mt-6 text-light-black dark:text-white">
      <app-rewards *ngIf="isEditing" isSignup="true"></app-rewards>

      <div
        *ngIf="!isEditing"
        class="detail-modal success-modal relative text-center"
      >
        <h1 class="text-box-dark dark:text-box-light text-xl font-bold">
          {{ 'rewards.yourGoalHasBeenSet' | translate }}
        </h1>
        <div
          class="mt-1 mb-5 text-sm text-box-dark dark:text-box-light font-medium"
        >
          {{ 'rewards.TraceOrUpdateYourGoal' | translate }}
        </div>
        <div class="image-container-modal" *ngIf="goalInfo?.meta?.photo">
          <img
            [src]="goalInfo?.meta.photo.thumbs?.member?.url"
            alt="Reward logo"
            width="200"
            class="object-cover border dark:border-white-line border-black-line rounded-xl m-auto"
          />
        </div>
        <div class="text-xs text-box-dark dark:text-box-light font-semibold">
          $5 {{ goalInfo?.meta?.reward_group_name }}

          <button
            type="button"
            (click)="editGoal('success')"
            class="text-sm font-semibold text-blue-dark ml-1"
          >
            {{ 'general.edit' | translate }}
          </button>
        </div>

        <div class="actions mt-6">
          <button
            type="button"
            routerLink="/dashboard"
            class="bg-blue-dark text-white px-4 h-10 text-sm font-bold rounded-full"
            (click)="onCloseSuccessModal()"
          >
            {{ 'rewards.startEarning' | translate }}
          </button>
        </div>
      </div>
    </article>
  </section>

  <section class="footer-contianer">
    <app-general-footer id="main-footer"></app-general-footer>
  </section>
</div>
