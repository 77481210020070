import { Injectable } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import { SegmentService } from '@suzy/shared/data-access/tracking';
import { MissionService } from '@suzy/shared/tools/mission';
import {
  ActionKind,
  MissionKind,
  RecruitmentStatus
} from '@asksuzy/typescript-sdk';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import swal from 'sweetalert2';
import { MetaData } from '../../core/models/meta-data.model';
import { MetaDataService } from '../../core/services/meta-data.service';
import { CoverComponent } from '../../views/actions/cover/cover.component';
import { EndingComponent } from '../../views/actions/ending/ending.component';
import { ActionLoaderComponent } from './action-loader/action-loader.component';
import { ActionsResolverService } from './actions-resolver.service';
import { StoreService } from '../../core/services/store.service';
import { SnackbarService } from '../../core/services/snackbar.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalEndingComponent } from './global-ending/global-ending.component';
import { PreviewService } from '../preview/preview.service';
import { SpecialMissionService } from '../../core/services/special-mission.service';
import { ExternalCoverComponent } from '../external-link/shared/external-cover/external-cover.component';

let modalsClosing: string[] = [];

export const beforeModalDismiss = (
  modalClass: string,
  id: string,
  delay = 300
): Promise<boolean> | boolean => {
  const elements = document.getElementsByClassName(modalClass);
  if (elements.length <= 0) {
    return true;
  }

  const element = elements[0];
  element.classList.add('is-hiding');
  if (id && !modalsClosing.includes(id)) {
    return new Promise<boolean>(resolve => {
      setTimeout(() => {
        resolve(true);
      }, delay);
    });
  } else {
    return true;
  }
};

@Injectable()
export class ActionsService {
  labelByKind = {};
  startDate: number;
  startTime: number;
  currentActionStepPriority$ = new BehaviorSubject<number>(-1);
  refreshDashboard$ = new Subject<void>();
  metaData: MetaData = {
    userId: '',
    type: '',
    actionId: undefined,
    missionId: undefined,
    text: '',
    response: undefined,
    attempt: undefined,
    startDateTime: undefined,
    actionStartDateTime: undefined,
    completedDateTime: undefined,
    dwellingTime: undefined,
    completionTime: undefined,
    interactionDateTime: undefined,
    closedDateTime: undefined,
    skipDateTime: undefined,
    skipFlag: false,
    mousePosition: undefined,
    deviceInfo: undefined,
    validationCount: 0,
    questionCounts: undefined,
    responseCount: undefined,
    numberOfOptions: undefined,
    optionPositions: undefined
  };

  constructor(
    private router: Router,
    private modals: NgbModal,
    private actionResolver: ActionsResolverService,
    private missionService: MissionService,
    private sdk: SuzySdkService,
    private snackbarService: SnackbarService,
    private translate: TranslateService,
    private storeService: StoreService,
    private metaDataService: MetaDataService,
    private segmentService: SegmentService,
    private previewService: PreviewService,
    private specialMission: SpecialMissionService
  ) {
    this.labelByKind[ActionKind.poll] = 'Poll';
    this.labelByKind[ActionKind.multiplechoice] = this.translate.instant(
      'multipleChoice.multipleChoice'
    );
    this.labelByKind[ActionKind.openended] = this.translate.instant(
      'openEnded.openEnded'
    );
    this.labelByKind[ActionKind.fillblank] = 'Fill Blank';
    this.labelByKind[ActionKind.viewmedia] = 'View Media';
    this.labelByKind[ActionKind.photoacquisition] = 'Photo Acquisition';
    this.labelByKind[ActionKind.suzypro] = 'Suzy Pro';
    this.labelByKind[ActionKind.focusgroup] = this.translate.instant(
      'focusGroup.focusGroup'
    );
    this.labelByKind[ActionKind.grid] =
      this.translate.instant('grid.customGrid');
    this.labelByKind[ActionKind.gridcustom] =
      this.translate.instant('grid.customGrid');
    this.labelByKind[ActionKind.gridrankscale] =
      this.translate.instant('grid.gridRankScale');
    this.labelByKind[ActionKind.sampling] =
      this.translate.instant('sampling.sampling');
    this.labelByKind[ActionKind.sharetext] = 'Share Text';
    this.labelByKind[ActionKind.sharephoto] = 'Share Photo';
    this.labelByKind[ActionKind.manual] = 'Manual';
    this.labelByKind[ActionKind.survey] =
      this.translate.instant('survey.survey');
    this.labelByKind[ActionKind.sharing] = 'Sharing';
    this.labelByKind[ActionKind.screening] = 'Screening';
    this.labelByKind[ActionKind.bazaarvoice] = 'Bazaarvoice';
    this.labelByKind[ActionKind.webvisit] = 'Web Visit';
    this.labelByKind[ActionKind.address] = 'Address';
    this.labelByKind[ActionKind.accept] = 'Accept';
  }

  doMission(mission: any, modalSettings?: any): Subject<any> {
    if (
      !mission.mission_caption ||
      mission.mission_kind === MissionKind.external_cint
    ) {
      // mission doesn't have cover, so start now
      this.segmentService.trackMission('Mission Started', {
        action_count: mission.action_count,
        brand_id: mission.brand_id,
        mission_id: mission.mission_id,
        points_available: mission.points,
        mission_kind: mission.mission_kind,
        has_cover: false,
        monadic_variant_quantity: mission.monadic_variants
          ? mission.monadic_variants.length
          : undefined,
        tag: mission.specialType
      });
    }
    this.startTime = new Date().getTime();
    switch (mission.mission_kind) {
      case MissionKind.survey:
      case MissionKind.splittesting:
        return this.doSurvey(mission, mission.first_action, modalSettings);
      case MissionKind.sharing:
        return this.doMissionWithCover(
          mission,
          mission.first_action,
          'sharing',
          modalSettings
        );
      case MissionKind.sampling:
        return this.doMissionWithCover(
          mission,
          mission.first_acton,
          'sampling',
          modalSettings
        );
      case MissionKind.screening:
        return this.doSurvey(mission, mission.first_action, modalSettings);
      case MissionKind.maxdiff:
        return this.doSurvey(mission, mission.first_action, modalSettings);
      case MissionKind.focusgroup:
        return this.doMissionWithCover(
          mission,
          mission.first_action,
          'focus_group',
          modalSettings
        );
      case MissionKind.external_cint:
        return this.doAction(
          mission,
          mission.first_action,
          undefined,
          modalSettings
        );
      case MissionKind.external_link:
        return this.doMissionWithExternalCover(
          mission,
          mission.first_action,
          modalSettings
        );

      default:
        return this.doAction(
          mission,
          mission.first_action,
          undefined,
          modalSettings
        );
    }
  }

  doAction(
    mission: any,
    action: any,
    settings: any = {},
    modalSettings?: any
  ): Subject<any> {
    this.startDate = Date.now();
    this.startTime = new Date().getTime();
    const actionComponent = this.actionResolver.resolveActionType(action);

    const modalClass =
      this.specialMission.Properties.crowdtap_special_missions.find(
        item => item.mission_id === mission.mission_id
      )
        ? `general-modal specialMission action-modal-${new Date().getTime()}`
        : `action-modal-${new Date().getTime()}`;
    let modal;

    if (!actionComponent) {
      return undefined;
    }

    if (modalSettings) {
      this.previewService.activeSurveyPanel = false;
      modal = this.modals.open(ActionLoaderComponent, {
        windowClass: modalSettings.windowClass,
        container: modalSettings.container,
        backdrop: modalSettings.backdrop
      });
    } else {
      modal = this.modals.open(ActionLoaderComponent, {
        windowClass: modalClass,
        beforeDismiss: () => {
          return beforeModalDismiss(modalClass, mission.mission_id);
        }
      });
    }
    const actionLoaderComponent =
      modal.componentInstance as ActionLoaderComponent;
    actionLoaderComponent.initialize(
      mission,
      action,
      actionComponent,
      settings,
      this
    );

    modal.result.then(
      () => {
        return;
      },
      reason => {
        if (reason === 'in-progress') {
          const inProgressIndex = this.storeService.missions.findIndex(m => {
            return m.mission_id === mission.mission_id;
          });

          this.storeService.completedMissionsIndex.push({
            index: inProgressIndex,
            answer_status: 'skipped'
          });

          this.refreshDashboard$.next();

          if (this.router.url !== '/in-progress' && !mission['IN_PROGRESS']) {
            setTimeout(() => {
              this.snackbarService.appendComponentToBody(
                this.translate.instant('dashboard.movedInProgress'),
                3500
              );
            }, 2000);
          }
        }

        if (
          reason === ModalDismissReasons.ESC ||
          reason === ModalDismissReasons.BACKDROP_CLICK
        ) {
          this.sendMetaData(mission, action);
        }
        if (actionLoaderComponent && actionLoaderComponent.subject) {
          if (reason !== 'allAnswered' && reason !== 'skippedAction') {
            actionLoaderComponent.subject.next('refresh');
          }
        }

        return;
      }
    );

    return actionLoaderComponent.subject;
  }

  closeAllModals(reason?: string): void {
    this.modals.dismissAll(reason);
  }

  doMissionWithSteps(mission: any, steps: any): Subject<any> {
    this.startTime = new Date().getTime();
    const pendingAction = steps[0].pending_actions[0];

    switch (mission.mission_kind) {
      case MissionKind.survey:
        return this.continueMissionWithCover(mission, pendingAction, 'survey');
      case MissionKind.splittesting:
        return this.continueMissionWithCover(mission, pendingAction, 'survey');
      case MissionKind.sharing:
        return this.continueMissionWithCover(mission, pendingAction, 'sharing');
      case MissionKind.screening:
        return this.continueMissionWithCover(mission, pendingAction, 'survey');
      case MissionKind.maxdiff:
        return this.continueMissionWithCover(mission, pendingAction, 'survey');
      case MissionKind.sampling:
        return this.continueMissionWithCover(
          mission,
          pendingAction,
          'sampling'
        );
      default:
        return this.doAction(mission, pendingAction);
    }
  }

  continueMissionWithCover(
    mission: any,
    action: any,
    type: string
  ): Subject<any> {
    const subject = new Subject<any>();
    this.doAction(mission, action, undefined).subscribe(result => {
      if (
        type === 'sampling' &&
        mission.sampling &&
        mission.sampling.recruitment_status === RecruitmentStatus.none
      ) {
        subject.next();
      } else if (result !== 'refresh') {
        if (!mission['IN_PROGRESS']) {
          const modalClass = `ending-modal-${new Date().getTime()}`;
          const endingModal = this.modals.open(EndingComponent, {
            windowClass: modalClass,
            beforeDismiss: () => {
              return beforeModalDismiss(modalClass, mission.mission_id);
            }
          });
          const endingComponent =
            endingModal.componentInstance as EndingComponent;
          endingComponent.type = type;
          endingComponent.points = result.detail.points_awarded;
          endingComponent.mission = mission;
          endingComponent.startTime = this.startTime;

          endingModal.result.then(
            res => {
              subject.next('refresh');
            },
            () => {
              subject.next('refresh');
            }
          );
        }
      }
    });

    return subject;
  }

  doMissionWithCover(
    mission: any,
    action: any,
    type: string,
    modalSettings?: any
  ): Subject<any> {
    this.startDate = Date.now();
    this.startTime = new Date().getTime();
    const subject = new Subject<any>();
    const modalClass = `cover-modal-${new Date().getTime()}`;

    let coverModal;
    if (modalSettings) {
      coverModal = this.modals.open(CoverComponent, {
        windowClass: modalSettings.windowClass,
        container: modalSettings.container,
        backdrop: modalSettings.backdrop,
        beforeDismiss: () => {
          return beforeModalDismiss(
            modalSettings.windowClass,
            mission.mission_id
          );
        }
      });
    } else {
      coverModal = this.modals.open(CoverComponent, {
        windowClass: modalClass,
        beforeDismiss: () => {
          return beforeModalDismiss(modalClass, mission.mission_id);
        }
      });
    }

    const coverComponent = coverModal.componentInstance as CoverComponent;
    coverComponent.description = mission.mission_description;
    coverComponent.title = mission.mission_caption;
    coverComponent.kind = mission.mission_kind;
    coverComponent.mission = mission;
    coverComponent.action = action;
    coverComponent.actionService = this;
    if (modalSettings && modalSettings.preview) {
      coverComponent.modalSettings = modalSettings;
    }
    coverModal.result.then(
      data => {
        subject.next(data);
      },
      reason => {
        if (
          reason === ModalDismissReasons.ESC ||
          reason === ModalDismissReasons.BACKDROP_CLICK
        ) {
          this.sendMetaData(mission, action);
        }

        return;
      }
    );

    return subject;
  }

  doSurvey(mission: any, action: any, modalSettings?: any): Subject<any> {
    this.startDate = Date.now();
    this.startTime = new Date().getTime();
    const subject = new Subject<any>();
    const modalClass = `survey-modal-${new Date().getTime()}`;
    let coverModal;

    if (modalSettings) {
      this.previewService.activeSurveyPanel = true;
      coverModal = this.modals.open(CoverComponent, {
        windowClass: modalSettings.windowClass
          ? modalSettings.windowClass
          : modalClass,
        container: modalSettings.container,
        backdrop: modalSettings.backdrop,
        beforeDismiss: () => {
          return beforeModalDismiss(
            modalSettings.windowClass,
            mission.mission_id
          );
        }
      });
    } else {
      coverModal = this.modals.open(CoverComponent, {
        windowClass: modalClass,
        beforeDismiss: () => {
          return beforeModalDismiss(modalClass, mission.mission_id);
        }
      });
    }

    const coverComponent = coverModal.componentInstance as CoverComponent;
    coverComponent.description = mission.mission_description;
    coverComponent.title = mission.mission_caption;
    coverComponent.kind = mission.mission_kind;
    coverComponent.mission = mission;
    coverComponent.action = action;
    coverComponent.actionService = this;
    if (modalSettings && modalSettings.preview) {
      coverComponent.modalSettings = modalSettings;
    }
    coverModal.result.then(
      data => {
        subject.next(data);
      },
      reason => {
        if (
          reason === ModalDismissReasons.ESC ||
          reason === ModalDismissReasons.BACKDROP_CLICK
        ) {
          this.sendMetaData(mission, action);
        }

        if (reason !== 'allAnswered' && reason !== 'skippedAction') {
          subject.next('refresh');
        }
        // this.modals.dismissAll();

        return;
      }
    );

    return subject;
  }

  doMissionWithExternalCover(
    mission: any,
    action: any,
    modalSettings?: any
  ): Subject<any> {
    this.startDate = Date.now();
    this.startTime = new Date().getTime();
    const subject = new Subject<any>();
    const modalClass = `survey-modal-${new Date().getTime()}`;
    let coverModal;

    if (modalSettings) {
      this.previewService.activeSurveyPanel = true;
      coverModal = this.modals.open(ExternalCoverComponent, {
        windowClass: modalSettings.windowClass
          ? modalSettings.windowClass
          : modalClass,
        container: modalSettings.container,
        backdrop: modalSettings.backdrop,
        beforeDismiss: () => {
          return beforeModalDismiss(
            modalSettings.windowClass,
            mission.mission_id
          );
        }
      });
    } else {
      coverModal = this.modals.open(ExternalCoverComponent, {
        windowClass: modalClass,
        beforeDismiss: () => {
          return beforeModalDismiss(modalClass, mission.mission_id);
        }
      });
    }

    const coverComponent =
      coverModal.componentInstance as ExternalCoverComponent;
    coverComponent.description = mission.mission_description;
    coverComponent.title = mission.mission_caption;
    coverComponent.kind = mission.mission_kind;
    coverComponent.mission = mission;
    coverComponent.action = action;
    coverComponent.actionService = this;
    // TO DO : Confirm with server response GLB-1496
    coverComponent.logoPath = mission.mission_logo?.thumbs?.admin?.url
      ? mission.mission_logo?.thumbs?.admin?.url
      : '/assets/img/external/external-link-logo.svg';

    if (modalSettings && (modalSettings.preview || modalSettings.external)) {
      coverComponent.modalSettings = modalSettings;
    }
    coverModal.result.then(
      data => {
        subject.next(data);
      },
      reason => {
        if (
          reason === ModalDismissReasons.ESC ||
          reason === ModalDismissReasons.BACKDROP_CLICK
        ) {
          this.sendMetaData(mission, action);
        }

        subject.next('refresh');
        return;
      }
    );

    return subject;
  }

  skipAction(action: any, mission: any): Subject<any> {
    const subject = new Subject<any>();
    const input = {
      brand_id: mission.brand_id,
      mission_id: mission.mission_id,
      action_id: action.action_id
    };
    this.sdk.ProtocolRespond.skip(
      mission.brand_id,
      mission.mission_id,
      input
    ).subscribe(
      data => {
        if (data.success) {
          this.missionService.onMissionAnswered(mission.mission_id, data.meta);
          subject.next('skip');
        } else {
          let message = data.message;

          if (data.errors) {
            for (const error of Object.keys(data.errors)) {
              message += `\n - ${data.errors[error]}`;
            }
          }

          swal.fire({
            buttonsStyling: false,
            confirmButtonText: 'Continue',
            confirmButtonClass: 'btn-confirm',
            customClass: 'general-modal',
            showConfirmButton: true,
            text: message,
            title: 'Error',
            type: 'warning'
          });

          subject.next(false);
        }
      },
      error => {
        swal.fire({
          buttonsStyling: false,
          confirmButtonText: 'Continue',
          confirmButtonClass: 'btn-confirm',
          customClass: 'general-modal',
          showConfirmButton: true,
          text: error,
          title: 'Error',
          type: 'warning'
        });

        subject.next(false);
      }
    );

    return subject;
  }

  getLabelByKind(kind: string): string {
    return this.labelByKind[kind];
  }

  getMissionByKind(mission: any): string {
    switch (mission.mission_kind) {
      case MissionKind.standard:
        return 'Standard';
      case MissionKind.survey:
        return this.translate.instant('survey.survey');
      case MissionKind.maxdiff:
        return this.translate.instant('survey.survey');
      case MissionKind.sampling:
        return this.translate.instant('sampling.sampling');
      case MissionKind.sharing:
        return 'Sharing';
      case MissionKind.screening:
        return 'Screening';
      case MissionKind.focusgroup:
        return this.translate.instant('focusGroup.focusGroup');
      default:
        return 'Standard';
    }
  }

  sendMetaData(mission, action): void {
    const closedDateTime = Date.now();
    const meta = {
      ...this.metaData,
      type:
        this.getMissionByKind(mission) === 'Standard'
          ? this.getLabelByKind(action.action_kind)
          : this.getMissionByKind(mission),
      actionId: action ? action.action_id : mission.first_action.action_id,
      missionId: mission.mission_id,
      text: action ? action.ui.title : mission.first_action.ui.title,
      closedDateTime,
      attempt: this.startDate,
      startDateTime: this.startDate,
      actionStartDateTime: this.startDate
    };

    this.metaDataService.createMetaData(meta);
  }

  setModalClosing(id: string) {
    if (modalsClosing.length > 1) {
      modalsClosing.shift();
    }
    modalsClosing.push(id);
  }

  showGlobalEndingComponent(
    mission,
    action,
    cintRedirect,
    message?
  ): Subject<any> {
    const subject = new Subject<any>();
    const startTime = new Date().getTime();
    const endingModal = this.modals.open(GlobalEndingComponent, {
      size: 'xl',
      backdrop: false
    });
    const endingComponent =
      endingModal.componentInstance as GlobalEndingComponent;
    if (action) {
      endingComponent.type =
        this.labelByKind[
          action.action_kind
        ]; /* Where I will get the action type  */
    }
    if (mission) {
      endingComponent.points = mission.points;
      endingComponent.mission = mission;
    }
    endingComponent.startTime = startTime;
    endingComponent.cintRedirect = cintRedirect;
    endingComponent.message = message;

    endingModal.result.then(
      res => {
        if (res) {
          subject.next(res);
        } else {
          subject.next('refresh');
          this.modals.dismissAll();

          return;
        }
      },
      () => {
        subject.next('refresh');
        this.modals.dismissAll();

        return;
      }
    );
    return subject;
  }
}
