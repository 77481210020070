import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-mission-expired',
  template: `
    <div
      class="error-popover flex h-screen items-center justify-center text-center"
    >
      <div>
        <svg
          class="m-auto"
          width="73"
          height="74"
          viewBox="0 0 73 74"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M62.22 31.8801C61.9309 31.8801 61.6537 31.7652 61.4492 31.5608C61.2448 31.3564 61.13 31.0791 61.13 30.7901V12.7901C61.13 11.8476 60.7563 10.9436 60.0908 10.2763C59.4254 9.60895 58.5224 9.2327 57.58 9.23006H50.11C49.8402 9.20413 49.5898 9.07861 49.4075 8.87801C49.2253 8.6774 49.1243 8.41609 49.1243 8.14506C49.1243 7.87403 49.2253 7.61272 49.4075 7.41211C49.5898 7.2115 49.8402 7.08599 50.11 7.06006H57.58C59.098 7.06271 60.5528 7.66757 61.6253 8.74187C62.6977 9.81616 63.3 11.2721 63.3 12.7901V30.7901C63.3013 30.9327 63.2744 31.0742 63.2207 31.2064C63.167 31.3386 63.0876 31.4589 62.9872 31.5602C62.8868 31.6616 62.7673 31.742 62.6356 31.7969C62.5039 31.8518 62.3627 31.8801 62.22 31.8801Z"
            fill="#0088FF"
            stroke="#3589F9"
          />
          <path
            d="M28.94 57.6299H6.34999C5.59583 57.6299 4.84909 57.4811 4.15258 57.1918C3.45608 56.9026 2.82352 56.4788 2.29118 55.9446C1.75884 55.4104 1.33718 54.7763 1.0504 54.0788C0.763623 53.3813 0.61736 52.6341 0.619992 51.8799V12.7799C0.622636 11.261 1.22718 9.80513 2.30119 8.73112C3.3752 7.65711 4.83111 7.05257 6.34999 7.04992H13.81C13.9612 7.03539 14.1138 7.05263 14.258 7.10054C14.4022 7.14846 14.5348 7.22599 14.6472 7.32815C14.7597 7.43032 14.8495 7.55486 14.911 7.6938C14.9725 7.83273 15.0043 7.98299 15.0043 8.13492C15.0043 8.28686 14.9725 8.43712 14.911 8.57605C14.8495 8.71499 14.7597 8.83953 14.6472 8.9417C14.5348 9.04386 14.4022 9.12139 14.258 9.16931C14.1138 9.21722 13.9612 9.23446 13.81 9.21992H6.34999C5.40663 9.22256 4.50266 9.59848 3.83561 10.2655C3.16855 10.9326 2.79263 11.8366 2.78999 12.7799V51.8799C2.79264 52.8224 3.16887 53.7253 3.83622 54.3908C4.50356 55.0562 5.40755 55.4299 6.34999 55.4299H28.94C29.2098 55.4559 29.4602 55.5814 29.6425 55.782C29.8247 55.9826 29.9257 56.2439 29.9257 56.5149C29.9257 56.7859 29.8247 57.0473 29.6425 57.2479C29.4602 57.4485 29.2098 57.574 28.94 57.5999V57.6299Z"
            fill="#0088FF"
            stroke="#3589F9"
          />
          <path
            d="M22.89 15.28H13.81C13.5209 15.28 13.2437 15.1652 13.0392 14.9607C12.8348 14.7563 12.72 14.4791 12.72 14.19V2.09C12.72 1.80091 12.8348 1.52367 13.0392 1.31925C13.2437 1.11484 13.5209 1 13.81 1H22.89C23.0327 0.999994 23.1739 1.02825 23.3056 1.08315C23.4373 1.13805 23.5568 1.2185 23.6572 1.31985C23.7576 1.4212 23.837 1.54144 23.8907 1.67363C23.9444 1.80582 23.9713 1.94733 23.97 2.09V14.19C23.9713 14.3327 23.9444 14.4742 23.8907 14.6064C23.837 14.7386 23.7576 14.8588 23.6572 14.9601C23.5568 15.0615 23.4373 15.1419 23.3056 15.1968C23.1739 15.2517 23.0327 15.28 22.89 15.28ZM14.89 13.1H21.79V3.17H14.9L14.89 13.1Z"
            fill="#0088FF"
            stroke="#3589F9"
          />
          <path
            d="M50.11 15.28H41.04C40.7509 15.28 40.4737 15.1652 40.2693 14.9607C40.0648 14.7563 39.95 14.4791 39.95 14.19V2.09C39.95 1.80091 40.0648 1.52367 40.2693 1.31925C40.4737 1.11484 40.7509 1 41.04 1H50.11C50.3991 1 50.6763 1.11484 50.8808 1.31925C51.0852 1.52367 51.2 1.80091 51.2 2.09V14.19C51.2 14.3331 51.1718 14.4749 51.117 14.6071C51.0623 14.7394 50.982 14.8595 50.8808 14.9607C50.7795 15.062 50.6594 15.1423 50.5271 15.197C50.3949 15.2518 50.2531 15.28 50.11 15.28ZM42.11 13.1H49.01V3.17H42.11V13.1Z"
            fill="#0088FF"
            stroke="#3589F9"
          />
          <path
            d="M41.04 9.21992H22.89C22.7388 9.23446 22.5862 9.21722 22.442 9.16931C22.2978 9.12139 22.1652 9.04386 22.0528 8.9417C21.9403 8.83953 21.8505 8.71499 21.789 8.57605C21.7275 8.43712 21.6957 8.28686 21.6957 8.13492C21.6957 7.98299 21.7275 7.83273 21.789 7.6938C21.8505 7.55486 21.9403 7.43032 22.0528 7.32815C22.1652 7.22599 22.2978 7.14846 22.442 7.10054C22.5862 7.05263 22.7388 7.03539 22.89 7.04992H41.04C41.1912 7.03539 41.3438 7.05263 41.488 7.10054C41.6322 7.14846 41.7648 7.22599 41.8772 7.32815C41.9897 7.43032 42.0795 7.55486 42.141 7.6938C42.2025 7.83273 42.2343 7.98299 42.2343 8.13492C42.2343 8.28686 42.2025 8.43712 42.141 8.57605C42.0795 8.71499 41.9897 8.83953 41.8772 8.9417C41.7648 9.04386 41.6322 9.12139 41.488 9.16931C41.3438 9.21722 41.1912 9.23446 41.04 9.21992Z"
            fill="#0088FF"
            stroke="#3589F9"
          />
          <path
            d="M62.22 24.35H1.70999C1.55875 24.3646 1.40615 24.3473 1.26197 24.2994C1.11779 24.2515 0.985213 24.174 0.872757 24.0718C0.760301 23.9697 0.670451 23.8451 0.608963 23.7062C0.547475 23.5672 0.515709 23.417 0.515709 23.265C0.515709 23.1131 0.547475 22.9629 0.608963 22.8239C0.670451 22.685 0.760301 22.5604 0.872757 22.4583C0.985213 22.3561 1.11779 22.2786 1.26197 22.2307C1.40615 22.1827 1.55875 22.1655 1.70999 22.18H62.22C62.4898 22.206 62.7402 22.3315 62.9225 22.5321C63.1047 22.7327 63.2057 22.994 63.2057 23.265C63.2057 23.5361 63.1047 23.7974 62.9225 23.998C62.7402 24.1986 62.4898 24.3241 62.22 24.35Z"
            fill="#0088FF"
            stroke="#3589F9"
          />
          <path
            d="M53.9 72.76C50.2422 72.762 46.6659 71.679 43.6237 69.6481C40.5815 67.6171 38.2101 64.7295 36.8094 61.3505C35.4087 57.9715 35.0417 54.2529 35.7548 50.6653C36.4679 47.0776 38.2291 43.7821 40.8156 41.1956C43.4021 38.6092 46.6976 36.848 50.2852 36.1348C53.8729 35.4217 57.5914 35.7887 60.9704 37.1894C64.3495 38.5901 67.2371 40.9616 69.268 44.0038C71.299 47.046 72.382 50.6222 72.38 54.28C72.3747 59.1796 70.426 63.877 66.9615 67.3415C63.497 70.806 58.7996 72.7547 53.9 72.76ZM53.9 37.97C50.6742 37.97 47.5208 38.9266 44.8386 40.7188C42.1565 42.5109 40.066 45.0582 38.8315 48.0385C37.5971 51.0187 37.2741 54.2981 37.9034 57.462C38.5327 60.6258 40.0861 63.5319 42.3671 65.8129C44.6481 68.0939 47.5542 69.6473 50.7181 70.2766C53.8819 70.906 57.1613 70.583 60.1416 69.3485C63.1218 68.114 65.6691 66.0235 67.4613 63.3414C69.2534 60.6592 70.21 57.5058 70.21 54.28C70.2074 49.9552 68.4881 45.8082 65.43 42.75C62.3718 39.6919 58.2249 37.9727 53.9 37.97Z"
            fill="#0088FF"
            stroke="#3589F9"
          />
          <path
            d="M61.15 58.26H53.9C53.7573 58.2613 53.6158 58.2343 53.4836 58.1806C53.3514 58.127 53.2312 58.0476 53.1298 57.9472C53.0285 57.8468 52.948 57.7273 52.8931 57.5956C52.8382 57.4639 52.81 57.3226 52.81 57.18V47.03C52.7955 46.8787 52.8127 46.7261 52.8606 46.582C52.9085 46.4378 52.9861 46.3052 53.0882 46.1927C53.1904 46.0803 53.3149 45.9904 53.4539 45.9289C53.5928 45.8675 53.7431 45.8357 53.895 45.8357C54.0469 45.8357 54.1972 45.8675 54.3361 45.9289C54.475 45.9904 54.5996 46.0803 54.7018 46.1927C54.8039 46.3052 54.8815 46.4378 54.9294 46.582C54.9773 46.7261 54.9945 46.8787 54.98 47.03V56.09H61.15C61.4198 56.1159 61.6702 56.2414 61.8525 56.442C62.0347 56.6426 62.1357 56.9039 62.1357 57.175C62.1357 57.446 62.0347 57.7073 61.8525 57.9079C61.6702 58.1085 61.4198 58.234 61.15 58.26Z"
            fill="#0088FF"
            stroke="#3589F9"
          />
        </svg>

        <p class="text-black py-6 dark:text-white  font-bold">
          This survey has already ended
        </p>
        <button
          type="button"
          (click)="questionLoad()"
          class="relative px-8 overflow-hidden text-white rounded-full font-bold bg-blue-dark h-11"
        >
          Start another survey
        </button>
      </div>
    </div>
  `
})
export class MissionExpiredNotifyComponent {
  data: any = {};

  constructor(private activeModal: NgbActiveModal) {}

  questionLoad() {
    this.activeModal.close();
  }
}
