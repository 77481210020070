import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  // tslint:disable-next-line:pipe-naming
  name: 'absUrl'
})
export class AbsUrlPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value.includes('http')) {
      return `http://${value}`;
    }

    return value;
  }
}
