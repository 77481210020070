import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '@suzy/crowdtap/data-access/user';
import {
  CampaignKeysName,
  PanelKeysName,
  PromoKeysName,
  ReferralKeysName,
  SegmentService
} from '@suzy/shared/data-access/tracking';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { PasswordValidators } from '../../../../views/anonymous/signup/account-creation-email/PasswordValidators';
import {
  ISignupUser,
  SignupService
} from '../../../../views/anonymous/signup/signup.service';

@Component({
  selector: 'app-account-creation-email',
  templateUrl: './account-creation-email.component.html',
  styleUrls: ['./account-creation-email.component.scss']
})
export class AccountCreationEmailComponent implements AfterViewInit, OnInit {
  @ViewChild('passwordFields', { static: true }) passwordFields: ElementRef;
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef;
  el_passwordFields: HTMLElement;
  el_wrapper: HTMLElement;

  form: UntypedFormGroup;
  user: ISignupUser;
  submitted = false;
  isChange = false;
  isLoading: boolean;
  currentSource: any;
  campaignKeysName: CampaignKeysName;
  sourceUrl: string;
  currentPanelToken: string;
  newPanelToken: string;
  panelKeysName: PanelKeysName;
  currentPanelKeys: any;
  currentCampaignKeys: any;
  currentReferralKeys: any;
  currentReferralToken: any;
  currentPromoKeys: any;
  currentPromoToken: any;
  promoKeysName: PromoKeysName;
  referralKeysName: ReferralKeysName;
  newReferralToken: any;
  newPromoToken: any;
  skipLinkPathContent: string;
  skipLinkPathFooter: string;

  constructor(
    private fb: UntypedFormBuilder,
    private signupService: SignupService,
    private router: Router,
    private auth: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private gtm: Angulartics2GoogleTagManager,
    private titleService: Title,
    private segmentService: SegmentService
  ) {}

  ngOnInit(): void {
    this.setHeadTitle();
    this.skipLinkPathContent = `${this.router.url}#main-content`;
    this.skipLinkPathFooter = `${this.router.url}#main-footer`;
    this.user = this.signupService.getUser();
    this.createForm();
    this.checkPanelToken();
    this.checkReferralToken();
    this.checkPromoToken();
  }

  ngAfterViewInit(): void {
    this.el_passwordFields = this.passwordFields.nativeElement as HTMLElement;
    this.el_wrapper = this.wrapper.nativeElement as HTMLElement;
    this.checkCampaignKeys();
  }

  setHeadTitle(): any {
    this.titleService.setTitle('Crowdtap® - Sign Up');
  }

  onBackToTop(): void {
    this.el_wrapper.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    });
  }

  checkCampaignKeys(): void {
    this.campaignKeysName = new CampaignKeysName();
    this.currentSource = this.auth.getLocalKey(this.campaignKeysName.utmSource);
    this.sourceUrl = this.auth.getLocalKey(this.campaignKeysName.source);

    if (!this.sourceUrl) {
      const snapshot: RouterStateSnapshot = this.router.routerState.snapshot;

      if (snapshot.url.includes('?')) {
        this.auth.setLocalKey(
          this.campaignKeysName.source,
          snapshot.url.substring(snapshot.url.indexOf('?'))
        );
      }
    }

    if (!this.currentSource) {
      this.activatedRoute.queryParams.subscribe(params => {
        if (params[this.campaignKeysName.utmSource]) {
          for (const prop in params) {
            if (params[prop]) {
              this.auth.setLocalKey(prop, params[prop]);
            }
          }
          this.currentCampaignKeys = this.auth.getCurrentCampaignKeys();
        }
      });
    } else {
      this.currentCampaignKeys = this.auth.getCurrentCampaignKeys();
    }
  }

  checkPanelToken(): void {
    this.panelKeysName = new PanelKeysName();
    this.currentPanelToken = this.auth.getLocalKey(
      this.panelKeysName.panelToken
    );
    this.setNewPanelKeys();
    this.currentPanelKeys = this.auth.getCurrentPanelKeys();
  }

  setNewPanelKeys(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params[this.panelKeysName.panelToken]) {
        for (const prop in params) {
          if (prop === this.panelKeysName.panelToken) {
            this.newPanelToken = params[prop];
            if (!this.currentPanelToken) {
              this.auth.setLocalKey(prop, this.newPanelToken);
            } else {
              if (this.currentPanelToken !== this.newPanelToken) {
                this.auth.setLocalKey(prop, this.newPanelToken);
              }
            }
            this.currentPanelKeys = this.auth.getCurrentPanelKeys();
          }
        }
      } else {
        this.auth.purgePanelKeys();
      }
    });
  }

  checkReferralToken(): void {
    this.referralKeysName = new ReferralKeysName();
    this.currentReferralToken = this.auth.getLocalKey(
      this.referralKeysName.referralToken
    );
    this.setNewReferralKeys();
    this.currentReferralKeys = this.auth.getCurrentReferralKeys();
  }

  setNewReferralKeys(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params[this.referralKeysName.referralToken]) {
        for (const prop in params) {
          if (prop === this.referralKeysName.referralToken) {
            this.newReferralToken = params[prop];
            if (!this.currentReferralToken) {
              this.auth.setLocalKey(prop, this.newReferralToken);
            } else {
              if (this.currentReferralToken !== this.newReferralToken) {
                this.auth.setLocalKey(prop, this.newReferralToken);
              }
            }
            this.currentReferralKeys = this.auth.getCurrentReferralKeys();
          }
        }
      } else {
        this.auth.purgeReferralKeys();
      }
    });
  }

  checkPromoToken(): void {
    this.promoKeysName = new PromoKeysName();
    this.currentPromoToken = this.auth.getLocalKey(
      this.promoKeysName.promoToken
    );
    this.setNewPromoKeys();
    this.currentPromoKeys = this.auth.getCurrentPromoKeys();
  }

  setNewPromoKeys(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params[this.promoKeysName.promoToken]) {
        for (const prop in params) {
          if (prop === this.promoKeysName.promoToken) {
            this.newPromoToken = params[prop];
            if (!this.currentPromoToken) {
              this.auth.setLocalKey(prop, this.newPromoToken);
            } else {
              if (this.currentPromoToken !== this.newPromoToken) {
                this.auth.setLocalKey(prop, this.newPromoToken);
              }
            }
            this.currentPromoKeys = this.auth.getCurrentPromoKeys();
          }
        }
      } else {
        this.auth.purgePromoKeys();
      }
    });
  }

  createForm(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(50),
          PasswordValidators.password
        ]
      ]
    });
  }

  fieldErrors(name: string): any {
    const control = this.form.get(name);

    if (control && (control.touched || this.submitted) && control.errors) {
      return control.errors;
    } else {
      return undefined;
    }
  }

  onChangeHandler(e): void {
    this.checkInput(e);
  }

  checkInput(e): void {
    if (e.target.value.length > 0) {
      this.el_passwordFields.classList.add('ng-changed');
      this.isChange = true;
    } else {
      this.el_passwordFields.classList.remove('ng-changed');
      this.isChange = false;
    }
  }

  toggleVisibility(e): void {
    if (e.target.nextElementSibling.type === 'password') {
      e.target.nextElementSibling.type = 'text';
      e.target.classList.add('isVisible');
    } else {
      e.target.nextElementSibling.type = 'password';
      e.target.classList.remove('isVisible');
    }
  }

  hasValue(field): boolean {
    return this.form.get(field).value;
  }

  onSubmit(): void {
    this.submitted = true;
    this.isLoading = true;

    if (this.form.valid) {
      const model = this.form.value;

      this.signupService.setUser({
        ...this.user,
        email: model.email,
        password: model.password
      });
      this.gtm.eventTrack('js-home-sign-up-success', {
        event: 'js-home-sign-up-success',
        gtmCustom: {
          is_fb_registered: false,
          is_email_registered: true
        }
      });
      this.segmentService.identify(undefined, {
        email: model.email
      });
      this.segmentService.track('Sign Up Initiated', {
        method: 'email'
      });

      this.router.navigate(['auth', 'get-to-know-you']);
    }
  }
}
