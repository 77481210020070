import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable, of } from 'rxjs/';
import { map, catchError, retry } from 'rxjs/operators';
import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import { AuthenticationService } from '@suzy/crowdtap/data-access/user';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class EthnicitiesResolver implements Resolve<Observable<any>> {
  constructor(
    private sdk: SuzySdkService,
    private router: Router,
    private auth: AuthenticationService,
    private translate: TranslateService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this.sdk.ProtocolRegister.ethnicityFind().pipe(
      map((response: any) => {
        if (response.success) {
          return response.items.map(item => {
            return {
              id: item.ethnicity_id,
              name: item.token
            };
          });
        } else {
          return response;
        }
      }),
      retry(5),
      catchError(error => {
        window.location.href = this.router
          .parseUrl(this.router.url)
          .root.children['primary'].segments.map(it => it.path)
          .join('/');

        return of({ error });
      })
    );
  }
}
