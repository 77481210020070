<button class="btn btn-upload" (click)="fileInput.click()">
  {{ 'general.upload' | translate }}
</button>
<input
  type="file"
  accept="{{ fileTypes || 'image/png' }}"
  hidden
  #fileInput
  required
  (change)="handleFileInput($event.target.files)"
/>
<!-- <span>{{ imageName }}</span> -->
