<h1>Crowdtap® Cookie Policy</h1>
<p>Updated: September 12, 2022</p>
<p>
  This Cookie Policy (“Policy”) describes how Suzy, Inc., the owner of
  Crowdtap®, uses cookies on the
  <a href="http://www.crowdtap.com/">www.crowdtap.com</a> website, the Crowdtap
  platform, the Crowdtap mobile application, and any other websites, mobile
  applications, or platforms that display this Notice (collectively, the
  “Sites,” and any, a "Site").
</p>
<p>
  Throughout this Policy, we will use the terms “Suzy”, "us", "we", or "our" to
  collectively mean Suzy, Inc., the owner of the Crowdtap platform, and its
  subsidiaries and affiliates.
</p>
<p>
  If you have questions or feedback on this Policy, please email trust@suzy.com.
</p>
<h2>Table of Contents</h2>
<div class="toc">
  <ul>
    <li>
      <a routerLink="./" fragment="what-are-cookies">What are Cookies?</a>
    </li>
    <li>
      <a routerLink="./" fragment="what-are-first-party-cookies"
        >What are First Party Cookies?</a
      >
    </li>
    <li>
      <a routerLink="./" fragment="what-are-third-party-cookies"
        >What are Third Party Cookies?</a
      >
    </li>
    <li>
      <a
        routerLink="./"
        fragment="what-are-session-cookies-vs-persistent-cookies"
      >
        What are Session Cookies vs. Persistent Cookies?
      </a>
    </li>
    <li>
      <a routerLink="./" fragment="how-does-suzy-use-cookies"
        >How Does Suzy Use Cookies?</a
      >
    </li>
    <li>
      <a routerLink="./" fragment="cookie-list">Cookie List</a>
      <ul>
        <li>
          <a routerLink="./" fragment="necessary-cookies">Necessary Cookies</a>
        </li>
        <li>
          <a routerLink="./" fragment="functional-cookies"
            >Functional Cookies</a
          >
        </li>
        <li>
          <a routerLink="./" fragment="analytics-performance-cookies"
            >Analytics / Performance Cookies</a
          >
        </li>
        <li>
          <a routerLink="./" fragment="marketing-tracking-cookies"
            >Marketing / Tracking Cookies</a
          >
        </li>
        <li>
          <a routerLink="./" fragment="other-tracking-technologies"
            >Other Tracking Technologies</a
          >
        </li>
      </ul>
    </li>
    <li>
      <a routerLink="./" fragment="what-are-my-choices">What are My Choices?</a>
    </li>
    <li>
      <a routerLink="./" fragment="browser-settings-and-do-not-track-disclosure"
        >Browser Settings and Do Not Track Disclosure</a
      >
    </li>
    <li>
      <a routerLink="./" fragment="where-can-i-learn-more-about-cookies"
        >Where Can I Learn More About Cookies?</a
      >
    </li>
    <li>
      <a routerLink="./" fragment="customer-use-of-cookies"
        >Customer Use of Cookies</a
      >
    </li>
    <li><a routerLink="./" fragment="contact-us">Contact Us</a></li>
    <li>
      <a routerLink="./" fragment="changes-to-this-policy"
        >Changes to this Policy</a
      >
    </li>
  </ul>
</div>

<h2 id="what-are-cookies">What are Cookies?</h2>
<p>
  A cookie is a small text file placed on your device (computer, tablet,
  smartphone, or any other mobile device). When you access a website, a cookie
  is placed on your device, and it will send information to the party that
  placed the cookie. Each cookie expires after a certain period of time
  depending on what it is used for.
</p>
<p>
  In this Policy, we use the word “cookies” as a catchall term to include not
  only cookies, but also other tracking technologies such as device identifiers,
  geolocation data, pixels, web beacons, and page tags.
</p>
<h2 id="what-are-first-party-cookies">What are First Party Cookies?</h2>
<p>
  The difference between a first-party cookie and a third-party cookie relates
  to who places the cookie on your device. When visiting the Sites, first-party
  cookies are cookies set by and controlled by us. These cookies enable us to
  operate an efficient service and to track patterns of user behavior to our
  Sites.
</p>
<h2 id="what-are-third-party-cookies">What are Third Party Cookies?</h2>
<p>
  Third party cookies are cookies set by someone other than us for purposes like
  collecting information on user behavior, demographics, or personalized
  marketing. Third-party cookies are also used to report usage statistics of the
  Sites and to deliver advertisements on and through the Sites.
</p>
<p>
  We do not have control over the placement of cookies by third parties, even if
  you are directed to them from our Sites. We do not have control over the
  information supplied by the cookies, nor do we retain access to this
  information. This information is controlled wholly by that third party,
  according to the respective privacy policy of the third party.
</p>
<h2 id="what-are-session-cookies-vs-persistent-cookies">
  What are Session Cookies vs. Persistent Cookies?
</h2>
<p>
  Cookies can be "persistent" or "session" cookies. Persistent cookies remain on
  your device when you go offline, while session cookies expire as soon as you
  close your web browser or end your browser session.
</p>
<h2 id="how-does-suzy-use-cookies">How Does Suzy Use Cookies?</h2>
<p>
  Like most commercial websites, we use cookies on our Sites. We use both
  session and persistent cookies on the Sites. We use first-party cookies and
  allow third parties to place cookies on your device.
</p>
<p>We use cookies for the following purposes:</p>
<ul>
  <li>Assisting you in navigating the Sites;</li>
  <li>To make your next visit to the Sites easier and more useful to you;</li>
  <li>Enabling certain functions of the Sites;</li>
  <li>Authentication;</li>
  <li>
    Server Affinity (which means that we use cookies to speed load times by
    sending traffic to the optimal server);
  </li>
  <li>
    UserIQ (which that use a third party cookie to provide communications and
    tutorials to users like you);
  </li>
  <li>
    Spam prevention (in other words, keeping bad actors from acting badly);
  </li>
  <li>
    To provide internal analytics, which we use to monitor and improve the Sites
    and features;
  </li>
  <li>To provide social media features; and</li>
  <li>
    To share information about your use of the Sites with our social media,
    advertising, and analytics partners, who may combine it with other
    information you've provided to them or that they've collected from your use
    of their services.
  </li>
</ul>
<h2 id="cookie-list">Cookie List</h2>
<p>
  We want you to be in a position to make an informed decision about cookies.
  Below is a list of the cookies and other tracking technologies we use on the
  Sites. We differentiate between:
</p>
<ol>
  <li>
    Necessary cookies that are absolutely essential for the Site's technical
    features,
  </li>
  <li>
    Functional cookies that allow us to provide enhanced functionality and
    personalization,
  </li>
  <li>
    Analytics cookies that allow us to analyze Site usage and performance, and
  </li>
  <li>
    Marketing / tracking cookies that are used by advertising companies to serve
    ads relevant to your interests.
  </li>
</ol>
<p>
  To accept or reject certain cookies, use our Cookie Preferences page. Please
  note that rejecting, clearing, or disabling cookies might impact your ability
  to use the Sites effectively--particularly your ability to use the Crowdtap
  platform effectively. For example, enabling cookies ensures a smoother
  survey-taking experience.
</p>
<h3 id="necessary-cookies">
  <strong>Necessary Cookies</strong>
</h3>
<h4>
  <strong>(Example: a cookie used for login authentication)</strong>
</h4>
<p>
  These cookies are necessary for the Sites and cannot be switched off in our
  systems. These cookies are required for the Sites to operate correctly, remain
  secure, enable essential Site features, and stabilize the Sites.
</p>
<p>
  Often, these cookies are set in response to actions made by you which amount
  to a request for services, such as logging in or filling in forms. We also use
  accounts-related cookies to authenticate users and prevent fraudulent use of
  user accounts. For example, we use cookies to authenticate you for
  survey-related services. When you log on to our websites, authentication
  cookies are set which let us know who you are during a browsing session.
  (Example cookie name: sso_user).
</p>
<p>
  We have to load necessary cookies for legitimate interests pursued by us in
  delivering essential functionality of our Sites to you.
  <strong>
    This category of cookies is “on” by default and cannot be disabled
  </strong>
  .
</p>
<h3 id="functional-cookies">
  <strong>Functional Cookies</strong>
</h3>
<h4>
  <strong>(Example: a cookie that stores your language preference)</strong>
</h4>
<p>
  These cookies collect information about your choices and preferences and use
  of the Sites, which enable us to provide enhanced functionality and
  personalization. For example, these cookies show us which are the most
  frequently visit pages on the Sites, allow us to present Sites according to
  the settings you selected (for example, a user's language preference), help us
  record any difficulties you have with the Sites, show us whether our
  advertising is effective or not, enable us to test new functionality, and
  allow us to measure and analyze site traffic at the aggregate level.
</p>
<p>
  These cookies may be set by us or by third party providers whose services we
  have added to our pages.
</p>
<p>
  If you do not allow these cookies, then some or all parts of the Sites will
  not function properly.
</p>
<h3 id="analytics-performance-cookies">
  <strong>Analytics / Performance Cookies</strong>
</h3>
<h4>
  <strong>(Example: a cookie that reports site usage statistics)</strong>
</h4>
<p>
  These cookies allow us to track user browsing behavior, including how the
  Sites are used, which pages are the most and least popular, and from what
  sources Site traffic arrives. This information is used to measure and improve
  the performance of our Sites, as well as to test new advertisements, pages,
  features, or functionality to see how users react to them.
</p>
<p>
  These cookies may be set by us or by third party providers whose services we
  have added to our pages.
</p>
<p>
  If you do not allow these cookies, we will not know when you have visit our
  Site.<strong><em></em></strong>
</p>
<h3 id="marketing-tracking-cookies">
  <strong>Marketing / Tracking Cookies</strong>
</h3>
<h4>
  <strong>
    (Example: cookies set from marketing affiliates like LinkedIn, Facebook, or
    TikTok)
  </strong>
</h4>
<p>
  We may use cookies to market our services to you on third party websites or to
  measure and track the effectiveness of our marketing efforts so that we can
  tailor our advertising more effectively.
</p>
<p>
  These cookies may be set by us or by our advertising partners. They can be
  used by these third parties to build a profile of your interests based on the
  browsing information they collect from you, which includes uniquely
  identifying your browser and terminal equipment.
</p>
<p>
  Generally, these cookies are persistent cookies that track web activity to
  provide targeted marketing. For example, ad networks follow your online
  activities over time by collecting website navigational information through
  automated means, including through the use of cookies. They use this
  information to provide advertisements about products and services that may be
  of interest to you. You may see these advertisements on other websites.
</p>
<p>
  If you do not allow these cookies, you will still see basic advertising on
  your browser that is generic but not based on your interests.
</p>
<h3 id="other-tracking-technologies">
  <strong>Other Tracking Technologies</strong>
</h3>
<h4>
  <strong>1. Geolocation and Device Identifiers</strong>
</h4>
<p>
  If you access the Sites from a mobile or other device, we may collect a unique
  device identifier assigned to that device ("UDID"), geolocation data, or other
  transactional information for that device.
</p>
<p>
  We may collect information about your geolocation when your computer or mobile
  or other type of device is set to provide geolocation information or from
  other information such as your IP address, GPS, or WiFi information. For
  example, when your computer or device's GPS signal allows us to show you our
  nearby stores or to otherwise collect your geolocation information to the city
  you are located in when you visit or use the Sites.
</p>
<h4>
  <strong>2. Web Beacons</strong>
</h4>
<p>
  We may use web beacons (sometimes called “tracking pixels” or “clear gifs”)
  from time to time to recognize or track visitors to the Sites. Web beacons are
  tiny graphics files that contain a unique identifier that enable us to
  recognize when someone has visited our Sites or opened an email that we have
  sent them.
</p>
<p>
  This allows us, for example, to monitor the traffic patterns of users from one
  page within our Sites to another, to deliver or communicate with cookies, to
  understand whether you have come to our Sites from an online advertisement
  displayed on a third-party website, to improve site performance, and to
  measure the success of email marketing campaigns. In many instances, these
  technologies are reliant on cookies to function properly, and so declining
  cookies will impair their functioning.
</p>
<h2 id="what-are-my-choices">What are My Choices?</h2>
<p>
  By default, the browsing experience of our Sites is set to Necessary cookies
  <strong>only. </strong>You may, though, choose to consent to additional
  cookies using our Cookie Preferences form. If you later change your mind, you
  can customize your preferences at any time through our Cookie Preferences
  form.
</p>
<p>
  If you choose to reject certain cookies, you may still use our Sites, but your
  access to some functionality and areas will be reduced or restricted.
</p>
<h2 id="browser-settings-and-do-not-track-disclosure">
  Browser Settings and Do Not Track Disclosure
</h2>
<p>
  “Do Not Track” is a feature enabled on some browsers that sends a signal to
  request that a website disable its tracking or cross-website user tracking. We
  treat any user-enabled global privacy controls, such as a browser plug-in or
  privacy setting, device setting, or other mechanism, that communicate or
  signal your choice to opt-out of the sale of your personal information as a
  valid request submitted pursuant to applicable privacy laws for that browser
  or device, or, if known, for the individual.
</p>
<h2 id="where-can-i-learn-more-about-cookies">
  Where Can I Learn More About Cookies?
</h2>
<p>You can learn more about cookies at the following third-party websites:</p>
<ul>
  <li>
    AllAboutCookies:<a href="http://www.allaboutcookies.org/"> </a>
    <a href="http://www.allaboutcookies.org/">
      http://www.allaboutcookies.org/
    </a>
  </li>
  <li>
    Network Advertising Initiative:
    <a href="http://www.networkadvertising.org/"> </a>
    <a href="http://www.networkadvertising.org/">
      http://www.networkadvertising.org/
    </a>
  </li>
</ul>
<h2 id="customer-use-of-cookies">Customer Use of Cookies</h2>
<p>
  If you are a third party provider of ours and you separately use cookies or
  similar technologies on your site in conjunction with a Suzy product, you are
  independently responsible for management of the data collected through those
  cookies and for compliance with all laws related to usage of these
  technologies.
</p>
<h2 id="contact-us">Contact Us</h2>
<p>
  If you have questions or complaints relating to this Policy or would like to
  change your cookie preferences, you can do so by:
</p>
<ol>
  <li>customizing your Cookie Preferences;</li>
  <li>
    contacting
    <a href="https://support.crowdtap.com/">Crowdtap Support</a>;
  </li>
  <li>e-mailing trust@suzy.com; or</li>
  <li>
    writing to us at: ATTN: Legal Dept., Suzy, Inc., 228 Park Avenue South, PMB
    85529 Broadway, New York, NY 10003
  </li>
</ol>
<p>
  We generally respond to support requests within 10-14 days after the request
  is placed.
</p>
<h2 id="changes-to-this-policy">Changes to this Policy</h2>
<p>
  We may update or revise this Policy as needed from time to time in light of,
  for example, changing business practices, technology, or legal requirements.
  When we make changes to this Policy, we will amend the “updated” date at the
  top of this page. We encourage users to visit this page periodically to review
  any updates and remain informed about how we are processing and protecting
  data.
</p>
<h3>Prior Versions of this Notice</h3>
<ul>
  <li><a routerLink="/cookie-policy/2019-01-19">January 19, 2019</a></li>
</ul>
