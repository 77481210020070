<div class="preroll-container">
  <div class="preroll-instruction-container" *ngIf="preRollCaption">
    <p class="text-base font-semibold md:text-lg lg:text-xl lg:font-medium">
      {{ preRollCaption }}
    </p>
  </div>
  <div class="preroll-container-inner">
    <div class="preroll-container-inner-body">
      <div
        [ngClass]="{ darkMode: isDarkMode }"
        class="view-media-prompt preroll-kind-image"
        (click)="onViewPrerollImage()"
        data-track="member-view-media"
        *ngIf="preRollKind === preRollKindImage"
        onContextMenu="return false;"
      >
        <div class="view-media-prompt-items">
          <div class="view-media-prompt-item">
            <div
              class="text-sm font-semibold icon md:text-base md:font-medium lg:text-lg"
              [ngClass]="{ darkMode: isDarkMode }"
            >
              <svg
                width="36"
                height="35"
                viewBox="0 0 36 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M24.8333 14.5423L16.2916 9.60521C15.235 8.99519 13.9334 8.99486 12.8765 9.60433C11.8196 10.2138 11.1679 11.3406 11.1666 12.5606V22.4348C11.1679 23.6542 11.8189 24.7805 12.875 25.3902C13.9321 26.0005 15.2345 26.0005 16.2916 25.3902L24.8333 20.4531C25.887 19.8419 26.5356 18.7159 26.5356 17.4977C26.5356 16.2795 25.887 15.1535 24.8333 14.5423ZM23.1249 17.4978L14.5833 22.4349V12.5607L23.1249 17.4978ZM18 0.41449C8.5651 0.41449 0.916626 8.06296 0.916626 17.4978C0.916626 26.9327 8.5651 34.5812 18 34.5812C27.4348 34.5812 35.0833 26.9327 35.0833 17.4978C35.0833 12.967 33.2834 8.62183 30.0797 5.41808C26.876 2.21434 22.5307 0.41449 18 0.41449ZM17.9999 31.1643C10.4521 31.1643 4.33328 25.0455 4.33328 17.4976C4.33328 9.94972 10.4521 3.83094 17.9999 3.83094C25.5478 3.83094 31.6666 9.94972 31.6666 17.4976C31.6666 21.1222 30.2267 24.5984 27.6637 27.1614C25.1007 29.7244 21.6246 31.1643 17.9999 31.1643Z"
                  fill="white"
                />
              </svg>

              {{ 'general.viewMedia' | translate }}
            </div>
          </div>
        </div>
      </div>
      <!-- <div
        [ngClass]="{ darkMode: isDarkMode }"
        class="view-media-prompt preroll-kind-image isHandheld"
        (click)="onPrerollViewed()"
        *ngIf="preRollKind === preRollKindImage"
        data-track="member-view-media"
      >
        <a
          [href]="preRollUrl | absUrl"
          target="_blank"
          #prerollLink
          onContextMenu="return false;"
        >
          <div class="view-media-prompt-items">
            <div class="view-media-prompt-item">
              <div
                class="text-sm font-semibold icon md:text-base md:font-medium lg:text-lg"
                [ngClass]="{ darkMode: isDarkMode }"
              >
                <svg
                  width="36"
                  height="35"
                  viewBox="0 0 36 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M24.8333 14.5423L16.2916 9.60521C15.235 8.99519 13.9334 8.99486 12.8765 9.60433C11.8196 10.2138 11.1679 11.3406 11.1666 12.5606V22.4348C11.1679 23.6542 11.8189 24.7805 12.875 25.3902C13.9321 26.0005 15.2345 26.0005 16.2916 25.3902L24.8333 20.4531C25.887 19.8419 26.5356 18.7159 26.5356 17.4977C26.5356 16.2795 25.887 15.1535 24.8333 14.5423ZM23.1249 17.4978L14.5833 22.4349V12.5607L23.1249 17.4978ZM18 0.41449C8.5651 0.41449 0.916626 8.06296 0.916626 17.4978C0.916626 26.9327 8.5651 34.5812 18 34.5812C27.4348 34.5812 35.0833 26.9327 35.0833 17.4978C35.0833 12.967 33.2834 8.62183 30.0797 5.41808C26.876 2.21434 22.5307 0.41449 18 0.41449ZM17.9999 31.1643C10.4521 31.1643 4.33328 25.0455 4.33328 17.4976C4.33328 9.94972 10.4521 3.83094 17.9999 3.83094C25.5478 3.83094 31.6666 9.94972 31.6666 17.4976C31.6666 21.1222 30.2267 24.5984 27.6637 27.1614C25.1007 29.7244 21.6246 31.1643 17.9999 31.1643Z"
                    fill="white"
                  />
                </svg>

                {{ 'general.viewMedia' | translate }}
              </div>
            </div>
          </div>
        </a>
      </div> -->
      <div
        [ngClass]="{ darkMode: isDarkMode }"
        class="view-media-prompt preroll-kind-link"
        (click)="onPrerollViewed()"
        *ngIf="preRollKind === preRollKindLink"
        data-track="member-view-media"
        onContextMenu="return false;"
      >
        <a [href]="preRollUrl | absUrl" target="_blank" #prerollLink>
          <div class="view-media-prompt-items">
            <div class="view-media-prompt-item">
              <div
                class="text-sm font-semibold icon md:text-base md:font-medium lg:text-lg"
                [ngClass]="{ darkMode: isDarkMode }"
              >
                <svg
                  width="36"
                  height="35"
                  viewBox="0 0 36 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M24.8333 14.5423L16.2916 9.60521C15.235 8.99519 13.9334 8.99486 12.8765 9.60433C11.8196 10.2138 11.1679 11.3406 11.1666 12.5606V22.4348C11.1679 23.6542 11.8189 24.7805 12.875 25.3902C13.9321 26.0005 15.2345 26.0005 16.2916 25.3902L24.8333 20.4531C25.887 19.8419 26.5356 18.7159 26.5356 17.4977C26.5356 16.2795 25.887 15.1535 24.8333 14.5423ZM23.1249 17.4978L14.5833 22.4349V12.5607L23.1249 17.4978ZM18 0.41449C8.5651 0.41449 0.916626 8.06296 0.916626 17.4978C0.916626 26.9327 8.5651 34.5812 18 34.5812C27.4348 34.5812 35.0833 26.9327 35.0833 17.4978C35.0833 12.967 33.2834 8.62183 30.0797 5.41808C26.876 2.21434 22.5307 0.41449 18 0.41449ZM17.9999 31.1643C10.4521 31.1643 4.33328 25.0455 4.33328 17.4976C4.33328 9.94972 10.4521 3.83094 17.9999 3.83094C25.5478 3.83094 31.6666 9.94972 31.6666 17.4976C31.6666 21.1222 30.2267 24.5984 27.6637 27.1614C25.1007 29.7244 21.6246 31.1643 17.9999 31.1643Z"
                    fill="white"
                  />
                </svg>

                {{ 'general.viewMedia' | translate }}
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div class="py-6 preroll-container-inner-footer" *ngIf="!prerollVisible">
      <div class="row">
        <div class="text-center col-12">
          <button
            disabled
            class="h-12 px-8 text-sm font-bold text-white rounded-full md:text-base bg-blue-dark disabled:opacity-50 text-uppercase"
          >
            {{ 'general.next' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
