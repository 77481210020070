import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import {
  AuthenticationService,
  UserProfileService
} from '@suzy/crowdtap/data-access/user';
import { PointsService } from '@suzy/crowdtap/tools/points';
import { environment } from 'apps/crowdtap/src/environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  profilePhoto: string;
  taps: number;
  streak: number;
  points$: Observable<number>;
  @Input() isMobile: boolean;
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private auth: AuthenticationService,
    private userProfileService: UserProfileService,
    private pointsService: PointsService
  ) {
    this.points$ = this.pointsService.points$;
  }

  ngOnInit(): void {
    this.auth
      .ensureUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: any) => {
        this.profilePhoto = this.userProfileService.getUserProfileThumbnail();
        this.setUpPoints();
      });
  }

  setUpPoints(): void {
    this.pointsService.refreshPoints();
  }

  doLogout(): void {
    this.auth.logout(true);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
