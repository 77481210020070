<div class="wrapper" #wrapper>
  <app-general-header viewKind="signup"></app-general-header>

  <section class="anonymous" aria-label="main" role="main" id="main-content">
    <article
      class="flex flex-col w-full px-4 m-auto mt-6 max-w-auth sm:max-w-sm-auth"
    >
      <div class="flex overflow-hidden text-xs rounded progress-bar bg-gray">
        <div
          [ngStyle]="{ width: user.progressStatus + '%' }"
          class="flex flex-col justify-center text-center text-white shadow-none whitespace-nowrap bg-blue-dark"
        ></div>
      </div>
      <div class="center-column">
        <h1
          class="mt-6 mb-8 text-4xl font-bold text-center text-light-black dark:text-white sm:text-3xl"
        >
          {{ 'almostThere.title' | translate }}
        </h1>
        <p
          class="mt-6 mb-8 text-center subtitle text-light-black dark:text-white"
        >
          {{ 'almostThere.subtitle' | translate }}
        </p>

        <form [formGroup]="almostThereForm" (ngSubmit)="onSubmit()">
          <div class="mb-6 form-row">
            <label class="text-light-black dark:text-white">
              {{ 'address.city' | translate }}
            </label>

            <div #cityAddress>
              <input
                type="text"
                class="block w-full p-2 mt-2 border rounded-sm input-edge text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
                formControlName="city"
                placeholder="{{ 'address.city' | translate }}"
                data-track="member-sign-up-zip-code"
              />
            </div>
            <small
              *ngIf="fieldErrors('city')"
              class="form-text text-muted danger"
            >
              <span *ngIf="fieldErrors('city').required">
                {{ 'general.required' | translate }}
              </span>
            </small>
          </div>
          <div class="mb-6 form-row">
            <label class="text-light-black dark:text-white">
              {{ 'address.state' | translate }}
            </label>

            <div #location>
              <select
                formControlName="state_id"
                data-track="member-sign-up-state"
                class="block w-full p-2 mt-2 mb-3 border rounded-sm form-select form-select-lg input-edge text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
                aria-label=".form-select-lg example"
              >
                <option value="" selected disabled>
                  {{ 'address.state' | translate }}
                </option>
                <option *ngFor="let state of stateOptions" [value]="state.id">
                  {{ state.short }}
                </option>
              </select>
            </div>
            <small
              *ngIf="fieldErrors('state_id')"
              class="form-text text-muted danger"
            >
              <span *ngIf="fieldErrors('state_id').required">
                {{ 'general.required' | translate }}
              </span>
            </small>
          </div>
          <div class="mb-6 form-row">
            <label class="text-light-black dark:text-white">
              {{ 'address.zipCode' | translate }}
            </label>

            <div #zipCode>
              <input
                type="text"
                class="block w-full p-2 mt-2 border rounded-sm input-edge text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
                formControlName="zip_code"
                placeholder="{{ 'address.zipCode' | translate }}"
                data-track="member-sign-up-zip-code"
              />
            </div>
            <small
              *ngIf="fieldErrors('zip_code')"
              class="form-text text-muted danger"
            >
              <span *ngIf="fieldErrors('zip_code').pattern">
                {{ 'address.invalidFormat' | translate }}
              </span>
            </small>
            <small
              *ngIf="fieldErrors('zip_code')"
              class="form-text text-muted danger"
            >
              <span *ngIf="fieldErrors('zip_code').required">
                {{ 'general.required' | translate }}
              </span>
            </small>
          </div>
          <div class="mb-6">
            <div class="relative check-container">
              <input
                type="checkbox"
                class="styled-checkbox"
                name=""
                id="check-1"
                formControlName="accepted"
                data-track="member-login-keep-sign-up-btn"
              />
              <label
                class="custom-checkbox text-light-black dark:text-white"
                for="check-1"
                style="font-size: 14px"
              >
                <span>
                  {{ 'almostThere.agreement' | translate }}
                  <a
                    routerLink="/terms-of-service"
                    class="text-blue-dark underline"
                  >
                    Terms of Service
                  </a>
                  and
                  <a
                    routerLink="/privacy-policy"
                    class="text-blue-dark underline"
                    >Privacy Policy</a
                  >
                </span>
              </label>
              <label class="custom-checkbox" for="check-1">
                <span
                  class="check-icon"
                  [ngClass]="{ error: fieldErrors('accepted')?.required }"
                ></span>
              </label>
            </div>
            <small
              *ngIf="fieldErrors('accepted')"
              class="form-text text-muted danger"
            >
              <span *ngIf="fieldErrors('accepted')?.required">
                You must accept Crowdtap's Terms of Service and Privacy Policy
              </span>
            </small>
          </div>

          <section class="flex justify-center mt-6 space-x-8 text-center">
            <button
              type="button"
              class="text-black action-btn dark:text-white max-w-38"
              (click)="returnToAboutYourself()"
            >
              {{ 'auth.back' | translate }}
            </button>
            <button
              type="submit"
              (click)="onSubmit()"
              data-track="reg2-btn-finish"
              class="relative font-bold text-white rounded-full w-68 h-14 bg-blue-dark"
              [disabled]="isLoading"
            >
              {{ 'auth.createAccount' | translate }}
              <div class="absolute left-8 button-spinner top-7">
                <suzy-spinner
                  *ngIf="isLoading"
                  size="small"
                  button="true"
                  [round]="10"
                  [color]="'#ffffff'"
                  [overlay]="false"
                >
                </suzy-spinner>
              </div>
            </button>
          </section>
        </form>
      </div>
    </article>
  </section>
  <section class="footer-contianer">
    <app-general-footer id="main-footer"></app-general-footer>
  </section>
</div>
