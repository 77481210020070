import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs/';
import {
  SuzySdkService,
  OnboardingStatus
} from '@suzy/shared/data-access/suzy-sdk';

@Injectable()
export class UserProfileService {
  userData: any;
  localMachinePreferences: string;
  userUpdated$ = new Subject<any>();
  private storage: Storage;

  constructor(private suzySDK: SuzySdkService) {
    this.storage = localStorage;
  }

  setCurrentUser(user): void {
    if (user) {
      this.userData = user;
      this.localMachinePreferences = `localMachinePreferences-${this.userData.user_id}`;
    }
    this.userData = { ...this.userData, ...user };
    this.userUpdated$.next(this.userData);
  }

  getUserProfileThumbnail(): string {
    if (this.userData?.profile_photo && this.userData?.profile_photo?.thumbs) {
      return this.userData.profile_photo.thumbs.member.url;
    }
  }

  getCurrentUser(): any {
    return this.userData;
  }

  getAttribute(attr: string): any {
    if (this.userData) {
      if (this.userData.attributions) {
        return this.userData.attributions.find(value => value === attr);
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  getValidUserHandle(): string {
    if (this.userData.handle_verified_utc) {
      return this.userData.handle;
    } else {
      return '';
    }
  }

  updateProfile(profileId, userProfile): Observable<any> {
    return this.suzySDK.UserProfile.updateUserProfile(profileId, userProfile);
  }

  setUserLocalMachinePreferences(key, value): any {
    let localMachinePreferences = {};
    const storageValue = this.storage.getItem(this.localMachinePreferences);
    if (storageValue) {
      localMachinePreferences = JSON.parse(storageValue);
    }
    localMachinePreferences[key] = value;
    this.storage.setItem(
      this.localMachinePreferences,
      JSON.stringify(localMachinePreferences)
    );
  }

  getUserLocalMachinePreferences(key): any {
    const localMachinePreferences = this.storage.getItem(
      this.localMachinePreferences
    );
    if (localMachinePreferences) {
      return JSON.parse(localMachinePreferences)[key];
    } else {
      return undefined;
    }
  }

  getOnboardingStatus(status: number): string {
    switch (status) {
      case OnboardingStatus.completed:
        return 'Completed';
      case OnboardingStatus.grandfathered:
        return 'Grandfathered';
      case OnboardingStatus.onboarding:
        return 'Onboarding';
      case OnboardingStatus.trapped:
        return 'Trapped';
      default:
        return 'Unknown';
    }
  }
}
