import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  OnDestroy
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LightboxComponent } from '@suzy/shared/ui/lightbox';
import { PreRollKind } from '@suzy/shared/data-access/suzy-sdk';
import { AbsUrlPipe } from '@suzy/shared/tools/url';

@Component({
  selector: 'suzy-preroll-secondary-trigger',
  templateUrl: './secondary-trigger.component.html',
  styleUrls: ['./secondary-trigger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecondaryTriggerComponent implements OnInit, OnDestroy {
  @Input() prerollKind: PreRollKind;
  @Input() prerollUrl: string;
  @Input() isInline?: boolean = false;
  @Output() imagePrerollClick = new EventEmitter<string>();
  @Output() linkPrerollClick = new EventEmitter<void>();

  get isValidKind() {
    return (
      this.prerollKind === PreRollKind.image ||
      this.prerollKind === PreRollKind.link
    );
  }

  constructor(private ngbModal: NgbModal, private absUrl: AbsUrlPipe) {}

  ngOnInit(): void {
    if (this.isInline) {
      window['noPrint'] = true;
      window['noCopy'] = true;
      window['noScreenshot'] = true;
    }
  }

  ngOnDestroy(): void {
    window['noPrint'] = false;
    window['noCopy'] = false;
    window['noScreenshot'] = false;
  }

  onClick(): void {
    if (this.prerollKind === PreRollKind.image) {
      this.onImagePrerollClick();
    } else if (this.prerollKind === PreRollKind.link) {
      this.onLinkPrerollClick();
    }
  }

  onImagePrerollClick(): void {
    let lightbox;
    if (document.body.classList.contains('isPreview')) {
      lightbox = this.ngbModal.open(LightboxComponent, {
        windowClass: 'preview-mission-preroll',
        container: '#previewPreroll',
        backdrop: true
      });
    } else {
      lightbox = this.ngbModal.open(LightboxComponent, { size: 'lg' });
    }

    const lightboxComponent = lightbox.componentInstance as LightboxComponent;
    lightboxComponent.imageUrl = this.prerollUrl;
    lightboxComponent.getGuid.then(result => {
      this.imagePrerollClick.emit(result);
    });
  }

  onLinkPrerollClick(): void {
    const url = this.absUrl.transform(this.prerollUrl);
    window.open(url, '_blank');

    this.linkPrerollClick.emit();
  }
}
