import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { MissionService } from '@suzy/shared/tools/mission';
import { MissionExpiredNotifyComponent } from './mission-expired-notify.component';

interface alertData {
  mission?: any;
  title?: string;
  message?: string;
  accept?: string;
}

@Injectable()
export class MissionExpiredService {
  readonly storageKey = 'mission-failed-cache';
  constructor(
    private modals: NgbModal,
    private missionService: MissionService
  ) {}

  cacheMission(missionId: string): void {
    let currentCache: Array<any> = JSON.parse(
      localStorage.getItem(this.storageKey)
    );

    if (currentCache === null || currentCache === undefined) {
      currentCache = [];
    }

    if (currentCache.indexOf(missionId) === -1) {
      currentCache.push({ id: missionId, time: moment().unix() });
    }
    localStorage.setItem(this.storageKey, JSON.stringify(currentCache));
  }

  isMissionInCache(missionId: string): boolean {
    const storageValue = localStorage.getItem(this.storageKey);

    if (storageValue === null || storageValue === undefined) {
      return false;
    }

    let currentCache: Array<any> = JSON.parse(storageValue);

    currentCache = currentCache.filter(item => {
      return moment().unix() - item.time < 60 * 60;
    });

    localStorage.setItem(this.storageKey, JSON.stringify(currentCache));

    if (currentCache.filter(item => item.id === missionId).length === 0) {
      return false;
    }

    return true;
  }

  notify(data: alertData = {}): void {
    data.title = data.title || 'Notice';
    data.message = data.message || '';
    data.accept = data.accept || 'OK';
    data.mission = data.mission;

    this.cacheMission(data.mission.mission_id);

    const modal = this.modals.open(MissionExpiredNotifyComponent);
    (<MissionExpiredNotifyComponent>modal.componentInstance).data = data;

    modal.result.then(() => {
      this.missionService.missionExpired$().next(data.mission);
    });
  }
}
