import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { fadeInOut } from '../../../core/animations';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
  animations: [fadeInOut]
})
export class DefaultLayoutComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('top', { static: true }) top: ElementRef;
  @ViewChild('backtotop', { static: true }) backtotop: ElementRef;
  @ViewChild('mainPanel', { static: true }) mainPanel: ElementRef;

  _pageTop: HTMLElement;
  _backtotop: HTMLElement;
  _mainPanel: HTMLElement;
  lastScrollPos_Y: number;
  currentScrollPos_Y: number;
  offset: number;
  indicator: boolean;
  classes: any;
  viewOptions: any;
  displayCookieMessage = false;
  cookieKey = 'ate_the_crowdtap_cookies';
  isMobile: boolean;
  skipLinkPathContent: string;
  skipLinkPathFooter: string;
  unsubscribe$: Subject<void> = new Subject<void>();
  isSidebarVisible = false;
  isMobileLayout = false;
  currentYear: number;

  mobileLayoutHandler = (mql: MediaQueryList) => {
    this.isMobileLayout = mql.matches;
  };

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    if (this.router.url.includes('mobile')) {
      this.isMobile = true;
    }
    $.getScript('./assets/js/smooth-scrolling-polyfill.min.js');
    this.lastScrollPos_Y = 0;
    this.currentScrollPos_Y = 0;
    this.offset = 500;
    this.indicator = false;
    this.classes = {
      isShow: 'show',
      isHide: 'hide'
    };
    this.viewOptions = {
      block: 'start',
      behavior: 'smooth'
    };
    this.skipLinkPathContent = `${this.router.url}#main-content`;
    this.skipLinkPathFooter = `${this.router.url}#main-footer`;

    const isMobileLayoutMediaQuery = window.matchMedia('(max-width: 599px)');
    this.isMobileLayout = isMobileLayoutMediaQuery.matches;

    if (isMobileLayoutMediaQuery?.addEventListener) {
      isMobileLayoutMediaQuery?.addEventListener(
        'change',
        (mql: MediaQueryListEvent) => {
          this.isMobileLayout = mql.matches;
        }
      );
    } else {
      isMobileLayoutMediaQuery?.addListener((mql: MediaQueryListEvent) => {
        this.isMobileLayout = mql.matches;
      });
    }
  }

  ngAfterViewInit(): void {
    this._pageTop = this.top?.nativeElement as HTMLElement;
    this._backtotop = this.backtotop?.nativeElement as HTMLElement;
    this._mainPanel = this.mainPanel?.nativeElement as HTMLElement;

    this._backtotop?.addEventListener(
      'click',
      this.backToTop.bind(this),
      false
    );
    this._mainPanel?.addEventListener('scroll', this.onScroll.bind(this));
  }

  onScroll(): void {
    this.currentScrollPos_Y = this._mainPanel.scrollTop;
    this.getIndicator();
  }

  getIndicator(): void {
    if (!this.indicator) {
      requestAnimationFrame(this.update.bind(this));
    }
    this.indicator = true;
  }

  update(): void {
    if (this.currentScrollPos_Y < this.offset) {
      this.btnBTTHide();
    } else if (this.currentScrollPos_Y < this.lastScrollPos_Y) {
      this.btnBTTShow();
    } else {
      this.btnBTTHide();
    }

    this.lastScrollPos_Y = this.currentScrollPos_Y;

    this.indicator = false;
  }

  btnBTTShow(): void {
    if (this._backtotop.classList.contains(this.classes.isHide)) {
      this._backtotop.classList.remove(this.classes.isHide);
      this._backtotop.classList.add(this.classes.isShow);
    }
  }

  btnBTTHide(): void {
    if (
      this._backtotop.classList.contains(this.classes.isShow) ||
      !this._backtotop.classList.contains(this.classes.isHide)
    ) {
      this._backtotop.classList.remove(this.classes.isShow);
      this._backtotop.classList.add(this.classes.isHide);
    }
  }

  backToTop(): void {
    this._pageTop.scrollIntoView(this.viewOptions);
  }

  skipToMainContent() {
    this._mainPanel.focus();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this._backtotop) {
      this._backtotop.removeEventListener('click', this.backToTop.bind(this));
    }
    if (this._mainPanel) {
      this._mainPanel.removeEventListener('scroll', this.onScroll.bind(this));
    }
    cancelAnimationFrame(this.update.bind(this));
  }
}
