import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import {
  AuthenticationService,
  UserProfileService,
  VerificationService
} from '@suzy/crowdtap/data-access/user';
import { NavigationService } from '@suzy/crowdtap/tools/navigation';
import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import { FileUploadService } from '@suzy/shared/feature/file-upload';
import { DeviceService } from '@suzy/shared/tools/device';
import { MissionService } from '@suzy/shared/tools/mission';
import { CookieService } from 'ngx-cookie-service';
import { DateFormatPipe, MomentModule } from 'ngx-moment';
import { SharedModule } from '../shared/shared.module';
import { ActionsModule } from '../views/actions/actions.module';
import { AnonymousModule } from '../views/anonymous/anonymous.module';
import { CookieNotificationComponent } from './components/cookie-notification/cookie-notification.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NotificationSidebarComponent } from './components/notification-sidebar/notification-sidebar.component';
import { SidebarProfileComponent } from './components/sidebar-profile/sidebar-profile.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ToggleFullscreenDirective } from './directives/toggle-fullscreen.directive';
import { AuthGuard } from './guards/auth.guard';
import { DashboardLoginGuard } from './guards/dashboard-login.guard';
import { LocalizeGuard } from './guards/localize.guard';
import { RedirectGuard } from './guards/redirect.guard';
import { UserStatusGuard } from './guards/user-status.guard';
import { VerifyGuard } from './guards/verify.guard';
import { AnonymousLayoutComponent } from './layouts/anonymous/anonymous-layout.component';
import { DefaultLayoutComponent } from './layouts/default/default-layout.component';
import {
  AccessibilityStatementResolver,
  ChildStatusOptionsResolver,
  CookiesResolver,
  CountriesResolver,
  CurrentUserResolver,
  EducationOptionsResolver,
  EmploymentOptionsResolver,
  EthnicitiesResolver,
  HouseholdOptionsResolver,
  IncomeLevelOptionsResolver,
  IndustryOptionsResolver,
  ParentingOptionsResolver,
  PrivacyResolver,
  ReferResolver,
  RelationshipOptionsResolver,
  StatesResolver,
  TermsResolver,
  TrackRewardsResolver
} from './resolvers';
import { AppAlertModule } from './services/app-alert/app-alert.module';
import { DateNativeAdapter } from './services/date-native-adapter.service';
import { DemographicService } from './services/demographic.service';
import { GrecaptchaService } from './services/grecaptcha.service';
import { LegalServices } from './services/legal.service';
import { MetaDataService } from './services/meta-data.service';
import { ScriptLoaderService } from './services/script-loader.service';
import { SettingsService } from './services/settings.service';
import { SnareServices } from './services/snare.service';

@NgModule({
  exports: [
    CommonModule,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ToggleFullscreenDirective,
    NgbModule,
    HttpClientModule
  ],
  imports: [
    RouterModule,
    CommonModule,
    NgbModule,
    AppAlertModule,
    ActionsModule,
    MomentModule,
    TranslateModule,
    AnonymousModule,
    SharedModule
  ],
  declarations: [
    AnonymousLayoutComponent,
    DefaultLayoutComponent,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ToggleFullscreenDirective,
    SidebarProfileComponent,
    CookieNotificationComponent,
    NotificationSidebarComponent
  ],
  providers: [
    AuthGuard,
    AuthenticationService,
    LocalizeGuard,
    RedirectGuard,
    CookieService,
    SuzySdkService,
    ScriptLoaderService,
    NavigationService,
    FileUploadService,
    UserProfileService,
    DateNativeAdapter,
    DemographicService,
    MissionService,
    StatesResolver,
    EthnicitiesResolver,
    EducationOptionsResolver,
    IndustryOptionsResolver,
    EmploymentOptionsResolver,
    HouseholdOptionsResolver,
    IncomeLevelOptionsResolver,
    AccessibilityStatementResolver,
    RelationshipOptionsResolver,
    ChildStatusOptionsResolver,
    ParentingOptionsResolver,
    CountriesResolver,
    TrackRewardsResolver,
    PrivacyResolver,
    TermsResolver,
    CurrentUserResolver,
    LegalServices,
    SnareServices,
    SettingsService,
    VerifyGuard,
    DeviceService,
    CookiesResolver,
    GrecaptchaService,
    MetaDataService,
    DateFormatPipe,
    DashboardLoginGuard,
    VerificationService,
    UserStatusGuard,
    ReferResolver,
    TranslatePipe
  ]
})
export class CoreModule {}
