import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { GlobalIsolateSdkService } from '@suzy/shared/data-access/global-isolate-sdk';
import {
  ActionKind,
  ActionStructureMultipleChoiceKind,
  MissionKind,
  PreRollKind,
  StepKind,
  SuzySdkService,
  WellKnownErrorCodes
} from '@suzy/shared/data-access/suzy-sdk';
import { SegmentService } from '@suzy/shared/data-access/tracking';
import { MissionService } from '@suzy/shared/tools/mission';
import { LightboxComponent } from '@suzy/shared/ui/lightbox';
import { Subject } from 'rxjs';
import swal from 'sweetalert2';
import { isArray } from 'util';
import { MetaData } from '../../../core/models/meta-data.model';
import { AppAlertService } from '../../../core/services/app-alert/app-alert.service';
import { MissionExpiredService } from '../../../core/services/mission-expired/mission-expired.service';
import { MetaDataService } from '../../../core/services/meta-data.service';
import { Mission } from '../../../views/actions/mission.model';
import { AuthenticationService } from '@suzy/crowdtap/data-access/user';
import { PreviewService } from '../../preview/preview.service';
import { environment } from 'apps/crowdtap/src/environments/environment';
import { take } from 'rxjs/operators';
import { GlobalService } from '../../global/global.service';
import { SettingsService } from '../../../core/services/settings.service';
import { LaunchDarklyService } from '@suzy/shared/data-access/feature-flag';
import { SkipModalComponent } from '../skip-modal/skip-modal.component';
import { SkipModalService } from '../../../core/services/skip-modal-service';
import { beforeModalDismiss } from '../actions.service';

export interface MultipleChoiceAction {
  helpText: string;
  includeOther: boolean;
  noneText: string;
  otherText: string;
  minChoices: number;
  maxChoices: number;
  options: Array<any>;
  question: string;
  variants: Array<any>;
  has_monadic: boolean;
}

@Component({
  selector: 'app-multiple-choice',
  templateUrl: './multiple-choice.component.html',
  styleUrls: ['./multiple-choice.component.scss']
})
export class MultipleChoiceComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  action: any = {};
  mission: Mission;
  multipleChoice: MultipleChoiceAction;
  multipleChoiceKind = ActionStructureMultipleChoiceKind;
  subject: Subject<any> = new Subject<any>();

  selectedCheckboxes: Array<any> = [];
  minChoices: number;
  maxChoices: number;
  isOther: boolean;
  isDisabled: boolean;
  errorMessage = '';
  otherValue = '';
  isLoading = false;
  hasPreroll = false;
  prerollViewed = false;
  isSuccess = false;
  isSubmitting = false;
  preroll_image: number = PreRollKind.image;
  preroll_link: number = PreRollKind.link;
  isNoneSelected: boolean;
  imageModalIsActive: boolean;
  activeImageUrl: string;
  activeImageAltText: string;
  activeAnswerChoiceText: string;
  currentChoiceIndex: number;
  mcOptionsExcludingNone = [];

  variantKey = 0;
  question = '';
  preRollUrl = '';
  preRollCaption = '';
  preRollKind = PreRollKind.none;
  showPreroll = true;

  metaData: MetaData;
  startDate: number;
  timeToAction: number;
  clientX: number;
  clientY: number;
  timeToActionIsUpdated = false;
  optionPositions = [];
  isTrapQuestion: boolean;
  disableSkip: boolean;
  radioButtonIndexSelected: number;
  startTime: number;
  isSkipping: boolean;
  isAutoAssignOnPreview: boolean;
  selectedRadio: any;
  isPreview = false;
  isLegalAction = false;
  reachedPolicyEnd = true;
  showTopGradient = false;
  showBottomGradient = false;
  policyHasScrollbar = false;
  scrollErrorMessage: string;

  @ViewChild('externalContent', { static: false }) externalContent: ElementRef;
  @HostListener('scroll', ['$event'])
  private trackActionName = true;

  constructor(
    private suzySDK: SuzySdkService,
    private globalSDK: GlobalIsolateSdkService,
    private route: ActivatedRoute,
    private alerts: AppAlertService,
    private missionExpiredService: MissionExpiredService,
    private missionService: MissionService,
    private skipModalService: SkipModalService,
    private modals: NgbModal,
    private metaDataService: MetaDataService,
    private translate: TranslateService,
    private segmentService: SegmentService,
    private auth: AuthenticationService,
    private hostElement: ElementRef,
    private previewService: PreviewService,
    private globalService: GlobalService,
    private settingsService: SettingsService,
    private launchDarklyService: LaunchDarklyService
  ) {}

  ngOnInit(): void {
    this.trackActionName = this.launchDarklyService.getGLB723Flag();
    if (this.action.multiple_choice.image_answers) {
      window['noPrint'] = true;
      window['noCopy'] = true;
      window['noScreenshot'] = true;
    }
    const action = this.action.multiple_choice;
    this.isPreview = document.body.classList.contains('isPreview');
    if (this.isPreview && this.action.action_kind === ActionKind.auto_assign) {
      return this.initAutoAssign(this.action);
    } else {
      this.initAction(action);
      this.shuffleAndOrganize(action);
    }
    this.question = this.action.multiple_choice.question;
    if (this.monadicEnabled()) {
      // Monadic question
      this.setupMonadic();
    } else {
      // Not a monadic question
      this.preRollKind = this.action.preroll_kind;

      if (
        this.action.preroll_kind !== 'undefined' &&
        this.action.preroll_kind !== PreRollKind.none
      ) {
        this.preRollCaption = this.action.preroll_instruction;
        if (
          this.action.preroll_kind === PreRollKind.image &&
          this.action.preroll
        ) {
          this.hasPreroll = true;
          this.preRollUrl = this.action.preroll.thumbs.full.url;
        } else if (
          this.action.preroll_kind === PreRollKind.link &&
          this.action.preroll_body
        ) {
          this.hasPreroll = true;
          this.preRollUrl = this.action.preroll_body;
        }
      }
    }
    this.startDate = Date.now();
    this.startTime = new Date().getTime();
    this.metaData = {
      userId: '',
      type: 'multiple_choice',
      actionId: undefined,
      missionId: undefined,
      text: this.question,
      response: undefined,
      attempt: this.startDate,
      startDateTime: this.startDate,
      actionStartDateTime: this.startDate,
      completedDateTime: undefined,
      dwellingTime: undefined,
      completionTime: undefined,
      interactionDateTime: undefined,
      closedDateTime: undefined,
      skipDateTime: undefined,
      skipFlag: false,
      mousePosition: undefined,
      deviceInfo: undefined,
      validationCount: 0,
      questionCounts: undefined,
      responseCount: undefined,
      numberOfOptions: this.multipleChoice.options.length,
      optionPositions: undefined
    };
    if (
      this.mission.first_action &&
      this.mission.first_action.multiple_choice
    ) {
      this.isTrapQuestion =
        this.mission.first_action.multiple_choice.expected_answers_enabled;
      if (this.mission.mission_kind === MissionKind.external_link) {
        this.isLegalAction = this.action?.is_legal_consent;
      }
    }
    if (
      (this.mission.first_action && this.mission.first_action.prevent_skip) ||
      this.mission.mission_kind !== MissionKind.standard
    ) {
      this.disableSkip = this.mission.first_action.prevent_skip;
    }
    this.segmentService.trackActionStarted({
      brand_id: this.mission.brand_id,
      action_id: this.action.action_id,
      action_name: this.trackActionName
        ? this.action.multiple_choice.question
        : undefined,
      mission_id: this.mission.mission_id,
      action_kind: this.action.action_kind,
      multiple_choice_kind: this.action.multiple_choice.multiple_choice_kind,
      image_answers: this.action.multiple_choice.image_answers ?? false,
      passive_preroll_enabled: this.action.passive_preroll_enabled,
      preroll_kind: this.action.preroll_kind,
      monadic_variant_id:
        this.action.has_monadic && this.action.monadic_variants.length === 1
          ? this.action.monadic_variants[0].monadic_variant_id
          : undefined,
      points_available:
        this.mission.action_count === 1
          ? this.mission.points
          : this.action.points_extra,
      tag: this.mission.specialType
    });
    this.action.multiple_choice.options.forEach(option => {
      option.isSelected = false;
      if (!option.is_none || !option.piped_is_none) {
        this.mcOptionsExcludingNone.push(option);
      }
    });
    this.skipModalService.isSkip.pipe(take(1)).subscribe(data => {
      if (data === this.metaData.type) {
        this.skipAction();
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.isLegalAction) {
      const externalElement = this.externalContent?.nativeElement;

      this.policyHasScrollbar =
        externalElement?.scrollHeight > externalElement?.clientHeight;
      this.showBottomGradient = this.policyHasScrollbar ? true : false;
      this.reachedPolicyEnd = this.policyHasScrollbar ? false : true;
    }
  }

  chooseExactlyOneChoice(): boolean {
    return this.multipleChoice.maxChoices === 1;
  }

  chooseExactlyXChoices(): boolean {
    return (
      !this.chooseExactlyOneChoice() &&
      this.multipleChoice.minChoices === this.multipleChoice.maxChoices
    );
  }

  chooseAtLeastXChoices(): boolean {
    return (
      this.multipleChoice.minChoices > 1 &&
      this.multipleChoice.maxChoices >= this.multipleChoice.options.length &&
      this.multipleChoice.minChoices < this.multipleChoice.options.length
    );
  }

  chooseUpToXChoices(): boolean {
    return (
      this.multipleChoice.minChoices === 1 && this.multipleChoice.maxChoices > 1
    );
  }

  chooseBetweenXandYChoices(): boolean {
    return (
      this.multipleChoice.minChoices > 1 &&
      this.multipleChoice.maxChoices < this.multipleChoice.options.length &&
      this.multipleChoice.minChoices !== this.multipleChoice.maxChoices
    );
  }

  setupMonadic(): void {
    this.variantKey =
      this.mission.monadic_variants.length <
      this.mission.monadic_variant_key + 1
        ? 0
        : this.mission.monadic_variant_key;

    if (!this.variantKey) {
      this.variantKey = 0;
    }
    const variant = this.mission.monadic_variants[this.variantKey];

    this.globalService.setMonadicVariantId(variant.monadic_variant_id); //Storing variant ID

    this.hasPreroll = true;
    this.showPreroll = !this.action.passive_preroll_enabled;

    if (!this.showPreroll) {
      this.prerollViewed = true;
    }

    this.preRollCaption = variant.preroll_instruction;
    this.preRollKind = variant.preroll_kind;
    if (this.preRollKind === PreRollKind.image) {
      this.preRollUrl = variant.preroll.thumbs.full.url;
    } else if (this.preRollKind === PreRollKind.link) {
      this.preRollUrl = variant.preroll_body;
    }
  }

  monadicEnabled(): boolean {
    return this.action.has_monadic;
  }

  init(): void {
    let action;

    if (
      document.body.classList.contains('isPreview') &&
      this.action.action_kind === ActionKind.auto_assign
    ) {
      this.initAutoAssign(this.action);
      action = this.action;
    } else {
      action = this.action.multiple_choice;
      this.initAction(action);
    }
    this.shuffleAndOrganize(action);
  }

  shuffleAndOrganize(action: any) {
    if (action.randomize) {
      this.multipleChoice.options = this.shuffle(this.multipleChoice.options);
    }

    if (action.randomize && (action.include_other || action.include_none)) {
      const tempArray = [];
      const bottomOptions = this.multipleChoice.options.filter((item, idx) => {
        if (item.is_none || item.is_other || item.piped_is_none) {
          return true;
        } else {
          tempArray.push(item);
        }
      });

      if (bottomOptions.length > 1) {
        if (bottomOptions[1].is_other === true) {
          bottomOptions.reverse();
        }
      }
      this.multipleChoice.options = tempArray.concat(bottomOptions);
    }
  }

  ngOnDestroy(): void {
    this.subject.complete();
    this.subject = undefined;
    window['noPrint'] = false;
    window['noCopy'] = false;
    window['noScreenshot'] = false;
  }

  initAction(action: any): void {
    this.multipleChoice = {
      helpText: action.help_text,
      includeOther: action.include_other,
      otherText: action.other_prompt,
      noneText: action.none_prompt,
      minChoices: action.min_choices,
      maxChoices: action.max_choices,
      options: [].concat(action.options),
      variants: [].concat(action.variants),
      question: action.question,
      has_monadic: action.has_monadic
    };

    if (this.multipleChoice?.maxChoices > this.multipleChoice?.options.length) {
      this.multipleChoice.maxChoices = this.multipleChoice.options.length;
    }
  }

  initAutoAssign(action: any) {
    const autoAssign = action.auto_assign;
    this.multipleChoice = {
      helpText: '',
      includeOther: autoAssign.include_other,
      otherText: autoAssign.other_prompt,
      noneText: autoAssign.none_prompt,
      minChoices: autoAssign.min_choices,
      maxChoices: autoAssign.max_choices,
      options: [].concat(autoAssign.options),
      variants: [],
      question: autoAssign.question,
      has_monadic: autoAssign.has_monadic
    };
    if (this.multipleChoice?.maxChoices > this.multipleChoice?.options.length) {
      this.multipleChoice.maxChoices = this.multipleChoice.options.length;
    }
    this.action.multiple_choice = {
      multiple_choice_kind: this.multipleChoiceKind.standard,
      image_answers: autoAssign.image_answers
    };
    this.question = this.action.is_prerequisite
      ? autoAssign.question
      : autoAssign.question + ' [Auto-Assign]';
    this.isAutoAssignOnPreview = true;
    this.action.auto_assign.options.forEach(option => {
      if (option.isSelected) {
        this.selectedRadio = option.answer_id;
      }
    });
  }

  onPrerollViewedEvent(guid?: string): void {
    this.segmentService.trackMediaOpen({
      brand_id: this.mission.brand_id,
      action_id: this.action.action_id,
      mission_id: this.mission.mission_id,
      action_kind: this.action.action_kind,
      asset_id: this.action.preroll ? this.action.preroll.asset_id : undefined,
      asset_url: this.preRollUrl,
      preroll_kind: this.preRollKind,
      passive_preroll_enabled: this.action.passive_preroll_enabled,
      is_on_question: false,
      image_attribuion: guid
    });
    this.prerollViewed = true;
  }

  onViewPrerollImage(guid?: string): void {
    this.segmentService.trackMediaOpen({
      brand_id: this.mission.brand_id,
      action_id: this.action.action_id,
      mission_id: this.mission.mission_id,
      action_kind: this.action.action_kind,
      asset_id: this.action.preroll ? this.action.preroll.asset_id : undefined,
      asset_url: this.preRollUrl,
      preroll_kind: this.preRollKind,
      passive_preroll_enabled: this.action.passive_preroll_enabled,
      is_on_question: true,
      image_attribuion: guid
    });
  }

  onViewPrerollLink(): void {
    this.segmentService.trackMediaOpen({
      brand_id: this.mission.brand_id,
      action_id: this.action.action_id,
      mission_id: this.mission.mission_id,
      action_kind: this.action.action_kind,
      asset_id: this.action.preroll ? this.action.preroll.asset_id : undefined,
      asset_url: this.preRollUrl,
      preroll_kind: this.preRollKind,
      passive_preroll_enabled: this.action.passive_preroll_enabled,
      is_on_question: true
    });
  }

  viewOptionImage(event: { option: any; index?: number }): void {
    this.imageModalIsActive = true;
    if (event.option.asset) {
      this.activeImageUrl = event.option.asset.thumbs.full.url;
    } else if (event.option.asset_upload) {
      this.activeImageUrl = event.option.asset_upload.url;
    } else {
      this.activeImageUrl = '';
    }
    this.activeImageAltText = event.option.asset_alt_text;
    this.activeAnswerChoiceText = event.option.answer_text;
    this.currentChoiceIndex = event.index
      ? event.index
      : this.mcOptionsExcludingNone.findIndex(
          opt => event.option.answer_text === opt.answer_text
        );
  }

  previousImage(i: number): void {
    let prevOption: any;
    if (i === 0) {
      prevOption =
        this.mcOptionsExcludingNone[this.mcOptionsExcludingNone.length - 1];
    } else {
      prevOption = this.mcOptionsExcludingNone[i - 1];
    }
    this.viewOptionImage({ option: prevOption });
  }

  nextImage(i: number): void {
    let nextOption: any;
    if (i >= this.mcOptionsExcludingNone.length - 1) {
      nextOption = this.mcOptionsExcludingNone[0];
    } else {
      nextOption = this.mcOptionsExcludingNone[i + 1];
    }
    this.viewOptionImage({ option: nextOption });
  }

  onCheckBoxChange({ option, event }): void {
    this.errorMessage = '';
    this.updateTimeToAction();
    const isChecked = event.target.checked;
    const totalSelected = this.selectedCheckboxes.length;
    const index = this.selectedCheckboxes.indexOf(option.answer_id);

    if (option.is_none === true || option.piped_is_none === true) {
      this.isNoneSelected = true;
      if (isChecked) {
        this.selectedCheckboxes = [];
        this.isOther = false;
        this.multipleChoice.options.forEach(item => {
          item.isSelected = false;
        });
        option.isSelected = true;
        this.selectedCheckboxes.push(option.answer_id);
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
        option.isSelected = false;
        this.selectedCheckboxes = [];
      }
    } else if (isChecked && index < 0) {
      if (this.isDisabled === true) {
        const selectedOption = this.multipleChoice.options.find(
          item => item.answer_id === this.selectedCheckboxes[0]
        );
        selectedOption.isSelected = false;
        this.isDisabled = false;
        this.selectedCheckboxes = [];
      }
      if (totalSelected + 1 > this.multipleChoice.maxChoices) {
        for (let x of this.multipleChoice.options) {
          if (this.selectedCheckboxes[0] === x.answer_id) {
            x.isSelected = false;
            break;
          }
        }
        this.selectedCheckboxes.splice(0, 1);
        this.selectedCheckboxes.push(option.answer_id);
        option.isSelected = true;
      } else {
        this.selectedCheckboxes.push(option.answer_id);
        if (option.is_other === true) {
          this.isOther = true;
        }
        option.isSelected = true;
      }
    } else {
      this.selectedCheckboxes.splice(index, 1);
      if (option.is_other === true) {
        this.isOther = false;
      }
      option.isSelected = false;
    }
  }

  onRadioChange({ option }): void {
    this.errorMessage = '';
    this.isOther = option.is_other;
    this.radioButtonIndexSelected = option.priority;
  }

  onOtherUpdateChange(value): void {
    this.errorMessage = '';
    this.otherValue = value;
  }

  submitMultipleOption(input): any {
    if (this.isOther) {
      input.response_value = this.otherValue;
      input.response_display = this.otherValue;
    }
    if (this.settingsService.isGlobalApp()) {
      const variantId = this.globalService.getMonadicVariantId() ?? null;
      if (variantId) {
        input.monadic_variant_id = variantId;
      }
    }
    this.isSubmitting = true;
    this.isLoading = true;
    if (
      this.mission.isGlobal ||
      this.settingsService.isPreviewApp() ||
      this.settingsService.isGlobalApp() ||
      this.settingsService.isExternalApp()
    ) {
      this.globalResponse(input);
    } else {
      this.crowdtapResponse(input);
    }
  }

  crowdtapResponse(input): void {
    input = {
      ...input,
      correlation_data: this.action.correlation_data
        ? this.action.correlation_data
        : undefined
    };

    this.suzySDK.ProtocolRespond.respond(
      this.mission.brand_id,
      this.mission.mission_id,
      input
    ).subscribe(
      data => {
        if (data.success) {
          this.previewService.disablePreviewControls = false;
          this.isLoading = false;
          this.isSuccess = true;
          setTimeout(() => {
            this.missionService.onMissionAnswered(
              this.mission.mission_id,
              data.meta
            );
            this.subject.next(data);
          }, 800);

          if (
            data.error_code &&
            data.error_code === WellKnownErrorCodes.onboarding_trap_warning
          ) {
            this.isSuccess = false;
            this.segmentService.trackQuestionFailedModalViewed({
              action_id: this.action.action_id,
              brand_id: this.mission.brand_id,
              mission_id: this.mission.mission_id
            });
            swal.fire({
              customClass: 'general-modal attention-modal',
              html:
                'Your response was flagged as going against one of our ' +
                '<a href="https://support.crowdtap.com/hc/en-us/articles/360030907972-Community-guidelines" target="_blank">Community Guidelines.</a>' +
                ' Make sure to read thoroughly, answer honestly, be respectful and follow our guidelines to remain in good standing.' +
                '</br>' +
                '</br>' +
                'Your account will be disabled after the 3rd warning.',
              title: 'Attention!',
              imageUrl: '../../../../assets/img/svg/icons/ic-warning-blue.svg',
              imageWidth: 80,
              imageHeight: 80,
              confirmButtonText: 'I Understand'
            });
          }
          if (
            data.error_code &&
            data.error_code === WellKnownErrorCodes.onboarding_trap_banned
          ) {
            this.isSuccess = false;
            this.segmentService.trackQuestionFailedModalViewed({
              action_id: this.action.action_id,
              brand_id: this.mission.brand_id,
              mission_id: this.mission.mission_id
            });
            swal
              .fire({
                customClass: 'general-modal',
                title: `Account Suspended`,
                html:
                  'You didn’t meet our attention check requirements. If this was done in error, please ' +
                  '<a href="https://support.crowdtap.com/hc/en-us?_ga=2.32338235.443825342.1658849519-400642095.1658179860" target="_blank">contact support</a>',
                imageUrl: '../../../../assets/img/svg/icons/ic-suspension.svg',
                imageWidth: 94,
                imageHeight: 94,
                imageAlt: 'Temporary suspension',
                confirmButtonText: 'Got it'
              })
              .then(result => {
                if (result) {
                  this.auth.logout(false, false, '/');
                }
              });
          }
        } else {
          let message = data.message;
          if (data.errors) {
            for (const error of Object.keys(data.errors)) {
              message += `\n - ${data.errors[error]}`;
            }
          }

          this.isLoading = false;
          this.isSubmitting = false;
          let errorCode = [404, 409, 401];
          if (errorCode.includes(data.error_code)) {
            this.openMissionExpiredModal(message);
          } else {
            this.errorMessage = message;
          }
        }
      },
      error => {
        this.isLoading = false;
        let errorCode = [404, 409, 401];
        if (errorCode.includes(error.error_code)) {
          this.openMissionExpiredModal(error.message);
        } else {
          this.errorMessage = error;
        }
      }
    );
  }

  globalResponse(input): void {
    input = {
      payload: {
        ...input,
        correlation_data: this.action.correlation_data
          ? this.action.correlation_data
          : undefined
      }
    };
    this.globalService.respondToGlobalActionAsync(input).subscribe(
      data => {
        if (data.success) {
          this.previewService.disablePreviewControls = false;
          this.isLoading = false;
          this.isSuccess = true;
          setTimeout(() => {
            this.missionService.onMissionAnswered(
              this.mission.mission_id,
              data.meta
            );
            this.subject.next(data);
          }, 800);
        } else {
          this.isLoading = false;
          this.isSubmitting = false;
          if (
            document.body.classList.contains('isPreview') &&
            (data.message_token == 'mission.ScreenedOut' ||
              data.message_token == 'missionTransfer.InvalidLegalQuestion' ||
              data.message_token ==
                'missionTransfer.no_redirect.InvalidLegalQuestion' ||
              data.message_token ==
                'external_link.no_redirect.mission.ScreenedOut' ||
              data.message_token == 'external_link.mission.ScreenedOut')
          ) {
            this.subject.next(data);
          } else {
            let message = '';
            if (data.errors) {
              for (const error of Object.keys(data.errors)) {
                message += `\n - ${data.errors[error]}`;
              }
              this.errorMessage = message;
            }
          }
        }
      },
      error => {
        this.isLoading = false;
        this.isSubmitting = false;
      }
    );
  }

  skipAction() {
    if (this.isSubmitting) {
      return;
    }
    this.sendMetaData('skip');
    this.isSkipping = true;
    this.hostElement.nativeElement
      .closest('.modal')
      .classList.add('is-skipping');
    const input = {
      brand_id: this.mission.brand_id,
      mission_id: this.mission.mission_id,
      action_id: this.action.action_id
    };
    this.suzySDK.ProtocolRespond.skip(
      this.mission.brand_id,
      this.mission.mission_id,
      input
    ).subscribe(
      data => {
        if (data.success) {
          this.skipModalService.isSuccess.next(true);
          setTimeout(() => {
            this.subject.next('skip');
            this.isSkipping = false;
            if (this.mission.action_count === 1) {
              this.segmentService.trackMission('Mission Skipped', {
                action_count: this.mission.action_count,
                brand_id: this.mission.brand_id,
                mission_id: this.mission.mission_id,
                points_available: this.mission.points,
                mission_kind: this.mission.mission_kind,
                has_cover: false,
                monadic_variant_quantity: this.mission.monadic_variants
                  ? this.mission.monadic_variants.length
                  : undefined,
                tag: this.mission.specialType
              });
            }
            this.missionService.onMissionAnswered(
              this.mission.mission_id,
              data.meta
            );
          }, 1000);
        } else {
          this.skipModalService.isSuccess.next(false);
          this.isSkipping = false;
          let message = data.message;
          if (data.errors) {
            for (const error of Object.keys(data.errors)) {
              message += `\n - ${data.errors[error]}`;
            }
          }
          this.alerts.notify({ title: 'Error', message });
        }
      },
      error => {
        this.skipModalService.isSuccess.next(false);
        this.isSkipping = false;
        this.alerts.notify({ title: 'Error', message: error });
      }
    );
  }

  onSkip($event): void {
    $event.preventDefault();
    this.openSkipModal();
  }

  openSkipModal(): void {
    const modalClass = `modal-${new Date().getTime()}`;
    let modal;
    modal = this.modals.open(SkipModalComponent, {
      windowClass: modalClass,
      beforeDismiss: () => {
        return beforeModalDismiss(modalClass, this.mission.mission_id);
      }
    });
    const instance = modal.componentInstance as SkipModalComponent;
    instance.kind = this.mission.mission_kind;
    instance.mission = this.mission;
    instance.action = this.mission.first_action;
    instance.actionService = null;
    instance.actionType = this.metaData.type;
  }

  onMouseMoveEvent(event: MouseEvent): void {
    this.clientX = event.clientX;
    this.clientY = event.clientY;
  }

  updateTimeToAction(): void {
    if (!this.timeToActionIsUpdated) {
      this.timeToAction = Date.now();
    }
    this.timeToActionIsUpdated = true;
  }

  sendMetaData(actionType: string): void {
    let completedDateTime: number;
    let skipDateTime: number;
    let skipFlag: boolean;
    switch (actionType) {
      case 'submit':
        completedDateTime = Date.now();
        break;
      case 'skip':
        skipDateTime = Date.now();
        skipFlag = false;
        break;
      default:
        break;
    }

    const meta = {
      ...this.metaData,
      actionId: this.action.action_id,
      missionId: this.mission.mission_id,
      text: this.question,
      completedDateTime,
      interactionDateTime: this.timeToAction,
      skipDateTime,
      skipFlag,
      mousePosition: { x: this.clientX, y: this.clientY },
      numberOfOptions: this.multipleChoice.options.length,
      optionPositions: this.optionPositions
    };

    this.metaDataService.createMetaData(meta);
  }

  onSubmit(responseForm: NgForm): void {
    if (this.isSubmitting) {
      return;
    }

    let input: any;
    if (this.multipleChoice.maxChoices > 1) {
      // multiple selections
      if (this.isValidInput(this.selectedCheckboxes)) {
        this.multipleChoice.options.forEach((option, index) => {
          this.selectedCheckboxes.forEach(selectedId => {
            if (option.answer_id === selectedId) {
              this.optionPositions.push(index);
            }
          });
        });

        this.sendMetaData('submit');
        this.segmentService.trackAction('Action Completed', {
          brand_id: this.mission.brand_id,
          action_id: this.action.action_id,
          action_name: this.trackActionName
            ? this.action.multiple_choice.question
            : undefined,
          mission_id: this.mission.mission_id,
          action_kind: this.action.action_kind,
          multiple_choice_kind:
            this.action.multiple_choice.multiple_choice_kind,
          monadic_variant_id:
            this.action.has_monadic && this.action.monadic_variants.length === 1
              ? this.action.monadic_variants[0].monadic_variant_id
              : undefined,
          tag: this.mission.specialType
        });

        input = {
          action_id: this.action.action_id,
          answer_ids: this.selectedCheckboxes
        };

        if (this.monadicEnabled()) {
          input.monadic_variant_id =
            this.mission.monadic_variants[this.variantKey].monadic_variant_id;
        }

        this.submitMultipleOption(input);
      }
    } else {
      // single selection
      let options: any;
      if (
        this.action.multiple_choice.multiple_choice_kind ===
        this.multipleChoiceKind.monadic
      ) {
        this.multipleChoice.options.forEach(option => {
          if (option.isSelected) {
            options = option.answer_id;
          }
        });
      } else {
        options = responseForm.value.options;
      }

      if (this.isLegalAction && !this.isValidLegalAction(options)) {
        return;
      }

      if (this.isValidInput(options)) {
        this.multipleChoice.options.forEach((option, index) => {
          if (option.answer_id === options) {
            this.optionPositions.push(index);
          }
        });
        this.sendMetaData('submit');
        this.segmentService.trackAction('Action Completed', {
          brand_id: this.mission.brand_id,
          action_id: this.action.action_id,
          action_name: this.trackActionName
            ? this.action.multiple_choice.question
            : undefined,
          mission_id: this.mission.mission_id,
          action_kind: this.action.action_kind,
          multiple_choice_kind:
            this.action.multiple_choice.multiple_choice_kind,
          monadic_variant_id:
            this.action.has_monadic && this.action.monadic_variants.length === 1
              ? this.action.monadic_variants[0].monadic_variant_id
              : undefined,
          tag: this.mission.specialType
        });

        input = {
          action_id: this.action.action_id,
          answer_ids: [options]
        };

        if (this.monadicEnabled()) {
          input.monadic_variant_id =
            this.mission.monadic_variants[this.variantKey].monadic_variant_id;
        }

        this.submitMultipleOption(input);
      }
    }
  }

  isValidLegalAction(options: any): boolean {
    let isValidLegal = true;
    this.scrollErrorMessage = '';
    this.errorMessage = '';

    if (this.policyHasScrollbar && !this.reachedPolicyEnd) {
      isValidLegal = false;
      this.translate
        .get('externalLink.scrollError')
        .pipe(take(1))
        .subscribe(value => {
          this.scrollErrorMessage = value;
        });
    }

    if (!options) {
      isValidLegal = false;
      this.translate
        .get('externalLink.optionsError')
        .pipe(take(1))
        .subscribe(value => {
          this.errorMessage = value;
        });
    }

    return isValidLegal;
  }

  onSubmitPrerequisite(responseForm: NgForm): void {
    this.isLoading = true;
    if (this.isSubmitting) {
      return;
    }

    let input: any;
    if (this.multipleChoice.maxChoices > 1) {
      // multiple selections
      if (this.isValidInput(this.selectedCheckboxes)) {
        this.multipleChoice.options.forEach((option, index) => {
          this.selectedCheckboxes.forEach(selectedId => {
            if (option.answer_id === selectedId) {
              this.optionPositions.push(index);
            }
          });
        });

        this.sendMetaData('submit');
        this.segmentService.trackAction('Action Completed', {
          brand_id: this.mission.brand_id,
          action_id: this.action.action_id,
          action_name: this.trackActionName
            ? this.action.multiple_choice.question
            : undefined,
          mission_id: this.mission.mission_id,
          action_kind: this.action.action_kind,
          multiple_choice_kind:
            this.action.multiple_choice.multiple_choice_kind,
          monadic_variant_id:
            this.action.has_monadic && this.action.monadic_variants.length === 1
              ? this.action.monadic_variants[0].monadic_variant_id
              : undefined,
          tag: this.mission.specialType
        });

        input = {
          action_id: this.action.action_id,
          answer_ids: this.selectedCheckboxes,
          ...(this.isOther && this.otherValue
            ? {
                response_value: this.otherValue,
                response_display: this.otherValue
              }
            : {})
        };

        if (this.monadicEnabled()) {
          input.monadic_variant_id =
            this.mission.monadic_variants[this.variantKey].monadic_variant_id;
        }
        this.previewService
          .submitPrerequisite(input, this.mission)
          .subscribe(response => {
            this.isLoading = false;

            if (response.errors) {
              let message = response.message;

              for (const error of Object.keys(response.errors)) {
                message += response.errors[error];
              }
              this.errorMessage = message;
            } else {
              this.isSuccess = true;
            }
          });
      }
    } else {
      // single selection
      let options: any;
      if (
        this.action.multiple_choice.multiple_choice_kind ===
        this.multipleChoiceKind.monadic
      ) {
        this.multipleChoice.options.forEach(option => {
          if (option.isSelected) {
            options = option.answer_id;
          }
        });
      } else {
        options = responseForm.value.options;
      }
      if (this.isValidInput(options)) {
        this.multipleChoice.options.forEach((option, index) => {
          if (option.answer_id === options) {
            this.optionPositions.push(index);
          }
        });
        this.sendMetaData('submit');
        this.segmentService.trackAction('Action Completed', {
          brand_id: this.mission.brand_id,
          action_id: this.action.action_id,
          action_name: this.trackActionName
            ? this.action.multiple_choice.question
            : undefined,
          mission_id: this.mission.mission_id,
          action_kind: this.action.action_kind,
          multiple_choice_kind:
            this.action.multiple_choice.multiple_choice_kind,
          monadic_variant_id:
            this.action.has_monadic && this.action.monadic_variants.length === 1
              ? this.action.monadic_variants[0].monadic_variant_id
              : undefined,
          tag: this.mission.specialType
        });

        input = {
          action_id: this.action.action_id,
          answer_ids: [options],
          ...(this.isOther && this.otherValue
            ? {
                response_value: this.otherValue,
                response_display: this.otherValue
              }
            : {})
        };

        if (this.monadicEnabled()) {
          input.monadic_variant_id =
            this.mission.monadic_variants[this.variantKey].monadic_variant_id;
        }

        this.previewService
          .submitPrerequisite(input, this.mission)
          .subscribe(response => {
            this.isLoading = false;
            if (response.errors) {
              let message = response.message;

              for (const error of Object.keys(response.errors)) {
                message += response.errors[error];
              }
              this.errorMessage = message;
            } else {
              this.isSuccess = true;
            }
          });
      }
    }
  }

  getSelectedCheckboxAnswerIndexArray(): Array<number> {
    const optionIndexArr = [];
    this.selectedCheckboxes.forEach(checkbox => {
      this.multipleChoice.options.forEach(option => {
        if (checkbox === option.answer_id) {
          optionIndexArr.push(option.priority);
        }
      });
    });

    return optionIndexArr;
  }

  isValidInput(input): boolean {
    if (
      this.mission.mission_kind === MissionKind.splittesting &&
      this.action.multiple_choice?.multiple_choice_kind ===
        ActionStructureMultipleChoiceKind.monadic
    ) {
      if (!input) {
        this.errorMessage =
          'You must indicate an answer choice for the question before submitting.';
        return false;
      }
    }
    if (this.isNoneSelected) {
      return true;
    }
    input = Array.isArray(input) ? input : [input];
    if (
      input.length < this.multipleChoice.minChoices ||
      input.length > this.multipleChoice.maxChoices
    ) {
      this.translate
        .get('general.mustSelect')
        .pipe(take(1))
        .subscribe(value => {
          this.errorMessage = value;
        });
      this.metaData.validationCount++;

      return false;
    } else if (this.isOther === true && this.otherValue.trim().length === 0) {
      this.translate
        .get('multipleChoice.otherMustHaveValue')
        .pipe(take(1))
        .subscribe(value => {
          this.errorMessage = value;
        });

      this.metaData.validationCount++;

      return false;
    } else {
      return true;
    }
  }

  shuffle(array: Array<any>): Array<any> {
    let m = array.length;
    let t;
    let i;
    // While there remain elements to shuffle…
    while (m) {
      // Pick a remaining element…
      i = Math.floor(Math.random() * m--);
      // And swap it with the current element.
      t = array[m];
      array[m] = array[i];
      array[i] = t;
    }

    return array;
  }

  removeErrorPopover(): void {
    this.errorMessage = '';
  }

  openMissionExpiredModal(message: any) {
    this.missionExpiredService.notify({
      mission: this.mission,
      title: 'Error',
      message: message
    });
  }

  onScrollExternal(event: any): any {
    // Detect Scroll to Bottom
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight - 1
    ) {
      this.reachedPolicyEnd = true;
      this.showBottomGradient = false;
      this.scrollErrorMessage = '';
    } else {
      if (event.target.scrollTop === 0) {
        this.showTopGradient = false;
        this.showBottomGradient = true;
      } else {
        this.showTopGradient = true;
        this.showBottomGradient = true;
      }
    }
    // Show and hide gradients on scrolling
  }
}
