<ng-template #questionHost></ng-template>
<div
  id="reverseAnimation"
  *ngIf="
    completedMissionData?.detail &&
    !specialMissions &&
    !isExternal &&
    !isPreview &&
    !isGlobal
  "
  class="animated-popup bg-body-light dark:bg-body-dark"
  [ngClass]="{ reverseAnimation: removePointsAnimation }"
>
  <div class="flex items-center justify-between text-black">
    <div class="items-center">
      <div class="flex items-center">
        <ng-container
          *ngIf="
            specialType === specialTileType.profile_tile;
            then special;
            else normal
          "
        >
        </ng-container>

        <ng-template #special>
          <span class="relative">
            <img
              class="sparkles"
              src="assets/img/svg/icons/ic-sparkles.svg"
              alt=""
            />
          </span>
          <div>
            <p
              class="pt-2 text-sm font-bold sm:text-xl text-light-black dark:text-body-light"
            >
              {{ message }}
            </p>
            <p class="mt-2 text-sm font-semibold sm:text-lg text-card-10">
              {{ message2 }}
            </p>
          </div>
        </ng-template>

        <ng-template #normal>
          <span class="relative">
            <img
              class="w-36 sm:w-48"
              src="assets/img/svg/app-default/dashboard/popup-count-bg.svg"
              alt=""
            />

            <span
              class="absolute text-base sm:text-2xl left-0 right-0 flex items-center justify-center h-12 sm:h-10 m-auto font-bold text-black top-10 sm:top-16 w-14"
              >+{{ completedMissionData?.detail?.points_awarded }}</span
            >
          </span>
          <div>
            <p
              class="pt-2 text-sm font-bold sm:text-xl text-light-black dark:text-body-light"
            >
              {{ message | translate }}
            </p>
            <p class="mt-2 text-sm font-semibold sm:text-lg text-card-10">
              {{ completedMissionData?.detail?.points_awarded }}
              {{ 'ending.pointsEarneds' | translate }}
            </p>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
