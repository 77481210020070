import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialSigninComponent } from './components/social-signin/social-signin.component';
import { PhoneInputDirective } from './directives/phone-input.directive';
import { ConvertMarkdownToHtmlPipe } from './pipes/convert-markdown-to-html.pipe';
import { StripMarkdownPipe } from './pipes/strip-markdown.pipe';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { SnackbarService } from '../core/services/snackbar.service';
import { TranslatePipe, TranslateModule } from '@ngx-translate/core';
import { TruncatePipe } from './pipes/truncate.pipe';
import { RecaptchaModalComponent } from './components/recaptcha-modal/recaptcha-modal.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { SharedUiSpinnerModule } from '@suzy/shared/ui/spinner';
import { SharedUiLightboxModule } from '@suzy/shared/ui/lightbox';
import { ProfileMenuComponent } from './components/profile-menu/profile-menu.component';
import { ProfileMenuService } from './components/profile-menu/profile-menu.service';
import { RouterModule } from '@angular/router';
import { PhonePipe } from './pipes/phone.pipe';
import { Markdown2HtmlPipe } from './pipes/markdown2html.pipe';
import { PipingTokenComponent } from './components/piping-token/piping-token.component';
@NgModule({
  exports: [
    SocialSigninComponent,
    PhoneInputDirective,
    TruncatePipe,
    ConvertMarkdownToHtmlPipe,
    SharedUiSpinnerModule,
    SharedUiLightboxModule,
    StripMarkdownPipe,
    SnackbarComponent,
    RecaptchaModalComponent,
    ProfileMenuComponent,
    Markdown2HtmlPipe
  ],
  imports: [
    RouterModule,
    CommonModule,
    TranslateModule,
    SharedUiLightboxModule,
    SharedUiSpinnerModule,
    RecaptchaFormsModule,
    RecaptchaModule
  ],
  declarations: [
    SocialSigninComponent,
    PhoneInputDirective,
    ConvertMarkdownToHtmlPipe,
    StripMarkdownPipe,
    SnackbarComponent,
    TruncatePipe,
    RecaptchaModalComponent,
    ProfileMenuComponent,
    PhonePipe,
    Markdown2HtmlPipe,
    PipingTokenComponent
  ],
  providers: [
    SnackbarService,
    TranslatePipe,
    ProfileMenuService,
    PhonePipe,
    Markdown2HtmlPipe
  ]
})
export class SharedModule {}
