<div class="sidebar-profile-box" *ngIf="points$ | async as points">
  <div
    class="points-graph pre-1000"
    [ngClass]="{ 'pre-1000': points === 0 && points < 1000 }"
  >
    <h5>
      {{ 'general.points' | translate }}
      <a
        href="https://support.crowdtap.com/hc/en-us/articles/360025099111-Why-don-t-my-points-look-the-same-"
        target="_blank"
      >
        <span class="ft-help-circle"></span>
      </a>
    </h5>
    <div class="points-graph-total">{{ points }}</div>
    <div class="points-graph-redeem">
      <a
        class="btn"
        routerLink="/rewards/gift-cards"
        data-track="member-dashboard-redeem"
        >{{ 'nav.redeem' | translate }}</a
      >
    </div>
  </div>
</div>
