import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-warning-info',
  templateUrl: './warning-info.html',
  styleUrls: ['./warning-info.scss']
})
export class WarningInfoComponent {
  @Input() message: string;
}
