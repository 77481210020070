import { Injectable } from '@angular/core';
import { SegmentService } from './segment.service';

declare global {
  interface Window {
    truste: any;
  }
}

enum ConsentType {
  noPreference = 0,
  required = 1,
  functional = 2,
  advertising = 3,
  uncategorized = 4
}

enum TrustArcMessageConsentType {
  required = 0,
  functional = 1,
  advertising = 2
}

enum ConsentSource {
  Implied = 'implied',
  Asserted = 'asserted'
}

interface TrustArcMessage {
  data: string;
  message: string;
  source: string;
}

interface TrustArcConsentDesicion {
  source: ConsentSource;
  consentDecision: Array<ConsentType>;
}

@Injectable({
  providedIn: 'root'
})
export class TrustArcService {
  functionalAllowed = true;
  advertisingAllowed = true;
  source = 'implied';

  private truste: any;
  private segmentService: SegmentService;

  constructor() {
    if (window === undefined) {
      return;
    }
    this.truste = window.truste;

    var apiObject = {
      PrivacyManagerAPI: {
        action: 'getConsentDecision',
        timestamp: new Date().getTime(),
        self: 'crowdtap.com'
      }
    };
    window.top.postMessage(JSON.stringify(apiObject), '*');
    window.addEventListener('message', e => this.messageHandler(e), false);
  }

  getDecision(): void {
    if (this.truste === undefined) {
      return;
    }

    var decision = this.truste.cma.callApi(
      'getGDPRConsentDecision',
      'crowdtap.com'
    );
    // implied or asserted
    this.advertisingAllowed =
      decision.source === ConsentSource.Implied ||
      decision.consentDecision.includes(ConsentType.advertising);
    this.functionalAllowed =
      decision.source === ConsentSource.Implied ||
      decision.consentDecision.includes(ConsentType.functional);
    this.source = decision.source;
  }

  messageHandler(e: any): void {
    try {
      var json: TrustArcMessage =
        e &&
        e.data != '' &&
        !e.data.startsWith('setImmediate') &&
        JSON.parse(e.data);
      if (json && json.message == 'submit_preferences') {
        var consentDecision: Array<TrustArcMessageConsentType> = json.data
          .split(',')
          .map(Number);
        var oldFunctional = this.functionalAllowed;
        var oldAdvertising = this.advertisingAllowed;
        this.source = ConsentSource.Asserted;
        this.functionalAllowed = consentDecision.includes(
          TrustArcMessageConsentType.functional
        );
        this.advertisingAllowed = consentDecision.includes(
          TrustArcMessageConsentType.advertising
        );

        if (
          this.functionalAllowed !== oldFunctional ||
          this.advertisingAllowed !== oldAdvertising
        ) {
          location.reload();
        }
      }
    } catch (e) {}
  }

  setSegmentService(segmentService: SegmentService) {
    this.segmentService = segmentService;
  }
}
