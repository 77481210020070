import { MissionExpiredService } from './mission-expired.service';
import { NgModule } from '@angular/core';
import { MissionExpiredNotifyComponent } from './mission-expired-notify.component';
import { ActionsService } from '../../../views/actions/actions.service';
@NgModule({
  imports: [],
  exports: [MissionExpiredNotifyComponent],
  declarations: [MissionExpiredNotifyComponent],
  providers: [MissionExpiredService, ActionsService]
})
export class MissionExpiredModule {}
