<form
  novalidate=""
  (ngSubmit)="onSubmit()"
  #f="ngForm"
  class="h-full"
  [ngClass]="{ preview: isPreview }"
>
  <!-- Form Content Starts -->
  <div class="form">
    <div class="form-group form-filling">
      <div
        id="fake-corner"
        [style.width]="fakeCorner.width"
        [style.height]="fakeCorner.height"
      >
        &nbsp;
      </div>
      <div class="form-field-container">
        <div class="w-full bg-body-light dark:bg-body-dark">
          <table
            class="w-11/12 md:w-1/2 m-auto maxDiff-action-table"
            [ngClass]="{ darkMode: isDarkMode }"
          >
            <tr class="header-row">
              <th class="text-xs break-all py-3 text-left">
                {{ action?.maxdiff?.chained_action_index }} of
                {{ action?.maxdiff?.chained_action_count }}
              </th>
              <th
                class="text-xs break-all py-3"
                id="{{ action?.maxdiff?.low_value_answer_id }}"
              >
                {{ action?.maxdiff?.low_value_ui_text }}
              </th>
              <th
                class="text-xs break-all py-3"
                id="{{ action?.maxdiff?.high_value_answer_id }}"
              >
                {{ action?.maxdiff?.high_value_ui_text }}
              </th>
            </tr>
          </table>
        </div>
        <table
          class="w-11/12 md:w-1/2 m-auto maxDiff-action-table"
          [ngClass]="{ darkMode: isDarkMode }"
        >
          <tr *ngFor="let option of listArry; index as i">
            <td class="text-sm py-4">
              <div
                *ngIf="action?.maxdiff?.image_answers; else notImage"
                class="image-container relative w-64 h-60 bg-body-light dark:bg-body-dark rounded-lg border-active-link dark:border-black-light"
              >
                <span class="expand-icon" (click)="viewOptionImage(option, i)">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1040_105497)">
                      <rect width="18" height="18" fill="white" />
                      <path
                        d="M16.5059 0H1.57342C0.727629 0 0 0.662273 0 1.50789V16.4403C0 17.2859 0.727629 18 1.57342 18H16.5059C17.3517 18 18 17.2859 18 16.4403V1.50789C18 0.662273 17.3516 0 16.5059 0ZM17.2341 16.4662C17.2341 16.8902 16.8903 17.234 16.4662 17.234H1.5338C1.10971 17.234 0.765984 16.8902 0.765984 16.4662V1.5338C0.765984 1.10978 1.10974 0.765984 1.5338 0.765984H16.4662C16.8903 0.765984 17.2341 1.10978 17.2341 1.5338V16.4662Z"
                        fill="#3A3940"
                      />
                      <path
                        d="M14.3588 3.2981H10.529C10.3175 3.2981 10.146 3.46938 10.146 3.68109C10.146 3.8928 10.3175 4.06408 10.529 4.06408H13.4342L10.4923 6.99314C10.3427 7.14273 10.3427 7.37856 10.4923 7.52815C10.5671 7.60296 10.6651 7.63717 10.763 7.63717C10.861 7.63717 10.9392 7.59818 11.014 7.52337L13.9362 4.57968V7.48492C13.9362 7.6966 14.1076 7.86792 14.3191 7.86792C14.5306 7.86792 14.7021 7.69663 14.7021 7.48492V3.65518C14.7021 3.44347 14.5703 3.2981 14.3588 3.2981Z"
                        fill="#3A3940"
                      />
                      <path
                        d="M7.9599 10.05C7.81031 9.90038 7.55803 9.89391 7.40844 10.0435L4.44665 12.9855V10.0803C4.44665 9.86858 4.27516 9.69727 4.06366 9.69727C3.85216 9.69727 3.68066 9.86855 3.68066 10.0803V13.91C3.68066 13.9352 3.70309 13.9602 3.70805 13.985C3.71037 13.9969 3.72472 14.008 3.72816 14.0194C3.73185 14.0318 3.73962 14.0444 3.74458 14.0564C3.75048 14.0706 3.76086 14.0835 3.76831 14.0968C3.77302 14.1052 3.77794 14.1141 3.78332 14.1222C3.81145 14.1643 3.84836 14.2006 3.89055 14.2288C3.89877 14.2342 3.90798 14.2379 3.91653 14.2427C3.92971 14.2501 3.94272 14.2579 3.95682 14.2638C3.96884 14.2688 3.98146 14.2716 3.99384 14.2753C4.00533 14.2787 4.01637 14.2961 4.02825 14.2984C4.05297 14.3034 4.07814 14.3189 4.10324 14.3189H7.93302C8.14452 14.3189 8.31602 14.1476 8.31602 13.9359C8.31602 13.7242 8.14452 13.5529 7.93302 13.5529H5.02778L7.96978 10.598C8.11944 10.4485 8.10953 10.1996 7.9599 10.05Z"
                        fill="#3A3940"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1040_105497">
                        <rect width="18" height="18" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>

                <img
                  [src]="option.asset.thumbs.full.url"
                  class="w-full h-4/5 rounded-t-lg object-contain"
                  [alt]="option.asset_alt_text"
                />

                <span
                  class="block px-2 py-3 text-center text-sm text font-medium text-light-black dark:text-light-white"
                  >{{ option.answer_text }}</span
                >
              </div>
              <ng-template #notImage>
                <span
                  class="block px-2 py-3 text-sm text font-medium text-light-black dark:text-light-white"
                  >{{ option.answer_text }}</span
                >
              </ng-template>
            </td>
            <td class="text-center">
              <div
                class="least radio-btn bg-body-light dark:bg-body-dark"
                id="{{ option.answer_id }}"
              >
                <input
                  type="radio"
                  name="low"
                  [checked]="option.isLowSelected"
                  (change)="
                    selectChoice(
                      option,
                      'low',
                      action.maxdiff.low_value_answer_id
                    )
                  "
                  id="low-{{ i }}"
                />
                <label for="low-{{ i }}"> </label>
              </div>
            </td>
            <td class="text-center">
              <div
                class="most radio-btn bg-body-light dark:bg-body-dark"
                id="{{ option.answer_id }}"
              >
                <input
                  type="radio"
                  name="high"
                  [checked]="option.isHighSelected"
                  (change)="
                    selectChoice(
                      option,
                      'high',
                      action.maxdiff.high_value_answer_id
                    )
                  "
                  id="high-{{ i }}"
                />
                <label for="high-{{ i }}"> </label>
              </div>
            </td>
          </tr>
        </table>
        <div
          *ngIf="errorMessage"
          class="text-error sm:px-8 w-full justify-center m-auto flex items-center space-x-2 text-xxs md:text-xs font-semibold"
        >
          <svg
            class="mr-2"
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.50016 4.04034C8.06294 4.04034 7.7085 4.39478 7.7085 4.83201V7.99867C7.7085 8.4359 8.06294 8.79034 8.50016 8.79034C8.93739 8.79034 9.29183 8.4359 9.29183 7.99867V4.83201C9.29183 4.39478 8.93739 4.04034 8.50016 4.04034ZM9.22849 10.8646C9.21117 10.8141 9.18721 10.7662 9.15724 10.7221L9.06224 10.6033C8.83459 10.3787 8.49414 10.3131 8.19933 10.4371C8.10339 10.4771 8.01503 10.5334 7.93808 10.6033C7.78989 10.7527 7.70727 10.955 7.70849 11.1654C7.70974 11.2688 7.73126 11.371 7.77182 11.4662C7.84381 11.6651 8.00043 11.8217 8.19933 11.8937C8.39094 11.9784 8.60938 11.9784 8.80099 11.8937C8.99988 11.8217 9.15651 11.6651 9.22849 11.4662C9.26906 11.371 9.29058 11.2688 9.29183 11.1654C9.29571 11.1127 9.29571 11.0598 9.29183 11.0071C9.2782 10.9566 9.25683 10.9085 9.22849 10.8646ZM8.50016 0.0820312C4.12791 0.0820312 0.583496 3.62644 0.583496 7.9987C0.583496 12.371 4.12791 15.9154 8.50016 15.9154C12.8724 15.9154 16.4168 12.371 16.4168 7.9987C16.4168 5.89907 15.5828 3.88543 14.0981 2.40077C12.6134 0.916106 10.5998 0.0820312 8.50016 0.0820312ZM8.50017 14.332C5.00237 14.332 2.16684 11.4965 2.16684 7.99867C2.16684 4.50087 5.00237 1.66534 8.50017 1.66534C11.998 1.66534 14.8335 4.50087 14.8335 7.99867C14.8335 9.67838 14.1662 11.2893 12.9785 12.477C11.7908 13.6647 10.1799 14.332 8.50017 14.332Z"
              fill="#FF5F57"
            />
          </svg>
          <p class="w-80 sm:w-full md:w-1/2 wide">{{ errorMessage }}</p>
        </div>
      </div>
      <!-- Form fields : Container for checkbox Ends -->
    </div>

    <div
      class="relative flex items-center justify-center py-6 space-x-8 align-middle md:space-x-2 form-group"
    >
      <div *ngIf="!disableSkip">
        <button
          class="text-sm font-bold text-blue-dark md:text-base skip md:w-36"
          (click)="onSkip($event)"
          data-track="member-maxDiff-skip"
          (mousemove)="onMouseMoveEvent($event)"
          [ngClass]="{ loading: isSkipping }"
          [disabled]="isSkipping"
        >
          <span class="btn-label"> {{ 'general.skip' | translate }}</span>
          <span class="dot dot-1"></span>
          <span class="dot dot-2"></span>
          <span class="dot dot-3"></span>
        </button>
      </div>
      <div>
        <button
          class="animation-submit-btn"
          [ngClass]="{
            primarySection: !isLoading && !isSuccess,
            loadingSection: isLoading,
            successSection: isSuccess
          }"
          data-track="member-maxDiff-continue"
          (mousemove)="onMouseMoveEvent($event)"
          [disabled]="isSkipping || isSubmitting || isLoading"
        >
          <div class="primaryTitle text submit-label">
            {{ 'general.submit' | translate }}
          </div>
          <div class="primaryTitle text continue-label">
            {{ 'general.continue' | translate }}
          </div>
          <div class="loadingTitle text">
            <suzy-spinner
              class="absolute left-0 right-0 button-spinner top-3"
              size="small"
              button="true"
              [round]="7"
              [color]="'#ffffff'"
              [overlay]="false"
            >
            </suzy-spinner>
          </div>
          <div class="successTitle text">
            <svg class="animated-check" viewBox="0 0 24 24">
              <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />
            </svg>
          </div>
        </button>
      </div>
    </div>
  </div>
  <!-- Form Content Ends -->
</form>
