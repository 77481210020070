import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { PreRollKind, SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import { MissionService } from '@suzy/shared/tools/mission';
import { AppAlertService } from '../../../core/services/app-alert/app-alert.service';
import { ActionComponent } from '../../../views/actions/shared/action-component';
import { SkipModalComponent } from '../skip-modal/skip-modal.component';
import { SkipModalService } from '../../../core/services/skip-modal-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { beforeModalDismiss } from '../actions.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-link-share',
  templateUrl: './link-share.component.html',
  styleUrls: ['./link-share.component.scss']
})
export class LinkShareComponent
  extends ActionComponent
  implements OnDestroy, OnInit
{
  @ViewChild('f') responseForm: NgForm;

  remaining: any;
  response = '';
  hasPreroll: Boolean = false;
  prerollViewed: Boolean = false;
  isValid: Boolean = true;
  isSubmitting = false;
  shared = false;

  preRollKind = PreRollKind.none;
  preRollCaption = '';
  preRollUrl = '';
  disableSkip: boolean;

  constructor(
    private sdk: SuzySdkService,
    private alerts: AppAlertService,
    private modals: NgbModal,
    private missionService: MissionService,
    private skipModalService: SkipModalService
  ) {
    super();
  }

  ngOnInit(): void {
    this.preRollKind = this.action.preroll_kind;
    this.preRollCaption = this.action.preroll_instruction;

    if (
      this.action.preroll_kind !== 'undefined' &&
      this.action.preroll_kind !== PreRollKind.none
    ) {
      if (
        this.action.preroll_kind === PreRollKind.image &&
        this.action.preroll
      ) {
        this.hasPreroll = true;
        this.preRollUrl = this.action.preroll.thumbs.full.url;
      } else if (
        this.action.preroll_kind === PreRollKind.link &&
        this.action.preroll_body
      ) {
        this.hasPreroll = true;
        this.preRollUrl = this.action.preroll_body;
      }
    }

    if (this.mission.first_action && this.mission.first_action.prevent_skip) {
      this.disableSkip = this.mission.first_action.prevent_skip;
    }
    this.skipModalService.isSkip.pipe(take(1)).subscribe(data => {
      if (data === 'link_share') {
        this.skipAction();
      }
    });
  }

  init(): void {
    this.remaining = this.action.share_link.max_characters;
  }

  ngOnDestroy(): void {
    this.subject.complete();
    this.subject = undefined;
  }

  onPrerollViewedEvent(): void {
    this.prerollViewed = true;
  }

  validateInput(input): Boolean {
    return (this.isValid = this.response.trim().length > 0);
  }

  skipAction(): void {
    this.isSubmitting = true;
    this.sdk.ProtocolRespond.skip(
      this.mission.brand_id,
      this.mission.mission_id,
      this.action.action_id
    ).subscribe(
      data => {
        if (data.success) {
          this.skipModalService.isSuccess.next(true);
          setTimeout(() => {
            this.missionService.onMissionAnswered(
              this.mission.mission_id,
              data.meta
            );
            this.subject.next(data);
          }, 1000);
        } else {
          this.skipModalService.isSuccess.next(false);
          document.getElementById('skipBtn').classList.remove('loading');
          let message = data.message;
          if (data.errors) {
            for (const error of Object.keys(data.errors)) {
              message += `\n - ${data.errors[error]}`;
            }
          }
          this.alerts.notify({ title: 'Error', message });
        }
      },
      error => {
        this.skipModalService.isSuccess.next(false);
        document.getElementById('skipBtn').classList.remove('loading');
        this.alerts.notify({ title: 'Error', message: error });
      },
      () => {
        this.isSubmitting = false;
      }
    );
  }

  openSkipModal(): void {
    const modalClass = `modal-${new Date().getTime()}`;
    let modal;
    modal = this.modals.open(SkipModalComponent, {
      windowClass: modalClass,
      beforeDismiss: () => {
        return beforeModalDismiss(modalClass, 'skip');
      }
    });
    const instance = modal.componentInstance as SkipModalComponent;
    instance.kind = this.mission.mission_kind;
    instance.mission = this.mission;
    instance.action = this.mission.first_action;
    instance.actionService = null;
    instance.actionType = 'link_share';
  }

  skip($event): void {
    $event.preventDefault();
    this.openSkipModal();
  }

  onShared(): void {
    this.shared = true;
  }

  onSubmit(): void {
    const input = {
      action_id: this.action.action_id,
      answer_id: this.action.share_link.answer_id,
      response_value: this.response,
      response_display: this.response,
      answer_items: this.action.share_link.platforms
    };

    this.isSubmitting = true;

    this.sdk.ProtocolRespond.respond(
      this.mission.brand_id,
      this.mission.mission_id,
      input
    ).subscribe(
      data => {
        if (data.success) {
          this.missionService.onMissionAnswered(
            this.mission.mission_id,
            data.meta
          );
          this.subject.next(data);
        } else {
          let message = data.message;
          if (data.errors) {
            for (const error of Object.keys(data.errors)) {
              message += `\n - ${data.errors[error]}`;
            }
          }
          this.alerts.notify({ title: 'Error', message });
        }
      },
      error => {
        this.alerts.notify({ title: 'Error', message: error });
      },
      () => {
        this.isSubmitting = false;
      }
    );
  }
}
