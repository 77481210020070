import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-preview-ending',
  templateUrl: './preview-ending.component.html',
  styleUrls: ['./preview-ending.component.scss']
})
export class PreviewEndingComponent {
  errorBarMessage: string;
  @Input() isLoading: any;

  constructor(public modal: NgbActiveModal) {}

  onRestartSurvey(): void {
    this.isLoading = true;
    this.modal.dismiss('refresh');
  }
}
