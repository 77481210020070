import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@suzy/crowdtap/data-access/user';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import {
  FacebookLoginProvider,
  SocialAuthService
} from 'angularx-social-login';
import swal from 'sweetalert2';
import { GrecaptchaService } from '../../../core/services/grecaptcha.service';
import { SignupService } from '../../../views/anonymous/signup/signup.service';

@Component({
  selector: 'app-social-signin',
  templateUrl: './social-signin.component.html',
  styleUrls: ['./social-signin.component.scss']
})
export class SocialSigninComponent implements OnInit {
  @Input() label;
  @Input() isLogin;
  @Input() currentCampaignKeys;
  @Input() currentPanelKeys;
  @Input() currentReferralKeys;
  @Input() currentPromoKeys;
  isLoading: boolean;
  grecaptchaToken: string;
  trackAttr = 'member-sign-up-facebook';

  constructor(
    private authService: AuthenticationService,
    private socialAuthService: SocialAuthService,
    private signupService: SignupService,
    private gtm: Angulartics2GoogleTagManager,
    private router: Router,
    private grecaptchaService: GrecaptchaService
  ) {}

  ngOnInit(): void {
    if (this.isLogin) {
      this.trackAttr = 'member-login-facebook';
    }

    this.grecaptchaService.execute('fb-login', token => {
      this.grecaptchaToken = token;
    });

    if (this.currentPanelKeys) {
      this.currentPanelKeys = this.authService.getCurrentPanelKeys();
    }
  }

  socialSignIn(socialPlatform: string): void {
    let socialPlatformProvider;
    this.isLoading = true;

    if (socialPlatform === 'facebook') {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
      this.isLoading = false;
    }

    this.socialAuthService.signIn(socialPlatformProvider).then(userData => {
      this.authService
        .authenticateFacebookUser(userData, this.grecaptchaToken)
        .subscribe(response => {
          this.isLoading = false;

          if (this.currentPanelKeys) {
            this.currentPanelKeys = this.authService.getCurrentPanelKeys();
            response.item.panel_token = this.currentPanelKeys.panelToken;
          }

          if (this.currentReferralKeys) {
            this.currentReferralKeys = this.authService.getCurrentReferralKeys();
            response.item.referral_token = this.currentReferralKeys.referral_token;
          }

          if (this.currentPromoKeys) {
            this.currentReferralKeys = this.authService.getCurrentPromoKeys();
            response.item.promotion_token = this.currentReferralKeys.promotion_token;
          }

          if (response.success) {
            this.gtm.eventTrack('js-home-fb-login-success', {
              event: 'js-home-fb-login-success',
              gtmCustom: {
                is_fb_registered: true,
                is_email_registered: false
              }
            });

            if (this.currentCampaignKeys) {
              response.item.source = this.currentCampaignKeys.source;
              response.item.c_source = this.currentCampaignKeys.utmSource;
              response.item.c_medium = this.currentCampaignKeys.utmMedium;
              response.item.c_name = this.currentCampaignKeys.utmName;
              response.item.c_terms = this.currentCampaignKeys.utmTerm;
              response.item.c_content = this.currentCampaignKeys.utmContent;
              response.item.c_misc_one = this.currentCampaignKeys.miscOne;
              response.item.c_misc_two = this.currentCampaignKeys.miscTwo;
            }

            this.authService.login(response.item);
            this.authService.purgeCampaignKeys();
            this.router.navigate(['dashboard']);
          } else if (response.meta) {
            this.gtm.eventTrack('js-home-fb-auth-success', {
              event: 'js-home-fb-auth-success'
            });
            this.signupService.setUser({
              ...response.meta,
              ...{ facebook_access_token: userData.authToken }
            });
            this.authService.purgePanelKeys();
            this.authService.purgeCampaignKeys();
            this.router.navigate(['auth', 'get-to-know-you']);
          } else {
            this.gtm.eventTrack('js-home-fb-auth-failed', {
              event: 'js-home-fb-auth-failed'
            });

            swal.fire({
              buttonsStyling: false,
              confirmButtonText: 'Continue',
              confirmButtonClass: 'btn-confirm',
              customClass: 'general-modal',
              showConfirmButton: true,
              text: response.message,
              title: 'Error authenticating facebook user',
              type: 'warning'
            });
          }
        });
    });
    // Since we didn't get any response when there is a facebook issue, I added this for temporary solution.
    setTimeout(() => {
      this.isLoading = false;
    }, 1000 * 60);
  }
}
