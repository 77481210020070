import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbsUrlPipe } from './abs-url.pipe';
import { ArabicNumberPipe } from './convert-to-eastern-arabic-number.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [AbsUrlPipe, ArabicNumberPipe],
  exports: [AbsUrlPipe, ArabicNumberPipe],
  providers: [AbsUrlPipe]
})
export class SharedToolsUrlModule {}
