import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  transform(value: string): string {
    if (!value.match(/^\+1\d{10}$/)) {
      return value;
    }

    return value.replace(/^\+1(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }
}
