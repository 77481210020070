import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'cookie-policy-v20220915',
  templateUrl: './cookie-policy-v20220915.component.html',
  styleUrls: ['./cookie-policy-v20220915.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookiePolicyV20220915Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
