<div class="pt-6" #successAimation>
  <!-- ngBootstrap Progress Bar Starts -->
  <ngb-progressbar [value]="100" class="progressbar-xs"></ngb-progressbar>
  <!-- ngBootstrap Progress Bar Ends -->

  <svg
    class="m-auto pb-2"
    width="53"
    height="52"
    viewBox="0 0 53 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.4999 51.2C40.6384 51.2 52.0999 39.7385 52.0999 25.6C52.0999 11.4615 40.6384 0 26.4999 0C12.3614 0 0.899902 11.4615 0.899902 25.6C0.899902 39.7385 12.3614 51.2 26.4999 51.2Z"
      fill="#68C97F"
    />
    <path
      d="M14.407 25.5026L21.9651 33.0607L38.5929 16.4329"
      stroke="white"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <h3 class="text-light-black dark:text-white sm:text-4xl">
    {{ 'auth.passwordUpdated' | translate }}
  </h3>
  <p class="text-light-black dark:text-white">
    {{ 'auth.passwordUpdatedText' | translate }}
  </p>
  <p class="text-light-black dark:text-white">
    <button
      class="font-bold text-white rounded-full w-52 h-14 bg-blue-dark"
      routerLink="/auth/login"
    >
      {{ 'general.gotIt' | translate }}
    </button>
  </p>
  <p class="text-light-black dark:text-white">
    {{ 'support.needHelp' | translate }}
    <a
      href="https://support.crowdtap.com/"
      class="text-blue-dark"
      target="_blank"
      >{{ 'support.visitOurSupportPage' | translate }}</a
    >
  </p>
</div>
