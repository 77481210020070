import {
  Component,
  AfterViewInit,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { interval } from 'rxjs';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements AfterViewInit, OnInit {
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef;
  el_wrapper: HTMLElement;
  externalSurveyData = {
    status: undefined,
    userPoint: undefined,
    surveyPoint: undefined,
    partnerResponseId: undefined
  };
  maxCounter = 10;
  counter = 0;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.externalSurveyData.status = params['status'];
      this.externalSurveyData.surveyPoint = params['survey_point'];
      this.externalSurveyData.userPoint = params['user_point'];
      this.externalSurveyData.partnerResponseId = params['partner_response_id'];
    });

    if (window.opener) {
      window.opener.postMessage(
        JSON.stringify(this.externalSurveyData),
        window.origin
      );
    }
  }

  ngAfterViewInit(): void {
    this.el_wrapper = this.wrapper.nativeElement as HTMLElement;
    this.startCountDown();
  }

  startCountDown(): void {
    const counterSubscription = interval(1000).subscribe(value => {
      this.counter = value;
      if (this.maxCounter - this.counter <= 0) {
        counterSubscription.unsubscribe();
        window.close();
      }
    });
  }

  closeWindow(): void {
    window.close();
  }

  onBackToTop(): void {
    this.el_wrapper.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    });
  }
}
