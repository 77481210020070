import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'suzy-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent implements OnInit {
  @Input() size = 'large';
  @Input() color: string;
  @Input() round: string;
  @Input() button = false;
  @Input() overlay = true;
  @Input() text = false;
  @Input() negativeMarginTop = false;
  @Input() overlayColor: string;
  @Input() animatedPath: boolean;

  svg: {
    minWidth: number;
    minHeight: number;
    width: number;
    height: number;
    radius: number;
    stroke: number;
  };

  config = {
    large: {
      minWidth: 25,
      minHeight: 25,
      width: 50,
      height: 50,
      radius: 20,
      stroke: 2
    },
    medium: {
      minWidth: 25,
      minHeight: 25,
      width: 50,
      height: 50,
      radius: 10,
      stroke: 2
    },
    small: {
      minWidth: 25,
      minHeight: 25,
      width: 50,
      height: 50,
      radius: 5,
      stroke: 2
    }
  };

  ngOnInit(): void {
    if (!this.color) {
      this.color = '#3589F9';
    }
    if (!this.round) {
      this.color = '5';
    }
    this.svg = this.config[this.size];
  }

  getViewBoxAttr(): string {
    return `${this.svg.minWidth} ${this.svg.minHeight} ${this.svg.width} ${this.svg.height}`;
  }
}
