import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'cookie-policy-current',
  templateUrl: './cookie-policy-current.component.html',
  styleUrls: ['./cookie-policy-current.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookiePolicyCurrentComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
