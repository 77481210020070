import { Injectable } from '@angular/core';
import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import { map } from 'rxjs/operators';

@Injectable()
export class LegalServices {
  constructor(private sdk: SuzySdkService) {}

  getLegalList(): Array<any> {
    return [
      {
        name: 'terms-of-service',
        linkUrl: '/terms-of-service',
        linkName: 'nav.termsOfService'
      },
      {
        name: 'privacy-policy',
        linkUrl: '/privacy-policy',
        linkName: 'nav.privacyPolicy'
      },
      {
        name: 'financial-incentive',
        linkUrl: '/notice-of-financial-incentive',
        linkName: 'nav.noticeOfFinancialIncentive'
      },
      {
        name: 'cookie-policy',
        linkUrl: '/cookie-policy',
        linkName: 'nav.cookiesPolicy'
      },
      {
        name: 'biometric-policy',
        linkUrl: '/biometric-data-policy',
        linkName: 'nav.biometricPolicy'
      },
      {
        name: 'accessibility-statement',
        linkUrl: '/accessibility-statement',
        linkName: 'nav.accessibilityStatement'
      }
    ];
  }

  getLegal(name): any {
    return this.sdk.ProtocolEngage.getLegalNotice(name).pipe(
      map((response: any) => {
        if (response.success) {
          const legalData = {
            caption: '',
            content: ''
          };
          if (response.items.length) {
            const activeLegal = response.items.find(
              legal => legal.enabled === true
            );
            if (activeLegal) {
              legalData.caption = activeLegal.caption;
              legalData.content = activeLegal.content;
            }
          }

          return legalData;
        } else {
          throw new Error(response.message);
        }
      })
    );
  }

  getPublicLegal(name): any {
    return this.sdk.ProtocolRegister.getLegals(name).pipe(
      map((response: any) => {
        if (response.success) {
          const legalData = {
            caption: '',
            content: ''
          };
          if (response.items.length) {
            const activeLegal = response.items.find(
              legal => legal.enabled === true
            );
            if (activeLegal) {
              legalData.caption = activeLegal.caption;
              legalData.content = activeLegal.content;
            }
          }

          return legalData;
        } else {
          throw new Error(response.message);
        }
      })
    );
  }
}
