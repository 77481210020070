import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MissionService } from '@suzy/shared/tools/mission';
@Component({
  selector: 'app-ending',
  templateUrl: './ending.component.html',
  styleUrls: ['./ending.component.scss']
})
export class EndingComponent implements OnInit, OnDestroy {
  points: any;
  currentPoints: number;
  type: string;
  mission: any;
  startTime: number;
  showLoader = true;
  timeOut: any;

  constructor(
    private renderer: Renderer2,
    public modal: NgbActiveModal,
    private missionService: MissionService
  ) {}

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'isActionModal');
  }

  closeModal(): void {
    this.missionService.setMissionOpen(false);
    this.modal.dismiss(true);
  }

  timeCompleted(event): void {
    if (event === 'completed') {
      this.showLoader = false;
    } else if (event === 'redirect') {
      if (this.timeOut) {
        return;
      }
      this.timeOut = setTimeout(() => {
        this.closeModal();
      });
    }
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'isActionModal');
    clearTimeout(this.timeOut);
  }
}
