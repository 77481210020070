import { Injectable } from '@angular/core';
import { take, map } from 'rxjs/operators';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { IncentiveProgramService } from '@suzy/crowdtap/data-access/user';
import { LaunchDarklyService } from '@suzy/shared/data-access/feature-flag';

@Injectable({
  providedIn: 'root'
})
export class RAFEligibleGuard implements CanActivate {
  constructor(
    private router: Router,
    private launchDarklyService: LaunchDarklyService,
    private incentiveProgramService: IncentiveProgramService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.incentiveProgramService.getIncentivePrograms().pipe(
      map(res => {
        const programs = res;
        if (this.launchDarklyService.getRAFFlag() && this.InProgram(programs)) {
          return true;
        } else {
          this.router.navigate(['/dashboard']);

          return false;
        }
      }),
      take(1)
    );
  }

  InProgram(programs: any): boolean {
    if (programs.success) {
      const matchingPrograms = programs.items.filter(
        program =>
          program.enabled &&
          program.member_visible &&
          this.incentiveProgramService.isRafProgram(
            program.incentive_program_id
          )
      );

      return matchingPrograms.length > 0;
    } else {
      return false;
    }
  }
}
