import { Injectable, NgZone } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class GrecaptchaService {
  protected readonly windowGrecaptcha = 'grecaptcha';
  protected readonly windowOnLoadCallbackProperty =
    'grecaptcha_onload_callback';

  constructor(protected zone: NgZone) {}

  cleanup(): void {
    window[this.windowOnLoadCallbackProperty] = undefined;
    window[this.windowGrecaptcha] = undefined;
  }

  execute(action: string, callback: (token: string) => void): void {
    const grecaptchaToken = environment.grecaptchaToken;

    this.registerCaptchaScript(grecaptchaToken, grecaptcha => {
      this.zone.runOutsideAngular(() => {
        grecaptcha.execute(grecaptchaToken, { action }).then(token => {
          this.zone.run(() => callback(token));
        });
      });
    });
  }

  registerCaptchaScript(
    apiToken: string,
    onLoad: (grecaptcha: any) => void
  ): void {
    if (this.grecaptchaScriptLoaded()) {
      this.zone.run(() => {
        onLoad(window[this.windowGrecaptcha]);
      });

      return;
    }

    window[this.windowOnLoadCallbackProperty] = () =>
      this.zone.run(onLoad.bind(this, window[this.windowGrecaptcha]));

    const scriptElem = document.createElement('script');
    scriptElem.innerHTML = '';
    scriptElem.src = this.getCaptchaScriptUrl(apiToken);
    scriptElem.async = true;
    scriptElem.defer = true;

    document.getElementsByTagName('head')[0].appendChild(scriptElem);
  }

  private grecaptchaScriptLoaded(): boolean {
    if (
      window[this.windowOnLoadCallbackProperty] &&
      window[this.windowGrecaptcha]
    ) {
      return true;
    }

    return false;
  }

  private getCaptchaScriptUrl(apiToken: string): string {
    return `https://www.google.com/recaptcha/api.js?onload=${this.windowOnLoadCallbackProperty}&render=${apiToken}`;
  }
}
