import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { LaunchDarklyService } from '@suzy/shared/data-access/feature-flag';
import {
  ChallengePeriod,
  ChallengeType,
  SuzySdkService
} from '@suzy/shared/data-access/suzy-sdk';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { find, map, switchMap, takeUntil } from 'rxjs/operators';

@Injectable()
export class ChallengeService {
  constructor(
    private suzySdkService: SuzySdkService,
    private launchDarklyService: LaunchDarklyService
  ) {}

  private availableChallenge: AvailableChallenge;
  private currentChallenge: ChallengeInfo;
  private currentStreak: ChallengeInfo;
  private availableCheckedKey = 'challenge-available-checked';
  private availableEligibleKey = 'challenge-available-eligible';
  private availableClosedKey = 'challenge-available-closed';
  private milestoneShownKey = 'challenge-milestone-{milestone}-shown';
  private missionBuffer = 3;
  private closeModalLimit = 2;
  private availableChallengeSubject$ = new BehaviorSubject<AvailableChallenge>(
    undefined
  );
  private inProgressChallengeSubject$ = new BehaviorSubject<ChallengeInfo>(
    undefined
  );
  private streakSubject$ = new BehaviorSubject<number>(undefined);
  private milestoneModalSubject$ = new Subject<ChallengeInfo>();
  availableChallenge$ = this.availableChallengeSubject$.asObservable();
  inProgressChallenge$ = this.inProgressChallengeSubject$.asObservable();
  streak$ = this.streakSubject$.asObservable();
  milestoneModal$ = this.milestoneModalSubject$.asObservable();
  availableMissions = 0;

  private processInProgress(
    challenge: InProgressChallenge | Challenge,
    showMilestone: boolean
  ): void {
    var stage = ChallengeStage.inProgress;
    if (challenge.awarded_utc) {
      stage = ChallengeStage.awarded;
    } else if (challenge.completed_utc) {
      stage = ChallengeStage.completed;
    }
    this.currentChallenge = {
      points: challenge.track_challenge.award_points,
      missions_required:
        challenge.track_challenge?.track_challenge_config?.mission
          .required_mission_count,
      missions_progress: this.missionsProgress(challenge),
      end_utc: new Date(challenge.expires_utc),
      updated_utc: new Date(challenge.updated_utc),
      track_challenge_id: challenge.track_challenge_id,
      challengeStage: stage,
      user_track_challenge_id: challenge.user_track_challenge_id,
      challenge_period: challenge.track_challenge.challenge_period,
      challenge_type: challenge.track_challenge.challenge_type
    };
    this.inProgressChallengeSubject$.next(this.currentChallenge);
    if (this.availableChallengeSubject$.value) {
      this.availableChallengeSubject$.next(undefined);
    }
    this.checkMilestone(showMilestone);
  }

  private missionsProgress(challenge: InProgressChallenge | Challenge): number {
    if ('progress' in challenge) {
      return challenge.progress.mission.mission_count <
        challenge.track_challenge.track_challenge_config.mission
          .required_mission_count
        ? challenge.progress.mission.mission_count
        : challenge.track_challenge.track_challenge_config.mission
            .required_mission_count;
    } else if (
      challenge.track_challenge?.challenge_type === ChallengeType.mission &&
      (challenge.awarded_utc || challenge.completed_utc)
    ) {
      return challenge.track_challenge.track_challenge_config.mission
        .required_mission_count;
    } else {
      return undefined;
    }
  }

  private processStreak(challenge?: InProgressChallenge): void {
    if (challenge) {
      this.currentStreak = {
        points: undefined,
        missions_required: undefined,
        missions_progress: undefined,
        end_utc: new Date(challenge.expires_utc),
        updated_utc: new Date(challenge.updated_utc),
        track_challenge_id: challenge.track_challenge_id,
        challengeStage: undefined,
        user_track_challenge_id: challenge.user_track_challenge_id,
        challenge_period: challenge.track_challenge.challenge_period,
        challenge_type: challenge.track_challenge.challenge_type
      };
      this.streakSubject$.next(challenge.streak_count);
    } else {
      // user has never had a streak
      this.streakSubject$.next(0);
    }
  }

  private checkMilestone(showMilestone: boolean): void {
    if (
      this.currentChallenge &&
      this.currentChallenge.challengeStage === ChallengeStage.inProgress
    ) {
      const milestoneOne = Math.ceil(
        this.currentChallenge.missions_required / 3
      );
      const milestoneTwo = Math.ceil(
        (2 * this.currentChallenge.missions_required) / 3
      );
      // if a mission doesn't count towards challenge, this could incorrectly redisplay milestone
      if (
        showMilestone &&
        this.currentChallenge.missions_progress === milestoneOne
      ) {
        this.checkShowMilestone('1');
      } else if (
        showMilestone &&
        this.currentChallenge.missions_progress === milestoneTwo
      ) {
        this.checkShowMilestone('2');
      } else if (
        this.currentChallenge.missions_progress ===
        this.currentChallenge.missions_required
      ) {
        this.completeChallenge(
          this.currentChallenge.user_track_challenge_id
        ).subscribe(data => {
          if (showMilestone && data && data.success) {
            this.checkShowMilestone('3');
          }
        });
      }
    } else if (
      showMilestone &&
      this.currentChallenge &&
      this.currentChallenge.challengeStage === ChallengeStage.completed
    ) {
      this.checkShowMilestone('3');
    }
  }

  private getDateUtcInt(): number {
    const now = new Date();

    return this.getDateInt(now);
  }
  private getDateEstNum(): number {
    return this.getDateInt(this.getDateEst());
  }
  private getDateEst(): Date {
    let currentEst = this.dateToEst(new Date());

    return currentEst;
  }
  private getDateInt(d: Date): number {
    return parseInt(
      d.getUTCFullYear().toString() +
        d.getUTCMonth().toString().padStart(2, '0') +
        d.getUTCDate().toString().padStart(2, '0'),
      10
    );
  }
  private dateToEst(dateUtc: Date): Date {
    return new Date(
      dateUtc.toLocaleString('en-US', { timeZone: 'America/New_York' })
    );
  }
  private isTodayEst(dateUtc: Date): boolean {
    let dateEst = this.getDateInt(this.dateToEst(dateUtc));
    return this.isTodayEstNum(dateEst);
  }
  private isTodayEstNum(dateEst: Number): boolean {
    return dateEst === this.getDateEstNum();
  }

  private extendStreakAfterLoad(): Observable<boolean> {
    if (this.currentStreak && this.isTodayEst(this.currentStreak.updated_utc)) {
      return of(false);
    }
    const streak =
      this.suzySdkService.ProtocolChallenge.processStreakChallenges();

    return streak.pipe(
      map((data: any) => {
        if (data && data.success) {
          const streakChallenge: InProgressChallenge[] =
            data.item.in_progress.filter(challenge => {
              return (
                challenge.track_challenge.challenge_type ===
                ChallengeType.streak_infinite
              );
            });

          if (streakChallenge.length > 0) {
            if (!this.isTodayEst(streakChallenge[0].updated_utc)) {
              return false;
            }
            this.processStreak(streakChallenge[0]);

            return true;
          }
        } else {
          return false;
        }
      })
    );
  }

  checkShowMilestone(milestone: string): void {
    const dateUtc = this.getDateUtcInt();
    var key = this.milestoneShownKey.replace('{milestone}', milestone);
    const lastShown = localStorage.getItem(key);
    if (!lastShown || parseInt(lastShown, 10) !== dateUtc) {
      this.milestoneModalSubject$.next(this.currentChallenge);
      localStorage.setItem(key, dateUtc.toString());
    }
  }

  challengeModalClosed(challengeName: string): void {
    const closedChallengesData = localStorage.getItem(this.availableClosedKey);
    var closedChallenges = {};
    if (closedChallengesData) {
      closedChallenges = JSON.parse(closedChallengesData);
      if (closedChallenges[challengeName]) {
        closedChallenges[challengeName]++;
      } else {
        closedChallenges[challengeName] = 1;
      }
    } else {
      closedChallenges[challengeName] = 1;
    }
    localStorage.setItem(
      this.availableClosedKey,
      JSON.stringify(closedChallenges)
    );
  }

  getChallenges(showAvailable = true): void {
    const dateUtc = this.getDateUtcInt();
    const lastChecked = localStorage.getItem(this.availableCheckedKey);
    var eligible = true;
    if (lastChecked && parseInt(lastChecked, 10) === dateUtc) {
      eligible = localStorage.getItem(this.availableEligibleKey) === 'true';
    }
    this.suzySdkService.ProtocolChallenge.getAvailableChallenges().subscribe(
      data => {
        if (data && data.success) {
          const challenges: Challenges = data;
          const closedChallengesData = localStorage.getItem(
            this.availableClosedKey
          );
          var closedChallenges = {};
          if (closedChallengesData) {
            closedChallenges = JSON.parse(closedChallengesData);
            // remove closed modal statuses for challenges no longer available
            Object.keys(closedChallenges).forEach(key => {
              if (
                !challenges?.item?.available?.some(challenge => {
                  return challenge.track_challenge_id === key;
                })
              ) {
                delete closedChallenges[key];
              }
            });
            localStorage.setItem(
              this.availableClosedKey,
              JSON.stringify(closedChallenges)
            );
          }

          // remove expired completed challenges (in case BE isn't)
          if (challenges?.item?.completed?.length > 0) {
            challenges.item.completed = challenges.item.completed.filter(
              challenge => {
                const dateUtc = this.getDateUtcInt();
                const expires = this.getDateInt(
                  new Date(challenge.expires_utc)
                );

                return expires > dateUtc;
              }
            );
          }

          if (
            this.launchDarklyService.getCWDT1796Flag() &&
            this.launchDarklyService.getCWDT1796SM1809Flag()
          ) {
            const streakChallenge = challenges.item.in_progress.filter(
              challenge => {
                return (
                  challenge.track_challenge.challenge_type ===
                  ChallengeType.streak_infinite
                );
              }
            );

            if (streakChallenge.length > 0) {
              this.processStreak(streakChallenge[0]);
            } else {
              this.processStreak();
            }
          }

          if (
            this.launchDarklyService.getCWDT1182Flag() &&
            this.launchDarklyService.getCWDT1182SM1691Flag()
          ) {
            const in_progress = challenges.item.in_progress.filter(
              challenge => {
                return (
                  challenge.track_challenge.challenge_type ===
                  ChallengeType.mission
                );
              }
            );
            if (in_progress.length > 0) {
              this.processInProgress(in_progress[0], false);
            } else if (challenges?.item?.completed?.length > 0) {
              // currently only one challenge at a time
              // will need to be altered in phase 2
              const completed = challenges.item.completed.filter(challenge => {
                return !challenge.awarded_utc;
              });
              if (completed.length > 0) {
                // completed but not claimed
                this.getStatus(completed[0].user_track_challenge_id);
              } else {
                const awarded = challenges.item.completed.filter(challenge => {
                  const dateUtc = this.getDateUtcInt();
                  const awarded = this.getDateInt(
                    new Date(challenge.awarded_utc)
                  );

                  return awarded === dateUtc;
                });
                if (awarded.length > 0) {
                  this.processInProgress(awarded[0], false);
                  //this.getStatus(awarded[0].user_track_challenge_id);
                }
              }
            } else {
              if (this.inProgressChallengeSubject$ !== undefined) {
                // queue to hide challenge pill, etc
                this.inProgressChallengeSubject$.next(undefined);
              }
              if (showAvailable) {
                if (eligible && challenges?.item?.available?.length > 0) {
                  // only check for available challenges under mission threshold once a utc day
                  // once multiple active challenges are possible, this logic will need to change

                  const eligibleChallenges = challenges.item.available.filter(
                    challenge => {
                      // filter missions where user has enough missions to complete challenge
                      return (
                        challenge.enabled &&
                        challenge.challenge_type === ChallengeType.mission &&
                        challenge.track_challenge_config?.mission
                          ?.required_mission_count +
                          this.missionBuffer <
                          this.availableMissions &&
                        (!closedChallenges[challenge.track_challenge_id] ||
                          closedChallenges[challenge.track_challenge_id] <
                            this.closeModalLimit)
                      );
                    }
                  );

                  if (eligibleChallenges.length > 0) {
                    if (this.availableChallenge !== eligibleChallenges[0]) {
                      this.availableChallenge = eligibleChallenges[0];
                      this.availableChallengeSubject$.next(
                        this.availableChallenge
                      );
                    }
                    localStorage.setItem(this.availableEligibleKey, 'true');
                  } else {
                    localStorage.setItem(this.availableEligibleKey, 'false');
                  }
                } else {
                  localStorage.setItem(this.availableEligibleKey, 'false');
                }
                localStorage.setItem(
                  this.availableCheckedKey,
                  dateUtc.toString()
                );
              }
            }
          }
        }
      }
    );
  }

  startChallenge(track_challenge_id: string): Observable<ChallengeWrapper> {
    const challenge =
      this.suzySdkService.ProtocolChallenge.startChallenge(track_challenge_id);
    challenge.subscribe(data => {
      if (data && data.success) {
        const challenge: ChallengeWrapper = data;
        this.getStatus(challenge.item.user_track_challenge_id);
      }
    });

    return challenge;
  }

  getStreakStatus(): void {
    if (
      this.currentStreak &&
      !this.isTodayEst(new Date(this.currentStreak.updated_utc))
    ) {
      this.getStatus(this.currentStreak.user_track_challenge_id);
    } else if (
      !this.currentStreak &&
      this.launchDarklyService.getCWDT1796Flag() &&
      this.launchDarklyService.getCWDT1796SM1809Flag()
    ) {
      this.getChallenges();
    }
  }

  getChallengeStatus(user_track_challenge_id?: string): void {
    if (
      this.launchDarklyService.getCWDT1182Flag() &&
      this.launchDarklyService.getCWDT1182SM1691Flag() &&
      (user_track_challenge_id ||
        (this.currentChallenge &&
          this.currentChallenge.challengeStage !== ChallengeStage.awarded))
    ) {
      this.getStatus(
        user_track_challenge_id ?? this.currentChallenge.user_track_challenge_id
      );
    }
  }

  private getStatus(
    user_track_challenge_id: string
  ): Observable<ChallengeStatus> {
    const challenge =
      this.suzySdkService.ProtocolChallenge.getUserTrackChallenge(
        user_track_challenge_id
      );
    challenge.subscribe(data => {
      if (data && data.success) {
        const in_progress: InProgressChallenge = data.item;
        if (
          in_progress.track_challenge.challenge_type === ChallengeType.mission
        ) {
          this.processInProgress(in_progress, true);
        } else if (
          in_progress.track_challenge.challenge_type ===
          ChallengeType.streak_infinite
        ) {
          this.processStreak(in_progress);
        }
      }
    });

    return challenge;
  }

  completeChallenge(
    user_track_challenge_id?: string
  ): Observable<ChallengeWrapper> {
    const challenge = this.suzySdkService.ProtocolChallenge.completeChallenge(
      user_track_challenge_id
    );

    challenge.subscribe((data: ChallengeWrapper) => {
      if (data && data.success && data.item?.completed_utc) {
        this.currentChallenge.challengeStage = ChallengeStage.completed;
        this.inProgressChallengeSubject$.next(this.currentChallenge);
      }
    });

    return challenge;
  }

  claimPoints(user_track_challenge_id?: string): Observable<ChallengeWrapper> {
    const challenge = this.suzySdkService.ProtocolChallenge.claimReward(
      user_track_challenge_id
    );

    challenge.subscribe((data: ChallengeWrapper) => {
      if (data && data.success && data.item?.awarded_utc) {
        this.currentChallenge.challengeStage = ChallengeStage.awarded;
        this.inProgressChallengeSubject$.next(this.currentChallenge);
      }
    });

    return challenge;
  }

  extendStreakWithoutMission(): Observable<boolean> {
    if (
      this.launchDarklyService.getCWDT1796Flag() &&
      this.launchDarklyService.getCWDT1796SM1809Flag()
    ) {
      if (this.streakSubject$.value !== undefined) {
        return this.extendStreakAfterLoad();
      } else {
        return this.streakSubject$.pipe(
          find(streakCount => streakCount !== undefined),
          switchMap(() => {
            return this.extendStreakAfterLoad();
          })
        );
      }
    } else {
      return of(false);
    }
  }

  getTimeRemaining(): string {
    var end_utc: Date;
    if (this.currentChallenge.challenge_period === ChallengePeriod.daily) {
      end_utc = new Date();
      end_utc.setUTCHours(24, 0, 0, 0);
    } else {
      end_utc = this.currentChallenge.end_utc;
    }
    const ms = end_utc.getTime() - Date.now();
    const s = Math.floor(ms / 1000);
    const m = Math.floor(s / 60);
    const h = Math.floor(m / 60);
    const days = Math.floor(h / 24);
    const hours = h % 24;
    const mins = m % 60;

    var remaining = '';
    if (days) {
      remaining += days.toString() + 'd ';
    }
    if (remaining || hours) {
      remaining += hours.toString() + 'h ';
    }
    if (remaining || mins) {
      remaining += mins.toString() + 'm';
    }

    return remaining;
  }
}

export class Challenges {
  item: ChallengeItem;
  success: boolean;
}

export class ChallengeStatus {
  item: InProgressChallenge;
  success: boolean;
}

export class ChallengeWrapper {
  item: Challenge;
  success: boolean;
}

export class ChallengeItem {
  in_progress: InProgressChallenge[];
  completed: Challenge[];
  available: AvailableChallenge[];
}
export class ChallengeMission {
  required_mission_count: number;
}
export class TrackChallengeConfig {
  mission: ChallengeMission;
}

export class ProgressMission {
  mission_count: number;
}
export class Progress {
  mission: ProgressMission;
}

export class ChallengeInfo {
  points: number;
  missions_required: number;
  missions_progress: number;
  end_utc: Date;
  updated_utc: Date;
  track_challenge_id: string;
  challengeStage: ChallengeStage;
  user_track_challenge_id?: string;
  challenge_period?: ChallengePeriod;
  challenge_type?: ChallengeType;
}

export class Challenge {
  user_track_challenge_key: string;
  user_track_challenge_id: string;
  user_id: string;
  user_track_id: string;
  track_challenge_id: string;
  start_utc: string;
  completed_utc?: Date;
  expires_utc: string;
  awarded_utc?: string;
  created_utc: string;
  updated_utc: Date;
  streak_count: number;
  previous_streak_count: number;
  sync_success_utc: string;
  track_challenge: TrackChallenge;
}

export class AvailableChallenge {
  track_challenge_id: string;
  track_id: string;
  challenge_name: string;
  description: string;
  challenge_period: ChallengePeriod;
  challenge_type: ChallengeType;
  enabled: boolean;
  start_utc: string;
  end_utc: string;
  award_points: number;
  track_challenge_config: TrackChallengeConfig;
}

export class InProgressChallenge extends Challenge {
  progress: Progress;
}

export class TrackChallenge extends AvailableChallenge {
  sync_success_utc: Date;
}

export enum ChallengeStage {
  available = 0,
  inProgress = 1,
  completed = 2,
  awarded = 3
}
