import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import { MissionService } from '@suzy/shared/tools/mission';
import { AppAlertService } from '../../../core/services/app-alert/app-alert.service';
import { SnareServices } from '../../../core/services/snare.service';
import { ActionComponent } from '../../../views/actions/shared/action-component';

@Component({
  selector: 'app-bazaarvoice-review',
  templateUrl: './bazaarvoice-review.component.html',
  styleUrls: ['./bazaarvoice-review.component.scss']
})
export class BazaarvoiceReviewComponent
  extends ActionComponent
  implements OnDestroy, OnInit {
  @ViewChild('f') responseForm: NgForm;
  isSubmitting: boolean;
  blackBoxString: string;
  recomendation: string;
  rating: string;
  review = '';
  reviewMaxLength = 1000;
  reviewMinLength = 50;
  errorMessageReview: string;

  constructor(
    private sdk: SuzySdkService,
    private snareService: SnareServices,
    private alerts: AppAlertService,
    private missionService: MissionService
  ) {
    super();
  }

  init(): void {
    // DO NOT REMOVE
    // This is needed for the ActionComponent
  }

  ngOnInit(): void {
    this.snareService.getBlackBoxString().subscribe(data => {
      this.blackBoxString = data;
    });

    // Reverse sort is for UI purpose
    if (this.action.bazaar_voice.recommend_options.length) {
      this.action.bazaar_voice.recommend_options = this.action.bazaar_voice.recommend_options.reverse();
    }
    if (this.action.bazaar_voice.rate_options.length) {
      this.action.bazaar_voice.rate_options = this.action.bazaar_voice.rate_options.reverse();
    }
  }

  ngOnDestroy(): void {
    this.subject.complete();
    this.subject = undefined;
  }

  isValidInput(input): boolean {
    this.errorMessageReview = '';

    if (this.review.length < this.reviewMinLength) {
      this.errorMessageReview = `Please enter at least ${this.reviewMinLength} characters`;

      return false;
    }

    if (!this.recomendation || !this.review || !this.rating) {
      this.alerts.notify({
        title: 'Error',
        message: 'All fields are required.'
      });

      return false;
    }

    return true;
  }

  onSubmit(): void {
    const input = {
      action_id: this.action.action_id,
      response_value: this.review,
      response_display: this.review,
      answer_id: this.action.bazaar_voice.answer_id,
      answer_ids: [this.recomendation, this.rating],
      fingerprint: this.blackBoxString
    };
    if (this.isValidInput(input)) {
      this.isSubmitting = true;
      this.sdk.ProtocolRespond.respond(
        this.mission.brand_id,
        this.mission.mission_id,
        input
      ).subscribe(
        data => {
          this.isSubmitting = false;
          if (data.success) {
            this.missionService.onMissionAnswered(
              this.mission.mission_id,
              data
            );
            this.subject.next(data);
          } else {
            let message = data.message;
            if (data.errors) {
              for (const error of Object.keys(data.errors)) {
                message += `\n - ${data.errors[error]}`;
              }
            }
            this.alerts.notify({ title: 'Error', message });
          }
        },
        error => {
          this.alerts.notify({ title: 'Error', message: error });
        },
        () => {
          this.isSubmitting = false;
        }
      );
    }
  }
}
