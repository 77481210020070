<div #wrapper>
  <app-general-header></app-general-header>

  <section aria-label="main" role="main" id="main-content">
    <article class="container">
      <div class="center-column">
        <h1 class="text-light-black dark:text-white">
          {{ 'unauthorized.sorry' | translate }}
        </h1>
        <p
          [innerHTML]="'unauthorized.youCannotAccessCrowdtap' | translate"
          class="text-light-black dark:text-white"
        ></p>
      </div>
    </article>
  </section>

  <app-general-footer> </app-general-footer>
</div>
