<div class="action-modal-container">
  <div class="modal-header">
    <button
      _ngcontent-c11=""
      aria-label="Close"
      class="close action-modal-close"
      type="button"
      (click)="closeModal()"
      [attr.data-track]="'member-' + type + '-end-close'"
    >
      <span _ngcontent-c11="" aria-hidden="true" class="sr-only">×</span>
    </button>
  </div>
  <div class="modal-body action-modal-body">
    <div class="success-animation">
      <div class="success-icon">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 130.2 130.2"
        >
          <circle class="circle" cx="65.1" cy="65.1" r="62.1" />
          <path
            class="check"
            d="M2,17 L15,30 C15,30 39.8559912,5 43,2"
            stroke="#000000"
            stroke-width="4"
          ></path>
        </svg>
      </div>
      <div class="success-message">
        <h1
          *ngIf="!message"
          [innerHTML]="'ending.youveFinishedYourSurvey' | translate"
        ></h1>
        <h1 *ngIf="message">{{ message }}</h1>
        <ng-container>
          <div class="laoding-spinner" *ngIf="showLoader">
            <span>{{ 'ending.youWillBeRedirected' | translate }} </span>
            <ending-loader
              (CompleteEvent)="timeCompleted($event)"
            ></ending-loader>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
