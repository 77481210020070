<div #wrapper>
  <app-general-header></app-general-header>

  <section aria-label="main" role="main" id="main-content">
    <article class="container">
      <div class="center-column">
        <h1
          class="text-light-black dark:text-white"
          [innerHTML]="'share.sharedViaCrowdtap' | translate"
        ></h1>
        <p
          class="text-light-black dark:text-white"
          [innerHTML]="'share.contentWasSharedViaCrowdtap' | translate"
        ></p>

        <a
          routerLink="/"
          class="submit-btn"
          target="_blank"
          [innerHTML]="'share.goToCrowdtap' | translate"
        >
        </a>
      </div>
    </article>
  </section>

  <app-general-footer> </app-general-footer>
</div>
