<aside
  class="fixed bottom-0 left-0 z-10 flex flex-col justify-between w-full sm:min-h-screen sm:max-h-screen 2xl:min-h-large lg:max-h-ipad bg-box-light dark:bg-box-dark sm:static sm:bottom-auto sm:left-auto sm:z-auto sm:w-20 md:w-auto sm:py-4 rounded-t-2xl sm:rounded-2xl overflow-hidden"
>
  <ul class="grid grid-cols-4 sm:flex sm:justify-around sm:flex-col md:w-52">
    <li
      class="flex justify-center sm:justify-start items-center {{
        isProgressShow
      }}"
      *ngFor="let link of links; let first = first"
      [ngClass]="{
        'sm:mt-4': !first,
        'sm:hidden': link.mobileOnly
      }"
    >
      <button
        [routerLink]="['/' + link?.url]"
        class="flex items-center w-full justify-center h-24 text-left profile-menu-trigger actions sm:h-16 md:h-auto sm:w-full md:w-auto sm:p-2 md:p-0"
        [ngClass]="
          isCurrentUrl(link.url)
            ? 'bg-active-link dark:bg-active-link-dark md:bg-transparent md:dark:bg-transparent'
            : 'bg:transparent'
        "
        (click)="handleClick($event, link)"
      >
        <span
          class="hidden w-2 rounded-br-lg h-14 rounded-tr-md md:block"
          [ngClass]="{
            'bg-active-link dark:bg-active-link-dark': isCurrentUrl(link.url)
          }"
        ></span>
        <span
          class="flex flex-col items-center content-center m-0 md:ml-2 md:flex-row"
        >
          <span
            class="flex items-center justify-center w-6 h-6 text-2xl md:h-8 md:w-8"
            *ngIf="link.imgSrc"
          >
            <img
              *ngIf="!link.iconClass && link.imgSrc"
              class="w-6 h-6 rounded-2xl"
              [src]="profilePhoto ? profilePhoto : link.imgSrc"
              [alt]="link.title"
            />
          </span>

          <svg
            *ngIf="link.title == 'Actions'"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.6 13.2008H1.2C0.537258 13.2008 5.96046e-08 13.7381 5.96046e-08 14.4008V22.8008C5.96046e-08 23.4636 0.537258 24.0008 1.2 24.0008H9.6C10.2627 24.0008 10.8 23.4636 10.8 22.8008V14.4008C10.8 13.7381 10.2627 13.2008 9.6 13.2008ZM8.4 21.6003H2.4V15.6002H8.4V21.6003ZM22.8 0.000537169H14.4C13.7373 0.000537169 13.2 0.537795 13.2 1.20054V9.60054C13.2 10.2633 13.7373 10.8005 14.4 10.8005H22.8C23.4627 10.8005 24 10.2633 24 9.60054V1.20054C24 0.537795 23.4627 0.000537169 22.8 0.000537169ZM21.6 8.40083H15.6V2.40083H21.6V8.40083ZM22.8 13.2008H14.4C13.7373 13.2008 13.2 13.7381 13.2 14.4008V22.8008C13.2 23.4636 13.7373 24.0008 14.4 24.0008H22.8C23.4627 24.0008 24 23.4636 24 22.8008V14.4008C24 13.7381 23.4627 13.2008 22.8 13.2008ZM21.6 21.6003H15.6V15.6002H21.6V21.6003ZM9.6 0.000488281H1.2C0.537258 0.000488281 0 0.537747 0 1.20049V9.60049C0 10.2632 0.537258 10.8005 1.2 10.8005H9.6C10.2627 10.8005 10.8 10.2632 10.8 9.60049V1.20049C10.8 0.537747 10.2627 0.000488281 9.6 0.000488281ZM8.4 8.40083H2.4V2.40083H8.4V8.40083Z"
              fill="white"
              class="fill-current dark:black"
            />
          </svg>

          <svg
            *ngIf="link.title == 'In Progress'"
            width="24"
            height="24"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M25.8002 13.8004C25.1374 13.8004 24.6002 14.3377 24.6002 15.0004C24.6296 19.6369 21.3608 23.6403 16.8121 24.5387C12.2634 25.4371 7.718 22.977 5.98255 18.6775C4.24711 14.378 5.81119 9.45186 9.70901 6.94094C13.6068 4.43002 18.7389 5.04259 21.9362 8.40041H19.0562C18.3934 8.40041 17.8562 8.93766 17.8562 9.60041C17.8562 10.2631 18.3934 10.8004 19.0562 10.8004H24.4922C25.1549 10.8004 25.6922 10.2631 25.6922 9.60041V4.20041C25.6922 3.53766 25.1549 3.00041 24.4922 3.00041C23.8294 3.00041 23.2922 3.53766 23.2922 4.20041V6.32441C19.1724 2.38617 12.8508 1.88902 8.16607 5.13484C3.4813 8.38065 1.72587 14.4739 3.9657 19.7146C6.20552 24.9554 11.8226 27.8977 17.4062 26.755C22.9898 25.6122 26.9993 20.6997 27.0002 15.0004C27.0002 14.6821 26.8737 14.3769 26.6487 14.1519C26.4237 13.9268 26.1184 13.8004 25.8002 13.8004Z"
              fill="white"
              class="fill-current dark:black"
            />
          </svg>

          <svg
            *ngIf="link.title === 'Rewards'"
            width="24"
            height="26"
            viewBox="0 0 24 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.5996 6.66668H19.1563C19.4454 6.07485 19.5969 5.42533 19.5996 4.76668C19.6075 2.96131 18.5167 1.33258 16.8443 0.652469C15.1719 -0.0276372 13.254 0.377563 11.9996 1.67601C10.7452 0.377563 8.82732 -0.0276372 7.15493 0.652469C5.48254 1.33258 4.39177 2.96131 4.39961 4.76668C4.40231 5.42533 4.55387 6.07485 4.84294 6.66668H4.39961C2.30093 6.66668 0.599609 8.368 0.599609 10.4667V13C0.599609 13.6996 1.16672 14.2667 1.86628 14.2667H3.13294V21.8667C3.13294 23.9654 4.83426 25.6667 6.93294 25.6667H17.0663C19.165 25.6667 20.8663 23.9654 20.8663 21.8667V14.2667H22.1329C22.8325 14.2667 23.3996 13.6996 23.3996 13V10.4667C23.3996 8.368 21.6983 6.66668 19.5996 6.66668ZM10.733 23.1333H6.93295C6.23339 23.1333 5.66628 22.5662 5.66628 21.8667V14.2667H10.733V23.1333ZM10.7329 11.7333H3.13294V10.4667C3.13294 9.76711 3.70005 9.2 4.39961 9.2H10.7329V11.7333ZM10.7329 6.66666H8.83294C7.7836 6.66666 6.93294 5.816 6.93294 4.76666C6.93294 3.71732 7.7836 2.86666 8.83294 2.86666C9.88228 2.86666 10.7329 3.71732 10.7329 4.76666V6.66666ZM13.2663 4.76666C13.2663 3.71732 14.1169 2.86666 15.1663 2.86666C16.2156 2.86666 17.0663 3.71732 17.0663 4.76666C17.0663 5.816 16.2156 6.66666 15.1663 6.66666H13.2663V4.76666ZM18.3329 21.8667C18.3329 22.5662 17.7658 23.1333 17.0663 23.1333H13.2663V14.2667H18.3329V21.8667ZM20.8663 11.7333H13.2663V9.2H19.5996C20.2992 9.2 20.8663 9.76711 20.8663 10.4667V11.7333Z"
              fill="white"
              class="fill-current dark:black"
            />
          </svg>

          <span
            class="w-auto m-0 mt-1 text-xs text-center capitalize md:ml-2 md:mt-0 md:w-36 md:text-sm md:text-left"
            >{{ link.copy | translate }}</span
          >
        </span>
      </button>
    </li>
  </ul>

  <div *ngIf="showRAF" class="justify-center hidden w-full pb-6 sm:flex">
    <button
      class="h-8 px-2 font-semibold rounded-full bg-blue-dark"
      (click)="handleReferClick($event)"
    >
      <span class="h-4 text-xs text-white md:h-5">
        <span class="md:hidden">{{ 'nav.referrals' | translate }}</span>
        <div class="items-center hidden font-semibold md:flex">
          <img
            class="mr-1"
            src="/assets/img/svg/icons/ic-type-sidebar-coin.svg"
            alt="Coins"
          />
          <span>{{ 'nav.refer' | translate }}</span>
        </div>
      </span>
    </button>
  </div>
</aside>
