import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GoalService } from 'apps/crowdtap/src/app/core/services/goal.service';
@Injectable()
export class CountUpdateService {
  countSource$ = new BehaviorSubject({});
  constructor(private goalService: GoalService) {}

  getCountSource() {
    return this.countSource$.asObservable();
  }

  updateCount(currentData: any) {
    this.countSource$.next(currentData);
  }
}
