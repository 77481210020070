import { take, map } from 'rxjs/operators';
import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserProfileService } from '@suzy/crowdtap/data-access/user';
import { AuthenticationService } from '@suzy/crowdtap/data-access/user';
import { VerificationService } from '@suzy/crowdtap/data-access/user';
import { VerificationUserStatus } from '@suzy/crowdtap/data-access/user';

@Injectable()
export class UserStatusGuard implements CanActivate {
  isOnboardUser: boolean;
  userVerification: number;
  verificationUserStatus = new VerificationUserStatus();

  constructor(
    private auth: AuthenticationService,
    private user: UserProfileService,
    private verificationService: VerificationService
  ) {}

  canActivate(): Observable<boolean> {
    this.isOnboardUser = this.verificationService.isOnboardUser(this.user);

    return this.auth.ensureUser().pipe(
      map(res => {
        const user = res;
        this.isOnboardUser = this.verificationService.isOnboardUser(user);
        this.userVerification = this.verificationService.getUserVerification(
          this.isOnboardUser,
          user
        );

        if (this.userVerification === this.verificationUserStatus.verified) {
          return true;
        } else {
          return false;
        }
      }),
      take(1)
    );
  }
}
