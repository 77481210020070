import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, chars = 40, trailer = '…'): string {
    if (!value) {
      value = '';
    }

    if (chars < 0) {
      // if negative, truncate the beginning of the string
      chars *= -1;

      return value.length > chars
        ? trailer + value.substring(value.length - chars, value.length)
        : value;
    } else {
      // if positive, truncate the end of the string
      return value.length > chars ? value.substring(0, chars) + trailer : value;
    }
  }
}
