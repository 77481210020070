<div
  *ngIf="snackbarIcon; else old"
  class="fixed bottom-64 shadow-toaster w-11/12 sm:w-2/4 p-2 flex items-center text-body-light dark:text-body-dark dark:bg-body-light bg-body-dark rounded-lg"
>
  <span class="pr-1.5 text-xl {{ snackbarIcon }}"></span>
  <p class="text-xs font-semibold">{{ snackbarContent }}</p>
</div>

<ng-template #old>
  <div class="snackbar-container">
    <i class="fas fa-redo"></i>
    <p>{{ snackbarContent | translate }}</p>
  </div>
</ng-template>
