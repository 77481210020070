<!--Login Page Starts-->
<section id="login">
  <div class="container-fluid">
    <div class="row full-height-vh">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="card gradient-indigo-purple text-center width-400">
          <div class="card-img">
            <img
              alt="Yellow suzy logo"
              class="mb-1"
              src="assets/img/suzy-yellow.svg"
              width="190"
            />
          </div>
          <div class="card-body">
            <div class="card-block">
              <form novalidate="" (ngSubmit)="onSubmit()" #f="ngForm">
                <div class="form-group">
                  <div class="col-md-12">
                    <input
                      type="email"
                      class="form-control"
                      name="inputEmail"
                      id="inputEmail"
                      [placeholder]="'auth.email' | translate"
                      ngModel
                      required
                      email
                    />
                  </div>
                </div>

                <div class="form-group">
                  <div class="col-md-12">
                    <input
                      type="password"
                      class="form-control"
                      name="inputPass"
                      id="inputPass"
                      [placeholder]="'auth.password' | translate"
                      ngModel
                      required
                    />
                  </div>
                </div>

                <!-- Doesn't make sense if we have http session state only -->
                <!-- <div class="form-group">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="custom-control custom-checkbox mb-2 mr-sm-2 mb-sm-0 ml-3">
                                <input type="checkbox" class="custom-control-input" checked id="rememberme">
                                <label class="custom-control-label float-left white" for="rememberme">Remember Me</label>
                            </div>
                        </div>
                    </div>
                </div> -->

                <div class="form-group form-cta">
                  <div class="col-md-12">
                    <button
                      type="submit"
                      class="btn btn-pink btn-block btn-raised"
                      [disabled]="!f.valid"
                    >
                      {{ 'general.submit' | translate }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="card-footer">
            <div class="float-left">
              <a (click)="onForgotPassword()" class="white">{{
                'auth.recoverPassword' | translate
              }}</a>
            </div>
            <div class="float-right">
              <a (click)="onRegister()" class="white">{{
                'auth.newUser' | translate
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title">Modal title</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="d('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>One fine body&hellip;</p>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary btn-raised"
        (click)="c('Close click')"
      >
        {{ 'general.close' | translate }}
      </button>
    </div>
  </ng-template>
</section>
<!--Login Page Ends-->
