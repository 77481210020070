<!-- Action Modal Starts -->
<div class="action-modal-container">
  <div class="head-container">
    <app-action-modal-header
      [mission]="mission"
      [metaData]="metaData"
      [isSpecialMission]="isSpecialMission"
      [disableButton]="mission?.isGlobal"
    ></app-action-modal-header>
    <!-- Action Modal Question Starts -->
    <div
      *ngIf="!isSpecialMission"
      class=" p-6 pt-0 border-b-2 dark:bg-body-dark bg-body-light action-modal-question border-card-{{
        activeColor
      }} dark:text-white md:pt-0 md:px-24 md:py-8 sm:px-20"
    >
      <h3
        class="mb-2 text-base font-medium"
        [innerHTML]="question | markdown2html : { secure: true }"
        data-track="member-open-ended-question-text"
      ></h3>
      <suzy-preroll-secondary-trigger
        *ngIf="hasPreroll && prerollViewed"
        [prerollKind]="preRollKind"
        [prerollUrl]="preRollUrl"
        (imagePrerollClick)="onViewPrerollImage($event)"
        (linkPrerollClick)="onViewPrerollLink()"
      >
      </suzy-preroll-secondary-trigger>
    </div>
    <!-- Action Modal Question Ends -->
  </div>
  <!-- Action Modal Body Starts -->
  <div
    [ngClass]="{ 'p-6 sm:px-20 md:px-24 md:py-8': !isSpecialMission }"
    class="modal-body action-modal-body dark:text-white"
  >
    <suzy-preroll
      (prerollViewedEvent)="onPrerollViewedEvent($event)"
      *ngIf="
        hasPreroll &&
        !prerollViewed &&
        showPreroll &&
        !isSpecialMission &&
        !(!action.preroll_inline_enabled && action.hidden_preroll_enabled)
      "
      [preRollKind]="preRollKind"
      [preRollCaption]="preRollCaption"
      [preRollUrl]="preRollUrl"
      [mission]="mission"
      [action]="action"
    ></suzy-preroll>

    <div
      *ngIf="isSpecialMission; else notSpecialMission"
      class="special-section relative"
    >
      <ng-lottie
        [options]="optionss"
        *ngIf="isFirstAnimationDone"
        class="flex justify-center absolute w-full h-full"
      ></ng-lottie>
      <ng-lottie
        [options]="options"
        (enterFrame)="enterFrame($event)"
        (animationCreated)="animationCreated($event)"
        class="flex justify-center m-auto lg:w-3/5 lg:relative lg:-top-2"
      >
      </ng-lottie>

      <div
        class="absolute bottom-12 lg:bottom-8 left-0 right-0 text-center dark:text-body-light"
        *ngIf="isFirstAnimationDone"
      >
        <div class="birthday-section">
          <div class="font-bold text-3xl mb-2">
            {{ question }}, {{ currentUser?.first_name }}!
          </div>
          <div class="font-medium text-sm my-4">
            {{ 'openEnded.heres' | translate }}
            <span class="font-bold text-card-12"
              >+{{ mission.points }} {{ 'openEnded.points' | translate }}</span
            >
            {{ 'openEnded.fromCrowdtap' | translate }}
          </div>
        </div>
        <suzy-action-forms-open-ended
          (responseKeyup)="updateTimeToAction()"
          (submitClick)="validateInput($event)"
          (formMouseMove)="onMouseMoveEvent($event)"
          (formSkip)="skip($event)"
          (formSubmit)="onSubmit($event)"
          [action]="action"
          [isSuccess]="isSuccess"
          [isSpecialMission]="isSpecialMission"
          [isLoading]="isLoading"
          [isSubmitting]="isSubmitting"
          [isErrorMessage]="isErrorMessage"
          [isValid]="isValid"
          [disableSkip]="isTrapQuestion || disableSkip"
          *ngIf="!hasPreroll || (hasPreroll && prerollViewed)"
          [disableButton]="mission?.isGlobal"
          [isSkipping]="isSkipping"
        ></suzy-action-forms-open-ended>
      </div>
    </div>
    <ng-template #notSpecialMission>
      <suzy-action-forms-open-ended
        (responseKeyup)="updateTimeToAction()"
        (submitClick)="validateInput($event)"
        (formMouseMove)="onMouseMoveEvent($event)"
        (formSkip)="skip($event)"
        (formSubmit)="onSubmit($event)"
        [action]="action"
        [isSuccess]="isSuccess"
        [isSpecialMission]="isSpecialMission"
        [isLoading]="isLoading"
        [isSubmitting]="isSubmitting"
        [isErrorMessage]="isErrorMessage"
        [isValid]="isValid"
        [disableSkip]="
          isTrapQuestion ||
          disableSkip ||
          mission?.mission_kind === missionKind.external_link
        "
        *ngIf="
          !hasPreroll ||
          (hasPreroll && prerollViewed) ||
          (!action.preroll_inline_enabled && action.hidden_preroll_enabled)
        "
        [disableButton]="mission?.isGlobal"
        [isSkipping]="isSkipping"
      ></suzy-action-forms-open-ended>
    </ng-template>
  </div>
  <!-- Action Modal Body Ends -->
</div>
<!-- Action Modal Ends -->
