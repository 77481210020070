<div class="flex flex-col h-full action-modal-container">
  <div
    class="flex justify-between p-6 sm:px-20 md:px-24 md:py-8 dark:text-white bg-body-light dark:bg-body-dark modal-heading"
  >
    <div class="flex">
      <h1
        class="text-xs font-semibold"
        [attr.data-track]="'member-prerequiste-beginning-title'"
      >
        Survey
      </h1>
    </div>
  </div>
  <div
    class="items-center justify-center flex-1 p-6 text-center border-t-2 sm:px-20 md:px-24 md:py-8 dark:text-white bg-box-light dark:bg-box-dark"
  >
    <div class="modal-body action-modal-body">
      <div class="modal-content">
        <h2
          class="text-sm font-normal md:text-lg lg:font-bold lg:text-xl mt-28"
        >
          This survey contains piped text from a previously launched question.
          You must select responses in order to properly preview this survey.
          This screen will not be visible to your audience.
        </h2>
        <p class="mt-12 text-sm font-normal">
          Please answer the following question ...
        </p>
      </div>

      <div class="relative flex justify-center space-x-2 md:space-x-16 actions">
        <button
          type="button"
          class="relative h-12 px-8 mt-8 ml-8 text-sm font-bold text-white rounded-full md:text-base w-52 bg-blue-dark disabled:bg-active-link disabled:text-active-link-dark"
          (click)="getStarted()"
        >
          {{ 'general.getStarted' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
