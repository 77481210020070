import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '@suzy/crowdtap/tools/navigation';
import { GlobalIsolateSdkService } from '@suzy/shared/data-access/global-isolate-sdk';
import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import {
  CampaignKeysName,
  PanelKeysName,
  PromoKeysName,
  ReferralKeysName,
  SegmentService,
  ZendeskService
} from '@suzy/shared/data-access/tracking';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { SocialUser } from 'angularx-social-login';
import {
  ProfileState,
  UserStatus,
  VerificationStatus
} from '@asksuzy/typescript-sdk';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs/';
import { map } from 'rxjs/operators';
import { UserProfileService } from './user-profile.service';
import { LaunchDarklyService } from '@suzy/shared/data-access/feature-flag';

@Injectable()
export class AuthenticationService {
  userUpdated: Observable<any>;
  trackUser = true;

  private userUpdatedSubject = new BehaviorSubject({});
  private _currentUser: any; // do not set directly, only via method
  private storage: Storage;
  private keys: any = null;
  private campaignKeysName: CampaignKeysName;
  private panelKeysName: PanelKeysName;
  private referralKeysName: ReferralKeysName;
  private promoKeysName: PromoKeysName;

  constructor(
    private router: Router,
    private suzySDK: SuzySdkService,
    private globalSDK: GlobalIsolateSdkService,
    private navigation: NavigationService,
    private userProfile: UserProfileService,
    private gtm: Angulartics2GoogleTagManager,
    private segmentService: SegmentService,
    private launchDarklyService: LaunchDarklyService,
    private zendeskService: ZendeskService
  ) {
    this.userUpdated = this.userUpdatedSubject.asObservable();
    this.storage = localStorage;
    this.campaignKeysName = new CampaignKeysName();
    this.panelKeysName = new PanelKeysName();
    this.referralKeysName = new ReferralKeysName();
    this.promoKeysName = new PromoKeysName();
  }

  setTrackUser(trackUser: boolean): void {
    this.trackUser = trackUser;
  }

  getCurrentUserId(): string {
    return this._currentUser.user_id;
  }

  login(user): void {
    const keys = {
      api_key: user.api_key,
      api_secret: user.api_secret,
      entitlements: user.entitlements
    };
    this.storage.setItem('apiKeys', JSON.stringify(keys));
    this.suzySDK.setApiCredentials(keys.api_key, keys.api_secret);
    this.globalSDK.setApiCredentials(keys.api_key, keys.api_secret);
    this.keys = keys;
    this.segmentService.identify(user.user_id);
    this.segmentService.track('Signed In', {
      email: user.email
    });
    this.launchDarklyService.identify(user.user_id, {
      created_utc: user.created_utc
    });
    this.setUser(user);
    this.zendeskService.logIn();
  }

  logout(
    redirect = true,
    closeMobile?: boolean,
    cintRedirect?: string,
    isExternal?: boolean
  ): any {
    this.keys = null;
    this.removeLocalKey('apiKeys');
    this.removeLocalKey('visitor');
    this.removeLocalKey('isMobile');
    this.suzySDK.clearApiCredentials();
    this.globalSDK.clearApiCredentials();
    this.purgeCampaignKeys();
    if (this.trackUser) {
      this.segmentService.track('Signed Out');
    }
    if (!isExternal) {
      this.segmentService.reset();
    }
    this.setUser(null);
    this.zendeskService.logOut();

    if (cintRedirect) {
      window.open(cintRedirect, '_self');
    }

    if (closeMobile) {
      return (window.location.href = '/close-browser/mobile');
    }

    if (redirect) {
      this.router.navigate(['/auth/login']);
    }
  }

  setVisitor(visitor): void {
    this.storage.setItem('visitor', JSON.stringify(visitor));
  }

  getVisitor(): any {
    const visitor = this.storage.getItem('visitor');
    if (visitor) {
      return JSON.parse(visitor);
    } else {
      return undefined;
    }
  }

  setUser(user: any): void {
    if (user) {
      this.gtm.setUsername(user.user_id);
      const userInformation = { ...user };
      delete userInformation.api_key;
      delete userInformation.api_secret;
      this.gtm.eventTrack('js-user-set-success', {
        event: 'js-user-set-success',
        gtmCustom: {
          userInformation
        }
      });
    }

    this._currentUser = user;
    this.userUpdatedSubject.next(user);
    this.userProfile.setCurrentUser(user);
    this.navigation.onUserChanged(this._currentUser);
  }

  calculateAge(birthday: string): number {
    const today = new Date();
    const bday = new Date(birthday);
    let age = today.getFullYear() - bday.getFullYear();
    const month = today.getMonth() - bday.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < bday.getDate())) {
      age--;
    }

    return age;
  }

  setUserProfile(profile: any): void {
    if (this._currentUser) {
      this._currentUser.profile = profile;
    }
  }

  isAuthenticated(): boolean {
    if (this.instantUser()) {
      this.segmentService.identify(this.getCurrentUserId());
      this.launchDarklyService.identify(this.getCurrentUserId(), {
        created_utc: this.instantUser().created_utc
      });
    } else {
      this.reloadUser();
      this.segmentService.identify(this.storage.apc_user_id);
      this.launchDarklyService.identify(this.storage.apc_user_id);
    }

    const hasKeys = this.ensureKeys(true) !== null;
    if (hasKeys) {
      this.zendeskService.logIn();
    }

    return hasKeys;
  }

  isAdmin(): boolean {
    const keys = this.ensureKeys(true);
    if (keys) {
      return keys.entitlements && keys.entitlements.includes('admin');
    }

    return false;
  }

  authenticateFacebookUser(
    facebookUser: SocialUser,
    recaptchaToken = ''
  ): Observable<any> {
    return this.suzySDK.ProtocolAuthorize.loginFacebook({
      access_token: facebookUser.authToken,
      persist: true,
      recaptcha_token: recaptchaToken
    }).pipe(
      map((response: any) => {
        if (response.success) {
          this.login(response.item);
        }

        return response;
      })
    );
  }

  ensureUser(getUser?: boolean): Observable<any> {
    return new Observable(observer => {
      if (!this.keys) {
        observer.error(false);
        observer.complete();
      } else if (!this._currentUser || getUser) {
        this.suzySDK.ProtocolAuthorize.getSelf(true).subscribe(
          data => {
            if (
              data.item.status !== undefined &&
              data.item.status !== UserStatus.active
            ) {
              this.logout();
              this.router.navigate(['/']);
            }
            this.setUser(data.item);
            observer.next(data.item);
            observer.complete();
          },
          error => {
            if (error.status === 401) {
              observer.complete();
              this.logout();
              this.router.navigate(['/']);
            } else {
              observer.error(error);
            }
          }
        );
      } else {
        observer.next(this._currentUser);
        observer.complete();
      }
    });
  }

  instantUser(): any {
    return this._currentUser;
  }

  reloadUser(): void {
    this.ensureUser(true).subscribe();
  }

  getMobileRouting(redirecTo?: string): string {
    if (redirecTo === 'taps') {
      return '/rewards/taps/mobile';
    }

    if (!this._currentUser.mobile_phone) {
      return '/auth/sms-authentication/mobile';
    }

    if (
      this._currentUser.verification_status ===
        VerificationStatus.not_verified ||
      this._currentUser.verification_status ===
        VerificationStatus.retry_allowed ||
      (this._currentUser.verification_status === VerificationStatus.denied &&
        this._currentUser.verification_attempts === 1)
    ) {
      return '/rewards/account-verify/mobile';
    }

    if (
      (this._currentUser.profile.profile_state === ProfileState.incomplete &&
        !this.isProfileLocked()) ||
      redirecTo === 'profile'
    ) {
      return '/profile/update/mobile';
    }

    this.logout(false, true);

    return '/close-browser/mobile';
  }

  getVerificationStatus(status: number): string {
    switch (status) {
      case VerificationStatus.not_verified:
        return 'Not Verified';
      case VerificationStatus.requested:
        return 'Requested';
      case VerificationStatus.error:
        return 'Error';
      case VerificationStatus.approving:
        return 'Approving';
      case VerificationStatus.approved:
        return 'Approved';
      case VerificationStatus.denied:
        return 'Denied';
      case VerificationStatus.duplicate:
        return 'Diplicate';
      case VerificationStatus.retry_allowed:
        return 'Retry Allowed';
      default:
        return '';
    }
  }

  isProfileLocked(): boolean {
    if (this._currentUser.profile) {
      const lockedUntil = moment(this._currentUser.profile.locked_until_utc);

      return lockedUntil.isAfter(moment());
    } else {
      return false;
    }
  }

  isCampaignKey(keyName: string): boolean {
    for (const key in this.campaignKeysName) {
      if (key && this.campaignKeysName[key] === keyName) {
        return true;
      }
    }

    return false;
  }

  setLocalKey(keyName: string, keyValue: any): void {
    if (this.isCampaignKey(keyName) && typeof keyValue === 'object') {
      keyValue = keyValue[0];
    }

    this.storage.setItem(keyName, JSON.stringify(keyValue));
  }

  getLocalKey(key: string): any {
    let keyValue = this.storage.getItem(key);
    if (!keyValue) {
      return undefined;
    }

    keyValue = JSON.parse(keyValue);
    if (this.isCampaignKey(key) && typeof keyValue !== 'string') {
      keyValue = keyValue[0];
      this.setLocalKey(key, keyValue);
    }

    return keyValue;
  }

  removeLocalKey(key: string): any {
    this.storage.removeItem(key);
  }

  getCurrentCampaignKeys(): any {
    let keys;
    keys = {
      source: this.getLocalKey(this.campaignKeysName.source),
      utmSource: this.getLocalKey(this.campaignKeysName.utmSource),
      utmMedium: this.getLocalKey(this.campaignKeysName.utmMedium),
      utmName: this.getLocalKey(this.campaignKeysName.utmName),
      utmTerm: this.getLocalKey(this.campaignKeysName.utmTerm),
      utmContent: this.getLocalKey(this.campaignKeysName.utmContent),
      miscOne: this.getLocalKey(this.campaignKeysName.miscOne),
      miscTwo: this.getLocalKey(this.campaignKeysName.miscTwo)
    };

    return keys;
  }

  purgeCampaignKeys(): void {
    for (const key in this.campaignKeysName) {
      if (key) {
        this.storage.removeItem(this.campaignKeysName[key]);
      }
    }
  }

  getCurrentPanelKeys(): any {
    let keys;
    keys = {
      panelToken: this.getLocalKey(this.panelKeysName.panelToken)
    };

    return keys;
  }

  purgePanelKeys(): void {
    for (const key in this.panelKeysName) {
      if (key) {
        this.storage.removeItem(this.panelKeysName[key]);
      }
    }
  }

  getCurrentReferralKeys(): any {
    let keys;
    keys = {
      referralToken: this.getLocalKey(this.referralKeysName.referralToken)
    };

    return keys;
  }

  purgeReferralKeys(): void {
    for (const key in this.referralKeysName) {
      if (key) {
        this.storage.removeItem(this.referralKeysName[key]);
      }
    }
  }

  getCurrentPromoKeys(): any {
    let keys;
    keys = {
      promoToken: this.getLocalKey(this.promoKeysName.promoToken)
    };

    return keys;
  }

  purgePromoKeys(): void {
    for (const key in this.promoKeysName) {
      if (key) {
        this.storage.removeItem(this.promoKeysName[key]);
      }
    }
  }

  private ensureKeys(ensureUser: boolean): any {
    if (!this.keys) {
      const savedKeys = this.storage.getItem('apiKeys');

      if (savedKeys) {
        const data = JSON.parse(savedKeys);

        this.suzySDK.setApiCredentials(data.api_key, data.api_secret);
        this.globalSDK.setApiCredentials(data.api_key, data.api_secret);
        this.keys = data;
        if (ensureUser) {
          this.ensureUser().subscribe();
        }
      }
    }

    return this.keys;
  }
}
