import {
  Injectable,
  Injector,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  ApplicationRef
} from '@angular/core';
import { SnackbarComponent } from '../../shared/components/snackbar/snackbar.component';

@Injectable()
export class SnackbarService {
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  appendComponentToBody(content: string, timing: number, icon?: string): void {
    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(SnackbarComponent)
      .create(this.injector);

    componentRef.instance.snackbarContent = content;
    componentRef.instance.snackbarIcon = icon;
    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    document.body.appendChild(domElem);
    setTimeout(() => {
      this.appRef.detachView(componentRef.hostView);
      componentRef.destroy();
    }, timing);
  }
}
