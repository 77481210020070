import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Gender } from '@suzy/shared/data-access/suzy-sdk';
import {
  ISignupUser,
  SignupService
} from '../../../../views/anonymous/signup/signup.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { LaunchDarklyService } from '@suzy/shared/data-access/feature-flag';

@Component({
  selector: 'about-your-self',
  templateUrl: './about-your-self.component.html',
  styleUrls: ['./about-your-self.component.scss']
})
export class AboutYourSelfComponent implements OnInit, AfterViewInit {
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef;
  aboutYourSelfForm: UntypedFormGroup;
  model: NgbDateStruct;
  submitted = false;
  Gender = Gender;
  stateOptions = [];
  user: ISignupUser;
  minBirthdate: NgbDateStruct = { year: 1910, month: 1, day: 1 };
  isLoading: boolean;
  el_wrapper: HTMLElement;
  ethnicities: Array<any>;

  private storage: Storage;

  constructor(
    private fb: UntypedFormBuilder,
    private signupService: SignupService,
    private router: Router,
    private route: ActivatedRoute,
    private launchDarklyService: LaunchDarklyService
  ) {}

  ngOnInit(): void {
    this.storage = localStorage;
    this.user = this.signupService.getUser();

    this.stateOptions = this.route.snapshot.data.states;
    this.createForm();

    this.route.data.subscribe(data => {
      this.ethnicities = data.ethnicities;
    });
  }

  ngAfterViewInit(): void {
    this.el_wrapper = this.wrapper.nativeElement as HTMLElement;
  }

  createForm(): void {
    this.aboutYourSelfForm = this.fb.group({
      birthdate: [undefined, Validators.required],
      gender: [Gender.female, Validators.required],
      ethnicity_id: ['', Validators.required]
    });
  }

  returnToGetToKnowYou(): void {
    this.router.navigateByUrl('/auth/get-to-know-you');
  }

  fieldErrors(name: string): any {
    const control = this.aboutYourSelfForm.get(name);

    if (control && (control.touched || this.submitted) && control.errors) {
      return control.errors;
    } else {
      return undefined;
    }
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.aboutYourSelfForm.valid) {
      const account = this.aboutYourSelfForm.value;
      this.isLoading = true;

      this.signupService.setUser({
        ...this.user,
        birthdate: account.birthdate,
        gender: account.gender,
        ethnicity_id: account.ethnicity_id,
        progressStatus: 65
      });

      this.router.navigate(['auth', 'almost-there']);
    }
  }
}
