<div class="action-modal-container">
  <div class="head-container">
    <app-action-modal-header
      [mission]="mission"
      [metaData]="metaData"
      [disableButton]="mission?.isGlobal"
    ></app-action-modal-header>

    <!-- Action Modal Question Starts -->
    <div
      class="text-sm font-bold action-modal-question dark:bg-body-dark bg-body-light border-b-2 border-card-{{
        activeColor
      }} dark:text-white md:pt-0 md:px-24 md:py-8 p-6 pt-0 sm:px-20"
    >
      <h3
        class="text-base font-medium"
        [innerHTML]="action.photo.question | markdown2html: { secure: true }"
        data-track="member-photo-question-text"
      ></h3>
      <suzy-preroll-secondary-trigger
        *ngIf="hasPreroll && prerollViewed"
        [prerollKind]="preRollKind"
        [prerollUrl]="preRollUrl"
      ></suzy-preroll-secondary-trigger>
    </div>
    <!-- Action Modal Question Ends -->
  </div>
  <!-- Action Modal Body Starts -->
  <div class="modal-body action-modal-body" data-simplebar>
    <!-- NOTE: data attri is need for SimpleBar Module -->

    <suzy-preroll
      (prerollViewedEvent)="onPrerollViewedEvent($event)"
      *ngIf="hasPreroll && !prerollViewed"
      [preRollKind]="preRollKind"
      [preRollCaption]="preRollCaption"
      [preRollUrl]="preRollUrl"
      [mission]="mission"
      [action]="action"
    ></suzy-preroll>

    <suzy-action-forms-photo-acquisition
      [action]="action"
      [disableSkip]="disableSkip"
      [isSuccess]="isSuccess"
      [isLoading]="isLoading"
      [isSubmitting]="isSubmitting"
      (timeToActionUpdate)="onTimeToActionUpdate($event)"
      [isErrorMessage]="isErrorMessage"
      (skip)="onSkip($event)"
      (mouseEvent)="onMouseMoveEvent($event)"
      (paFormSubmit)="onSubmit($event)"
      *ngIf="!hasPreroll || (hasPreroll && prerollViewed)"
      [disableButton]="mission?.isGlobal"
      [isSkipping]="isSkipping"
    ></suzy-action-forms-photo-acquisition>
  </div>
</div>
