import { Component } from '@angular/core';

@Component({
  selector: 'app-preview-info-view',
  templateUrl: './preview-info-view.component.html',
  styleUrls: ['./preview-info-view.component.scss']
})
export class PreviewInfoViewComponent {
  errorBarMessage: string;

  constructor() {}
}
