<div class="sm:self-auto" *ngIf="currentUser">
  <section class="relative text-left sm:text-center">
    <div *ngIf="!isSignup" class="modal-header">
      <button
        type="button"
        (click)="closeRewardModal()"
        class="absolute right-3 -top-2 sm:-top-5 sm:-right-3 z-20 cursor-pointer"
      >
        <span
          class="crowdtap-multiply text-body-dark dark:text-body-light text-lg font-bold"
        ></span>
      </button>
    </div>
    <h1
      class="text-box-dark mt-6 sm:mt-0 dark:text-box-light text-xl font-bold"
    >
      <ng-container *ngIf="!isSignup; else signupHeader">
        {{ 'rewards.chooseYourRewardGoal' | translate }}
      </ng-container>
      <ng-template #signupHeader>
        {{ 'rewards.whatsTheFirstReward' | translate }}
      </ng-template>
    </h1>
    <div
      class="mt-1 border-b border-active-link-dark sm:border-none pb-4 mb-5 sm:mb-0 text-sm text-box-dark dark:text-box-light font-medium"
    >
      {{ 'rewards.stayMotivated' | translate }}
    </div>

    <div *ngIf="!isSignup">
      <div class="relative w-full">
        <span class="absolute cursor-pointer left-0 top-3">
          <i
            class="crowdtap-search mt-8 ml-4 text-box-dark dark:text-box-light"
          ></i>
        </span>
        <input
          autocomplete="off"
          placeholder="{{ 'rewards.searchGiftCards' | translate }}"
          (keyup)="searchFilter(search)"
          [(ngModel)]="search"
          class="w-full search-input pl-10 text-body-dark dark:text-body-light bg-body-light placeholder-gray placeholder-font-semibold dark:bg-black-light"
        />
        <span
          *ngIf="rewardKeyword"
          (click)="search = ''; searchFilter(search)"
          class="absolute cursor-pointer right-4 top-4"
        >
          <i
            class="crowdtap-multiply mt-8 ml-4 text-box-dark dark:text-box-light"
          ></i>
        </span>
      </div>
    </div>
    <div *ngIf="isLoading">
      <div
        class="text-lg min-h-xxl pt-6 flex justify-center items-center text-box-dark dark:text-box-light font-bold"
      >
        {{ 'rewards.loading' | translate }}
      </div>
    </div>
    <div
      class="text-left min-h-xxl sm:pl-4 pt-6"
      *ngIf="!rewards.length && !isLoading"
    >
      <div>
        <div class="mb-2 text-box-dark dark:text-box-light text-sm font-bold">
          {{ rewards.length }} {{ 'rewards.results' | translate }}
          <span class="opacity-50"> “{{ search }}” </span>
        </div>
        <i
          class="crowdtap-search mt-8 ml-4 text-box-dark text-5xl dark:text-box-light"
        ></i>

        <div class="mt-3 text-box-dark dark:text-box-light text-lg font-bold">
          {{ 'rewards.noResultFound' | translate }}
        </div>
      </div>
    </div>
    <div *ngIf="rewards.length > 0 && (!isSignup || !isLoading)">
      <article
        class="sm:dark:bg-black-light sm:bg-box-light min-h-xl mt-6 rounded-lg p-1 sm:p-3 sm:max-h-xxl overflow-auto"
        [ngClass]="{ 'max-h-screen': !isSignup, 'max-h-365': isSignup }"
      >
        <app-rewards-list
          *ngIf="userVerificationStatus"
          [trackRewards]="rewards"
          [currentUser]="currentUser"
          [actionClick]="isNext"
          [isSignup]="isSignup"
          (selectCard)="selectCard($event)"
        >
        </app-rewards-list>
      </article>

      <div
        class="font-semibold align-middle text-error text-xs pt-6 text-left"
        *ngIf="cardNotSelected"
      >
        <i
          class="crowdtap-exclamation-circle text-xl align-middle leading-1.2 mr-1 font-medium"
        ></i>
        {{ 'rewards.pleaseSelectAReward' | translate }}
      </div>
      <div class="flex justify-center space-x-5 items-baseline mt-5">
        <div
          class="text-blue-dark font-bold text-sm cursor-pointer"
          (click)="closeRewardModal()"
        >
          {{ 'dashboard.maybeLater' | translate }}
        </div>
        <button
          *ngIf="!isSignup"
          type="button"
          (click)="next()"
          class="bg-blue-dark text-white px-6 h-10 text-sm font-bold rounded-full"
        >
          {{ 'rewards.next' | translate }}
        </button>
        <button
          *ngIf="isSignup"
          type="button"
          (click)="saveGoal()"
          class="bg-blue-dark text-white px-6 h-10 text-sm font-bold rounded-full"
        >
          {{ 'rewards.saveGoal' | translate }}
        </button>
      </div>
    </div>
  </section>
</div>
