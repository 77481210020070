export class VerificationUserStatus {
  verified = 1;
  oneAttempt = 2;
  approved = 3;
  readyToVerify = 4;
  denied = 5;
  notVerifiedOnboard = 6;
  notVerifiedExisting = 7;
  pending = 8;
}
