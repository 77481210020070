import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  OnInit,
  Input
} from '@angular/core';
import { AuthenticationService } from '@suzy/crowdtap/data-access/user';

@Component({
  selector: 'app-sms-success-animation',
  templateUrl: './sms-success-animation.component.html',
  styleUrls: ['./sms-success-animation.component.scss']
})
export class SMSSuccessAnimationComponent
  implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('successAnimation', { static: true }) successAimation: ElementRef;
  el_successAnimation: HTMLElement;
  timerAnimationFinished: any;
  redirectLink: string;

  @Input() isMobile: boolean;

  constructor(private auth: AuthenticationService) {}

  ngOnInit(): void {
    this.getRedirectLink();
  }

  getRedirectLink(): string {
    return this.isMobile ? this.auth.getMobileRouting() : '/dashboard';
  }

  ngAfterViewInit(): void {
    this.el_successAnimation = this.successAimation
      .nativeElement as HTMLElement;
    this.timerAnimationFinished = setTimeout(() => {
      const redirectUrl = this.getRedirectLink();
      window.location.href = redirectUrl;
    }, 7000);
  }

  ngOnDestroy(): void {
    if (this.timerAnimationFinished) {
      clearTimeout(this.timerAnimationFinished);
    }
  }
}
