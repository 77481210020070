import { Component } from '@angular/core';
import { startWith, map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-loading-mobile',
  templateUrl: './loading-mobile.component.html',
  styleUrls: ['./loading-mobile.component.scss']
})
export class LoadingMobileComponent {
  isMobile$ = this.route.data.pipe(
    startWith({ mobile: false }),
    map(data => data.mobile)
  );

  constructor(private route: ActivatedRoute) {}
}
