<div class="action-modal-container">
  <suzy-spinner
    *ngIf="isSubmitting"
    text="true"
    negativeMarginTop="true"
  ></suzy-spinner>
  <div class="head-container">
    <app-action-modal-header
      [mission]="mission"
      [disableButton]="mission?.isGlobal"
    >
    </app-action-modal-header>

    <div class="action-modal-question">
      <h3>{{ action.share_link.prompt }}</h3>
    </div>
  </div>

  <div class="modal-body action-modal-body">
    <suzy-preroll
      (prerollViewedEvent)="onPrerollViewedEvent()"
      *ngIf="hasPreroll && !prerollViewed"
      [preRollKind]="preRollKind"
      [preRollCaption]="preRollCaption"
      [preRollUrl]="preRollUrl"
      [mission]="mission"
      [action]="action"
    ></suzy-preroll>

    <form
      novalidate=""
      (ngSubmit)="onSubmit()"
      #f="ngForm"
      *ngIf="!hasPreroll || (hasPreroll && prerollViewed)"
    >
      <div class="form">
        <div class="form-group form-filling">
          <textarea
            type="text"
            class="form-control h-144"
            name="response"
            id="response"
            rows="6"
            [(ngModel)]="response"
            required
            maxlength="250"
            [minlength]="action.share_link.minlength"
            placeholder="{{
              action.share_link.placeholder || 'Your answer here'
            }}"
          ></textarea>
          <p class="char-counter">{{ 250 - response.length }}/250</p>
          <span class="error-inline-msg" *ngIf="!isValid">{{
            'general.thisIsNotAValidResponse' | translate
          }}</span>

          <app-share-social-media
            [message]="response"
            [hashtags]="action.share_link.hashtags"
            (shared)="onShared()"
            [platforms]="action.share_link.platforms"
            [url]="action.share_link.link"
          ></app-share-social-media>
        </div>

        <div class="form-group form-cta">
          <div class="row">
            <div class="col-6">
              <button
                class="btn form-btn form-btn-secondary text-uppercase"
                (click)="skip($event)"
                [disabled]="disableSkip"
                *ngIf="mission?.isGlobal"
              >
                {{ 'general.skip' | translate }}
              </button>
            </div>

            <div class="col-6">
              <button
                class="btn form-btn form-btn-primary form-btn-with-arrow text-uppercase"
                (click)="validateInput(responseForm.value.response)"
                [disabled]="!shared"
              >
                {{ 'general.submit' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
