import { Injectable } from '@angular/core';
import { GlobalIsolateSdkService } from '@suzy/shared/data-access/global-isolate-sdk';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalEndingComponent } from '../actions/global-ending/global-ending.component';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  private destroy$ = new Subject<boolean>();
  private monadicVariantId: string = '';
  private recentLanguage: string = '';

  constructor(
    private globalSDK: GlobalIsolateSdkService,
    private translate: TranslateService,
    private modals: NgbModal
  ) {}

  getAvailableAsync(missionId: string, cintId: string): Observable<any> {
    return this.globalSDK.Mission.getAvailableAsync(missionId, cintId).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  getValidateCintRespondentAsync(
    missionId: string,
    cintId: string
  ): Observable<any> {
    return this.globalSDK.Auth.validateCintRespondentAsync(
      missionId,
      cintId
    ).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  getMission(): Observable<any> {
    const forceRefresh = false;
    return this.globalSDK.Mission.getAvailableMissionsAsync(forceRefresh).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  getCurrentMissionLanguage(): string {
    return this.recentLanguage;
  }

  setLanguage(lan: string): void {
    this.recentLanguage = lan;
    this.translate.addLangs([...this.translate.getLangs(), lan]);
    this.translate.use(lan);
    this.translate
      .getTranslation(lan)
      .pipe(takeUntil(this.destroy$))
      .subscribe(val => {
        this.translate.setTranslation(lan, { ...val.tokens });
      });
    this.globalSDK.setLanguage(lan);
  }

  respondToGlobalActionAsync({
    method = 'POST',
    resource = 'actions/respond',
    payload,
    params = ''
  }): Observable<any> {
    return this.globalSDK
      .execute({
        method,
        resource,
        payload,
        params
      })
      .pipe(
        map(data => {
          if (data.success) {
            if (data.item && Object.keys(data.item).length > 0) {
              const newAction = data.item;
              const applied_translation_locale =
                newAction['applied_translation_locale'];
              if (applied_translation_locale)
                this.setLanguage(applied_translation_locale);
            }
          }
          return data;
        })
      );
  }

  showGlobalEndingComponent(
    mission,
    action,
    actionTypeName,
    cintRedirect,
    message?
  ): Subject<any> {
    const subject = new Subject<any>();
    const startTime = new Date().getTime();
    let endingModal = this.modals.open(GlobalEndingComponent, {
      windowClass: 'global-mission-container',
      container: '#globalMission',
      backdrop: false
    });

    const endingComponent =
      endingModal?.componentInstance as GlobalEndingComponent;
    if (action && endingComponent) {
      endingComponent.type =
        actionTypeName; /* Where I will get the action type  */
    }
    if (mission && endingComponent) {
      endingComponent.points = mission.points;
      endingComponent.mission = mission;
    }
    if (endingComponent) {
      endingComponent.startTime = startTime;
      endingComponent.cintRedirect = cintRedirect;
      endingComponent.message = message;

      endingModal.result.then(
        res => {
          if (res) {
            subject.next(res);
          } else {
            subject.next('refresh');
            return;
          }
        },
        () => {
          subject.next('refresh');
          this.modals.dismissAll();

          return;
        }
      );
    }
    return subject;
  }

  setMonadicVariantId(id: string): void {
    this.monadicVariantId = id;
  }

  getMonadicVariantId(): string {
    return this.monadicVariantId;
  }

  setApiKeyToGlobalSdk(keys: { api_key: string; api_secret: string }): void {
    this.globalSDK.setApiCredentials(keys.api_key, keys.api_secret);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
