import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpecialMissionService {
  constructor() {}
  private properties = {
    crowdtap_special_missions: [],
    crowdtap_completed_missions_daily: '0'
  };

  public get Properties() {
    return this.properties;
  }

  public set Properties(data: any) {
    this.properties.crowdtap_completed_missions_daily =
      data?.crowdtap_completed_missions_daily;
    if (data && data.crowdtap_special_missions) {
      this.properties.crowdtap_special_missions = JSON.parse(
        data.crowdtap_special_missions
      );
    }
  }
}
