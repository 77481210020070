import { CommonModule } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule,
  TranslateParser
} from '@ngx-translate/core';
import {
  DeviceService,
  SharedToolsDeviceModule
} from '@suzy/shared/tools/device';
import { Angulartics2Module } from 'angulartics2';
import {
  FacebookLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule
} from 'angularx-social-login';
import { SignalRConfiguration, SignalRModule } from 'ng2-signalr';
import { DragScrollModule } from 'ngx-drag-scroll';
import { FacebookModule } from 'ngx-facebook';
import { MomentModule } from 'ngx-moment';
import { Observable, of, Subject } from 'rxjs';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { BlockedIPGuard } from './core/guards/blocked-ip.guard';
import { TitleService } from './core/services/title.service';
import { CountUpdateService } from './core/services/count-update.service';
import { GoalService } from './core/services/goal.service';
import { SkipModalService } from './core/services/skip-modal-service';
import { SpecialMissionService } from './core/services/special-mission.service';
import {
  SharedDataAccessSuzySdkModule,
  SuzySdkService
} from '@suzy/shared/data-access/suzy-sdk';
import { GlobalIsolateSdkService } from '@suzy/shared/data-access/global-isolate-sdk';
import { SdkService } from '@suzy/shared/data-access/sdk';
import {
  SegmentService,
  TrustArcService,
  ZendeskService
} from '@suzy/shared/data-access/tracking';
import {
  AuthenticationService,
  UserProfileService,
  IncentiveProgramService
} from '@suzy/crowdtap/data-access/user';
import { MissionService } from '@suzy/shared/tools/mission';
import { PointsService } from '@suzy/crowdtap/tools/points';
import { SharedModule } from './shared/shared.module';
import { AnonymousModule } from './views/anonymous/anonymous.module';
import { SuccessAnimationComponent } from './views/success-animation/success-animation.component'; // *Added Temp for success animation
import { MissionExpiredModule } from './core/services/mission-expired/mission-expired.module';
import { map, startWith } from 'rxjs/operators';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LaunchDarklyService } from '@suzy/shared/data-access/feature-flag';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ErrorInterceptor } from './core/helpers/error.interceptor';
import { ChallengeService } from './core/services/challenge.service';
import { ModalQueueService } from './core/services/modal-queue-service';
import { ExternalLinkService } from './views/external-link/external-link.service';

declare global {
  interface Window {
    analytics: any;
  }
}

export function suzySdkFactory(
  http: HttpClient,
  deviceService: DeviceService
): SdkService {
  return new SuzySdkService(http, deviceService).initialize(environment.apiUrl);
}

export function globalIsolateSdkFactory(
  http: HttpClient,
  deviceService: DeviceService
): SdkService {
  return new GlobalIsolateSdkService(http, deviceService).initialize(
    environment.globalUrl
  );
}

export function segmentFactory(
  trustArcService: TrustArcService
): SegmentService {
  let eventPrefix: string = undefined;

  if (environment.preview) {
    eventPrefix = 'Preview ';
  }

  return new SegmentService(trustArcService).initialize(
    environment.segmentToken,
    eventPrefix
  );
}

export function signalRConfigFactory(): SignalRConfiguration {
  const config = new SignalRConfiguration();
  config.hubName = 'suzyHub';
  config.url = environment.signalRUrl;
  config.logging = false;
  config.executeEventsInZone = true;
  config.executeErrorsInZone = false;
  config.executeStatusChangeInZone = true;
  config.withCredentials = true;

  return config;
}

export function pointsFactory(
  missionService: MissionService,
  userProfileService: UserProfileService,
  authenticationService: AuthenticationService
): PointsService {
  return new PointsService(
    userProfileService,
    missionService,
    authenticationService
  );
}

export function incentiveProgramFactory(
  suzySdkService: SuzySdkService
): IncentiveProgramService {
  return new IncentiveProgramService(suzySdkService);
}

export function challengeServiceFactory(
  suzySdkService: SuzySdkService,
  launchDarklyService: LaunchDarklyService
): ChallengeService {
  return new ChallengeService(suzySdkService, launchDarklyService);
}

export class CrowdtapTranslationLoader extends TranslateLoader {
  constructor(private http: HttpClient) {
    super();
  }

  getTranslation(lang: string): Observable<any> {
    let language = navigator.language.toLowerCase();
    language = language.split('-')[0];
    if (language === 'en') {
      return this.http.get(
        `${environment.apiUrl}/server/language/console/en?platform=${environment.languagePlatform}`
      );
    } else {
      return of({});
    }
  }
}

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

export class CrowdtapMissingTranslationHandler
  implements MissingTranslationHandler
{
  tokens = {};
  private languageTokens$ = new Subject();

  constructor(private http: HttpClient, private parser: TranslateParser) {
    let language = navigator.language.toLowerCase();
    if (!language.startsWith('en') && !language.startsWith('es')) {
      language = 'en';
    }
    this.http.get(`/assets/i18n/${language}.json`).subscribe(
      (response: any) => {
        this.tokens = response.tokens;
        this.languageTokens$.next();
      },
      () => {
        language = language.split('-')[0];
        this.http.get(`/assets/i18n/${language}.json`).subscribe(
          (response: any) => {
            this.tokens = response.tokens;
            this.languageTokens$.next();
          },
          () => {
            this.http.get('/assets/i18n/en.json').subscribe((response: any) => {
              this.tokens = response.tokens;
              this.languageTokens$.next();
            });
          }
        );
      }
    );
  }

  handle(params: MissingTranslationHandlerParams): Observable<any> {
    return this.languageTokens$.pipe(
      startWith<void, void>(undefined),
      map(() => {
        return this.interpolate(params);
      })
    );
  }

  interpolate(params: MissingTranslationHandlerParams): any {
    try {
      return this.parser.interpolate(
        params.key.split('.').reduce((o, i) => o[i], this.tokens),
        params.interpolateParams
      );
    } catch (e) {
      if (params.interpolateParams) {
        return params.interpolateParams['Default'] || params.key;
      }

      return params.key;
    }
  }
}

@NgModule({
  declarations: [AppComponent, SuccessAnimationComponent], // *Added Temp for success animation
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    SocialLoginModule,
    SharedModule,
    HttpClientModule,
    SharedToolsDeviceModule,
    SharedDataAccessSuzySdkModule,
    FormsModule,
    MomentModule,
    DragScrollModule,
    AnonymousModule,
    NgbModule,
    MissionExpiredModule,
    NgScrollbarModule,
    SignalRModule.forRoot(signalRConfigFactory),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CrowdtapMissingTranslationHandler,
        deps: [HttpClient, TranslateParser]
      },
      loader: {
        provide: TranslateLoader,
        useClass: CrowdtapTranslationLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FacebookModule.forRoot(),
    Angulartics2Module.forRoot(),
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ServiceWorkerModule.register('/service-worker.js', { enabled: true })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: (launchDarklyService: LaunchDarklyService) => {
        return () => {
          return launchDarklyService.initialize(
            environment.launchDarkly,
            environment['allowLaunchDarklyMocks'] ?? false
          );
        };
      },
      deps: [LaunchDarklyService]
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: (zendeskService: ZendeskService) => {
        return () => {
          if (
            !(
              environment.preview ||
              environment.global ||
              environment.externalLink
            )
          ) {
            return zendeskService.initialize();
          }
        };
      },
      deps: [ZendeskService]
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: SuzySdkService,
      useFactory: suzySdkFactory,
      deps: [HttpClient, DeviceService]
    },
    {
      provide: GlobalIsolateSdkService,
      useFactory: globalIsolateSdkFactory,
      deps: [HttpClient, DeviceService]
    },
    {
      provide: SegmentService,
      useFactory: segmentFactory,
      deps: [TrustArcService]
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookAppId)
          }
        ]
      } as SocialAuthServiceConfig
    },
    {
      provide: PointsService,
      useFactory: pointsFactory,
      deps: [MissionService, UserProfileService, AuthenticationService]
    },
    {
      provide: IncentiveProgramService,
      useFactory: incentiveProgramFactory,
      deps: [SuzySdkService]
    },
    ChallengeService,
    BlockedIPGuard,
    TitleService,
    CountUpdateService,
    GoalService,
    SkipModalService,
    SpecialMissionService,
    ModalQueueService,
    ExternalLinkService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
