import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { environment } from 'apps/crowdtap/src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  static readonly darkModeStorageKey = 'dark-mode';

  isDarkModeSelected: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isDarkModeSelected$ = this.isDarkModeSelected.asObservable();

  private isDarkModeBypassed = false;

  private _isBrowser = false;
  get isBrowser(): boolean {
    return this._isBrowser;
  }

  constructor(
    @Optional() @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) platformId: any
  ) {
    this._isBrowser = isPlatformBrowser(platformId);
  }

  initDarkMode(): void {
    this.isDarkModeBypassed = false;
    const darkMode = this.isDarkModeInStorage()
      ? this.darkModeStorageValue()
      : !this.isSystemLightMode();

    this.setDarkMode(darkMode);
    this.initSystemThemeChangeListener();
  }

  resetDarkMode(): void {
    localStorage.removeItem(SettingsService.darkModeStorageKey);
    this.initDarkMode();
  }

  isSystemLightMode(): boolean {
    const systemLightModeMediaQuery = window.matchMedia(
      '(prefers-color-scheme: light)'
    );

    return systemLightModeMediaQuery.matches;
  }

  initSystemThemeChangeListener(): void {
    const systemLightModeMediaQuery = window.matchMedia(
      '(prefers-color-scheme: light)'
    );

    systemLightModeMediaQuery.addEventListener('change', event => {
      if (this.isDarkModeBypassed) {
        return;
      }
      const isSystemLightMode = event.matches;

      if (!this.isDarkModeInStorage()) {
        this.setDarkMode(!isSystemLightMode);
      }
    });
  }

  isDarkModeInStorage(): boolean {
    const darkModeStorageValue = localStorage.getItem(
      SettingsService.darkModeStorageKey
    );

    return (
      darkModeStorageValue !== undefined &&
      darkModeStorageValue !== null &&
      (darkModeStorageValue === 'true' || darkModeStorageValue === 'false')
    );
  }

  darkModeStorageValue(): boolean {
    const darkModeStorageValue = localStorage.getItem(
      SettingsService.darkModeStorageKey
    );

    return this.isDarkModeInStorage() && darkModeStorageValue === 'true';
  }

  toggleDarkMode(): void {
    if (this.isDarkModeBypassed) {
      return;
    }
    if (this._isBrowser) {
      const dark = !this.isDarkModeSelected.getValue();
      this.isDarkModeSelected.next(dark);
      this.setDarkMode(dark);
      localStorage.setItem(
        SettingsService.darkModeStorageKey,
        this.isDarkModeSelected.getValue().toString()
      );
    }
  }

  setDarkMode(dark: boolean): void {
    if (!this.isDarkModeBypassed) {
      this.isDarkModeSelected.next(dark);
    }
    if (dark && !this.isExternalApp() && !this.isPreviewApp()) {
      this.document.documentElement.classList.add('dark');
    } else {
      this.document.documentElement.classList.remove('dark');
    }
  }

  bypassDarkMode(dark: boolean): void {
    this.isDarkModeBypassed = true;
    this.setDarkMode(dark);
  }

  isPreviewApp(): boolean {
    return environment.preview ? true : false;
  }

  isGlobalApp(): boolean {
    return environment.global ? true : false;
  }

  isExternalApp(): boolean {
    return environment.externalLink ? true : false;
  }
}
