<div #wrapper>
  <app-general-header></app-general-header>

  <section aria-label="main" role="main" id="main-content">
    <article class="container">
      <div class="center-column">
        <h1 class="text-light-black dark:text-white">
          {{ 'unauthorized.invalidBrowser' | translate }}
        </h1>
        <p
          [innerHTML]="
            'unauthorized.pleaseMakeSureYourBrowserIsSupported' | translate
          "
          class="text-light-black dark:text-white"
        ></p>
        <a href="http://outdatedbrowser.com" class="submit-btn" target="_blank">
          {{ 'unauthorized.updateYourBrowser' | translate }}
        </a>
      </div>
    </article>
  </section>

  <app-general-footer> </app-general-footer>
</div>
