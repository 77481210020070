import {
  Component,
  Directive,
  ChangeDetectorRef,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  Renderer2,
  ElementRef,
  HostListener,
  ViewChildren,
  QueryList
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { DropEvent } from 'ng-drag-drop';
import { DeviceService } from '@suzy/shared/tools/device';
import {
  CdkDrag,
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem
} from '@angular/cdk/drag-drop';
import { ConsumptionMechanism } from '@asksuzy/typescript-sdk';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
declare var $;

@Directive({
  selector: '[clickOutside]'
})
export class ClickOutsideDirective {
  constructor(private _elementRef: ElementRef) {}

  @Output()
  public clickOutside = new EventEmitter<MouseEvent>();

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }

    const clickedInside = this._elementRef.nativeElement.contains(
      targetElement
    );
    if (!clickedInside) {
      this.clickOutside.emit(event);
    }
  }
}
@Component({
  selector: 'suzy-action-forms-grid-rank',
  templateUrl: './grid-rank.component.html',
  styleUrls: ['./grid-rank.component.scss']
})
export class GridRankComponent implements OnInit {
  @ViewChildren('toggleButton') toggleButton: QueryList<ElementRef>;
  @ViewChildren('menu') menu: QueryList<ElementRef>;

  unselectedRows = [];
  rowAnswers = [];
  emptyRowAnswers = [];
  fullRowAnswers = [];
  isRowSelected: number;
  isEmptyRowSelected: number;
  isAllRowSelected: number;
  timeToAction: number;
  timeToActionIsUpdated = false;
  isDarkMode: boolean;
  previousSelectLocation: number;
  errorMessageRank: string = '';
  selectedRow: number = null;

  @Input() action: any;
  @Input() isLoading: boolean;
  @Input() isSuccess: boolean;
  @Input() cols: any[];
  @Input() rows: any[];
  @Input() rowErrors: any[];
  @Input() colErrors: any[];
  @Input() allChoices: any[];
  @Input() lowestTerminalValue: string;
  @Input() highestTerminalValue: string;
  @Input() disableSkip: boolean;
  @Input() isSubmitting: boolean;
  @Input() errorMessage = '';
  @Input() disableButton: Boolean = false;
  @Input() isSkipping: boolean;

  @Output() mouseEvent = new EventEmitter<MouseEvent>();
  @Output() timeToActionUpdate = new EventEmitter<number>();
  @Output() updateMetaDataValidationCount = new EventEmitter();
  @Output() skip = new EventEmitter<Event>();
  @Output() grFormSubmit = new EventEmitter<any>();
  @Output() errorMessageChange = new EventEmitter<string>();

  @ViewChild('f') responseForm: NgForm;

  roundValue: number;
  showDragLine: boolean;

  constructor(
    private renderer: Renderer2,
    private deviceService: DeviceService,
    private translate: TranslateService
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      for (var div of this.toggleButton) {
        if (!(e.target !== div.nativeElement)) {
          this.isAllRowSelected = parseInt(div.nativeElement.id);
          break;
        } else {
          this.isAllRowSelected = -1;
        }
      }
    });
  }

  ngOnInit(): void {
    let screenName = this.deviceService.getDevice();
    this.roundValue = 10;
    this.isDarkMode = $('html').hasClass('dark');
    this.renderer.addClass(document.body, 'isGridAction');
    if (this.action.grid.randomize) {
      // Sort taken from https://css-tricks.com/snippets/javascript/shuffle-array/
      this.rows.sort(() => {
        return 0.5 - Math.random();
      });
    }

    this.rows.forEach((row, i) => {
      this.unselectedRows.push(i);

      const newRow = {
        text: row[0].row.answer_text,
        row: i,
        rank: undefined
      };

      this.rowAnswers.push(newRow);
      this.emptyRowAnswers.push(newRow);
    });
    this.rows = this.rows.map((row, index) => {
      row[0]['rank'] = undefined;
      row[0]['data'] = { index, lastUpdated: 0 };
      return row;
    });
  }

  allRowsSelected(): boolean {
    return this.unselectedRows.length === 0;
  }

  updateTimeToAction(): void {
    if (!this.timeToActionIsUpdated) {
      this.timeToAction = Date.now();
      this.timeToActionUpdate.emit(this.timeToAction);
    }
    this.timeToActionIsUpdated = true;
  }

  pillDropped(event: DropEvent, rowAnswer: any, i: number): void {
    const pill = event.dragData;
    rowAnswer.rank = pill;
    this.unselectedRows = this.unselectedRows.filter(item => item !== pill);
    this.emptyRowAnswers = this.emptyRowAnswers.filter(
      row => row.rank !== pill
    );

    this.fullRowAnswers.push(rowAnswer);
    this.sortSelectedRowAnswers();

    if (this.unselectedRows.length === 0) {
      this.sortWhenFull();
    }
  }

  sortSelectedRowAnswers(): void {
    this.fullRowAnswers.sort((a, b) => {
      return a.rank - b.rank;
    });
  }

  noRowsSelected(): boolean {
    return this.unselectedRows.length === this.rowAnswers.length;
  }

  someRowsSelected(): boolean {
    return !this.noRowsSelected() && !this.allRowsSelected();
  }

  reorderSelectedRowAnswer(): void {
    const ranks = [];
    this.fullRowAnswers.forEach(row => {
      ranks.push(row.rank);
    });
    ranks.sort();

    this.fullRowAnswers.forEach((row, i) => {
      row.rank = ranks[i];
    });
  }

  sortWhenFull(): void {
    const newRows = [];
    this.fullRowAnswers.forEach((answer, i) => {
      newRows.push(this.rows[answer.row]);
    });

    for (let i = 0; i < newRows.length; i++) {
      for (let j = 0; j < newRows[i].length; j++) {
        newRows[i][j].isSelected = i === j ? true : false;
      }
    }
    this.rows = newRows;
  }

  rankItemDropped(): void {
    if (this.allRowsSelected()) {
      this.allChoices = [];
      this.rows = this.rows.map((row, i) => {
        return row.map((innerRow, j) => {
          const isSelected = i === j ? true : false;
          const choice = { ...innerRow, isSelected };
          this.allChoices.push(choice);

          return choice;
        });
      });
    } else {
      this.reorderSelectedRowAnswer();
    }
  }

  selectMobileRow(event: MouseEvent): void {
    const source: Element = (event.srcElement as Element).parentElement;
    const parent: Element = source.parentElement;
    const siblings: Array<Element> = Array.from(parent.children);
    const select: HTMLSelectElement = source.getElementsByTagName('select')[0];

    siblings.forEach(child => {
      child.classList.remove('selected');
    });

    parent.classList.add('selecting');
    source.classList.add('selected');
  }

  selectMobileBlur(event: Event): void {
    const source: HTMLSelectElement = event.srcElement as HTMLSelectElement;
    const parent: Element = source.parentElement;
    const grandparent: Element = parent.parentElement;

    parent.classList.remove('selected');
    grandparent.classList.remove('selecting');
  }

  mobileInsertItem(rowAnswer): void {
    let oldFullRows = [...this.fullRowAnswers];
    this.fullRowAnswers = [];

    oldFullRows = oldFullRows.filter(row => row.row !== rowAnswer.row);
    let inserted = false;
    let priorRank = rowAnswer.rank;

    if (this.emptyRowAnswers.length === 0) {
      oldFullRows.forEach((row, index) => {
        row.rank = index;
      });
    }

    let increment = true;
    oldFullRows.forEach((row, index) => {
      if (rowAnswer.rank <= row.rank) {
        const newRow = { ...rowAnswer };
        if (priorRank === row.rank) {
          if (oldFullRows.length - index + 1 > this.rows.length - newRow.rank) {
            newRow.rank--;
            increment = false;
          }
          if (increment) {
            row.rank++;
          }
        }
        if (!inserted) {
          this.fullRowAnswers.push(newRow);
          inserted = true;
        }
        priorRank = row.rank;
      }
      this.fullRowAnswers.push(row);
    });

    if (!inserted) {
      this.fullRowAnswers.push(rowAnswer);
    }
  }

  mobileItemSelected(event: Event, rowAnswer): void {
    const source: HTMLSelectElement = event.srcElement as HTMLSelectElement;
    const parent: Element = source.parentElement;
    const grandparent: Element = parent.parentElement;
    const value: number = parseInt(source.value, 10);

    parent.classList.remove('selected');
    grandparent.classList.remove('selecting');

    rowAnswer.rank = value;
    this.mobileInsertItem(rowAnswer);
    this.unselectedRows = this.unselectedRows.filter(
      item => item !== rowAnswer.row
    );
    this.emptyRowAnswers = this.emptyRowAnswers.filter(
      row => row.rank !== value
    );
    this.sortSelectedRowAnswers();

    if (this.allRowsSelected()) {
      this.sortWhenFull();
      this.allChoices = [];
      this.rows = this.rows.map((row, i) => {
        return row.map((innerRow, j) => {
          const isSelected = i === j ? true : false;
          const choice = { ...innerRow, isSelected };
          this.allChoices.push(choice);

          return choice;
        });
      });
    }

    source.value = '';
    this.isEmptyRowSelected = -1;
    this.isRowSelected = -1;
    this.isAllRowSelected = -1;
  }

  mobileSetSelectedRow(index: number): void {
    this.previousSelectLocation = index;
  }

  mobileFullItemSelected(event: Event, oldIndex: number): void {
    const source = event.srcElement as HTMLSelectElement;
    const parent: Element = source.parentElement;
    const grandparent: Element = parent.parentElement;
    const newIndex = parseInt(source.value, 10);

    parent.classList.remove('selected');
    grandparent.classList.remove('selecting');

    const item = this.rows.splice(oldIndex, 1)[0]; // cut the element at index 'from'
    this.rows.splice(newIndex, 0, item);

    this.allChoices = [];
    this.rows = this.rows.map((row, i) => {
      return row.map((innerRow, j) => {
        const isSelected = i === j ? true : false;
        const choice = { ...innerRow, isSelected };
        this.allChoices.push(choice);

        return choice;
      });
    });
    source.value = '';
    this.isAllRowSelected = -1;
  }

  onMouseMoveEvent(event: MouseEvent) {
    this.mouseEvent.emit(event);
  }

  onSkip(event: Event) {
    this.skip.emit(event);
  }

  gridHasError(): any {
    this.errorMessageRank = '';
    const hasError = this.validateRankQuestion();
    let errorToken: string;

    this.translate
      .get('grid.mustRankAll')
      .pipe(take(1))
      .subscribe(value => {
        errorToken = value;
      });

    this.errorMessage = hasError ? errorToken : '';
    this.errorMessageChange.emit(this.errorMessage);
    return hasError;
  }

  // validation for rank type
  validateRankQuestion(): any {
    let hasError = false;
    this.cols.forEach((choice, idx) => {
      const selectedChoice = [];
      choice.forEach(item => {
        if (item.isSelected === true) {
          selectedChoice.push(item);
        }
      });
      if (selectedChoice.length !== 1) {
        this.colErrors[idx] = true;
        this.updateMetaDataValidationCount.emit();
        hasError = true;
      } else {
        this.colErrors[idx] = false;
      }
    });

    return hasError;
  }

  onSubmit() {
    if (!this.gridHasError()) {
      this.grFormSubmit.emit(this.allChoices);
    }
  }

  openMenu(i: number) {
    this.isEmptyRowSelected === i
      ? (this.isEmptyRowSelected = -1)
      : (this.isEmptyRowSelected = i);
  }
  openMenuRows(i: number) {
    this.isRowSelected === i
      ? (this.isRowSelected = -1)
      : (this.isRowSelected = i);
  }
  openMenuAll(i: number) {
    this.isAllRowSelected === i
      ? (this.isAllRowSelected = -1)
      : (this.isAllRowSelected = i);
  }

  swapArray(arr, cur, prev) {
    [arr[cur], arr[prev]] = [arr[prev], arr[cur]];
  }

  onRowRankSelected(event: Event, rowAnswer: any, index: number): void {
    const source = event.srcElement as HTMLSelectElement;
    const parent: Element = source.parentElement;
    const grandparent: Element = parent.parentElement;
    const value = parseInt(source.value, 10);

    parent.classList.remove('selected');
    grandparent.classList.remove('selecting');

    if (rowAnswer[0].rank === value) {
      return;
    }

    const currentIndex = this.getNearIndex(value, index);
    this.rows[index][0].rank = value;

    if (currentIndex != index && this.rows[currentIndex][0].rank != undefined) {
      this.transferItems(index, currentIndex);
      if (index < currentIndex) {
        this.adjustBottomToTop();
        this.correctMax();
        this.adjustTopToBottom();
      } else {
        this.adjustTopToBottom();
        this.correctMax();
        this.adjustBottomToTop();
      }
    } else {
      this.updateRankOrder(index, currentIndex);
    }

    source.value = '';
    this.isAllRowSelected = -1;

    if (this.checkAllSelected()) {
      this.updateRowAnswers();
    }
  }

  transferItems(prevIndex, curIndex) {
    moveItemInArray(this.rows, prevIndex, curIndex);
    const lastUpdated = this.getLastValueIndex();
    this.rows = this.rows.map((row, index) => {
      row[0]['data'] = { index, lastUpdated: lastUpdated };
      return row;
    });
  }

  adjustTopToBottom(): void {
    for (let i = 1; i < this.rows.length; i++) {
      const previous = this.rows[i - 1][0].rank;
      const current = this.rows[i][0].rank;

      if (current <= previous) {
        this.rows[i][0].rank = previous + 1;
      }
    }
  }

  adjustBottomToTop(): void {
    for (let i = this.rows.length - 2; i >= 0; i--) {
      const previous = this.rows[i + 1][0].rank;
      const current = this.rows[i][0].rank;
      if (current >= previous) {
        this.rows[i][0].rank = previous - 1;
      }
    }
  }

  correctMax(): void {
    const rowLength = this.rows.filter(row => row[0].rank !== undefined).length;
    if (this.rows[rowLength - 1][0].rank > this.rows.length - 1) {
      this.rows[rowLength - 1][0].rank = this.rows.length - 1;
    }
  }

  drop(event: CdkDragDrop<any[]>) {
    this.errorMessageChange.emit('');
    if (event.item.data.lastUpdated === 0 && event.currentIndex !== 0) {
      this.translate
        .get('grid.dragToTheTop')
        .pipe(take(1))
        .subscribe(value => {
          this.errorMessageRank = value;
        });

      return;
    }

    if (
      event.previousIndex !== event.currentIndex ||
      (event.previousIndex === 0 && event.currentIndex === 0)
    ) {
      const rank =
        event.currentIndex > 0 ? this.rows[event.currentIndex - 1][0].rank : -1;
      this.rows[event.previousIndex][0].rank = rank !== 9 ? rank + 1 : 9;
      this.updateRankOrder(event.previousIndex, event.currentIndex);
    } else {
      this.translate
        .get('grid.dragToTheTop')
        .pipe(take(1))
        .subscribe(value => {
          this.errorMessageRank = value;
        });
    }

    if (this.checkAllSelected()) {
      this.updateRowAnswers();
    }
  }

  updateRankOrder(previousIndex, currentIndex) {
    this.transferItems(previousIndex, currentIndex);
    this.adjustTopToBottom();
    this.correctMax();
    this.adjustBottomToTop();
  }

  checkAllSelected() {
    return this.rows.every(row => row[0].rank !== undefined);
  }

  updateRowAnswers() {
    this.allChoices = [];

    this.rows = this.rows.map((row, i) => {
      return row.map((innerRow, j) => {
        const isSelected = i === j ? true : false;
        const choice = { ...innerRow, isSelected };
        this.allChoices.push(choice);

        return choice;
      });
    });

    this.cols = this.cols.map((row, i) => {
      return row.map((innerRow, j) => {
        innerRow['isSelected'] = i === j ? true : false;
        return innerRow;
      });
    });
  }

  sortPredicate(index: number, item: CdkDrag<any>) {
    const sortPredicate =
      item.data.lastUpdated !== 0
        ? item.data.index < item.data.lastUpdated
          ? index <= item.data.lastUpdated - 1
          : index <= item.data.lastUpdated
        : item.data.index > index;
    return item.data.lastUpdated === -1 ? true : sortPredicate;
  }

  updateLastIndex() {
    const lastUpdated = this.getLastValueIndex();
    this.rows = this.rows.map((row, index) => {
      row[0]['data'] = { index, lastUpdated: lastUpdated };
      return row;
    });
  }

  getLastValueIndex() {
    for (let i = 0; i < this.rows.length; i++) {
      if (this.rows[i][0].rank === undefined) {
        return i;
      }
    }
    return -1;
  }

  getNearIndex(value, currIndex) {
    const rows = this.rows.map(x => x[0].rank).filter(x => x != undefined);
    if (!rows.length) return 0;

    if (this.rows[currIndex][0].rank != undefined) {
      const valueExists = rows.findIndex(x => x === value);
      if (valueExists != -1) {
        return valueExists;
      } else {
        const index = rows.findIndex(x => x >= value);
        if (
          this.rows[currIndex + 1] &&
          this.rows[currIndex + 1][0].rank > value
        ) {
          return index - 1;
        }
        return index != -1 ? index : rows.length - 1;
      }
    }

    if (rows[rows.length - 1] === 9) {
      const valueExists = rows.findIndex(x => x === value);
      if (rows[rows.length - 1] - rows[valueExists] === rows.length - 1) {
        return valueExists + 1;
      }
    }

    const index = rows.findIndex(x => x >= value);
    if (index != -1 || this.checkAllSelected()) {
      return index;
    }

    return rows.length;
  }

  cdkDragStarted(i, row) {
    this.isAllRowSelected = -1;
    this.errorMessageRank = '';
    row[0].data['index'] = i;
    this.selectedRow = i;
    this.showDragLine = true;
  }

  cdkDragEnded(event) {
    this.showDragLine = false;
  }
}
