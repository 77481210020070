import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActionKind, PreRollKind } from '@suzy/shared/data-access/suzy-sdk';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class MissionService {
  private missionAnsweredSubject$: Subject<string> = new Subject();
  private missionExpiredSubject$: Subject<string> = new Subject();
  private missionOpenSubject$: Subject<boolean> = new BehaviorSubject(false);
  private missionAnsweredMetaSubject$: BehaviorSubject<any> =
    new BehaviorSubject({});

  constructor(private translate: TranslateService) {}

  getMissionOpen(): Observable<any> {
    // used for disabling pill modals while missions are open
    return this.missionOpenSubject$.asObservable();
  }

  setMissionOpen(status: boolean) {
    this.missionOpenSubject$.next(status);
  }

  missionAnswered$(): Observable<any> {
    return this.missionAnsweredSubject$.asObservable();
  }

  missionExpired$(): Subject<any> {
    return this.missionExpiredSubject$;
  }

  missionAnsweredMeta$(): Observable<any> {
    return this.missionAnsweredMetaSubject$.asObservable();
  }

  onMissionAnswered(missionId: string, meta: any): void {
    if (missionId !== undefined) {
      this.missionAnsweredSubject$.next(missionId);
    }
    this.missionAnsweredSubject$.next(meta);
  }

  getActionByKind(action): any {
    switch (action.action_kind) {
      case ActionKind.openended:
        return action.open_ended;
      case ActionKind.maxdiff:
        return action.maxdiff;
      case ActionKind.multiplechoice:
        return action.multiple_choice;
      case ActionKind.photoacquisition:
        return action.photo;
      case ActionKind.grid:
      case ActionKind.gridcustom:
      case ActionKind.gridrankscale:
        return action.grid;
      default:
        break;
    }
  }

  getPrerollKind(kind: number): string {
    switch (kind) {
      case PreRollKind.none:
        return 'None';
      case PreRollKind.image:
        return 'Image';
      case PreRollKind.video:
        return 'Video';
      case PreRollKind.text:
        return 'Text';
      case PreRollKind.link:
        return 'Link';
      default:
        return 'None';
    }
  }

  getActionKind(actionKind: number): string {
    switch (actionKind) {
      case ActionKind.poll:
        return 'Poll';
      case ActionKind.multiplechoice:
        return this.translate.instant('multipleChoice.multipleChoice');
      case ActionKind.openended:
        return this.translate.instant('openEnded.openEnded');
      case ActionKind.fillblank:
        return 'Fill Blank';
      case ActionKind.viewmedia:
        return 'View Media';
      case ActionKind.photoacquisition:
        return 'Photo Acquisition';
      case ActionKind.suzypro:
        return 'Suzy Pro';
      case ActionKind.focusgroup:
        return this.translate.instant('focusGroup.focusGroup');
      case ActionKind.grid:
        return 'Grid';
      case ActionKind.gridcustom:
        return this.translate.instant('grid.customGrid');
      case ActionKind.gridrankscale:
        return this.translate.instant('grid.gridRankScale');
      case ActionKind.sampling:
        return 'Sampling';
      case ActionKind.sharetext:
        return 'Share Text';
      case ActionKind.sharephoto:
        return 'Share Photo';
      case ActionKind.manual:
        return 'Manual';
      case ActionKind.survey:
        return 'Survey';
      case ActionKind.sharing:
        return 'Sharing';
      case ActionKind.screening:
        return 'Screening';
      case ActionKind.splittesting:
        return 'Split Testing';
      default:
        return '';
    }
  }

  chooseMonadicKey(mission) {
    if (mission.monadic_enabled) {
      if (mission.monadic_variant_key !== undefined) {
        return;
      }
      if (mission.monadic_variants) {
        mission.monadic_variant_key = Math.floor(
          Math.random() * mission.monadic_variants.length
        );
      } else {
        mission.monadic_variant_key = Math.floor(
          Math.random() * mission.first_action.multiple_choice.variants.length
        );
      }
    }
  }
}
