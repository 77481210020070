import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileMenuService {
  display: BehaviorSubject<boolean> = new BehaviorSubject(false);
  display$ = this.display.asObservable();

  private _isBrowser = false;
  get isBrowser(): boolean {
    return this._isBrowser;
  }

  constructor(
    @Optional() @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) platformId: any
  ) {
    this._isBrowser = isPlatformBrowser(platformId);
    this.setupDocumentClickSubscription();
  }

  toggleDisplay(isDesktop = false): void {
    if (this.display.value) {
      this.display.next(false);
      if (this._isBrowser && !isDesktop) {
        this.document
          .querySelector('.content-wrapper')
          .classList.add('overflow-y-auto');
        this.document
          .querySelector('.content-wrapper')
          .classList.remove('overflow-hidden');
      }
      return;
    }

    this.display.next(true);
    if (this._isBrowser && !isDesktop) {
      this.document
        .querySelector('.content-wrapper')
        .classList.add('overflow-hidden');
      this.document
        .querySelector('.content-wrapper')
        .classList.remove('overflow-y-auto');
    }
  }

  private setupDocumentClickSubscription(): void {
    if (this._isBrowser) {
      fromEvent(this.document, 'click').subscribe((e: MouseEvent) => {
        if (this.display.value) {
          if ((e.target as Element).closest('.profile-menu')) {
            return;
          }
          this.toggleDisplay();
        }
      });
    }
  }
}
