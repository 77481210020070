<h1>Crowdtap® Cookies Policy</h1>
<div>
  <p>Updated: January 19, 2019</p>
  <p>
    Suzy, Inc. ("us", "we", or "our") uses cookies on the
    http://www.crowdtap.com/ website (the "Service"). When you first access the
    Service, you may receive a message advising you that cookies and similar
    technologies are in use. By clicking "accept", closing the message, or
    continuing to browse the Sites, you signify that you understand and agree to
    the use of these technologies, as described in this Cookies Policy.
  </p>
  <p>
    Our Cookies Policy explains what cookies are, how we use cookies, how
    third-parties we may partner with may use cookies on the Service, your
    choices regarding cookies and further information about cookies.
  </p>
  <p>
    In this Cookies Policy, we take reasonable efforts to identify the different
    kinds of cookies and technologies that may be in use in the Service, and,
    where possible, to identify the specific cookies and technologies. Note,
    however, that technology and the internet are constantly changing, and many
    of the components and elements of the Service evolve over time. We will make
    efforts to update the specific cookies and technologies as they change from
    time to time but cannot guarantee that this Cookie Policy will be complete
    at all times or will reflect all changes immediately.
  </p>
  <h2>What are cookies</h2>
  <p>
    Cookies are small pieces of text sent to your web browser by a website you
    visit. A cookie file is stored in your web browser and allows the Service or
    a third-party to recognize you and make your next visit easier and the
    Service more useful to you. Cookies on the Service may be delivered in a
    first-party (set by the Service) or third-party (set by another website)
    context and may also be set in association with emails you receive from us.
  </p>
  <p>
    Cookies can be "persistent" or "session" cookies. Persistent cookies remain
    on your personal computer or mobile device when you go offline, while
    session cookies are deleted as soon as you close your web browser.
  </p>
  <h2>How Suzy, Inc. uses cookies</h2>
  <p>
    When you use and access the Service, we may place a number of cookies files
    in your web browser.
  </p>
  <p>We use cookies for the following purposes:</p>
  <p>To enable certain functions of the Service</p>
  <p>- Authentication</p>
  <p>
    - Server Affinity (Which means that we use cookies to speed load times by
    sending traffic to the optimal server.)
  </p>
  <p>- UserIQ (third party) – provides communication and tutorials to users</p>
  <p>- Spam-prevention (keeping bad actors from acting badly)</p>
  <p>
    To provide analytics which are internal to Suzy and used to monitor and
    improve the Service and to provide features of the Service
  </p>
  <p>- Google Analytics, UserIQ, and Facebook</p>
  <p>To provide social media features</p>
  <p>
    We also share information about your use of our site with our social media,
    advertising and analytics partners who may combine it with other information
    that you’ve provided to them or that they’ve collected from your use of
    their services.
  </p>
  <p>
    We use both session and persistent cookies on the Service and we use
    different types of cookies to run the Service:
  </p>
  <p>
    <strong>Accounts-Related and Essential cookies</strong> . These cookies are
    essential for enabling and providing access to certain features of the
    Service and to remember information that changes the way the Service behaves
    or looks, such as a user's language preference on the Service. These cookies
    do not gather information about you that is used for marketing purposes and
    do not remember where you have been on the internet. We may also use
    accounts-related cookies to authenticate users and prevent fraudulent use of
    user accounts. We may use these cookies to remember information that changes
    the way the Service behaves or looks, such as the "remember me"
    functionality. This category of cookies cannot be disabled.
  </p>
  <p>· Authentication (HTML 5 local storage)</p>
  <ul>
    <li>Server Affinity</li>
  </ul>
  <p>
    <strong>Analytics cookies.</strong> We may use analytics cookies to track
    information how the Service is used so that we can make improvements. We may
    also use analytics cookies to test new advertisements, pages, features or
    new functionality of the Service to see how our users react to them.
  </p>
  <ul>
    <li>Google Analytics</li>
    <li>UserIQ</li>
    <li>Facebook</li>
  </ul>
  <h2>Third-party cookies</h2>
  <p>
    Third party cookies are cookies set by someone other than the website owner
    for purposes such as collecting information on user behavior, demographics,
    or personalized marketing. In addition to our own cookies, we may also use
    various third-parties cookies to report usage statistics of the Service,
    deliver advertisements on and through the Service. We do not have control
    over the placement of cookies by other websites, even if you are directed to
    them from our website.<a></a>
  </p>
  <p><a></a> · Facebook login/sign up</p>
  <p><a></a> · IESnare (spam prevention)<a></a></p>
  <h2>What are your choices regarding cookies</h2>
  <p>
    If you'd like to delete cookies or instruct your web browser to delete or
    refuse cookies, please visit the help pages of your web browser.
  </p>
  <p>
    Please note, however, that if you delete cookies or refuse to accept them,
    you might not be able to use all of the features we offer, you may not be
    able to store your preferences, and some of our pages might not display
    properly.
  </p>
  <p>
    For the Chrome web browser, please visit this page from Google:
    <a href="https://support.google.com/accounts/answer/32050">
      https://support.google.com/accounts/answer/32050
    </a>
  </p>
  <p>
    For the Internet Explorer web browser, please visit this page from
    Microsoft:
    <a href="http://support.microsoft.com/kb/278835">
      http://support.microsoft.com/kb/278835
    </a>
  </p>
  <p>
    For the Firefox web browser, please visit this page from Mozilla:
    <a
      href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored"
    >
      https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored
    </a>
  </p>
  <p>For the Safari web browser, please visit this page from Apple:</p>
  <p>
    <a
      href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
    >
      https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
    </a>
  </p>
  <p>
    For any other web browser, please visit your web browser's official web
    pages.
  </p>
  <h2>Changing or Withdrawing Consent</h2>
  <p>
    You can at any time change or withdraw your consent for the collection of
    cookies by changing the settings within your particular browser. If you
    choose to reject cookies, you may still use the Service though your access
    to some functionality and areas may be restricted. As the means by which you
    can refuse cookies through your web browser controls vary from
    browser-to-browser, you should visit your browser’s help menu for more
    information. Collection of your data from our analytics cookies can be
    deleted. If cookies are deleted, the information collected prior to the
    preference change may still be used, however, we will stop using the
    disabled cookie to collect any further information from your user
    experience.
  </p>
  <h2>Where can you find more information about cookies</h2>
  <p>
    You can learn more about cookies and the following third-party websites:
  </p>
  <p>
    AllAboutCookies:
    <a href="http://www.allaboutcookies.org/">
      http://www.allaboutcookies.org/
    </a>
  </p>
  <p>
    Network Advertising Initiative:
    <a href="http://www.networkadvertising.org/">
      http://www.networkadvertising.org/
    </a>
  </p>
  <h2>Other Tracking Technologies</h2>
  <h3>Geolocation and Device Identifiers</h3>
  <p>
    If you access the Service from a mobile or other device, we may collect a
    unique device identifier assigned to that device ("UDID"), geolocation data,
    or other transactional information for that device.
  </p>
  <p>
    We may collect information about your geolocation when your computer or
    mobile or other type of device is set to provide geolocation information or
    from other information such as your IP address, GPS, or WiFi information.
    For example, when your computer or device’s GPS signal allows us to show you
    our nearby stores or to otherwise collect your geolocation information to
    the city you are located in when you visit or use the Service.
  </p>
  <h3></h3>
  <h3>Web Beacons</h3>
  <p>
    Cookies are not the only way to recognize or track visitors to a website. We
    may use other, similar technologies from time to time, like web beacons
    (sometimes called “tracking pixels” or “clear gifs”). These are tiny
    graphics files that contain a unique identifier that enable us to recognize
    when someone has visited our Service or opened an email that we have sent
    them. This allows us, for example, to monitor the traffic patterns of users
    from one page within our Service to another, to deliver or communicate with
    cookies, to understand whether you have come to our Service from an online
    advertisement displayed on a third-party website, to improve site
    performance, and to measure the success of email marketing campaigns. In
    many instances, these technologies are reliant on cookies to function
    properly, and so declining cookies will impair their functioning.
  </p>
  <h3>Flash Cookies/Local Shared Objects</h3>
  <p>
    Our Service may also use so-called “Flash Cookies” (also known as Local
    Shared Objects or “LSOs”) to, among other things, collect and store
    information about your use of our services, fraud prevention and for other
    site operations.
  </p>
  <p>
    If you do not want Flash Cookies stored on your computer, you can adjust the
    settings of your Flash player to block Flash Cookies by following the
    instructions at:
  </p>
  <p>
    http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html
  </p>
  <p>
    Please note that setting the Flash Player to restrict or limit acceptance of
    Flash Cookies may reduce or impede the functionality of some Flash
    applications, including, potentially, Flash applications used in connection
    with our services or online content.
  </p>
</div>
