import swal from 'sweetalert2';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';
import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import { Subject } from 'rxjs';
import { CampaignKeysName } from '@suzy/shared/data-access/tracking';
import { AuthenticationService } from '@suzy/crowdtap/data-access/user';
import { ActivatedRoute, RouterStateSnapshot, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef;

  campaignKeysName: CampaignKeysName;
  currentSource: any;
  isPassSubmission: boolean;
  isFailSubmission: boolean;
  lostForm: UntypedFormGroup;
  el_wrapper: HTMLElement;
  submitted = false;
  isLoading = false;
  sourceUrl: string;
  subject: Subject<any>;
  currentCampaignKeys: any;
  skipLinkPathContent: string;
  skipLinkPathFooter: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private sdk: SuzySdkService,
    private auth: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.setHeadTitle();
    this.skipLinkPathContent = `${this.router.url}#main-content`;
    this.skipLinkPathFooter = `${this.router.url}#main-footer`;
    this.el_wrapper = this.wrapper.nativeElement as HTMLElement;
    this.isPassSubmission = false;
    this.isFailSubmission = false;
    this.lostForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
    this.subject = new Subject<any>();
  }

  get f() {
    return this.lostForm.controls;
  }

  setHeadTitle(): any {
    this.titleService.setTitle('Crowdtap® - Forgot Password');
  }

  fieldErrors(name: string): any {
    const login_fields = this.lostForm.get(name);
    if (
      login_fields &&
      (login_fields.touched || this.submitted) &&
      login_fields.errors
    ) {
      return login_fields.errors;
    } else {
      return undefined;
    }
  }

  successAnimation(): void {
    this.isPassSubmission = true;
  }

  isFieldTouched(field): boolean {
    return this.lostForm.get(field).touched;
  }

  hasValue(field): boolean {
    return this.lostForm.get(field).value;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.lostForm.invalid) {
      return;
    }
    this.isLoading = true;
    const input = this.lostForm.value;
    this.sdk.ProtocolAuthorize.passwordResetStart(input).subscribe(
      data => {
        this.isLoading = false;

        if (data.success) {
          this.successAnimation();
        } else {
          swal.fire({
            buttonsStyling: false,
            confirmButtonText: 'Continue',
            confirmButtonClass: 'btn-confirm',
            customClass: 'general-modal',
            showConfirmButton: true,
            text: data.message,
            title: 'Error',
            type: 'warning'
          });
        }
      },
      error => {
        this.isLoading = false;
        swal.fire({
          buttonsStyling: false,
          confirmButtonText: 'Continue',
          confirmButtonClass: 'btn-confirm',
          customClass: 'general-modal',
          showConfirmButton: true,
          text: error,
          title: 'Error',
          type: 'warning'
        });
      }
    );
  }

  onBackToTop(): void {
    this.el_wrapper.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    });
  }

  onOpenSignUpModal(): void {
    this.subject.next();
  }
}
