import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { Gender } from '@suzy/shared/data-access/suzy-sdk';
import {
  ISignupUser,
  SignupService
} from '../../../../views/anonymous/signup/signup.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'get-to-know-you',
  templateUrl: './get-to-know-you.component.html',
  styleUrls: ['./get-to-know-you.component.scss']
})
export class GetToKnowYouComponent implements OnInit, AfterViewInit {
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef;
  getToKnowYouForm: UntypedFormGroup;
  submitted = false;
  Gender = Gender;
  user: ISignupUser;
  minBirthdate: NgbDateStruct = { year: 1910, month: 1, day: 1 };
  submitting = false;
  isLoading: boolean;
  el_wrapper: HTMLElement;

  private storage: Storage;

  constructor(
    private fb: UntypedFormBuilder,
    private signupService: SignupService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.storage = localStorage;
    this.user = this.signupService.getUser();

    this.createForm();
  }

  ngAfterViewInit(): void {
    this.el_wrapper = this.wrapper.nativeElement as HTMLElement;
  }

  createForm(): void {
    this.getToKnowYouForm = this.fb.group({
      first_name: [this.user.first_name, Validators.required],
      last_name: [this.user.last_name, Validators.required],
      email: [this.user.email, [Validators.required, Validators.email]]
    });
  }

  fieldErrors(name: string): any {
    const control = this.getToKnowYouForm.get(name);

    if (control && (control.touched || this.submitted) && control.errors) {
      return control.errors;
    } else {
      return undefined;
    }
  }

  returnToEmailCreate(): void {
    this.router.navigateByUrl('/auth/account-create-email');
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.getToKnowYouForm.valid) {
      const account = this.getToKnowYouForm.value;
      this.isLoading = true;

      this.signupService.setUser({
        ...this.user,
        first_name: account.first_name,
        last_name: account.last_name,
        email: account.email,
        progressStatus: 45
      });

      this.router.navigate(['auth', 'about-yourself']);
    }
  }
}
