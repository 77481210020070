import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'notice-of-financial-incentive-policy-current',
  templateUrl: './notice-of-financial-incentive-policy-current.component.html',
  styleUrls: ['./notice-of-financial-incentive-policy-current.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoticeOfFinancialIncentivePolicyCurrentComponent
  implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
