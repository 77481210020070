<footer [ngClass]="{ 'dark-mode': isMobile }">
  <div class="footer-container">
    <div class="left">
      <div *ngIf="!isMobile; else showWhiteLogo" class="logo-crowdtap">
        <a routerLink="/">
          <img
            src="/assets/img/svg/logo/ic-crowdtap-logo-footer.svg"
            alt="Black Crowdtap logo"
          />
        </a>
      </div>
      <ng-template #showWhiteLogo>
        <div class="logo-crowdtap">
          <img
            src="/assets/img/svg/logo/ic-crowdtap-logo-white.svg"
            alt="White Crowdtap logo"
          />
        </div>
      </ng-template>

      <div class="footer-nav">
        <a href="https://support.crowdtap.com/" target="_blank">{{
          'nav.help' | translate
        }}</a>
        <a routerLink="/terms-of-service">{{
          'nav.termsOfService' | translate
        }}</a>
        <a routerLink="/privacy-policy">{{
          'nav.privacyPolicy' | translate
        }}</a>
      </div>
    </div>
    <div class="footer-social-links" *ngIf="!isMobile; else showWhiteIcons">
      <a href="http://facebook.com/Crowdtap" target="_blank">
        <img
          src="/assets/img/svg/icons/ic-facebook-black.svg"
          alt="Black Facebook icon"
        />
      </a>
      <a href="http://twitter.com/Crowdtap" target="_blank">
        <img
          src="/assets/img/svg/icons/ic-twitter-black.svg"
          alt="Black Twitter icon"
        />
      </a>
      <a href="http://instagram.com/crowdtap" target="_blank">
        <img
          src="/assets/img/svg/icons/ic-instagram-black.svg"
          alt="Black Instagram icon"
        />
      </a>
    </div>

    <ng-template #showWhiteIcons>
      <div class="footer-social-links">
        <a href="http://facebook.com/Crowdtap" target="_blank">
          <img
            src="/assets/img/svg/icons/ic-fb-circle-white.svg"
            alt="White Facebook icon"
          />
        </a>
        <a href="http://twitter.com/Crowdtap" target="_blank">
          <img
            src="/assets/img/svg/icons/ic-twitter-circle-white.svg"
            alt="White Twitter icon"
          />
        </a>
        <a href="http://instagram.com/crowdtap" target="_blank">
          <img
            src="/assets/img/svg/icons/ic-instagram-circle-white.svg"
            alt="White Instragram icon"
          />
        </a>
      </div>
    </ng-template>
  </div>
  <div class="copyright">
    <small
      [innerHTML]="'general.footerCopyright' | translate: { year: currentYear }"
    ></small>
  </div>
</footer>
