import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-invalid-browser',
  templateUrl: './invalid-browser.component.html',
  styleUrls: ['./invalid-browser.component.scss']
})
export class InvalidBrowserComponent implements AfterViewInit {
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef;
  el_wrapper: HTMLElement;

  ngAfterViewInit(): void {
    this.el_wrapper = this.wrapper.nativeElement as HTMLElement;
  }

  onBackToTop(): void {
    this.el_wrapper.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    });
  }
}
