import { Injectable } from '@angular/core';
import {
  ActionKind,
  ActionStructureGridKind,
  ActionStructureMultipleChoiceKind,
  MissionKind
} from '@asksuzy/typescript-sdk';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalIsolateSdkService } from '@suzy/shared/data-access/global-isolate-sdk';
import { SegmentService } from '@suzy/shared/data-access/tracking';
import { Subject } from 'rxjs';
import { Markdown2HtmlPipe } from '../../shared/pipes/markdown2html.pipe';
import { PreviewCoverPrerequisiteComponent } from './shared/preview-cover-prerequisite/preview-cover-prerequisite.component';
import { PreviewEndingComponent } from './shared/preview-ending/preview-ending.component';
import { PreviewInfoViewComponent } from './shared/preview-info-view/preview-info-view.component';
import { PreviewNotEligibleComponent } from './shared/preview-not-eligible/preview-not-eligible.component';

@Injectable()
export class PreviewService {
  currentAction$: Subject<any> = new Subject();
  restartMission$: Subject<any> = new Subject();
  runAction$: Subject<any> = new Subject();

  prerequisiteActionsAnswers = new Array();
  prerequisiteActions = new Array();
  firstActionAfterPrerequisite: any;
  disablePreviewControls: boolean;
  actionsList = [];
  hidePriorityNumber: boolean;
  activeSurveyPanel: boolean;

  constructor(
    private modals: NgbModal,
    private segmentService: SegmentService,
    private markdown2HtmlPipe: Markdown2HtmlPipe,
    private isolateSDK: GlobalIsolateSdkService
  ) {}

  getTitleByActionType(action): string {
    switch (action.action_kind) {
      case ActionKind.multiplechoice:
        if (action.is_legal_consent) {
          return 'Legal Consent';
        }
        if (action.multiple_choice?.expected_answers_enabled) {
          return 'Screener';
        }
        if (
          action.multiple_choice?.multiple_choice_kind ===
          ActionStructureMultipleChoiceKind.monadic
        ) {
          return 'Rating';
        } else {
          return 'Multiple Choice';
        }
      case ActionKind.openended:
        return 'Text Open End';
      case ActionKind.photoacquisition:
        return 'Photo';
      case ActionKind.grid:
        return 'Grid';
      case ActionKind.gridcustom:
        return 'Custom Grid';
      case ActionKind.gridrankscale:
        if (
          action.action_kind === ActionKind.gridrankscale &&
          action.grid.grid_kind === ActionStructureGridKind.scale
        ) {
          return 'Scale';
        } else {
          return 'Rank';
        }
      case ActionKind.maxdiff:
        return 'MaxDiff';
      case ActionKind.auto_assign:
        return 'Auto-Assign';
      case ActionKind.turf:
        return 'TURF';
      default:
        break;
    }
  }

  showPreviewInfoComponent(message): void {
    let endingModal = this.modals.open(PreviewInfoViewComponent, {
      windowClass: 'preview-mission-container preview-info',
      container: '#previewMission',
      backdrop: false
    });

    const endingComponent =
      endingModal.componentInstance as PreviewInfoViewComponent;
    endingComponent.errorBarMessage = message;
  }

  showPreviewEndingComponent(message): Subject<any> {
    const subject = new Subject<any>();
    let endingModal = this.modals.open(PreviewEndingComponent, {
      windowClass: 'preview-mission-container preview-info app-ending',
      container: '#previewMission',
      backdrop: false
    });
    this.activeSurveyPanel = true;

    const endingComponent =
      endingModal.componentInstance as PreviewEndingComponent;
    endingComponent.errorBarMessage = message;

    endingModal.result.then(
      data => {},
      reason => {
        if (reason) {
          subject.next('refresh');
        }
      }
    );
    return subject;
  }

  showNotEligibleComponent(): Subject<any> {
    const subject = new Subject<any>();
    let endingModal = this.modals.open(PreviewNotEligibleComponent, {
      windowClass: 'preview-mission-container preview-info app-ending',
      container: '#previewMission',
      backdrop: false
    });

    const endingComponent =
      endingModal.componentInstance as PreviewNotEligibleComponent;

    endingModal.result.then(
      data => {},
      reason => {
        if (reason) {
          subject.next('refresh');
        }
      }
    );

    return subject;
  }

  missionHasPiping(steps: Array<any>): boolean {
    const actionsWithPiping = steps.filter(
      step => step.first_action.calc_has_piping
    );

    return actionsWithPiping.length > 0 ? true : false;
  }

  getPipingRoute(action: any): any {
    switch (action.action_kind) {
      case ActionKind.openended:
        return action.open_ended.piping[0].pipe_route;
      case ActionKind.multiplechoice:
      case ActionKind.auto_assign:
        return action.multiple_choice.piping[0].pipe_route;
      case ActionKind.grid:
      case ActionKind.gridcustom:
      case ActionKind.gridrankscale:
        return action.grid.piping[0].pipe_route;
      default:
        break;
    }
  }

  getPipingData(action: any): any {
    switch (action.action_kind) {
      case ActionKind.openended:
        return action.open_ended.piping[0].pipe_data;
      case ActionKind.multiplechoice:
      case ActionKind.auto_assign:
        return action.multiple_choice.piping[0].pipe_data;
      case ActionKind.grid:
      case ActionKind.gridcustom:
      case ActionKind.gridrankscale:
        return action.grid.piping[0].pipe_data;
      default:
        break;
    }
  }

  getPipingQuestion(currentStep: any, actionsByMission: Array<any>): any {
    let pipingQuestion;
    if (
      this.prerequisiteActions?.length > 0 &&
      currentStep.first_action.mission_id !==
        currentStep.pipeRoute.source_mission_id
    ) {
      pipingQuestion = this.prerequisiteActions.filter(
        action => action.action_id === currentStep.pipeRoute.source_action_id
      )[0];
      if (pipingQuestion) {
        pipingQuestion.actionText = this.markdown2HtmlPipe.transform(
          pipingQuestion.multiple_choice?.question,
          {
            caller: currentStep.pipeRoute.source_mission_id,
            secure: true,
            hideIcon: true,
            hidePriority: this.hidePriorityNumber
          }
        );
      }
    } else {
      pipingQuestion = actionsByMission.filter(
        action =>
          action.first_action.action_id ===
          currentStep.pipeRoute.source_action_id
      )[0];

      if (pipingQuestion) {
        pipingQuestion.actionText = this.markdown2HtmlPipe.transform(
          pipingQuestion.first_action.search_text,
          {
            caller: currentStep.pipeRoute.source_mission_id,
            secure: true,
            hideIcon: true,
            hidePriority: this.hidePriorityNumber
          }
        );
      }
    }
    return pipingQuestion;
  }

  showPreviewCoverPrerequisite(mission: any): Subject<any> {
    const subject = new Subject<any>();
    let coverModal = this.modals.open(PreviewCoverPrerequisiteComponent, {
      windowClass: 'preview-mission-container preview-info app-ending',
      container: '#previewMission',
      backdrop: false
    });
    this.disablePreviewControls = false;
    this.activeSurveyPanel = false;

    const coverPrerequisteComponent =
      coverModal.componentInstance as PreviewCoverPrerequisiteComponent;
    coverModal.result.then(
      response => {
        if (response) {
          subject.next(response);
        } else {
          return;
        }
      },
      () => {
        this.trackMissionStarted(
          'Preview Mission Started',
          mission,
          'preview_prerequisite_button'
        );
        subject.next('start');
        return;
      }
    );

    return subject;
  }

  addPrerequisiteActionAnswer(action: any): void {
    this.prerequisiteActionsAnswers.push(action);
  }

  undoPrerequisiteActionAnswer(): void {
    if (this.prerequisiteActionsAnswers.length > -1) {
      this.prerequisiteActionsAnswers.pop();
    }
  }

  restartPrerequisiteActionAnswer(): void {
    this.prerequisiteActionsAnswers = new Array();
  }

  submitPrerequisite(input: any, mission: any): Subject<any> {
    const subject = new Subject<any>();
    this.addPrerequisiteActionAnswer(input);

    const answeredActions = this.prerequisiteActionsAnswers;
    if (answeredActions.length < this.prerequisiteActions.length) {
      const action = this.prerequisiteActions[answeredActions.length];
      mission.first_action = action;
      mission.first_action.is_prerequisite = true;
      this.currentAction$.next(action);
      this.runAction$.next(mission);
    } else {
      // No more prerequisites. Save answers and get first action from main survey
      this.isolateSDK.Mission.submitMissionPrerequisitesAsync({
        inputs: answeredActions
      }).subscribe(response => {
        if (response.success) {
          response.restartAfterPrerequisite = true;
          this.firstActionAfterPrerequisite = response.item;
          subject.next(response);
          setTimeout(() => {
            this.restartMission$.next('restartAfterPrerequisites');
          }, 1000);
        } else {
          this.undoPrerequisiteActionAnswer();
          subject.next(response);
        }
      });
    }
    return subject;
  }

  setAutoAssignData(actions: Array<any>, mission: any): any {
    const actionsList = [];
    let autoAssign;

    actions.forEach(action => {
      let pipeRoute;
      let pipeData;
      const kind = action.first_action.action_kind;
      const question = (action.question = this.markdown2HtmlPipe.transform(
        action.first_action.search_text,
        {
          caller: mission.mission_id,
          view: action.first_action.action_id,
          secure: true,
          hideIcon: true,
          hidePriority: this.hidePriorityNumber
        }
      ));
      if (action.calc_has_piping) {
        pipeRoute = this.getPipingRoute(action.first_action);
        pipeData = this.getPipingData(action.first_action);
      }

      const currentAction = {
        action,
        kind,
        action_id: action.first_action.action_id,
        priority: action.priority,
        step_id: action.step_id,
        question: action.search_text,
        hasPiping: mission.has_piping,
        pipeRoute,
        pipeData
      };

      actionsList.push(currentAction);
    });

    this.actionsList = [...actionsList];
    const autoAssignIndex = this.actionsList.findIndex(
      x => x.kind === ActionKind.auto_assign
    );
    if (autoAssignIndex === -1) {
      autoAssign = undefined;
    } else {
      autoAssign = this.actionsList.splice(autoAssignIndex, 1)[0].action;
    }
    return autoAssign;
  }

  rankOrdinal(value: number): string {
    const lastTwoDigits = Math.floor(value % 100);
    const lastDigit = Math.floor(value % 10);

    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
      return 'TH';
    }

    switch (lastDigit) {
      case 1:
        return 'ST';
      case 2:
        return 'ND';
      case 3:
        return 'RD';
      default:
        return 'TH';
    }
  }

  addPipingBackticks(question: string): string {
    // Replacement then reverse to catch correct and incorrect options
    question = question.replace(
      /`(\{{0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}\}{0,1}:\{{0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}\}{0,1}:\d)`/,
      '$1'
    );
    question = question.replace(
      /(\{{0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}\}{0,1}:\{{0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}\}{0,1}:\d)/,
      '`$1`'
    );

    return question;
  }

  hasPreviewMissionContainer(): boolean {
    const coverTag = document.getElementsByClassName(
      'preview-mission-container'
    );
    return document.getElementById('previewMission').contains(coverTag[0]);
  }

  trackMissionStarted(name: string, mission: any, clickPath: string) {
    this.segmentService.trackPreviewMission(name, {
      action_count: mission.action_count,
      brand_id: mission.brand_id,
      has_cover: true,
      mission_id: mission.mission_id,
      mission_kind: mission.mission_kind,
      preview_device: 'desktop',
      click_path: clickPath,
      monadic_variant_quantity: mission.monadic_enabled
        ? mission.original_monadic_variants.length
        : undefined
    });
  }

  getMaxdiffLeastMostLabel(found: any, steps): string {
    if (!found || !found?.auto_assign) {
      return '';
    }

    const { auto_assign } = found;
    let maxDiffParentAction = steps.find(
      x =>
        x.step_id === found.parent_step_id ||
        x.action?.step_id === found.parent_step_id
    );

    if (!maxDiffParentAction) {
      return '';
    }

    if (maxDiffParentAction?.first_action) {
      maxDiffParentAction = maxDiffParentAction.first_action;
    }

    if (!maxDiffParentAction?.maxdiff) {
      return '';
    }

    const {
      low_value_answer_id,
      low_value_ui_text,
      high_value_answer_id,
      high_value_ui_text
    } = maxDiffParentAction.maxdiff;

    if (auto_assign.source_metric_id === low_value_answer_id) {
      return low_value_ui_text;
    } else if (auto_assign.source_metric_id === high_value_answer_id) {
      return high_value_ui_text;
    }

    return '';
  }
}
