import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import { AuthenticationService } from '@suzy/crowdtap/data-access/user';
@Injectable()
export class BlockedIPGuard implements CanActivate, OnDestroy {
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private suzySDK: SuzySdkService
  ) {}

  canActivate(): boolean {
    let activate = true;
    if (this.authService.isAuthenticated()) {
      this.suzySDK.ProtocolAuthorize.getSelf(true)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          response => (activate = true),
          error => {
            if (error === 'Access denied') {
              activate = true;
            }

            switch (error.error_code) {
              case 201:
                this.router.navigate(['/auth/country-not-supported']);
                activate = false;
                break;
              case 202:
                this.router.navigate(['/auth/blocked-ip']);
                activate = false;
                break;
              case 203:
                this.router.navigate(['/auth/invalid-browser']);
                activate = false;
                break;
              default:
                break;
            }
          }
        );
    }
    return activate;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
