import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import { AuthenticationService } from '@suzy/crowdtap/data-access/user';

@Injectable()
export class CountriesResolver implements Resolve<Observable<any>> {
  constructor(
    private sdk: SuzySdkService,
    private router: Router,
    private auth: AuthenticationService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this.sdk.Country.findAsync().pipe(
      map((response: any) => {
        if (response.success) {
          return response.items;
        } else {
          throw new Error(response.message);
        }
      }),
      retry(5),
      catchError(error => {
        window.location.href = this.router
          .parseUrl(this.router.url)
          .root.children['primary'].segments.map(it => it.path)
          .join('/');

        return of({ error });
      })
    );
  }
}
