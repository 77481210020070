<div class="share-social-media-links">
  <p>{{ 'share.shareTo' | translate }}</p>
  <ul>
    <li *ngIf="isFacebookEnabled">
      <a
        href="javascript:;"
        title="Share on Facebook"
        (click)="shareToFacebook()"
      >
        <span class="icon-facebook">
          <img src="assets/img/svg/social/facebook.svg" alt="Facebook icon" />
        </span>
      </a>
    </li>
    <li *ngIf="isTwitterEnabled">
      <a
        [href]="getTweetUrl()"
        title="Share on Twitter"
        target="_blank"
        (click)="shareToTwitter()"
      >
        <span class="icon-twitter">
          <img src="assets/img/svg/social/twitter.svg" alt="Twitter icon" />
        </span>
      </a>
    </li>
    <!-- <li>
      <a href="javascript:;" title="Share on instagram">
        <span class="icon-instagram">
          <img src="assets/img/svg/social/instagram.svg" alt="Instagram icon">
        </span>
      </a>
    </li> -->
  </ul>
</div>
