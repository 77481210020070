<div class="action-modal-container">
  <suzy-spinner *ngIf="isSubmitting"></suzy-spinner>

  <app-action-modal-header
    [title]="'address.chooseAddress' | translate"
    [mission]="mission"
  ></app-action-modal-header>

  <!-- Action Modal Question Starts -->
  <div class="action-modal-question">
    <h3>{{ 'address.chooseShippingAddress' | translate }}</h3>
  </div>
  <!-- Action Modal Question Ends -->

  <!-- Action Modal Body Starts -->
  <div class="modal-body action-modal-body" aria-label="main" role="main">
    <div class="add-shipping-address-form-container" *ngIf="isFormVisible">
      <form [formGroup]="addressForm" (ngSubmit)="saveAddress()">
        <div class="form">
          <section>
            <fieldset>
              <legend>{{ 'address.addNewAddress' | translate }}</legend>
              <div class="form-row">
                <div class="col-12">
                  <div class="form-group">
                    <label>{{ 'address.addressLineOne' | translate }}</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      formControlName="address1"
                    />
                    <small
                      *ngIf="fieldErrors('address1')"
                      class="form-text text-muted danger"
                    >
                      <span *ngIf="fieldErrors('address1').server">
                        {{ fieldErrors('address1').server }}
                      </span>
                      <span *ngIf="fieldErrors('address1').required">
                        {{ 'address.addressIsRequired' | translate }}
                      </span>
                    </small>
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="col-12">
                  <div class="form-group">
                    <label>{{ 'address.addressLineTwo' | translate }}</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      formControlName="address2"
                    />
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="col-12">
                  <div class="form-group">
                    <label>{{ 'address.city' | translate }}</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="city"
                    />
                    <small
                      *ngIf="fieldErrors('city')"
                      class="form-text text-muted danger"
                    >
                      <span *ngIf="fieldErrors('city').server">
                        {{ fieldErrors('city').server }}
                      </span>
                      <span *ngIf="fieldErrors('city').required">
                        {{ 'address.cityIsRequired' | translate }}
                      </span>
                    </small>
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="col-12">
                  <div class="form-group">
                    <label>{{ 'address.country' | translate }}</label>
                    <div class="custom-select">
                      <select class="form-control" formControlName="country_id">
                        <option></option>
                        <option
                          *ngFor="let country of countries"
                          [value]="country.country_id"
                        >
                          {{ country.country_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="col-12">
                  <div class="form-group">
                    <div class="row ml-0 mr-0">
                      <div class="col-6 pl-0">
                        <label>{{ 'address.state' | translate }}</label>
                        <div class="custom-select">
                          <select
                            class="form-control"
                            formControlName="state_id"
                          >
                            <option></option>
                            <option
                              *ngFor="let state of states"
                              [value]="state.id"
                            >
                              {{ state.name }}
                            </option>
                          </select>
                        </div>
                        <small
                          *ngIf="fieldErrors('state_id')"
                          class="form-text text-muted danger"
                        >
                          <span *ngIf="fieldErrors('state_id').server">
                            {{ fieldErrors('state_id').server }}
                          </span>
                          <span *ngIf="fieldErrors('state_id').required">
                            {{ 'general.required' | translate }}
                          </span>
                        </small>
                      </div>
                      <div class="col-6 pl-0 pr-0">
                        <label>{{ 'address.zipCode' | translate }}</label>
                        <input
                          type="text"
                          class="form-control"
                          formControlName="zip_code"
                        />
                        <small
                          *ngIf="fieldErrors('zip_code')"
                          class="form-text text-muted danger"
                        >
                          <span *ngIf="fieldErrors('zip_code').server">
                            {{ fieldErrors('zip_code').server }}
                          </span>
                          <span *ngIf="fieldErrors('zip_code').required">
                            {{ 'address.zipCodeIsRequired' | translate }}
                          </span>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="col-12">
                  <div class="form-group">
                    <label>{{ 'address.phone' | translate }}</label>
                    <input
                      appPhoneInput
                      type="text"
                      class="form-control"
                      formControlName="phone"
                    />
                    <small
                      *ngIf="fieldErrors('phone')"
                      class="form-text text-muted danger"
                    >
                      <span *ngIf="fieldErrors('phone').server">
                        {{ fieldErrors('phone').server }}
                      </span>
                      <span *ngIf="fieldErrors('phone').invalidPhone">
                        {{ 'address.phoneIsInvalid' | translate }}
                      </span>
                      <span *ngIf="fieldErrors('phone').required">
                        {{ 'address.phoneisRequired' | translate }}
                      </span>
                    </small>
                  </div>
                </div>
              </div>
            </fieldset>
          </section>

          <div class="builtin-form-footer">
            <div class="row">
              <div class="col-12">
                <button
                  type="button"
                  class="btn btn-light btn-light-lg"
                  (click)="cancel()"
                >
                  {{ 'general.cancel' | translate }}
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-primary-lg"
                  [disabled]="submitted && !addressForm.valid"
                >
                  {{ 'general.save' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="shipping-address-list" *ngIf="!isFormVisible">
      <div class="shipping-address-list-item">
        <div class="card card-add-item">
          <a (click)="addAddress()">
            <i class="icon icon-add">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" y1="8" x2="12" y2="16"></line>
                <line x1="8" y1="12" x2="16" y2="12"></line>
              </svg>
            </i>
            <span>{{ 'address.addAddress' | translate }}</span>
          </a>
        </div>
      </div>
      <!-- Add Address Button Card Ends -->

      <div
        class="shipping-address-list-item"
        *ngFor="let address of addressList"
      >
        <div class="card default">
          <div class="card-block">
            <div class="card-header">
              <span class="name"
                >{{ currentUser.first_name }} {{ currentUser.last_name }}</span
              >
            </div>
            <div class="card-body">
              <address>
                {{ address.address1 }}
                <ng-container *ngIf="address.address2">
                  <br />
                  {{ address.address2 }}
                </ng-container>
                <br />
                {{ address.city }}, {{ address.state_short }}
                {{ address.zip_code }} <br />
                {{ 'address.unitedStates' | translate }} <br />
                {{ 'address.phoneNumber' | translate }}:
                {{ address.phone }}
              </address>
            </div>
            <div class="card-footer">
              <div>
                <button class="btn btn-link" (click)="editAddress(address)">
                  {{ 'general.edit' | translate }}
                </button>
                <button
                  class="btn btn-tertiary"
                  [ngClass]="{ selected: isCurrentAddress(address) }"
                  (click)="selectAddress(address)"
                >
                  {{ 'address.useThisAddress' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group form-cta">
    <div class="row">
      <div class="col-6" *ngIf="allowSkipping">
        <button
          class="btn form-btn form-btn-secondary text-uppercase"
          (click)="skip($event)"
          [disabled]="disableSkip"
        >
          {{ 'general.skip' | translate }}
        </button>
      </div>
      <div [ngClass]="{ 'col-12': !allowSkipping, 'col-6': allowSkipping }">
        <button
          class="btn form-btn form-btn-primary form-btn-with-arrow text-uppercase"
          [disabled]="!selectedAddress.user_address_id || isSubmitting"
          (click)="submit()"
        >
          {{ 'general.continue' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
