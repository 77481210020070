import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrerollComponent } from './preroll/preroll.component';
import { TranslatePipe, TranslateModule } from '@ngx-translate/core';
import { SecondaryTriggerComponent } from './secondary-trigger/secondary-trigger.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedToolsUrlModule } from '@suzy/shared/tools/url';

@NgModule({
  imports: [CommonModule, NgbModule, TranslateModule, SharedToolsUrlModule],
  declarations: [PrerollComponent, SecondaryTriggerComponent],
  exports: [PrerollComponent, SecondaryTriggerComponent, SharedToolsUrlModule],
  providers: [TranslatePipe]
})
export class SharedActionsFeaturePrerollModule {}
