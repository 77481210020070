<div #wrapper>
  <app-general-header></app-general-header>

  <section aria-label="main" role="main" id="main-content">
    <article class="container">
      <div class="center-column">
        <h1 class="text-light-black dark:text-white">
          {{ 'unauthorized.accountDisabled' | translate }}
        </h1>
        <ng-container *ngIf="reason === 'Auth.Login.disabled'">
          <p class="text-light-black dark:text-white">
            {{ 'unauthorized.accountFlaggedDisabled' | translate }}
            <a
              href="https://support.crowdtap.com/hc/en-us/articles/16762298155284"
              target="_blank"
              >{{ 'unauthorized.clickHereQuality' | translate }}</a
            >.
          </p>
        </ng-container>
        <ng-container *ngIf="reason === 'Auth.Login.banned'">
          <p class="text-light-black dark:text-white">
            {{ 'unauthorized.accountFlaggedBanned' | translate }}
            <a
              href="https://support.crowdtap.com/hc/en-us/articles/16762298155284"
              target="_blank"
              >{{ 'unauthorized.clickHereQuality' | translate }}</a
            >.
          </p>
        </ng-container>

        <ng-container *ngIf="!reason">
          <p class="text-light-black dark:text-white">
            {{ 'unauthorized.youreUnableToLogInBecause' | translate }}
            <a routerLink="/terms-of-service" target="_blank">{{
              'nav.termsOfService' | translate
            }}</a
            >.
          </p>

          <p
            class="text-light-black dark:text-white"
            [innerHTML]="
              'unauthorized.ifYoudLikeToAppealToCrowdtap' | translate
            "
          ></p>

          <a
            href="http://support.crowdtap.com/"
            class="submit-btn"
            target="_blank"
          >
            {{ 'support.contactSupport' | translate }}
          </a>
        </ng-container>
      </div>
    </article>
  </section>

  <app-general-footer> </app-general-footer>
</div>
