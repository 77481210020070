import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from './spinner/spinner.component';
import { TranslatePipe, TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [SpinnerComponent],
  exports: [SpinnerComponent],
  providers: [TranslatePipe]
})
export class SharedUiSpinnerModule {}
