import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output
} from '@angular/core';

@Component({
  selector: 'app-cookie-notification',
  templateUrl: './cookie-notification.component.html',
  styleUrls: ['./cookie-notification.component.scss']
})
export class CookieNotificationComponent implements OnInit {
  @Output() ateTheCookies = new EventEmitter();
  isSidebarVisible = false;

  ngOnInit(): void {
    this.checkSidebar();
  }

  @HostListener('window:resize', ['$event'])
  windowResize($event): void {
    this.checkSidebar();
  }

  checkSidebar(): void {
    this.isSidebarVisible = !$('.app-sidebar').hasClass('hide-sidebar');
  }

  onGotItClick(): void {
    this.ateTheCookies.emit();
  }
}
